

























































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import { DataTableHeader } from 'vuetify';
import { LoginService } from '@/types/alligate';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';

export interface TypeItem {
  title: string;
}
export interface DataType {
  desserts: Type[];
  check: boolean;
  input: null;
  isSubmit: boolean;
}
interface Type {
  name: string;
  isValid: boolean;
}
export default Vue.extend({
  name: 'ExternalLoginSetting',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
  },
  created() {
    this.$emit('isGetFederatedLoginService');
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    oneLoginLoginService: {
      type: Object,
      default() {
        return {};
      },
    },
    appleIdLoginService: {
      type: Object,
      default() {
        return {};
      },
    },
    googleLoginService: {
      type: Object,
      default() {
        return {};
      },
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    isSamlFederatedLoginService: {
      type: String,
      default: 'false',
    },
    isFederatedLoginService: {
      type: String,
      default: 'false',
    },
    allowSubmit: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '能時間設定',
            align: 'start',
            sortable: true,
            value: 'name',
            class: 'grey',
          },
          {
            text: '詳細',
            align: 'start',
            sortable: true,
            value: 'isValid',
            class: 'grey',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
  },
  data: (): DataType => ({
    desserts: [
      {
        name: '能時間設定 1',
        isValid: true,
      },
      {
        name: '能時間設定 2',
        isValid: true,
      },
    ],
    check: true,
    input: null,
    isSubmit: false,
  }),
  watch: {
    //
  },
  methods: {
    checkIsChangeConfig() {
      if (this.googleLoginService.oldIsValid !== this.googleLoginService.isValid) {
        this.isSubmit = true;
      } else if (this.oneLoginLoginService.oldIsValid !== this.oneLoginLoginService.isValid ||
        this.oneLoginLoginService.oldMetadataUrl !== this.oneLoginLoginService.metadataUrl) {
        this.isSubmit = true;
      } else if (this.appleIdLoginService.oldIsValid !== this.appleIdLoginService.isValid) {
        this.isSubmit = true;
      } else {
        this.isSubmit = false;
      }
    },
  },
});
