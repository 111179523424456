


































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';

export default Vue.extend({
  name: 'NFCSetting',
  components: {
    InputForm,
  },
  props: {
    editStatusException: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    editStatus: {
      type: Boolean,
      default: false,
    },
    settingInfor: {
      type: Object,
      default: {},
    },
    listNfcSettingSelect: {
      default: [],
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    isFelica: {
      type: Boolean,
      default: false,
    },
    isMifare: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getEditStatus() {
      if (this.settingInfor.isOwnerCodeValid && (this.editStatusException === false)) {
        return false;
      } else {
        return true;
      }
    },
    checkIsFalica() {
      if (this.isFelica) {
        return true;
      } else {
        return false;
      }
    },
    checkIsMifare() {
      if (this.isMifare) {
        return true;
      } else {
        return false;
      }
    },
  },
});
