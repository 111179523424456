
















import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ModalLoading',
  components: {
    //
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
