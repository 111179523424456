var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "pt-0" }, [
    _c(
      "div",
      { staticClass: "allig-reflection-card-table" },
      [
        _c(
          "div",
          { staticClass: "round-action" },
          [
            _c(
              "v-row",
              { staticClass: "btn-div", attrs: { justify: "end" } },
              [
                _c("v-col", { attrs: { md: "4", xl: "3" } }, [
                  _c("div", { staticClass: "area-search-box" })
                ]),
                _vm._v(" "),
                _c(
                  "v-col",
                  [
                    _c(
                      "v-row",
                      { staticClass: "btn-div", attrs: { justify: "end" } },
                      [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "btn-add pt-0" },
                              [
                                _vm.authority.isAdmin === true ||
                                _vm.isAdminOfGatePassages === true
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "btn-second",
                                        attrs: {
                                          outlined: "",
                                          disabled:
                                            _vm.selectedItem.length === 0
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.itemDel = []
                                            _vm.isConfirm = true
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    チェックした一時通行権限を削除する\n                  "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              {
                staticClass: "ml-3",
                attrs: {
                  "no-gutters": "",
                  justify: "space-between",
                  "align-content": "center"
                }
              },
              [
                _c("show-per-page", {
                  attrs: {
                    "per-page": 1000,
                    page: 1,
                    "items-length": _vm.dataList.length
                  }
                }),
                _vm._v(" "),
                _c("v-spacer")
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-row",
          {
            staticClass: "btn-div area-search-box pl-0",
            attrs: { justify: "end" }
          },
          [
            _c("v-col", { staticClass: "pt-0", attrs: { sm: "12" } }, [
              _c("div", { staticClass: "round-data table-affiliation" }, [
                _c(
                  "div",
                  [
                    _c(
                      "v-data-table",
                      {
                        staticClass:
                          "border-table table-no-bg scroll-table customize-table-scroll table-time-of-group",
                        attrs: {
                          "mobile-breakpoint": 0,
                          "must-sort": "",
                          headers: _vm.headers,
                          items: _vm.dataList,
                          "items-per-page": -1,
                          "no-data-text": "データーはありません",
                          "loading-text": "データを読み込中です",
                          "hide-default-footer": "",
                          "show-select": true,
                          dense: "",
                          "item-key": "passageId",
                          loading: _vm.loading
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm.authority.isAdmin === false &&
                            _vm.isAdminOfGatePassages === false
                              ? {
                                  key: "header.data-table-select",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var props = ref.props
                                    return [
                                      _c(
                                        "v-simple-checkbox",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                color: "purple",
                                                disabled: ""
                                              }
                                            },
                                            "v-simple-checkbox",
                                            props,
                                            false
                                          ),
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              : null,
                            _vm.authority.isAdmin === false &&
                            _vm.isAdminOfGatePassages === false
                              ? {
                                  key: "item.data-table-select",
                                  fn: function(ref) {
                                    var item = ref.item
                                    var isSelected = ref.isSelected
                                    var select = ref.select
                                    return [
                                      _c("v-simple-checkbox", {
                                        attrs: {
                                          readonly: "",
                                          disabled: "",
                                          "aria-readonly": "",
                                          value: isSelected,
                                          ripple: false
                                        },
                                        on: {
                                          input: function($event) {
                                            return select($event)
                                          }
                                        }
                                      })
                                    ]
                                  }
                                }
                              : null,
                            {
                              key: "item.userId",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("span", { staticClass: "item-user-id" }, [
                                    _vm._v(_vm._s(item.userId))
                                  ])
                                ]
                              }
                            },
                            {
                              key: "item.icon",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "item-user-id" },
                                    [
                                      _vm.authority.isAdmin === true ||
                                      _vm.isAdminOfGatePassages === true
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: { icon: "", al: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteGateConfirm(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "gray--text",
                                                  attrs: {
                                                    "align-self": "ceb="
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-trash-can-outline"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.gateName",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("span", { staticClass: "item-user-id" }, [
                                    _vm._v(_vm._s(item.gate.text))
                                  ])
                                ]
                              }
                            },
                            {
                              key: "item.time",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatDateTime(
                                            new Date(item.notBefore * 1000)
                                          )
                                        ) +
                                        " ~ " +
                                        _vm._s(
                                          _vm.formatDateTime(
                                            new Date(item.notAfter * 1000)
                                          )
                                        )
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.selectedItem,
                          callback: function($$v) {
                            _vm.selectedItem = $$v
                          },
                          expression: "selectedItem"
                        }
                      },
                      [
                        _vm._v(" "),
                        _vm._v(" "),
                        _vm._v(" "),
                        _vm._v(" "),
                        _vm._v(" "),
                        _vm._v(" "),
                        _vm.authority.isAdmin === true ||
                        _vm.isAdminOfGatePassages === true
                          ? _c("template", { slot: "body.prepend" }, [
                              _c("tr", { staticClass: "mt-4" }, [
                                _c("td"),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "input-add",
                                      attrs: {
                                        items: _vm.gateList,
                                        "return-object": "",
                                        color: "green",
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "auto",
                                        "no-data-text": ""
                                      },
                                      model: {
                                        value: _vm.itemGate,
                                        callback: function($$v) {
                                          _vm.itemGate = $$v
                                        },
                                        expression: "itemGate"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("DateTimePicker", {
                                      attrs: {
                                        errorAddListGate: _vm.errorAddListGate,
                                        isUserPage: true,
                                        dateStartValue: _vm.dateStartGate,
                                        dateEndValue: _vm.dateEndGate,
                                        timeSValue: _vm.timeStartGate,
                                        timeEValue: _vm.timeEndGate
                                      },
                                      on: {
                                        getNewTimeStart: _vm.getNewTimeStart,
                                        getNewTimeEnd: _vm.getNewTimeEnd,
                                        getNewDateStart: _vm.getNewDateStart,
                                        getNewDateEnd: _vm.getNewDateEnd
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "input-add",
                                      attrs: {
                                        items: _vm.userList,
                                        "return-object": "",
                                        color: "green",
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "auto",
                                        "no-data-text": ""
                                      },
                                      model: {
                                        value: _vm.itemUser,
                                        callback: function($$v) {
                                          _vm.itemUser = $$v
                                        },
                                        expression: "itemUser"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "align-self-center" },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { justify: "center" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-secondary",
                                            attrs: {
                                              disabled:
                                                _vm.gateList.length > 0
                                                  ? false
                                                  : true,
                                              outlined: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.addGate()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    追加\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _vm.errorMessageLogs !== ""
                                ? _c("tr", [
                                    _c("td"),
                                    _vm._v(" "),
                                    _c("td", { attrs: { colspan: "5" } }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "error-special add hours"
                                        },
                                        [_vm._v(_vm._s(_vm.errorMessageLogs))]
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.errorAddListGate !== "" &&
                              _vm.errorGate === "" &&
                              _vm.errorTime === "" &&
                              _vm.errorMessageLogs === ""
                                ? _c("tr", [
                                    _c("td", { attrs: { colspan: "5" } }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "error-special add hours"
                                        },
                                        [_vm._v(_vm._s(_vm.errorAddListGate))]
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.errorAddListGate === "" &&
                              (_vm.errorGate !== "" || _vm.errorTime !== "")
                                ? _c("tr", [
                                    _c("td"),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "error-special add hours"
                                        },
                                        [_vm._v(_vm._s(_vm.errorGate))]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "error-special add end-time"
                                        },
                                        [_vm._v(_vm._s(_vm.errorTime))]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td"),
                                    _vm._v(" "),
                                    _c("td"),
                                    _vm._v(" "),
                                    _c("td")
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  1
                )
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("v-row", {
          staticStyle: { height: "20px" },
          attrs: { "no-gutters": "" }
        }),
        _vm._v(" "),
        _c("v-spacer"),
        _vm._v(" "),
        _c(
          "v-dialog",
          {
            attrs: { width: "600", persistent: "" },
            model: {
              value: _vm.showProgress,
              callback: function($$v) {
                _vm.showProgress = $$v
              },
              expression: "showProgress"
            }
          },
          [
            _c(
              "v-card",
              [
                _c("v-card-title", { staticClass: "background-color-green" }, [
                  _vm._v("関連付け削除")
                ]),
                _vm._v(" "),
                _c("v-divider"),
                _vm._v(" "),
                _c("v-row", {
                  staticStyle: { height: "5px" },
                  attrs: { "no-gutters": "" }
                }),
                _vm._v(" "),
                _vm.dataGatePass.total >= 0
                  ? _c(
                      "v-card-text",
                      { staticClass: "pa-4" },
                      [
                        _c(
                          "v-progress-linear",
                          {
                            staticStyle: { "pointer-events": "none" },
                            attrs: {
                              height: "25",
                              color: "green darken-1",
                              striped: ""
                            },
                            model: {
                              value: _vm.dataGatePass.progressLoad,
                              callback: function($$v) {
                                _vm.$set(_vm.dataGatePass, "progressLoad", $$v)
                              },
                              expression: "dataGatePass.progressLoad"
                            }
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  Math.round(_vm.dataGatePass.progressLoad)
                                ) + "%"
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _vm.dataGatePass.successItem > 0
                          ? _c("span", { staticClass: "success-label" }, [
                              _vm._v(
                                _vm._s(_vm.dataGatePass.total) +
                                  "件中" +
                                  _vm._s(_vm.dataGatePass.successItem) +
                                  " "
                              ),
                              _vm.type === "add"
                                ? _c("span", [_vm._v("件を追加しました。")])
                                : _c("span", [_vm._v("件を削除しました。")])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.dataGatePass.errorMessage.length > 0
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "error-box" },
                                [
                                  _vm._l(
                                    _vm.dataGatePass.errorMessage,
                                    function(item) {
                                      return [
                                        _c("p", {
                                          key: item,
                                          domProps: { innerHTML: _vm._s(item) }
                                        })
                                      ]
                                    }
                                  )
                                ],
                                2
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.dataGatePass.isFinish
                  ? _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-2", attrs: { justify: "end" } },
                          [
                            _c("success-button", {
                              attrs: { text: "OK" },
                              on: {
                                click: function($event) {
                                  return _vm.closeModalGate()
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("Modal", {
          attrs: {
            title: "一時通行権限",
            text: "一時通行権限を削除しますか？",
            smallScreen: "600",
            className: "common-modal"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("success-button", {
                    attrs: { text: "削除" },
                    on: {
                      click: function($event) {
                        return _vm.deleteTemporaryPermission()
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.isConfirm,
            callback: function($$v) {
              _vm.isConfirm = $$v
            },
            expression: "isConfirm"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }