




























import {Vue } from 'vue-property-decorator';
import GateLockDetailTemplate from '@/components/templates/GateLockDetailTemplate/GateLockDetailTemplate.vue';
import * as Config from '@/config';
const arrayDay = ['月曜', '火曜', '水曜', '木曜', '金曜', '土曜', '日曜'];
import store from '@/store';
export interface TypeDessert {
  name: string;
  isVaild: boolean;
}
export interface DataType {
  loading: boolean;
  successMessageLogs: string;
  errorMessageLogs: string;
  gateDetail: any;
  groupList: any;
  dataUnlockingTime: any;
  groupId: any;
  dayTypes: any;
  groupItemsOrigin: any;
  groupsAddFail: any;
  isFinishDetailGate: boolean;
  goBack: string;
  successMessageModal: string;
  errorMessageModal: string;
  showModal: boolean;
  showConfirm: boolean;
  isRemoteUnlockValid: boolean;
  loadingChild: boolean;
}
export default Vue.extend({
  name: 'GateDetailPage',
  components: {
    GateLockDetailTemplate,
  },
  props: {
    gateId: {
      type: String,
      default: '',
    },
    btnUnlock: {
      type: Boolean,
      default: false,
    },
    isAdminOfRemoteUnlock: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    loading: false,
    showConfirm: false,
    successMessageLogs: '',
    errorMessageLogs: '',
    gateDetail: {
      gateId: '',
      gateName: '',
      option1: '',
      option2: '',
      groups: [],
    },
    groupList: [],
    groupsAddFail: [],
    dataUnlockingTime: [],
    groupItemsOrigin: [],
    dayTypes: [],
    isFinishDetailGate: false,
    groupId: '',
    goBack: 'gates',
    showModal: false,
    successMessageModal: '',
    errorMessageModal: '',
    isRemoteUnlockValid: false,
    loadingChild: false,
  }),
  mounted() {
    const dataQuery = this.$route.query;
    if (this.gateId !== undefined && this.gateId !== '') {
      this.loadingChild = true;
      this.groupList = [];
      this.getGateData(this.gateId)
        .then(() => {
          this.loadingChild = false;
        });
    }
    // 権限確認
    const authority = store.state.auth;
    // 0 組織管理権限 1 所属管理権限あり 2 通行権限グループ通行権限あり 3 権限なし
    if (authority.isAdminOfRemoteUnlock) {
      const remoteUnlock = localStorage.getItem('remoteUnlock');
      if (remoteUnlock === 'true') {
        // 表示の変数
        this.isRemoteUnlockValid = true;
      } else {
        this.isRemoteUnlockValid = false;
      }
    } else {
      this.isRemoteUnlockValid = true;
    }
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
    gateId(value: any) {
      if (value !== '') {
        this.loadingChild = true;
        this.groupList = [];
        this.getGateData(this.gateId)
          .then(() => {
            this.loadingChild = false;
          });
      }
    },
  },
  methods: {
    remoteUnlock() {
      this.loading = true;
      this.successMessageModal  =  '';
      this.errorMessageModal = '';
      const dataQuery = this.$route.query;
      // const gateId =  dataQuery.gateId.toString();
      const gateId = this.gateDetail.gateId;
      this.$http.post(Config.GATES_LIST_API + '/' + encodeURIComponent(gateId) + '/remote-unlock')
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.successMessageModal = 'ゲートへリモート解錠要求を行いました。';
            this.loading = false;
          }
          this.loading = false;
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          switch (err.status) {
            case 400:
              this.errorMessageModal = 'リモート解錠要求に失敗しました。再度やり直してください。';
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageModal = Config.ERROR_403;
              } else {
                this.errorMessageModal = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageModal = 'リモート解錠要求に失敗しました。以下の原因の可能性があります。<br> ・ログインユーザーが組織管理権限を持っていない場合 <br> ・ログインユーザーがこのゲートに紐づく通行権限グループに登録されていない場合 <br>・ログインユーザーが個人コードを持っていない場合';
              break;
            case 500:
              this.errorMessageModal = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageModal = Config.ERROR_503;
              break;
            default:
              this.errorMessageModal = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
        }); // catch
    },
    clickConfirm(data: any) {
      this.successMessageModal  =  '';
      this.errorMessageModal  = '';
      this.showConfirm = true;
    },
    closeModalConfirm(data: any) {
      this.showConfirm = false;
    },
    clickBackGate() {
      this.$router.push({ path: Config.GATE, query: {goBack: 'gate-lock'}});
    },
    getGateData(gateId: string) {
      return new Promise((resolve, reject) => {
        this.errorMessageLogs = '';
        this.$http.get(Config.GATES_LIST_API + '?gateId=' + encodeURIComponent(gateId))
          .then((response: any) => {
            if (response.body.maxRecords > 0 && response.body.gates.length > 0) {
              this.gateDetail = response.body.gates[0];
              this.gateDetail.groups = [];
              resolve(null);
            }
          })
          .then( null, (err: any) => {
          console.log('err: ', err);
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          resolve(null);
        }); // catch
      });
    },
    validateBeforeSubmit(data: any) {
      this.errorMessageLogs = '';
    },
    resetData() {
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
  },
});
