

























































































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import GroupDetailForm from '@/components/organisms/GroupDetailForm/GroupDetailForm.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';
import { Group } from '@/types/alligate';
import moment from 'moment';
interface Type {
  groupId: string;
  groupName: string;
  userId: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
  userCount: number;
}
interface ArrayDefault {
  text: string;
  value: string;
}
interface ArrayTime {
  passableTimeSettingId: string;
  name: string;
  dayTypeName: string;
  startTime: string;
  endTime: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  searchTargetItems: SearchTargetItem[];
  selectedItem: Type[];
  getDataGroups: any;
}

export default Vue.extend({
  name: 'GroupLockListAdd',
  components: {
    Searchbox,
    ShowPerPage,
    SuccessButton,
    Pagination,
    DisplayOption,
    GroupDetailForm,
    SelectPerPage,
  },
  props: {
    groupTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    dataGroups: {
      type: Array as PropType<Type[]>,
      default: () => [],
    },
    gateList: {
      default: () => [],
    },
    groupOfGate: {
      default: () => [],
    },
    userList: {
      default: () => [],
    },
    dataTimes: {
      type: Array as PropType<ArrayTime[]>,
      default: () => [],
    },
  },
  data: (): DataType => ({
    searchTargetItems: [
      { label: '通行権限グループ名', value: 'groupName' },
    ],
    getDataGroups: [],
    selectedItem: [],
    selectedDisplayOption: [
      'groupName',
      'registered',
    ],
    displayOptionItems: [
      { label: '通行権限グループ名', value: 'groupName', isDisabled: true },
    ],
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '通行権限グループID',
            align: 'start',
            sortable: true,
            value: 'groupId',
          },
          {
            text: '通行権限グループ名',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'ステータス',
            align: 'start',
            sortable: true,
            value: 'isValid',
          },
          {
            text: '登録日',
            align: 'start',
            sortable: true,
            value: 'registered',
          },
        ];
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  watch: {
    loading() {
      this.getDataGroups = this.dataGroups;
      this.getDataGroups.map((o: { disable: boolean; }) => o.disable = false);
      this.dataGroups.forEach((element: any, key: any) => {
        const check = this.groupOfGate.some(({value: v}) => v === element.groupId);
        if (check) {
          this.getDataGroups[key].disable = true;
        }
      });
      this.selectedItem = [];
    },
    selectedItem(val: Group[]) {
      this.$emit('select-groups', val.map((item) => item.groupId));
    },
    page() {
      this.selectedItem = [];
    },
  },
  methods : {
    converDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD');
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
  },
});
