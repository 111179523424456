var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-table mt-2" },
    [
      _c(
        "v-row",
        {
          attrs: {
            "no-gutters": "",
            justify: "space-between",
            "align-content": "center"
          }
        },
        [
          _c("show-per-page", {
            staticClass: "ml-8",
            attrs: {
              "per-page": _vm.perPage,
              page: _vm.page,
              "items-length": _vm.dataGroupsModal.total
            }
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-checkbox", {
            staticClass: "text-caption-checkbox mr-10",
            attrs: {
              label: "有効な通行権限グループのみ表示",
              color: "green",
              dense: "",
              "hide-details": "",
              disabled: _vm.dataGroupsModal.loading
            },
            model: {
              value: _vm.showValid,
              callback: function($$v) {
                _vm.showValid = $$v
              },
              expression: "showValid"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "text-caption display-option included-option text-sm-caption text-md-caption text-lg-caption text-xl-caption mr-4",
              on: {
                click: function() {
                  _vm.showOption = !_vm.showOption
                }
              }
            },
            [
              _c("span", { staticClass: "display-option-title" }, [
                _vm._v("\n      表示オプション\n    ")
              ]),
              _vm._v(" "),
              _c(
                "span",
                [
                  _c("v-icon", { attrs: { dense: "" } }, [
                    _vm._v("mdi-chevron-down")
                  ])
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showOption
        ? _c(
            "v-row",
            { staticClass: "pt-0 pb-0 mt-0" },
            [
              _c(
                "v-container",
                { staticClass: "pt-0" },
                [
                  _c("display-option", {
                    attrs: {
                      isShowOption: _vm.showOption,
                      "display-option-items": _vm.displayOptionItems,
                      disabled: _vm.dataGroupsModal.loading,
                      isVaildOnly: _vm.isVaildOnly,
                      isVaildOnlyShow: false
                    },
                    on: {
                      "show-only-isVaild": function($event) {
                        return _vm.$emit("show-only-isVaild", $event)
                      },
                      input: function($event) {
                        return _vm.CheckColumn($event)
                      }
                    },
                    model: {
                      value: _vm.selectedDisplayOption,
                      callback: function($$v) {
                        _vm.selectedDisplayOption = $$v
                      },
                      expression: "selectedDisplayOption"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "pa-3 pt-0 mt-0" },
        [
          _c("v-col", { staticClass: "pt-0", attrs: { sm: "12" } }, [
            _c(
              "div",
              { staticClass: "table-affiliation" },
              [
                _c("v-data-table", {
                  staticClass:
                    "border-table scroll-table table-no-bg customize-table-scroll",
                  attrs: {
                    "mobile-breakpoint": 0,
                    "must-sort": "",
                    headers: _vm.headers,
                    items: _vm.dataGroupsModal.data,
                    "items-per-page": _vm.perPage,
                    "item-key": "groupId",
                    "no-data-text": "データーはありません",
                    loading: _vm.dataGroupsModal.loading,
                    "loading-text": "データを読み込中です",
                    "hide-default-footer": "",
                    "show-select":
                      _vm.authority.isAdmin === true ? true : false,
                    "custom-sort": _vm.customSort,
                    isGroupEditModalShow: _vm.isGroupEditModalShow,
                    expanded: _vm.expanded,
                    "show-expand": "",
                    "single-expand": ""
                  },
                  on: {
                    "item-expanded": function($event) {
                      return _vm.$emit("change-expanded-group", $event)
                    },
                    "update:expanded": function($event) {
                      _vm.expanded = $event
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.name",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("text-link", {
                              staticClass: "item-user-name",
                              attrs: { text: item.name },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("click-group-detail", item)
                                }
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "item.data-table-expand",
                        fn: function(ref) {
                          var expand = ref.expand
                          var item = ref.item
                          var isExpanded = ref.isExpanded
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", disabled: isExpanded },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "white",
                                                    elevation: "0"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return expand(!isExpanded)
                                                    }
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "item-count" },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-account")
                                                  ]),
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(item.userCount) +
                                                      "\n              "
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "mr-5 item-count"
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-door")
                                                  ]),
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(item.gateCount) +
                                                      "\n              "
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", { staticClass: "text-caption" }, [
                                  _vm._v("通行権限グループ内容を開く")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "transition",
                              { attrs: { name: "expand-icon" } },
                              [
                                isExpanded
                                  ? _c("v-icon", [_vm._v("mdi-chevron-down")])
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "item.registered",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.convertDate(item.registered)))
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.isValid",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.isValid ? "有効" : "無効") +
                                "\n          "
                            )
                          ]
                        }
                      },
                      _vm.authority.isAdmin
                        ? {
                            key: "item.icons",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: { icon: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.$emit(
                                                            "clickEditGroup",
                                                            item.groupId
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "mdi-square-edit-outline"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [_vm._v("通行権限グループ編集")])
                                  ]
                                )
                              ]
                            }
                          }
                        : null,
                      {
                        key: "expanded-item",
                        fn: function(ref) {
                          var headers = ref.headers
                          var item = ref.item
                          return [
                            _c(
                              "td",
                              {
                                staticClass: "pt-5",
                                attrs: { colspan: headers.length }
                              },
                              [
                                _c("gate-of-group-table", {
                                  attrs: {
                                    groupExpanedGate: _vm.groupExpanedGate,
                                    groupId: item.groupId,
                                    isReRenderGate: _vm.isReRenderGate,
                                    isRemoveGateSelected:
                                      _vm.isRemoveGateSelected
                                  },
                                  on: {
                                    "change-page-gate": function($event) {
                                      return _vm.$emit(
                                        "change-page-gate",
                                        $event
                                      )
                                    },
                                    "show-dialog-confirm": function($event) {
                                      return _vm.$emit(
                                        "show-dialog-confirm",
                                        $event
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("user-of-group-table", {
                                  attrs: {
                                    groupExpanedUser: _vm.groupExpanedUser,
                                    groupId: item.groupId,
                                    isReRenderUser: _vm.isReRenderUser,
                                    isRemoveUserSelected:
                                      _vm.isRemoveUserSelected
                                  },
                                  on: {
                                    "change-page-user": function($event) {
                                      return _vm.$emit(
                                        "change-page-user",
                                        $event
                                      )
                                    },
                                    "show-dialog-confirm": function($event) {
                                      return _vm.$emit(
                                        "show-dialog-confirm",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.groupSelected,
                    callback: function($$v) {
                      _vm.groupSelected = $$v
                    },
                    expression: "groupSelected"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-3",
          attrs: { "no-gutters": "", justify: "space-between" }
        },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "mr-5 mb-2" },
            [
              _c("select-per-page", {
                attrs: {
                  page: _vm.page,
                  "per-page-items": [25, 50, 100, 1000],
                  "items-length": _vm.dataGroupsModal.total,
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.innerPerPage,
                  callback: function($$v) {
                    _vm.innerPerPage = $$v
                  },
                  expression: "innerPerPage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            staticClass: "mb-1",
            attrs: {
              "page-length": Math.ceil(_vm.dataGroupsModal.total / _vm.perPage),
              disabled: _vm.loading
            },
            model: {
              value: _vm.innerPage,
              callback: function($$v) {
                _vm.innerPage = $$v
              },
              expression: "innerPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }