















































































































import Vue, { PropType } from 'vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import GateGroupListTable from '@/components/organisms/GateGroupListTable/GateGroupListTable.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import * as Config from '@/config';

export interface DataType {
  selectedGateIds: string[];
}
interface Type {
  gateId: string;
  gateName: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
}
interface ArrayDefault {
  text: string;
  value: string;
}
interface ArrayTime {
  passableTimeSettingId: string;
  name: string;
  dayTypeName: string;
  startTime: string;
  endTime: string;
}
export default Vue.extend({
  name: 'GateAddGroupTemplate',
  components: {
    SuccessButton,
    GateGroupListTable,
    Breadcrumbs,
  },
  props: {
    gateTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    showConfirm: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    errorMessageProcess: {
      default: [],
    },
    gateOfGroup: {
      default: [],
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataGates: {
      type: Array as PropType<Type[]>,
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    goBack: {
      type: String,
      default: '',
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        if (this.goBack === 'user') {
          return [
            { text: 'ユーザー', disabled: true },
            { text: 'ゲート', disabled: true },
          ];
        } else {
          return [
            { text: '通行権限グループ', disabled: true },
            { text: 'ゲート', disabled: true },
          ];
        }
      },
    },
  },
  methods: {
    clickBackGate(value: any) {
      this.$router.push({ path: '/' + value});
    },
  },
  data: (): DataType => ({
    selectedGateIds: [],
  }),
});
