













import Vue, { PropType } from 'vue';

export type AlertType = 'success' | 'error' | 'warning';

export default Vue.extend({
  name: 'Alert',
  props: {
    value: {
      type: Boolean,
    },
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<AlertType>,
      default: 'success',
    },
    // ○秒後に消したい場合は、以下に秒数をセットする。
    // 0の場合はデフォルト(Successならば5秒後、他は消さない)
    // マイナスの値をセットされた場合は削除しない
    timer: {
      type: Number,
      default: 0,
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value(val: boolean) {
      if (val) {
        this.startTimer();
      }
    },
    timer(val: number) {
      this.startTimer();
    },
  },
  computed: {
    innerValue: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        this.$emit('input', val);
        return;
      },
    },
  },
  methods: {
    startTimer() {
      if (this.timer === 0 && this.type === 'success') {
        setTimeout(() => {this.innerValue = false; }, 3000);
      } else if (this.timer > 0) {
        setTimeout(() => {this.innerValue = false; }, this.timer * 1000);
      }
    },
  },
  mounted() {
    this.startTimer();
  },
});
