







































































import {Vue } from 'vue-property-decorator';
import AffiliationListModalTemplate from '@/components/templates/AffiliationListModalTemplate/AffiliationListModalTemplate.vue';
import * as Config from '@/config';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import store from '@/store';
export interface DataType {
  perPage: number;
  currPage: number;
  keyword: string;
  scorp: any;
  keywordUser: string;
  scorpUser: any;
  loading: boolean;
  errorMessageLogs: string;
  successMessageLogs: string;
  dataGroupsModal: any;
  rowinErrDialog: number;
  successItemProcess: number;
  errorMessageProcess: any;
  progress: number;
  isFinishProcess: boolean;
  totalDataRowProcess: number;
  countGroupDetail: number;
  expandData: any;
  dataRelationUser: any;
  dataRelationGroup: any;
  userItems: any;
  authority: any;
  isGetUser: boolean;
  countGetUser: number;
  isShowValidUser: boolean;
  isAffiliationAdd: boolean;
  departmentItem: any;
  loadingChild: boolean;
  isDetail: boolean;
}
export default Vue.extend({
  name: 'AffiliationListModalPage',
  components: {
    AffiliationListModalTemplate,
  },
  props: {
    isPage: {
      type: String,
      default: '',
    },
    department: {
      type: Object,
      default: ({}),
    },
  },
  data: (): DataType => ({
    perPage: Config.PAGE_DEFAULT,
    currPage: 1,
    scorp: ['groupName'],
    keyword: '',
    scorpUser: ['name'],
    keywordUser: '',
    loading: false,
    errorMessageLogs: '',
    successMessageLogs: '',
    dataGroupsModal: {
      dataOri: [],
      data: [],
      total: 0,
    },
    rowinErrDialog: 0,
    successItemProcess: 0,
    errorMessageProcess: [],
    progress: 0,
    isFinishProcess: false,
    totalDataRowProcess: 0,
    expandData: {
      userAll: {
        data: [],
        total: 0,
        loading: false,
        page: 1,
        perPage: 25,
        keyword: '',
        scorp: ['userId', 'name'],
      },
      groupAll: {
        data: [],
        total: 0,
        loading: false,
        page: 1,
        perPage: 25,
        keyword: '',
        scorp: ['groupId', 'name'],
      },
      user: {
        data: [],
        dataOri: [],
        total: 0,
        loading: false,
        page: 1,
        perPage: 25,
        keyword: '',
        scorp: [],
        errorMsg: '',
      },
      group: {
        data: [],
        total: 0,
        page: 1,
        loading: false,
        dataOri: [],
        keyword: '',
        errorMsg: '',
      },
      gateGroup: {
        data: [],
        total: 0,
        loading: false,
        page: 1,
      },
      departmentAd: {
        data: [],
        dataOri: [],
        total: 0,
        loading: false,
        page: 1,
        errorMessage: '',
        sucessMessage: '',
      },
      errorData: {
        users: [],
        userEdit: [],
        userDel: [],
        groups: [],
      },
      isAdminToAddUser: false,
    },
    countGroupDetail: 0,
    dataRelationUser: {
      total: 0,
      progressLoad: 0,
      successItem: 0,
      errorMessage: [],
      isFinish: false,
    },
    dataRelationGroup: {
      total: 0,
      progressLoad: 0,
      successItem: 0,
      errorMessage: [],
      isFinish: false,
    },
    userItems: [],
    authority: store.state.auth,
    isGetUser: false,
    countGetUser: 0,
    isShowValidUser: true,
    isAffiliationAdd: false,
    departmentItem: '',
    loadingChild: false,
    isDetail: false,
  }),
  created() {
    //
    if (typeof localStorage.isVaildDataGroupModal === 'undefined') {
      localStorage.setItem('isVaildDataGroupModal', 'true');
    }
    if (typeof localStorage.groupModalSortKey === 'undefined') {
      localStorage.setItem('groupModalSortKey', 'registered');
    }
    if (typeof localStorage.groupModalSortName === 'undefined') {
      localStorage.setItem('groupModalSortName', 'desc');
    }
    if (typeof localStorage.pageSizeGroupModal === 'undefined') {
      localStorage.setItem('pageSizeGroupModal', Config.PAGE_DEFAULT.toString());
      this.perPage = Config.PAGE_DEFAULT;
    } else {
      this.perPage = parseInt(localStorage.pageSizeGroupModal);
    }
    if (this.isPage === 'detail' ) {
      this.loadAffDetail({isAction: 'detail', departmentId: this.department.departmentId});
    } else {
      this.getListGroupsModal();
    }
    this.departmentItem = this.department;
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
    countGroupDetail(val: any) {
      if (val >= this.expandData.group.total) {
        this.expandData.group.loading = false;
      }
    },
    countGetUser(value: any) {
      if (value >= 2 ) {
        this.isGetUser = true;
      }
    },
  },
  mounted() {
    //
  },
  methods: {
    //
    searchUserAddPage(item: any) {
      let keyword = item.text;
      keyword = keyword.replace(/\s/g, ',');
      this.expandData.userAll.keyword = keyword;
      const data = {
        page: this.expandData.userAll.page,
        perPage: this.expandData.userAll.perPage,
        keyword: this.expandData.userAll.keyword,
      };
      this.loadListUsers(data);
    },
    searchGroupAddPage(item: any) {
      let keyword = item.text;
      keyword = keyword.replace(/\s/g, ',');
      this.expandData.groupAll.keyword = keyword;
      const data = {
        page: this.expandData.groupAll.page,
        perPage: this.expandData.groupAll.perPage,
        keyword: this.expandData.groupAll.keyword,
      };
      this.loadListGroups(data);
    },
    searchUser(item: any) {
      this.expandData.user.data = [];
      this.expandData.user.total = 0;
      this.expandData.user.perPage = 25;
      this.expandData.user.page = 1;
      this.expandData.user.keyword = '';
      this.expandData.user.errorMsg = '';
      this.expandData.user.dataOri = [];
      this.expandData.user.dataOriDefault = [];
      this.expandData.user.loading = true;
      const keyword = item.agr.text;
      const data: any = [];
      item.agr.targets.forEach((element: any) => {
        if (element === 'name') {
          data.push('user.name');
        } else {
          data.push(element);
        }
      });
      this.expandData.user.scorp = data;
      this.keywordUser = encodeURIComponent(keyword);
      this.expandData.user.keyword = item.agr.text;
      this.scorpUser = data;
      if (item.page !== undefined && item.page === 'detail') {
        this.getDepartmentUserDetails(item.departmentId, 25, 1);
      } else {
        this.getDepartmentUser(item.departmentId, 25, 1);
      }
    },
    changePageAndPerPageUser(item: any) {
      this.expandData.user.data = [];
      this.expandData.user.total = 0;
      this.expandData.user.errorMsg = '';
      this.expandData.user.dataOri = [];
      this.expandData.user.dataOriDefault = [];
      this.expandData.user.perPage = item.agr.perPage;
      this.expandData.user.page = item.agr.page;
      this.expandData.user.loading = true;
      if (item.page !== undefined && item.page === 'detail') {
        this.getDepartmentUserDetails(item.departmentId, item.agr.perPage, item.agr.page);
      } else {
        this.getDepartmentUser(item.departmentId, item.agr.perPage, item.agr.page);
      }
    },
    deleteDepartment(data: any) {
      this.deleteDepartmentItem(data);
    },
    changePageGroup(item: any) {
      this.expandData.group.page = item.page;
      this.expandData.group.loading = true;
      let dataOri = this.expandData.group.dataOri;
      if (this.expandData.group.keyword !== '') {
        dataOri = this.expandData.group.dataOri.filter((element: any) =>
        element.group.groupId.includes(this.expandData.group.keyword) === true ||
        element.group.name.includes(this.expandData.group.keyword) === true);
      }
      const data  = Array.of(...dataOri);
      this.expandData.group.data =
      data.slice((item.page - 1) * 25, item.page * 25);
      setTimeout(() => {
        this.expandData.group.loading = false;
      }, 100);
    },
    searchGroupDe(item: any) {
      this.expandData.group.loading = true;
      const keyword = item.agr.text;
      // keyword = keyword.replace(/\s/g, ',');
      this.keywordUser = encodeURIComponent(keyword);
      this.scorpUser = item.agr.targets;
      const data = this.expandData.group.dataOri.filter((element: any) =>
      element.group.name.includes(keyword) === true);
      this.expandData.group.data = data;
      this.expandData.group.total = data.length;
      this.expandData.group.perPage = 25;
      this.expandData.group.page = 1;
      this.expandData.group.keyword = keyword;
      setTimeout(() => {
        this.expandData.group.loading = false;
      }, 100);
      // this.getDepartmentUser(item.departmentId, 25, 1);
    },
    changePageGateGroup(item: any) {
      this.expandData.gateGroup.page = item.page;
      this.getGateGroup(item.groupId, item.page);
    },
    expandGateGroup(data: any) {
      if (data.value) {
        this.expandData.gateGroup.data = [];
        this.expandData.gateGroup.dataOri = [];
        this.expandData.gateGroup.keyword = '';
        this.expandData.gateGroup.page = 1;
        this.expandData.gateGroup.total = 0;
        this.getGateGroup(data.item.group.groupId, 1);
      }
    },
    async loadAffDetail(item: any) {
      this.isDetail = false;
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      this.expandData.departmentAd.data = [];
      this.isShowValidUser = localStorage.isShowValidUser  !== undefined &&
      localStorage.isShowValidUser === 'true' ? true : false;
      this.countGetUser = 0;
      this.getDepartmentAuth(item.departmentId);
      if (item.isAction !== 'detail') {
        this.isGetUser = false;
        this.userItems = [];
        if (localStorage.isCustomUserAuthorityService === 'true') {
          this.getUsers(1);
        }
      } else {
        this.isDetail = true;
      }
      await this.wait(500);
      this.expandData.user.data = [];
      this.expandData.user.dataOri = [];
      this.expandData.user.dataOriDefault = [];
      this.expandData.user.total = 0;
      this.expandData.user.perPage = 25;
      this.expandData.user.page = 1;
      this.expandData.user.keyword = '';
      this.expandData.user.errorMsg = '';
      this.expandData.group.data = [];
      this.expandData.group.dataOri = [];
      this.expandData.group.total = 0;
      this.expandData.group.perPage = 25;
      this.expandData.group.page = 1;
      this.expandData.group.keyword = '';
      this.expandData.user.errorMsg = '';
      await this.getDepartmentPermisson(item.departmentId);
      this.getDepartmentUserDetails(item.departmentId, 25, 1);
      this.getDepartmentGroupDetails(item.departmentId, 1);
    },
    changeExpanded(data: any): any {
      if (data.change.value) {
        const dt = {
          tab : data.tab !== '' ?  data.tab : 0,
          departmentId: data.change.item.departmentId,
        };
        this.changeTab(dt);
      }
    },
    async changeTab(item: any): Promise<any> {
      this.isDetail = false;
      this.isShowValidUser = true;
      localStorage.setItem('isShowValidUser', 'true');
      this.expandData.groupAll.perPage = 25;
      this.expandData.groupAll.page = 1;
      if (item.tab === 0) {
        this.expandData.user.data = [];
        this.expandData.user.dataOri = [];
        this.expandData.user.dataOriDefault = [];
        this.expandData.user.total = 0;
        this.expandData.user.perPage = 25;
        this.expandData.user.page = 1;
        this.expandData.user.keyword = '';
        this.expandData.user.errorMsg = '';
        this.getDepartmentPermisson(item.departmentId);
        this.getDepartmentUser(item.departmentId, 25, 1);
      } else {
        this.expandData.group.loading = true;
        this.expandData.group.data = [];
        this.expandData.group.dataOri = [];
        this.expandData.group.dataOriDefault = [];
        this.expandData.group.total = 0;
        this.expandData.group.perPage = 25;
        this.expandData.group.page = 1;
        this.expandData.group.keyword = '';
        this.expandData.group.errorMsg = '';
        this.getDepartmentGroup(item.departmentId, 1);
      }
    },
    changePerPage(value: any): any {
      this.perPage = value;
      localStorage.setItem('pageSizeGroupModal', value);
      if (this.authority.isAdmin) {
        this.getListGroupsModal();
      } else {
        this.getDepartmentUserLoginChange();
      }
    },
    changePage(value: any): any {
      this.currPage = value;
      if (this.authority.isAdmin) {
        this.getListGroupsModal();
      } else {
        this.getDepartmentUserLoginChange();
      }
    },
    searchGroup(value: any): any {
      this.keyword = value.text;
      this.scorp = value.targets;
      if (this.authority.isAdmin) {
        this.getListGroupsModal();
      } else {
        this.getDepartmentUserLoginChange();
      }
    },
    getDepartmentUser(departmentId: any, perPage: any, page: any) {
      if (this.isDetail) {
        return;
      }
      this.expandData.user.loading = true;
      let apiLink = Config.RELATION_DEPARTMENT_USER_API + '?departmentId=' + encodeURIComponent(departmentId) + '&limit=' + perPage + '&page=' + page + '&embed=user&isExceptOptionalUser=true';
      if (this.isShowValidUser === true) {
        apiLink += '&isValidUser=true';
      }
      if (this.expandData.user.keyword !== '') {
        apiLink += '&keyword=' + this.expandData.user.keyword + '&scorp=' + this.expandData.user.scorp;
      }
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {

            this.expandData.user.total = response.body.maxRecords;
            response.body.relationsDepartmentUser.forEach((element: any) => {
              if (!this.isDetail) {
                element.disable = true;
                this.expandData.user.data.push(element);
                this.expandData.user.dataOri.push(element);
              }
            });
            this.expandData.user.loading = false;
            const i = this.dataGroupsModal.data.findIndex((x: any) =>
            x.departmentId === departmentId);
            if (i >= 0) {
              this.dataGroupsModal.data[i].userCount = response.body.maxRecords;
            }
          } else {
            this.expandData.user.loading = false;
            const i = this.dataGroupsModal.data.findIndex((x: any) =>
            x.departmentId === departmentId);
            if (i >= 0) {
              this.dataGroupsModal.data[i].userCount = response.body.maxRecords;
            }
          }
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              errorName = Config.ERROR_403;
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          this.expandData.user.errorMsg  = errorName;
          this.expandData.user.loading = false;
          const i = this.dataGroupsModal.data.findIndex((x: any) =>
            x.departmentId === departmentId);
          if (i >= 0) {
            this.dataGroupsModal.data[i].userCount = this.expandData.user.dataOri.length;
          }
        });
    },
    getDepartmentUserDetails(departmentId: any, perPage: any, page: any) {
      if (page === 1) {
        this.expandData.user.data = [];
        this.expandData.user.dataOri = [];
        this.expandData.user.dataOriDefault = [];
      }
      this.expandData.user.loading = true;
      let apiLink = Config.RELATION_DEPARTMENT_USER_API + '?departmentId=' + encodeURIComponent(departmentId) + '&limit=' + perPage + '&page=' + page + '&embed=user&isExceptOptionalUser=true';
      if (this.isShowValidUser === true) {
        apiLink += '&isValidUser=true';
      }
      if (this.expandData.user.keyword !== '') {
        apiLink += '&keyword=' + this.expandData.user.keyword + '&scorp=' + this.expandData.user.scorp;
      }
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {

            this.expandData.user.total = response.body.maxRecords;
            response.body.relationsDepartmentUser.forEach((element: any) => {
              element.disable = true;
              this.expandData.user.data.push(element);
              this.expandData.user.dataOri.push(element);
              this.expandData.user.dataOriDefault.push(element);
            });
            this.expandData.user.loading = false;
            const i = this.dataGroupsModal.data.findIndex((x: any) =>
            x.departmentId === departmentId);
            if (i >= 0) {
              this.dataGroupsModal.data[i].userCount = response.body.maxRecords;
            }
          } else {
            this.expandData.user.loading = false;
            const i = this.dataGroupsModal.data.findIndex((x: any) =>
            x.departmentId === departmentId);
            if (i >= 0) {
              this.dataGroupsModal.data[i].userCount = response.body.maxRecords;
            }
          }
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              errorName = Config.ERROR_403;
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          this.expandData.user.loading = false;
          this.expandData.user.errorMsg = errorName;
          const i = this.dataGroupsModal.data.findIndex((x: any) =>
            x.departmentId === departmentId);
          if (i >= 0) {
            this.dataGroupsModal.data[i].userCount = this.expandData.user.dataOri.length;
          }
        });
    },
    getDepartmentUserValid(event: any) {
      this.isShowValidUser = event.value;
      localStorage.setItem('isShowValidUser', event.value.toString());
      if (this.isAffiliationAdd) {
        this.getUsers(1);
      } else {
        this.expandData.user.data = [];
        this.expandData.user.total = 0;
        this.expandData.user.perPage = 25;
        this.expandData.user.page = 1;
        this.expandData.user.keyword = '';
        this.expandData.user.errorMsg = '';
        this.expandData.user.dataOri = [];
        this.expandData.user.dataOriDefault = [];
        if (this.isDetail) {
          this.getDepartmentUserDetails(event.departmentId, 25, 1);
        } else {
          this.getDepartmentUser(event.departmentId, 25, 1);
        }
      }
    },
    getDepartmentGroup(departmentId: any, page: any) {
    if (this.isDetail) {
      return;
    }
    this.$http.get(Config.RELATION_DEPARTMENT_GROUP_API + '?departmentId=' + encodeURIComponent(departmentId) + '&limit=1000&page=' + page + '&embed=group&isValidGroup=true&isExceptOptionalGate=true')
      .then((response: any) => {
        if (response.body.maxRecords > 0) {
          this.expandData.group.total = response.body.maxRecords;
          response.body.relationsDepartmentGroup.forEach((element: any, i: any) => {
            if (!this.isDetail) {
              element.group.gateCount = 0;
              element.disable = true;
              this.expandData.group.data.push(element);
              this.expandData.group.dataOri.push(element);
              this.getGroupDetail(element.group.groupId, i);
            }
          });
          if (response.body.maxRecords > response.body.endRecord) {
            page++;
            this.getDepartmentGroup(departmentId, page);
          } else {
            this.expandData.group.loading = false;
            const i = this.dataGroupsModal.data.findIndex((x: any) =>
            x.departmentId === departmentId);
            if (i >= 0) {
              this.dataGroupsModal.data[i].groupCount = response.body.maxRecords;
            }
          }
        } else {
          this.expandData.group.loading = false;
          const i = this.dataGroupsModal.data.findIndex((x: any) =>
          x.departmentId === departmentId);
          if (i >= 0) {
            this.dataGroupsModal.data[i].groupCount = response.body.maxRecords;
          }
        }
      })
      .then( null, (err: any) => {
        let errorName = '';
        switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              errorName = Config.ERROR_403;
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
        this.expandData.group.errorMsg  = errorName;
        this.expandData.group.loading = false;
        const i = this.dataGroupsModal.data.findIndex((x: any) =>
          x.departmentId === departmentId);
        if (i >= 0) {
          this.dataGroupsModal.data[i].groupCount = this.expandData.group.dataOri.length;
        }
      });
    },
    getDepartmentGroupDetails(departmentId: any, page: any) {
      if (page === 1) {
        this.expandData.group.data = [];
        this.expandData.group.dataOri = [];
        this.expandData.group.dataOriDefault = [];
      }
      this.expandData.group.loading = true;
      this.$http.get(Config.RELATION_DEPARTMENT_GROUP_API + '?departmentId=' + encodeURIComponent(departmentId) + '&limit=1000&page=' + page + '&embed=group&isValidGroup=true&isExceptOptionalGate=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            this.expandData.group.total = response.body.maxRecords;
            response.body.relationsDepartmentGroup.forEach((element: any, i: any) => {
              element.group.gateCount = 0;
              element.disable = true;
              this.expandData.group.data.push(element);
              this.expandData.group.dataOri.push(element);
              this.expandData.group.dataOriDefault.push(element);
              this.getGroupDetail(element.group.groupId, i);
            });
            if (response.body.maxRecords > response.body.endRecord) {
              page++;
              this.getDepartmentGroupDetails(departmentId, page);
            } else {
              this.expandData.group.loading = false;
              const i = this.dataGroupsModal.data.findIndex((x: any) =>
              x.departmentId === departmentId);
              if (i >= 0) {
                this.dataGroupsModal.data[i].groupCount = response.body.maxRecords;
              }
            }
          } else {
            this.expandData.group.loading = false;
            const i = this.dataGroupsModal.data.findIndex((x: any) =>
            x.departmentId === departmentId);
            if (i >= 0) {
              this.dataGroupsModal.data[i].groupCount = response.body.maxRecords;
            }
          }
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              errorName = Config.ERROR_403;
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          this.expandData.group.errorMsg  = errorName;
          this.expandData.group.loading = false;
          const i = this.dataGroupsModal.data.findIndex((x: any) =>
            x.departmentId === departmentId);
          if (i >= 0) {
            this.dataGroupsModal.data[i].groupCount = this.expandData.group.dataOri.length;
          }
        });
    },
    getListGroupsModal(): any {
      this.resetData();
      if (this.authority.isAdmin) {
        this.getDeaprtment();
      } else {
        this.loadingChild = true;
        this.getDepartmentUserLogin(1);
      }
    },
    getDepartmentUserLoginChange() {
      this.loadingChild = true;
      let dataOri = this.dataGroupsModal.dataOri;
      if (this.keyword !== '') {
        dataOri = this.dataGroupsModal.dataOri.filter((element: any) =>
        element.name.toLowerCase().includes(this.keyword.toLowerCase()) === true);
      }
      const data  = Array.of(...dataOri);
      this.dataGroupsModal.data =
      data.slice((this.currPage - 1) * this.perPage, this.currPage * this.perPage);
      setTimeout(() => {
        this.loadingChild = false;
      }, 100);
    },
    getDepartmentUserLogin(page: any) {
      const apiLink = Config.DEPARTMENT_ADMINISTRATOR_API +
      '?userId=' + encodeURIComponent(localStorage.userProfileId ) + '&limit=1000' + '&page=' + page;
      this.$http.get(apiLink)
        .then(async (response: any) => {
          if (response.body.maxRecords > 0) {
            const data = response.body.departmentAdministrators;
            const item: any = [];
            for (let i = 0; i < data.length; i += 10) {
              const chunk = data.slice(i, i + 10);
              const arrayPromise: any = [];
              for (const [index, element] of  chunk.entries()) {
                this.dataGroupsModal.dataOri.push(element);
                const result: any = this.getDepartmentItem(element);
                arrayPromise.push(result);
              }
              await Promise.all(arrayPromise);
            }
            this.dataGroupsModal.data  = this.dataGroupsModal.dataOri;
            this.dataGroupsModal.total = this.dataGroupsModal.dataOri.length;
            this.currPage = 1;
            if (response.body.maxRecords > response.body.endRecord) {
              page++;
              this.getDepartmentUserLogin(page);
            } else {
              //
              this.loading = false;
              this.loadingChild = false;
            }
          } else {
            this.loading = false;
            this.loadingChild = false;
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          this.loadingChild = false;
        });
    },
    getDepartmentItem(item: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.DEPARTMENT_API + '?limit=25&departmentId=' + encodeURIComponent(item.departmentId))
        .then((response: any) => {
          const index = this.dataGroupsModal.dataOri.findIndex((x: any) =>
          x.departmentId === item.departmentId);
          if (index >= 0) {
            let data: any = {};
            data = response.body.departments[0];
            this.dataGroupsModal.dataOri[index].groupCount = data.groupCount;
            this.dataGroupsModal.dataOri[index].name = data.name;
            this.dataGroupsModal.dataOri[index].userCount = data.userCount;
          }
          resolve(true);
        }).
        then( null, (err: any) => {
          resolve(false);
        });
      });
  },
    getDeaprtment() {
      this.loadingChild = true;
      let apiLink = Config.DEPARTMENT_API + '?limit=' + localStorage.pageSizeGroupModal +
      '&page=' + this.currPage ;
      if (this.keyword !== null && this.keyword !== undefined && this.keyword !== '') {
        apiLink += '&keyword=' + this.keyword + '&scorp=' + this.scorp;
      }
      if (localStorage.isVaildDataGroupModal === 'true') {
        apiLink += '&isValid=true';
      }
      this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.departments)) {
            this.dataGroupsModal.data = response.body.departments;
            this.dataGroupsModal.total = response.body.maxRecords;
            this.currPage = Math.ceil(response.body.startRecord / this.perPage);
          } else {
            this.dataGroupsModal.total = 0;
            this.currPage = 1;
          }
          this.loadingChild = false;
        })
        .then( null, (err: any) => {
          this.loadingChild = false;
          switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
        }); // catch
    },
    async clickGroupsAction(eventArgs: any) {
      this.rowinErrDialog = 0;
      this.errorMessageProcess = [];
      this.successItemProcess = 0;
      this.progress = 0;
      this.isFinishProcess = false;

      this.totalDataRowProcess = eventArgs.data.length;
      const percent = (100 / this.totalDataRowProcess);

      for (const item of  eventArgs.data) {
        let result;
        if (eventArgs.typeAction === 0) {
          //  enable
          result = await this.enableOrDisableGroup(item, true);
        } else if (eventArgs.typeAction === 1) {
          //  disable
          result = await this.enableOrDisableGroup(item, false);
        }
        if (result) {
          this.successItemProcess++;
        }
        this.progress += percent;
      }
      this.isFinishProcess = true;
    },
    enableOrDisableGroup(groupId: string, isEnable: boolean) {
      return new Promise((resolve) => {
        const data = {
          isValid: isEnable,
        };
        this.$http.put(Config.GROUP_LIST_API + '/' + encodeURIComponent(groupId), data)
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = 'groupIdが存在しない。';
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = groupId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        });
      });
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.errorMessageLogs = '';
      this.successMessageLogs = '';
      this.dataGroupsModal = {
        dataOri: [],
        data: [],
        total: 0,
      };
    },
    getGroupDetail(groupId: any, i: any) {
      this.expandData.group.data[i].group.gateCount = 0;
      const apiLink = Config.GROUP_LIST_API + '?groupId=' + encodeURIComponent(groupId) +
      '&embed=passableTimeSetting&isExceptOptionalGate=true';
      this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.groups) && response.body.groups.length > 0) {
            this.expandData.group.data[i].gateCount = response.body.groups[0].gateCount;
            this.expandData.group.data[i].group.gateCount = response.body.groups[0].gateCount;
            this.countGroupDetail++;
          }
        })
        .then( null, (err: any) => {
          //
          this.countGroupDetail++;
        }); // catch
    },
    getGateGroup(groupId: string, page: any) {
      return new Promise((resolve, reject) => {
        this.expandData.gateGroup.loading = true;
        this.$http.get(Config.RELATION_GATE_GROUP_API + '?groupId=' + encodeURIComponent(groupId) + '&embed=gate&limit=25&page=' + page + '&isExceptOptionalGate=true&isValid=true')
          .then(async (response: any) => {
            if (response.body.maxRecords > 0) {
            this.expandData.gateGroup.total = response.body.maxRecords;
            response.body.relationsGateGroup.forEach((element: any) => {
              this.expandData.gateGroup.data.push(element);
              this.expandData.gateGroup.dataOri.push(element);
            });
            this.expandData.gateGroup.loading = false;
            resolve(true);
          } else {
            this.expandData.gateGroup.loading = false;
            resolve(true);
          }
          })
          .then(null, (error) => {
            this.expandData.gateGroup.loading = false;
            resolve(false);
          });
      });
    },
    deleteDepartmentItem(departmentId: any): any {
      this.loading = true;
      this.$http.delete(Config.DEPARTMENT_API + '/' + encodeURIComponent(departmentId))
        .then((response: any) => {
          //
          this.loading = false;
          this.$emit('getReload', true);
        })
        .then( null, (err: any) => {
          this.loading = false;
          switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
        }); // catch
    },
    changePageUserAll(val: any) {
      this.expandData.userAll.page = val;
      const data = {
        page: this.expandData.userAll.page,
        perPage: this.expandData.userAll.perPage,
      };
      this.loadListUsers(data);
    },
    changePerPageUserAll(val: any) {
      this.expandData.userAll.perPage = val;
      const data = {
        page: this.expandData.userAll.page,
        perPage: this.expandData.userAll.perPage,
      };
      this.loadListUsers(data);
    },
    changePageGroupAll(val: any) {
      this.expandData.groupAll.page = val;
      const data = {
        page: this.expandData.groupAll.page,
        perPage: this.expandData.groupAll.perPage,
      };
      this.loadListGroups(data);
    },
    changePerPageGroupAll(val: any) {
      this.expandData.groupAll.perPage = val;
      const data = {
        page: this.expandData.groupAll.page,
        perPage: this.expandData.groupAll.perPage,
      };
      this.loadListGroups(data);
    },
    loadListUsers(item: any) {
      this.expandData.userAll.total = 0;
      this.expandData.userAll.data = [];
      this.expandData.userAll.loading = true;
      let apiLink = Config.USERS_LIST_API + '?limit=' + item.perPage + '&page=' + item.page + '&isValid=true';
      if (this.expandData.userAll.keyword !== '') {
        apiLink += '&keyword=' + this.expandData.userAll.keyword + '&scorp=' + this.expandData.userAll.scorp;
      }
      this.$http.get(apiLink)
        .then(async (response: any) => {
          if (response.body.maxRecords > 0) {
            this.expandData.userAll.total = response.body.maxRecords;
            for (let i = 0; i < response.body.users.length; i += 10) {
              const chunk = response.body.users.slice(i, i + 10);
              const arrayPromise: any = [];
              for (const [index, el] of  chunk.entries()) {
                el.user = {
                  userId: el.userId,
                  name: el.name,
                };
                el.user.name = el.name;
                el.company = '';
                this.expandData.userAll.data.push(el);
                const result =  this.getDepartmentUserDetail(el.userId);
                arrayPromise.push(result);
              }
              await Promise.all(arrayPromise);
            }
            this.expandData.userAll.loading = false;
          }
        })
        .then( null, (err: any) => {
          this.expandData.userAll.loading = false;
        }); // catch
  },
  getDepartmentUserDetail(userId: any) {
    return new Promise((resolve, reject) => {
      this.$http.get(Config.RELATION_DEPARTMENT_USER_API + `?userId=${encodeURIComponent(userId)}&embed=department`)
        .then((response: any) => {
          const data  = response.body.relationsDepartmentUser;
          const i = this.expandData.userAll.data.findIndex((x: any) =>
          x.userId === userId);
          if (i >= 0) {
            this.expandData.userAll.data[i].company = data.length > 0 ? data[0].department.name : '';
          }
          resolve(true);
        })
        .then( null, (err: any) => {
          resolve(false);
        });
    });
  },
  loadListGroups(item: any) {
    this.dataRelationGroup = {
      total: 0,
      progressLoad: 0,
      successItem: 0,
      errorMessage: [],
      isFinish: false,
    };
    this.expandData.groupAll.page = item.page;
    this.expandData.groupAll.perPage = item.perPage;
    this.expandData.groupAll.loading = true;
    let apiLink = Config.GROUP_LIST_API + '?limit=' + item.perPage + '&page=' + item.page + '&isValid=true&embed=passableTimeSetting';
    if (this.expandData.groupAll.keyword !== '') {
      apiLink += '&keyword=' + this.expandData.groupAll.keyword + '&scorp=' + this.expandData.groupAll.scorp;
    }
    this.$http.get(apiLink)
      .then((response: any) => {
        if (response.body.maxRecords > 0) {
          this.expandData.groupAll.data = response.body.groups;
          this.expandData.groupAll.total = response.body.maxRecords;
          this.expandData.groupAll.loading = false;
          this.expandData.groupAll.data.map((v: any) => Object.assign(v, {group: {
              groupId: v.groupId,
              name: v.name,
              gateCount: v.gateCount,
              passableTimeSetting: v.passableTimeSetting,
            }}));
        }
        this.expandData.groupAll.loading = false;
      })
      .then( null, (err: any) => {
        this.expandData.groupAll.loading = false;
      }); // catch
  },
  async addUsers(data: any) {
    let rowinErrDialog = 0;
    let successItemProcess = 0;
    let progress = 0;
    let isFinishProcess = false;
    const errorMessageProcess: any = [];
    const totalDataRowProcess = data.users.length;
    const percent = (100 / totalDataRowProcess);
    this.dataRelationUser.total = totalDataRowProcess;
    for (const item of data.users) {
      let result;
      result  = await this.postUserDepartment(item.userId, data.departmentId);
      if (result === true) {
        successItemProcess++;
      } else {
        const rowstatus = item.userId + '>><span>' + result + '</span>';
        this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
      }
      progress += percent;
      this.dataRelationUser.progress = progress;
      this.dataRelationUser.successItem = successItemProcess;
      this.dataRelationUser.progressLoad = progress;
      this.dataRelationUser.errorMessage = errorMessageProcess;
    }
    isFinishProcess = true;
    this.dataRelationUser.isFinish = isFinishProcess;
    if (successItemProcess > 0) {
      this.$emit('getReload', true);
    }
  },
  postUserDepartment(userId: string, departmentId: string) {
    return new Promise((resolve, reject) => {
      this.$http.post(Config.RELATION_DEPARTMENT_USER_API , {userId: (userId),
        departmentId: (departmentId)})
        .then((response: any) => {
          resolve(true);
        })
        .then( null, async (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 409:
              const result: any = await this.getDepartmentUserInfo(userId);
              let text = '';
              if (result.length > 0) {
                text = result[0].department.name;
              }
              errorName = Config.ERROR_409_DEPARTMENT.replace('{{所属名}}', text.toString());
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          resolve(errorName);
        }); // catch
    });
  },
  async removeUsers(data: any) {
    this.dataRelationUser = {
      total: 0,
      progressLoad: 0,
      successItem: 0,
      errorMessage: [],
      isFinish: false,
    };
    let rowinErrDialog = 0;
    let successItemProcess = 0;
    let progress = 0;
    let isFinishProcess = false;
    const errorMessageProcess: any = [];
    const totalDataRowProcess = data.users.length;
    const percent = (100 / totalDataRowProcess);
    this.dataRelationUser.total = totalDataRowProcess;
    for (const item of data.users) {
      let result;
      result  = await this.removeUserDepartment(item.relationId);
      if (result === true) {
        successItemProcess++;
      } else {
        const rowstatus = item.userId + '>><span>' + result + '</span>';
        this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
      }
      progress += percent;
      this.dataRelationUser.progress = progress;
      this.dataRelationUser.successItem = successItemProcess;
      this.dataRelationUser.progressLoad = progress;
      this.dataRelationUser.errorMessage = errorMessageProcess;
    }
    isFinishProcess = true;
    this.dataRelationUser.isFinish = isFinishProcess;
    if (successItemProcess > 0) {
      this.$emit('getReload', true);
    }
  },
  removeUserDepartment(relationId: string) {
    return new Promise((resolve, reject) => {
      this.$http.delete(Config.RELATION_DEPARTMENT_USER_API + '/' + encodeURIComponent(relationId))
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          resolve(errorName);
        }); // catch
    });
  },
  async removeGroups(data: any) {
    this.dataRelationGroup = {
      total: 0,
      progressLoad: 0,
      successItem: 0,
      errorMessage: [],
      isFinish: false,
    };
    let rowinErrDialog = 0;
    let successItemProcess = 0;
    let progress = 0;
    let isFinishProcess = false;
    const errorMessageProcess: any = [];
    const totalDataRowProcess = data.groups.length;
    const percent = (100 / totalDataRowProcess);
    this.dataRelationGroup.total = totalDataRowProcess;
    for (const item of data.groups) {
      let result;
      result  = await this.removeGroupDepartment(item.relationId);
      if (result === true) {
        successItemProcess++;
      } else {
        const rowstatus = item.group.name + '>><span>' + result + '</span>';
        this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
      }
      progress += percent;
      this.dataRelationGroup.progress = progress;
      this.dataRelationGroup.successItem = successItemProcess;
      this.dataRelationGroup.progressLoad = progress;
      this.dataRelationGroup.errorMessage = errorMessageProcess;
    }
    isFinishProcess = true;
    this.dataRelationGroup.isFinish = isFinishProcess;
  },
  removeGroupDepartment(relationId: string) {
    return new Promise((resolve, reject) => {
      this.$http.delete(Config.RELATION_DEPARTMENT_GROUP_API + '/' + encodeURIComponent(relationId))
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          resolve(errorName);
        }); // catch
    });
  },
  async addGroups(data: any) {
    this.dataRelationGroup = {
      total: 0,
      progressLoad: 0,
      successItem: 0,
      errorMessage: [],
      isFinish: false,
    };
    let rowinErrDialog = 0;
    let successItemProcess = 0;
    let progress = 0;
    let isFinishProcess = false;
    const errorMessageProcess: any = [];
    const totalDataRowProcess = data.groups.length;
    const percent = (100 / totalDataRowProcess);
    this.dataRelationGroup.total = totalDataRowProcess;
    for (const item of data.groups) {
      let result;
      result  = await this.addGroupDepartment(item.groupId, data.departmentId);
      if (result === true) {
        successItemProcess++;
      } else {
        const rowstatus = item.name + '>><span>' + result + '</span>';
        this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
      }
      progress += percent;
      this.dataRelationGroup.progress = progress;
      this.dataRelationGroup.successItem = successItemProcess;
      this.dataRelationGroup.progressLoad = progress;
      this.dataRelationGroup.errorMessage = errorMessageProcess;
    }
    isFinishProcess = true;
    this.dataRelationGroup.isFinish = isFinishProcess;
  },
  addGroupDepartment(group: string, ID: string) {
    return new Promise((resolve, reject) => {
      this.$http.post(Config.RELATION_DEPARTMENT_GROUP_API, {
        departmentId: ID,
        groupId: group,
      })
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 409:
              errorName = Config.ERROR_409_DE_GROUP;
              break;
            case 420:
              errorName = Config.ERROR_420_DE_GROUP;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          resolve(errorName);
        }); // catch
    });
  },
  getDepartmentAuth(departmentId: any) {
    if (localStorage.isCustomUserAuthorityService === 'true') {
      this.loadingChild = true;
      this.expandData.departmentAd.errorMessage = '';
      this.expandData.departmentAd.sucessMessage = '';
      this.$http.get(Config.DEPARTMENT_ADMINISTRATOR_API + '?limit=1000&departmentId=' + departmentId + '&embed=user')
      .then(async (response: any) => {
        const userPromises = response.body.departmentAdministrators.map(async (element: any) => {
          // const userName: any = await this.getUserName(element.userId);
          element.userName = element.user.name;
          return element;
        });
        this.expandData.departmentAd.data = await Promise.all(userPromises);
        this.expandData.departmentAd.dataOri = await Promise.all(userPromises);
        this.expandData.departmentAd.loading = response.body.departmentAdministrators;
        this.loadingChild = false;
        this.countGetUser++;
      }).
      then( null, (err: any) => {
        this.loadingChild = false;
        this.countGetUser++;
      });
    } else {
      this.loadingChild = false;
      this.countGetUser++;
    }
  },
  getUserName(userId: string) {
    return new Promise((resolve) => {
      this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
        .then((res: any) => {
          if (res.data.users !== undefined && res.data.users !== null && res.data.users.length > 0) {
            resolve(res.data.users[0].name);
          } else {
            resolve(null);
          }
        }, (err) => {
          resolve(null);
        });
    });
  },
  removeAffDetail(item: any) {
    this.loading = true;
    this.expandData.departmentAd.errorMessage = '';
    this.expandData.departmentAd.sucessMessage = '';
    this.$http.delete(Config.DEPARTMENT_API + '/' + item.departmentId)
      .then((response: any) => {
        this.loading = false;
        this.expandData.departmentAd.sucessMessage = item.name + 'を削除しました。';
      })
      .then( null, (err: any) => {
        this.loading = false;
        switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        // this.expandData.departmentAd.errorMessage = this.errorMessageLogs;
      }); // catch
  },
  updateAff(data: any) {
    this.loading = true;
    this.expandData.departmentAd.errorMessage = '';
    this.expandData.departmentAd.sucessMessage = '';
    this.successMessageLogs = '';
    this.$http.put(Config.DEPARTMENT_API + '/' + data.departmentId, {
        name: data.name,
      })
      .then((response: any) => {
        //
        this.departmentItem.name = data.name;
        this.actionCust(data);
        this.$emit('getReload', true);
      })
      .then( null, (err: any) => {
        this.loading = false;
        switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        this.expandData.departmentAd.errorMessage = this.errorMessageLogs;
      }); // catch
  },
  async actionCust(data: any) {
    this.expandData.errorData.userDel = [];
    this.expandData.errorData.users = [];
    this.expandData.errorData.userEdit = [];
    await this.deleteData(data);
    for (const item of data.data) {
      if (item.disabled) {
        const i = this.expandData.departmentAd.dataOri.findIndex((x: any) =>
        x.departmentAdministratorId === item.relationId);
        if (i >= 0) {
          const department = this.expandData.departmentAd.dataOri[i];
          if (department.isAdminToEditUser !== item.isAdminToEditUser ||
          department.isAdminToAddUser !== item.isAdminToAddUser ||
          department.isAdminToSetPcodesToUser !== item.isAdminToSetPcodesToUser) {
            const result = await this.updateDepartmentItem(data.departmentId, item);
            if (result === false) {
              this.expandData.errorData.userEdit.push(item.userId.text);
            }
          }
        }
      } else {
        if (item.userId !== '' && item.userId.value !== undefined &&
          item.userId.value !== '' && item.userId.value !== null) {
          const res = await this.addDepartmentItem(data.departmentId, item);
          if (res === false) {
            this.expandData.errorData.users.push(item.userId.text);
          }
        }
      }
    }
    // 所属ユーザー
    const userError = [];
    const groupError = [];
    if (this.expandData.user.dataOri.length === 0 && this.expandData.user.dataOriDefault.length > 0) {
      for (const item of this.expandData.user.dataOriDefault) {
        let result;
        result  = await this.removeUserDepartment(item.relationId);
        if (result !== true) {
          userError.push(item.user.name);
        }
      }
    } else {
      const delData = this.expandData.user.dataOriDefault.filter((o1: any) =>
      !this.expandData.user.dataOri.some((o2: any) => o1.userId === o2.userId));
      const addData = this.expandData.user.dataOri.filter((o1: any) =>
      !this.expandData.user.dataOriDefault.some((o2: any) => o1.userId === o2.userId));
      if (delData.length > 0) {
        for (const item of delData) {
          let result;
          result  = await this.removeUserDepartment(item.relationId);
          if (result !== true) {
            //
            userError.push(item.user.name);
          }
        }
      }
      if (addData.length > 0) {
        for (const item of addData) {
          let result;
          result  = await this.postUserDepartment(item.userId, data.departmentId);
          if (result !== true) {
            userError.push(item.user.name);
          }
        }
      }
    }
    // 設定されている通行権限グループ
    if (this.expandData.group.dataOri.length === 0 && this.expandData.group.dataOriDefault.length > 0) {
      for (const item of this.expandData.group.dataOriDefault) {
        let result;
        result  = await this.removeGroupDepartment(item.relationId);
        if (result !== true) {
          groupError.push(item.group.name);
        }
      }
    } else {
      const delDataGroup = this.expandData.group.dataOriDefault.filter((o1: any) =>
      !this.expandData.group.dataOri.some((o2: any) => o1.groupId === o2.groupId));
      const addDataGroup = this.expandData.group.dataOri.filter((o1: any) =>
      !this.expandData.group.dataOriDefault.some((o2: any) => o1.groupId === o2.groupId));
      if (delDataGroup.length > 0) {
        for (const item of delDataGroup) {
          let result;
          result  = await this.removeGroupDepartment(item.relationId);
          if (result !== true) {
            groupError.push(item.group.name);
          }
        }
      }
      if (addDataGroup.length > 0) {
        for (const item of addDataGroup) {
          let result;
          result  = await this.addGroupDepartment(item.groupId, data.departmentId);
          if (result !== true) {
            groupError.push(item.group.name);
          }
        }
      }
    }
    this.isGetUser = false;
    this.countGetUser = 1;
    this.expandData.departmentAd.data = [];
    this.expandData.departmentAd.dataOri = [];
    this.getDepartmentAuth(data.departmentId);
    this.resetRealtionData();
    this.getDepartmentUserDetails(data.departmentId, 25, 1);
    this.getDepartmentGroupDetails(data.departmentId, 1);
    this.loading = false;
    const errorData = this.expandData.errorData;
    const dataResult = errorData.users.concat(errorData.userEdit).concat(errorData.userDel);
    if (dataResult.length > 0 || userError.length > 0 || groupError.length > 0) {
      this.errorMessageLogs = '';
      if (dataResult.length > 0) {
        this.errorMessageLogs += '所属権限( ' + dataResult.toString() + ')編集に失敗しています。<br />';
      }
      if (userError.length > 0) {
        this.errorMessageLogs += '所属ユーザー( ' + userError.toString() + ')編集に失敗しています。<br />';
      }
      if (groupError.length > 0) {
        this.errorMessageLogs += '通行権限グループ( ' + groupError.toString() + ')編集に失敗しています。<br />';
      }
      this.errorMessageLogs += '失敗した項目は反映されませんので再度確認してください。';
      this.successMessageLogs = '';
    } else {
      this.errorMessageLogs = '';
      this.successMessageLogs = Config.EDIT_AFFILIATION_SUCCESS;
    }
  },
  getUsers(page: any) {
    const isValid = this.isShowValidUser ? '&isValid=true' : '';

    this.$http.get(Config.USERS_LIST_SIMPLE_API + '?limit=10000&page=' + page + '&sortKey=asc:userId' + isValid + '&isExceptOptionalUser=true&exclude=thumbnail')
      .then((response: any) => {
        if (response.body.maxRecords > 0) {
          response.body.users.forEach((element: any) => {
            if (element.isAdmin === false) {
              this.userItems.push({text: element.name, value: element.userId});
            }
          });
          this.countGetUser++;
          if (response.body.maxRecords > response.body.endRecord) {
            this.getUsers(page + 1);
          } else {
            this.countGetUser++;
          }
        } else {
          this.userItems = [];
          this.countGetUser++;
        }
      })
      .then( null, (err: any) => {
        this.countGetUser++;
      }); // catch
  },
  async deleteData(data: any) {
    this.expandData.errorData.userDel = [];
    if (data.deleteData.length > 0) {
      for (const item of data.deleteData) {
        const result = await this.deleteDepartmentUser(item.relationId);
        if (result === false) {
          this.expandData.errorData.userDel.push(item.userId.text);
        }
      }
    }
  },
  deleteDepartmentUser(ID: any) {
    return new Promise((resolve, reject) => {
      this.$http.delete(Config.DEPARTMENT_ADMINISTRATOR_API + '/' + ID)
      .then((response: any) => {
        resolve(true);
      })
      .then(null, (error: any) => {
        resolve(false);
      });
    });
  },
  updateDepartmentItem(ID: any, data: any) {
    return new Promise((resolve, reject) => {
      this.$http.put(Config.DEPARTMENT_ADMINISTRATOR_API + '/' + data.relationId, {
        isAdminToEditUser: data.isAdminToEditUser,
        isAdminToAddUser: data.isAdminToAddUser,
        isAdminToSetPcodesToUser: data.isAdminToSetPcodesToUser,
      })
      .then((response: any) => {
        //
        resolve(true);
      })
      .then(null, (error: any) => {
        //
        resolve(false);
      });
    });
  },
  addDepartmentItem(ID: any, data: any) {
    return new Promise((resolve, reject) => {
      this.$http.post(Config.DEPARTMENT_ADMINISTRATOR_API, {
        departmentId: ID,
        userId: data.userId.value,
        isAdminToEditUser: data.isAdminToEditUser,
        isAdminToAddUser: data.isAdminToAddUser,
        isAdminToSetPcodesToUser: data.isAdminToSetPcodesToUser,
      })
      .then((response: any) => {
        //
        resolve(true);
      })
      .then(null, (error: any) => {
        //
        resolve(false);
      });
    });
  },
  loadAllUserForAdd(item: any) {
    this.isAffiliationAdd = true;
    this.successMessageLogs = '';
    this.errorMessageLogs = '';
    this.userItems = [];
    if (localStorage.isCustomUserAuthorityService === 'true') {
      this.getUsers(1);
    }
  },
  registerAff(data: any) {
    this.loading = true;
    this.expandData.departmentAd.errorMessage = '';
    this.expandData.departmentAd.sucessMessage = '';
    this.successMessageLogs = '';
    this.$http.post(Config.DEPARTMENT_API, {
        name: data.name,
      })
      .then(async (response: any) => {
        //
        if ( data.data .length > 0) {
          for (const item of data.data) {
            if (item.userId !== '' && item.userId.value !== undefined &&
            item.userId.value !== '' && item.userId.value !== null) {
              await this.addDepartmentItem(response.body.departmentId, item);
            }
          }
        }
        if ( data.groupIds .length > 0) {
          for (const item of data.groupIds) {
            const result = await this.addGroupDepartment(item.groupId, response.body.departmentId);
            if (result !== true) {
              this.expandData.errorData.groups.push(item.name);
            }
          }
        }
        if ( data.userIds .length > 0) {
          for (const item of data.userIds) {
            const result = await this.postUserDepartment(item.userId, response.body.departmentId);
            if (result !== true) {
              this.expandData.errorData.users.push(item.name);
            }
          }
        }
        this.loading = false;
        this.successMessageLogs = Config.ADD_AFFILIATION_SUCCESS;
        this.$emit('getReload', true);
      })
      .then( null, (err: any) => {
        this.loading = false;
        switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        this.expandData.departmentAd.errorMessage = this.errorMessageLogs;
      }); // catch
  },
  reloadDataGroup(item: any) {
    this.expandData.group.data = [];
    this.expandData.group.dataOri = [];
    this.expandData.group.total = 0;
    this.expandData.group.loading = true;
    this.expandData.group.errorMsg = '';
    if (this.isDetail) {
    this.getDepartmentGroupDetails(item.departmentId, 1);
    } else {
      this.getDepartmentGroup(item.departmentId, 1);
    }
  },
  getUsersAgain() {
    this.userItems = [];
    if (localStorage.isCustomUserAuthorityService === 'true') {
      this.getUsers(1);
    }
  },
  getDepartmentPermisson(departmentId: any) {
    return new Promise((resolve, reject) => {
      if (localStorage.isCustomUserAuthorityService === 'true') {
        this.expandData.isAdminToAddUser = false;
        this.$http.get(Config.DEPARTMENT_ADMINISTRATOR_API + '?limit=25&departmentId=' + departmentId + '&userId=' +
        localStorage.userProfileId)
        .then(async (response: any) => {
          if (response.body.departmentAdministrators.length > 0 &&
            response.body.departmentAdministrators[0].isAdminToAddUser) {
            this.expandData.isAdminToAddUser = true;
          }
          resolve(true);
        }).
        then( null, (err: any) => {
          resolve(false);
        });
      } else {
        resolve(false);
      }
    });
  },
  wait(ms: any) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms);
    });
  },
  getDepartmentUserInfo(userId: any) {
    return new Promise((resolve, reject) => {
      this.$http.get(Config.RELATION_DEPARTMENT_USER_API + `?userId=${encodeURIComponent(userId)}&embed=department`)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            resolve(response.body.relationsDepartmentUser);
          } else {
            resolve([]);
          }
        })
        .then( null, (err: any) => {
          resolve([]);
        });
    });
  },
  affiliationAddItemDirect(item: any) {
    this.expandData.user.loading = true;
    for (let i = 0; i < item.length; i++) {
      const ii = this.expandData.user.dataOri.findIndex((x: any) =>
      x.userId === item[i].userId);
      const index = i;
      if (ii < 0) {
        const el = item[index].user = item[index];
        this.expandData.user.dataOri.push(el);
      }
    }
    const dataOri: any = this.expandData.user.dataOri;
    this.expandData.user.data = dataOri;
    this.expandData.user.total = dataOri.length;
    this.expandData.user.perPage = 25;
    this.expandData.user.page = 1;
    this.expandData.user.keyword = '';
    setTimeout(() => {
      this.expandData.user.loading = false;
    }, 100);
  },
  affiliationRemoveItemDirect(item: any) {
    for (let i = 0; i < item.length; i++) {
      const index = i;
      const jj = this.expandData.user.dataOri.findIndex((x: any) => x.userId === item[index].userId);
      if (jj >= 0) {
        this.expandData.user.dataOri.splice(parseInt(jj), 1);
      }
    }
    const dataOri  = Array.of(...this.expandData.user.dataOri);
    this.expandData.user.data = dataOri.slice(0, 25);
    this.expandData.user.perPage = 25;
    this.expandData.user.page = 1;
    this.expandData.user.keyword = '';
  },
  groupAddItemDirect(data: any) {
    const item = data.groups;
    this.expandData.group.loading = true;
    for (let i = 0; i < item.length; i++) {
      const ii = this.expandData.group.dataOri.findIndex((x: any) =>
      x.groupId === item[i].groupId);
      const index = i;
      if (ii < 0) {
        const el = item[index];
        el.relationId = '';
        el.disable = true;
        const jj = this.expandData.group.dataOriDefault.findIndex((x: any) => x.groupId === item[index].groupId);
        if (jj >= 0) {
          el.relationId = this.expandData.group.dataOriDefault[jj].relationId;
        }
        this.expandData.group.dataOri.push(el);
      }
    }
    const dataOri: any = this.expandData.group.dataOri;
    this.expandData.group.data = dataOri;
    this.expandData.group.total = dataOri.length;
    this.expandData.group.perPage = 25;
    this.expandData.group.page = 1;
    this.expandData.group.keyword = '';
    setTimeout(() => {
      this.expandData.group.loading = false;
    }, 100);
  },
  groupRemoveItemDirect(data: any) {
    const item = data.groups;
    for (let i = 0; i < item.length; i++) {
      const index = i;
      const jj = this.expandData.group.dataOri.findIndex((x: any) => x.groupId === item[index].groupId);
      if (jj >= 0) {
        this.expandData.group.dataOri.splice(parseInt(jj), 1);
      }
    }
    const dataOri  = Array.of(...this.expandData.group.dataOri);
    this.expandData.group.data = dataOri.slice(0, 25);
    this.expandData.group.perPage = 25;
    this.expandData.group.page = 1;
    this.expandData.group.keyword = '';
  },
  resetRealtionData() {
    this.expandData.user.data = [];
    this.expandData.user.dataOri = [];
    this.expandData.user.dataOriDefault = [];
    this.expandData.user.total = 0;
    this.expandData.user.perPage = 25;
    this.expandData.user.page = 1;
    this.expandData.user.keyword = '';
    this.expandData.user.errorMgs = [];
    this.expandData.group.data = [];
    this.expandData.group.errorMgs = [];
    this.expandData.group.dataOri = [];
    this.expandData.group.total = 0;
    this.expandData.group.perPage = 25;
    this.expandData.group.page = 1;
    this.expandData.group.keyword = '';
  },
},
});
