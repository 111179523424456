

































































































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import moment from 'moment';
import UsersAllowedTable from '@/components/organisms/UsersAllowedTable/UsersAllowedTable.vue';
import AccessPermissionGroupTable from '@/components/organisms/AccessPermissionGroupTable/AccessPermissionGroupTable.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import ModalTitle from '@/components/molecules/ModalTitle/ModalTitle.vue';
import ModalMessage from '@/components/molecules/ModalTitle/ModalMessage.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import ModalDetail from '@/components/molecules/Modal/Modal.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import ModalLoading from '@/components/molecules/ModalTitle/ModalLoading.vue';
interface Type {
  groupId: string;
  groupName: string;
  userId: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
  userCount: number;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  groupSelected: Type[];
  isVaildOnly: boolean;
  showOption: boolean;
  expanded: any;
  tab: any;
  actionItems: any;
  userList: any;
  dataGroups: any;
  isConfirm: boolean;
  isShow: boolean;
  isShowDetail: boolean;
  departments: any;
  listAff: any;
  departmentInfo: any;
  isAction: string;
  rulesValidate: any;
  from: any;
  dataAllow: any;
  isReloadData: any;
  deleteData: any;
}

export default Vue.extend({
  name: 'AffiliationTable',
  components: {
    ShowPerPage,
    Pagination,
    DisplayOption,
    SelectPerPage,
    TextLink,
    UsersAllowedTable,
    AccessPermissionGroupTable,
    InputForm,
    ModalTitle,
    Modal,
    SuccessButton,
    ModalMessage,
    ModalDetail,
    ModalLoading,
  },
  props: {
    isGetUser: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingChild: {
      type: Boolean,
      default: false,
    },
    authority: {
      type: Object,
      default: ({}),
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    dataGroupsModal: {
      type: Object,
      default: {
        data: [],
        total: 0,
      },
    },
    expandData: {
      type: Object,
      default: ({}),
    },
    dataRelationUser: {
      type: Object,
      default: ({}),
    },
    dataRelationGroup: {
      type: Object,
      default: ({}),
    },
    userItems: {
      default: [],
    },
    isPage: {
        type: String,
        default: '',
      },
    departmentItem: {
      type: Object,
      default: ({}),
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (typeof localStorage.displayOptionGroupModal === 'undefined') {
      localStorage.setItem('displayOptionGroupModal', JSON.stringify(this.selectedDisplayOption));
    } else {
      this.selectedDisplayOption = JSON.parse(localStorage.displayOptionGroupModal);
    }
    if (this.isPage === 'detail') {
      this.isShowDetail = true;
    }
  },
  watch: {
    userItems(data: any) {
      if (data.length > 0) {
        const dataAllow  = Array.of(...data);
        this.dataAllow = dataAllow;
      }
    },
    loadingChild(val: any) {
      if (val === false && this.isPage === 'detail' && this.isAction === 'detail') {
        // this.isShow = true;
        this.isShowDetail = true;
        this.departments = this.departmentItem;
      }
    },
    loading(val: any) {
      if (val === false && this.isPage === 'detail' && this.isAction === 'detail') {
        // this.isShow = true;
        this.isShowDetail = true;
        this.departments = this.departmentItem;
      }
      this.deleteData = [];
    },
    groupSelected(val: Type[]) {
      this.$emit('select-groups', val.map((item) => item.groupId));
    },
    isRemoveGroupSelected(value) {
        this.groupSelected = [];
    },
    selectedDisplayOption(value) {
      localStorage.setItem('displayOptionGroupModal', JSON.stringify(value));
    },
    isGetUser(val: any) {
      if (val === true && this.isAction === 'edit') {
        if (this.expandData.departmentAd.data.length > 0) {
        this.listAff = [];
        for (const el of this.expandData.departmentAd.data) {
          this.listAff.push({
            userId: {value: el.userId, text: el.userName},
            isAdminToView: true,
            isAdminToAddUser: el.isAdminToAddUser,
            isAdminToEditUser: el.isAdminToEditUser,
            isAdminToSetPcodesToUser: el.isAdminToSetPcodesToUser,
            relationId: el.departmentAdministratorId,
            disabled: true,
          });
        }
        this.changeAllow();
      }
      }
    },
  },
  data: (): DataType => ({
    rulesValidate: {
      requiredName: (name: string) => !!name || '所属名は必須です。',
      validateName: (name: string) => {
        if (name !== null && name !== undefined && name !== '') {
          return name.length <= 64 || '所属名は64文字以内で入力してください。';
        } else {
          return true;
        }
      },
    },
    isShow: false,
    isShowDetail: false,
    isConfirm: false,
    dataGroups: {
      total: 1,
      data: [
        {
          name: 'group1',
          gate: 0,
          time: '時間帯設定1',
        },
        {
          name: 'group2',
          gate: 0,
          time: '時間帯設定1',
        },
      ],
    },
    userList: [
      {
        userName: 'ivctest1',
        userId: 'ivctest1',
      },
    ],
    actionItems: [
      { title: '所属ユーザー', tabname: 'tab1' },
      { title: '設定されている通行権限グループ', tabname: 'tab2' },
    ],
    tab: 0,
    expanded: [],
    groupSelected: [],
    selectedDisplayOption: [
      'name',
      'gateCount',
    ],
    displayOptionItems: [
      { label: '所属名', value: 'name', isDisabled: true },
    ],
    isVaildOnly: localStorage.isVaildDataGroup === 'true' ? true : false,
    showOption: false,
    departments: {},
    listAff: [{
      userId: '',
      isAdminToView: true,
      isAdminToAddUser: false,
      isAdminToEditUser: false,
      isAdminToSetPcodesToUser: false,
      relationId: '',
      disabled: false,
    }],
    departmentInfo: [],
    isAction: 'detail',
    from: '',
    dataAllow: [],
    isReloadData: false,
    deleteData: [],
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '所属名',
            align: 'start',
            sortable: true,
            width: '200px',
            value: 'name',
          },
        ];
        headers.push({
            text: '所属ユーザー数 / 通行権限グループ数',
            align: 'start',
            sortable: false,
            width: '350px',
            value: 'data-table-expand',
          });
        headers.push({
          text: '',
          align: 'end',
          sortable: false,
          width: '150px',
          value: 'icons',
        });
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.expanded = [];
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.expanded = [];
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
      });
      return items;
    },
    convertDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm');
    },
    loadAffDetails(item: any, isLoad: any) {
      this.isShowDetail = true;
      this.departments = item;
      this.isAction = 'detail';
      this.from = '';
      if (isLoad) {
        this.$emit('load-aff-detail', {isAction: this.isAction, departmentId: item.departmentId});
      }
    },
    loadEdit(item: any) {
      this.isShow = true;
      this.isShowDetail = false;
      this.departments = item;
      this.isAction = 'edit';
      this.from = 'edit';
      this.$emit('load-aff-detail', {isAction: this.isAction, departmentId: item.departmentId});
      this.listAff = [];
      if (this.expandData.departmentAd.data.length > 0) {
        for (const el of this.expandData.departmentAd.data) {
          this.listAff.push({
            userId: {value: el.userId, text: el.userName},
            isAdminToView: true,
            isAdminToAddUser: el.isAdminToAddUser,
            isAdminToEditUser: el.isAdminToEditUser,
            isAdminToSetPcodesToUser: el.isAdminToSetPcodesToUser,
            relationId: el.departmentAdministratorId,
            disabled: true,
          });
        }
        this.changeAllow();
      }
      this.departmentInfo.name = this.departments.name;
    },
    removeAff() {
      this.$emit('remove-detail-aff', this.departments);
    },
    loadAffDetailsAgain() {
      this.isShow = false;
      this.isShowDetail = true;
      this.isConfirm = false;
      this.$emit('load-detail-aff-again');
    },
    addAffiliation() {
      //
      this.listAff.push({
        userId: '',
        relationId: '',
        isAdminToView: true,
        isAdminToAddUser: false,
        isAdminToEditUser: false,
        isAdminToSetPcodesToUser: false,
        disabled: false,
      });
    },
    updateAff() {
      //
      const result = (this.$refs.AffAddForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        this.$emit('update-aff', {data: this.listAff,
          name: this.departmentInfo.name,
          departmentId: this.departments.departmentId,
          deleteData: this.deleteData,
        });
      }
    },
    editAff() {
      this.from = 'detail';
      this.isShowDetail = false;
      this.isShow = true;
      this.isAction = 'edit';
      if (this.expandData.departmentAd.data.length > 0) {
        this.listAff = [];
        for (const item of this.expandData.departmentAd.data) {
          this.listAff.push({
            userId: item.userId,
            isAdminToView: true,
            isAdminToAddUser: item.isAdminToAddUser,
            isAdminToEditUser: item.isAdminToEditUser,
            isAdminToSetPcodesToUser: item.isAdminToSetPcodesToUser,
            relationId: item.departmentAdministratorId,
            disabled: true,
          });
        }
      }
      this.departmentInfo.name = this.departments.name;
      this.$emit('load-aff-detail', {isAction: this.isAction, departmentId: this.departments.departmentId});
      this.changeAllow();
    },
    clickBack(departmentInfo: any) {
      if (this.from === 'detail') {
        this.isAction = 'detail';
        this.isShowDetail = true;
        this.departments.name = departmentInfo.name;
        this.loadAffDetails(this.departments, this.successMessageLogs !== '' ? true : false);
      } else {
        this.isShow = false;
        this.isShowDetail = false;
        this.$emit('close-page-modal', this.successMessageLogs !== '' || this.isReloadData === true ? true : false);
        if (this.isPage === 'user' ) {
          if (this.successMessageLogs !== '' || this.isReloadData === true) {
            this.$emit('get-list-group-modal');
            this.isReloadData = false;
          }
        }
        // if (this.successMessageLogs !== '') {
        //   this.$emit('get-list-user');
        // }
      }
    },
    changeAllow() {
      this.dataAllow.forEach((element: any, key: any) => {
        const check = this.listAff.some((item: any) => item.userId.value === element.value);
        if (check) {
          this.dataAllow[key].disabled = true;
        }
      });
    },
    deleteAff(index: any) {
      const i = this.dataAllow.map((e: { value: any; }) =>
      e.value).indexOf(this.listAff[index].userId.value);
      if (i >= 0) {
        this.dataAllow[i].disabled = false;
      }
      if (this.listAff[index].disabled) {
        this.deleteData.push(this.listAff[index]);
      }
      this.listAff.splice(parseInt(index), 1);
    },
  },
});
