










export interface BreadcrumbItem {
  disabled?: boolean;
  exact?: boolean;
  href?: string;
  link?: boolean;
  text: string | number;
  to?: string | object;
}

import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'Breadcrumbs',
  props: {
    items: {
      type: Array as PropType<BreadcrumbItem[]>,
      default: () => [],
    },
  },
});
