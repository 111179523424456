
























































































































import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import ModalTitle from '@/components/molecules/ModalTitle/ModalTitle.vue';
const currentYear = new Date().getFullYear();
const previousYear =  currentYear - 10;
const years: any[] = [];
for (let i = currentYear - 10; i <= currentYear ; i++) {
  years.push({
    text :  i.toString() + '年',
    value :  i.toString(),
  });
}
export interface DataType {
  yearsItems: any;
  years: any;
  dateTime: any;
  showModal: boolean;
  popupProcess: boolean;
  inputDate: string;
  itemDate: any;
  item1: any;
  item2: any;
  c: number;
}
export default Vue.extend({
  name: 'Holiday',
  components: {
    InputForm,
    TextLink,
    SuccessButton,
    ModalTitle,
  },
  props: {
    months: {
      default: () => [],
    },
    timeAdd: {
      default: () => [],
    },
    dayTypesCalendar: {
      default: () => [],
    },
    loading: {
      default: false,
    },
    isFinishDate: {
      default: false,
    },
    isFinishProcess: {
      default: false,
    },
    saveFinish: {
      default: false,
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogsModal: {
      type: String,
      default: '',
    },
    successMessageLogsModal: {
      type: String,
      default: '',
    },
    isSaveDate: {
      type: Number,
      default: 0,
    },
  },
  data: (): DataType => ({
    yearsItems: years,
    itemDate: {
      inputDate: '',
      date: [],
      dayTypeId: '',
    },
    years: '2021',
    showModal: false,
    popupProcess: false,
    dateTime: [],
    inputDate: '',
    item1: ['2021-01-01'],
    item2: ['2022-01', '2022-01-01'],
    c: 0,
  }),
  created() {
    this.$emit('isGetCalendar');
    // const element = document.getElementById('div1');
    // element.classList.add('otherclass');
    // $('p').addClass( 'myClass yourClass' );
  },
  watch: {
    isFinishDate(value: any) {
      if (value) {
        setTimeout(() => {this.setColor(true); }, 10);
      }
    },
    isSaveDate(value: any) {
      if (value) {
        setTimeout(() => {this.setColor(false); }, 10);
      }
    },
    loading(value: any) {
      if (value === false && this.saveFinish === true) {
        this.showModal = true;
      }
    },
  },
  mounted() {
    // setTimeout(() => {this.setColor(); }, 1);
  },
  methods: {
    weekday(value: any) {
      const d = new Date(value);
      let title = '';
      switch (d.getDay()) {
        case 0 : title = '日'; break;
        case 1 : title = '月'; break;
        case 2 : title = '火'; break;
        case 3 : title = '水'; break;
        case 4 : title = '木'; break;
        case 5 : title = '金'; break;
        case 6 : title = '土'; break;
      }
      return title;
    },
    getTitle(value: any) {
      const title = value[0].split('-');
      return title[0] + '年' + title[1] + '月';
    },
    setDate(value: any, date: any) {
      this.itemDate.inputDate = value;
      this.itemDate.inputDate = value;
      this.itemDate.date = date;
      // this.showModal = true;
      this.$emit('setDateOne', this.itemDate);
    },
    closeDate() {
      this.itemDate.inputDate = '';
      this.itemDate.date = [];
      this.showModal = false;
      this.$emit('closeDate');
    },
    closeDateFinish() {
      this.showModal = false;
      this.itemDate = {
        inputDate: '',
        date: [],
        dayTypeId: '',
      };
      this.$emit('closeDateFinish');
    },
    saveDate() {
      this.dateTime = [];
      this.showModal = true;
      this.popupProcess = false;
    },
    cancelDate() {
      this.showModal = false;
      this.popupProcess = false;
      this.$emit('setTypeDate', {
        typeDate : {value: 'Del', text: '設定なし'},
      });
    },
    setTypeDate() {
      if (this.dateTime !== undefined && this.dateTime !== null && this.dateTime.length !== 0) {
        this.$emit('setTypeDate', {
        typeDate : this.dateTime,
        });
      }
      this.showModal = false;
    },
    saveAllDate() {
      // this.showModal = true;
      this.popupProcess = true;
      this.itemDate.dayTypeId = this.dateTime;
      this.$emit('saveAllDate', {
        itemDate : this.itemDate,
      });
    },
    setColor(isDefault: any) {
      const allDates = document.querySelectorAll('.allig-date-picker .bg-title-date .v-btn__content');
      allDates.forEach((x, i) => {
        const parent = x.closest('.allig-date-picker');
        let fullDay = '';
        if (x.parentNode !== null) {
          (x.parentNode as HTMLElement).classList.remove('type7', 'type8', 'type9', 'typeA',
          'typeB', 'typeC', 'typeD', 'typeE', 'typeF', 'Del', 'change');
        }
        if ( null === parent ) {
          // not parent
        } else {
          const monthYearDiv = parent.querySelector('.v-date-picker-title__date > div');
          const monthYear = (monthYearDiv as HTMLElement).innerHTML;
          const day = (x as HTMLElement).innerHTML;
          fullDay = monthYear !== null ? monthYear.toString().replace('年', '-').replace('月', '-')
          + (parseInt(day) < 10 ? '0' + day : day) : '';
          let color = '';
          let action = '';
          this.months.forEach((element: any, key: any) => {
            const index  = element.dayTypeId.map((e: { date: any; }) => e.date).indexOf(fullDay);
            if (index >= 0) {
              if (element.dayTypeId[index].action !== undefined && element.dayTypeId[index].action !== '' &&
              element.dayTypeId[index].action !== null) {
                action = element.dayTypeId[index].action;
              }
              switch (element.dayTypeId[index].dayTypeName) {
                case '休日' : color = 'type7'; break;
                case '特日1' : color = 'type8'; break;
                case '特日2' : color = 'type9'; break;
                case '特日3' : color = 'typeA'; break;
                case '特日4' : color = 'typeB'; break;
                case '特日5' : color = 'typeC'; break;
                case '特日6' : color = 'typeD'; break;
                case '特日7' : color = 'typeE'; break;
                case '特日8' : color = 'typeF'; break;
                case '設定なし' : color = 'Del'; break;
                case '' : color = 'Del'; break;
              }
            }
          });
          if (x.parentNode !== null && color !== '') {
            if (action !== '') {
              (x.parentNode as HTMLElement).classList.add(action);
            }
            (x.parentNode as HTMLElement).classList.add(color);
          }
        }
      });
    },
  },
});
