import Vue from 'vue';
import * as Config from '@/config';
import { Plan } from '@/types/alligate';

/**
 * プラン情報を取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<Group[]>} 取得した全プラン
 */
export async function getMyPlan(vue: Vue): Promise<Plan> {
  try {
    const response = await vue.$axios.get(Config.PLAN_API);
    return response.data.plans[0];
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('プラン情報の取得に失敗しました。プラン情報を取得する権限がありません。'));
      case 404:
        throw(new Error('プランが設定されていません。'));
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}
