






























































































import Vue, { PropType } from 'vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
export interface DataType {
  selectedItem: any;
}

export default Vue.extend({
  name: 'GroupGateTable',
  components: {
    Pagination,
    ShowPerPage,
    TextLink,
  },
  props: {
    groupExpanedGate: {
      type: Map,
      default: new Map(),
    },
    groupId: {
      default: '',
    },
    isReRenderGate: {
      default: 0,
    },
    isRemoveGateSelected: {
      default: 0,
    },
  },
  data: (): DataType => ({
    selectedItem: [],
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'ゲートID',
            align: 'start',
            sortable: true,
            value: 'gate.gateId',
          },
          {
            text: 'ゲート名',
            align: 'start',
            sortable: true,
            value: 'gate.name',
          },
        ];
        return headers;
      },
    },
  },
  watch: {
    isReRenderGate(value) {
      this.$forceUpdate();
    },
    isRemoveGateSelected(value) {
      this.selectedItem = [];
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (index[0] === 'gate.gateId') {
          if (!isDescending[0]) {
              return (a.gate.gateId < b.gate.gateId) ? -1 : 1;
          } else {
              return (b.gate.gateId < a.gate.gateId) ? -1 : 1;
          }
        } else if (index[0] === 'gate.name') {
          if (!isDescending[0]) {
              return (a.gate.name < b.gate.name) ? -1 : 1;
          } else {
              return (b.gate.name < a.gate.name) ? -1 : 1;
          }
        } else {
          if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    changePage(val: any) {
      this.$emit('change-page-gate', {page: val, groupId: this.groupId});
    },
    clickJoinUser(ID: any) {
      this.$router.push({ path: Config.GROUP_ADD_GATE, query: {groupId: ID, goBack: 'groups'}});
    },
    clickGateDetail(ID: any, groupId: any) {
      this.$router.push({ path: Config.GATE_DETAIL, query: {gateId: ID, goBack: 'groups'}});
    },
  },
});
