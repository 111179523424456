var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c(
        "v-container",
        { staticStyle: { position: "relative" } },
        [
          _c("ModalLoading", { attrs: { loading: _vm.loadingChild } }),
          _vm._v(" "),
          _vm.successMessageLogs !== "" &&
          _vm.groupsAddFail.length > 0 &&
          _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.Message.ERROR_GATE_EDIT.replace(
                          "GROUP_NAME",
                          _vm.groupsAddFail.toString()
                        )
                      )
                    }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.successMessageLogs !== "" &&
          _vm.groupsAddFail.length === 0 &&
          _vm.loading === false &&
          _vm.errorMessageTime === ""
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-success" },
                [
                  _vm._v("\n      " + _vm._s(_vm.successMessageLogs) + " "),
                  _c("br")
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.successMessageLogs !== "" &&
          _vm.groupsAddFail.length === 0 &&
          _vm.loading === false &&
          _vm.errorMessageTime !== ""
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [
                  _vm._v("\n      " + _vm._s(_vm.successMessageLogs) + " "),
                  _c("br"),
                  _vm._v("\n      " + _vm._s(_vm.errorMessageTime) + "\n    ")
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [_vm._v("\n      " + _vm._s(_vm.errorMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("gate-edit-form", {
            attrs: {
              gateDetail: _vm.gateDetail,
              groupList: _vm.groupList,
              groupId: _vm.groupId,
              dataUnlockingTime: _vm.dataUnlockingTime,
              dayTypes: _vm.dayTypes,
              isFinishEditGate: _vm.isFinishEditGate,
              dataUnlockingTimeConvert: _vm.dataUnlockingTimeConvert,
              successMessageLogs: _vm.successMessageLogs,
              errorMessageLogs: _vm.errorMessageLogs,
              errorMessageTime: _vm.errorMessageTime,
              groupsAddFail: _vm.groupsAddFail,
              loading: _vm.loading,
              listGateModel: _vm.listGateModel
            },
            on: {
              clickUpdateGate: function($event) {
                return _vm.$emit("clickUpdateGate", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }