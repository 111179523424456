import Vue from 'vue';
import * as Config from '@/config';
import { Controller, Gate } from '@/types/alligate';

/**
 * 全制御盤を取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<Controller[]>} 取得した制御盤
 */
export async function getAllControllers(vue: Vue): Promise<Controller[]> {
  try {
    const controllers = await getAllControllersIter(vue, 1);
    // 重複を排除する
    const val: Controller[] = [];
    controllers.forEach((c) => {
      if (val.findIndex((v) => v.controllerId === c.controllerId) === -1) {
        val.push(c);
      }
    });
    return val;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw new Error('制御盤情報の取得に失敗しました。制御盤情報を取得する権限がありません。');
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 全制御盤を取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<Controller[]>} 取得した制御盤の配列
 */
async function getAllControllersIter(vue: Vue, page: number): Promise<Controller[]> {
  const query = `?limit=1000&page=${page}&embed=controller`;
  try {
    const response = await vue.$axios.get(Config.RELATION_CONTROLLER_GATE_API + query);
    const ret = response.data.relationsControllerGate.map((r: any) => r.controller);
    if (response.data.endRecord === response.data.maxRecords) {
      return ret;
    } else {
      const nextControllers = await getAllControllersIter(vue, page + 1);
      return ret.concat(nextControllers);
    }
  } catch (err) {
    throw err;
  }
}

/**
 * 全制御盤配下のゲートを取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<Gate[]>} 取得したゲート情報
 */
export async function getAllControllerGates(vue: Vue): Promise<Gate[]> {
  try {
    const gates = await getAllControllerGatesIter(vue, 1);
    return gates;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw new Error('ゲート情報の取得に失敗しました。ゲート情報を取得する権限がありません。');
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 全制御盤を取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<Gate[]>} 取得したゲート情報の配列
 */
async function getAllControllerGatesIter(vue: Vue, page: number): Promise<Gate[]> {
  const query = `?limit=1000&page=${page}&embed=gate`;
  try {
    const response = await vue.$axios.get(Config.RELATION_CONTROLLER_GATE_API + query);
    const ret = response.data.relationsControllerGate.map((r: any) => r.gate);
    if (response.data.endRecord === response.data.maxRecords) {
      return ret;
    } else {
      const nextGates = await getAllControllerGatesIter(vue, page + 1);
      return ret.concat(nextGates);
    }
  } catch (err) {
    throw err;
  }
}
