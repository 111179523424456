var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-reflection-card-table mt-5 mb-5 pa-5" },
    [
      _c("h3", [_vm._v("ゲート一覧")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "round-data" },
        [
          _c("v-data-table", {
            staticClass: "border-table scroll-table",
            attrs: {
              "must-sort": "",
              headers: _vm.headers,
              items: _vm.dataList,
              "items-per-page": -1,
              "item-key": "userId",
              "no-data-text": "データーはありません",
              "loading-text": "データを読み込中です",
              loading: _vm.isGetDataList,
              "hide-default-footer": "",
              "custom-sort": _vm.customSort
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.name",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", { staticClass: "item-user-id" }, [
                        _vm._v(_vm._s(item.name))
                      ])
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.cardSelected,
              callback: function($$v) {
                _vm.cardSelected = $$v
              },
              expression: "cardSelected"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-row", {
        staticStyle: { height: "20px" },
        attrs: { "no-gutters": "" }
      }),
      _vm._v(" "),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }