

























import { Vue } from 'vue-property-decorator';
import InAllRoomModalTemplate from '../../templates/InAllRoomModalTemplate/InAllRoomModalTemplate.vue';
import { Room } from '@/types/alligate';
import { SearchValue } from '@/components/molecules/Search/Search.vue';

export interface DataType {
  allRoom: Room[];
  inRoomSearchValue: SearchValue;
}
export default Vue.extend({
  name: 'InAllRoomModalPage',
  components: {
    InAllRoomModalTemplate,
  },
  props: {
    successMessages: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    inAllRoom: {
      type: Array,
      default: () => [],
    },
    inAllRoomLoading: {
      type: Boolean,
      default: false,
    },
    inAllRoomSearchValue: {
      type: Object as () => SearchValue,
      default: () => ({
        text: '',
        targets: ['name'],
      }),
    },
    roomTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    isDel: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerInAllRoomSearchValue: {
      get(): SearchValue {
        return this.inAllRoomSearchValue;
      },
      set(val: SearchValue): void {
        this.$emit('input-in-all-room-search-value', val);
        return;
      },
    },
  },
});
