var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "allig-users-template",
      staticStyle: { position: "relative" }
    },
    [
      _vm.isModal === false
        ? _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isModal
        ? _c(
            "v-overlay",
            {
              staticClass: "overlay-group-tab",
              attrs: { absolute: "", value: _vm.loading, color: "white" }
            },
            [
              _c("v-progress-circular", {
                staticClass: "user-loading-progress",
                attrs: { indeterminate: "", color: "green" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "container-common" },
        [
          _c("user-detail-table", {
            attrs: {
              listphones: _vm.listphones,
              listGatePassagesInUser: _vm.listGatePassagesInUser,
              tempGroupPassages: _vm.tempGroupPassages,
              cardItems: _vm.cardItems,
              phoneItems: _vm.phoneItems,
              successMessageLogs: _vm.successMessageLogs,
              errorMessageLogs: _vm.errorMessageLogs,
              messageSetting: _vm.messageSetting,
              user: _vm.userData,
              loading: _vm.loading,
              isModal: _vm.isModal,
              gateList: _vm.gateList,
              isGetDepartment: _vm.isGetDepartment,
              authority: _vm.authority,
              dataProgress: _vm.dataProgress,
              dataAllow: _vm.dataAllow,
              titlePage: _vm.titlePage
            },
            on: {
              "select-users": function(userIds) {
                _vm.selectedUserIds = userIds
              },
              "confirm-action": function($event) {
                return _vm.$emit("confirmAction", $event)
              },
              "add-gate": function($event) {
                return _vm.$emit("add-gate", $event)
              },
              "del-gate": function($event) {
                return _vm.$emit("del-gate", $event)
              },
              "loading-from-child": function($event) {
                return _vm.$emit("loading-from-child", $event)
              },
              "close-modal-gate": function($event) {
                return _vm.$emit("close-modal-gate", $event)
              },
              "load-user-again": function($event) {
                return _vm.$emit("load-user-again", $event)
              },
              goUserEdit: function($event) {
                return _vm.goUserEdit($event)
              },
              goUserList: _vm.goUserList,
              "close-popup": function($event) {
                return _vm.$emit("close-popup", $event)
              },
              getReload: function($event) {
                return _vm.$emit("getReload", $event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showConfirm,
            callback: function($$v) {
              _vm.showConfirm = $$v
            },
            expression: "showConfirm"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("ModalTitle", {
                attrs: { title: "関連付け削除" },
                on: {
                  click: function($event) {
                    return _vm.$emit("closeDialogConfirm")
                  }
                }
              }),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _c("v-card-text", { staticClass: "pa-4" }, [
                _c("p", [
                  _vm._v("この通行権限グループとの関連付けを解除しますか？")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c("success-button", {
                        attrs: { text: "OK" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("click-leave-action", {
                              leave: "gate-passage",
                              action: 4
                            })
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showProgress,
            callback: function($$v) {
              _vm.showProgress = $$v
            },
            expression: "showProgress"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v("関連付け削除")
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _vm.totalDataRowProcess > 0
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-progress-linear",
                        {
                          staticStyle: { "pointer-events": "none" },
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.progress,
                            callback: function($$v) {
                              _vm.progress = $$v
                            },
                            expression: "progress"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(Math.round(_vm.progress)) + "%")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.successItemProcess > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.totalDataRowProcess) +
                                "件中" +
                                _vm._s(_vm.successItemProcess) +
                                "件を削除しました。"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageProcess.length > 0
                        ? _c("div", [
                            _c("span", { staticClass: "error-box-title" }, [
                              _vm._v("削除失敗")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "error-box" },
                              [
                                _vm._l(_vm.errorMessageProcess, function(item) {
                                  return [
                                    _c("p", {
                                      key: item,
                                      domProps: { innerHTML: _vm._s(item) }
                                    })
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFinishProcess
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c("success-button", {
                            attrs: { text: "OK" },
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "closeModalProgress",
                                  _vm.userData.userId
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }