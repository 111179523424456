import { Excel } from '.';
import * as Config from '@/config';

export class FaceImageTemplateExcel extends Excel {
  constructor() {
    super();
    this.fileName = `face-image_template.xlsx`;
    this.url = Config.DOWNLOAD_FACE_IMAGE_TEMPLATE_API;
  }
}
