var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-checkbox", {
    staticClass: "text-caption-checkbox mr-5",
    attrs: { label: _vm.title, color: "green", dense: "", "hide-details": "" },
    model: {
      value: _vm.isValid,
      callback: function($$v) {
        _vm.isValid = $$v
      },
      expression: "isValid"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }