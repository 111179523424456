var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "form", staticClass: "add-form common-class" },
    [
      _c(
        "div",
        [
          _vm.loading === false
            ? _c(
                "v-row",
                { attrs: { justify: "start" } },
                [
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length > 0 &&
                  _vm.pCodeAddFail.length > 0 &&
                  _vm.gateAddFail.length > 0
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "mb-4 font-weight-bold mt-3 bg-error messageRow",
                          attrs: { cols: "12", align: "left" }
                        },
                        [
                          _vm.isEdit
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_EDIT_USER_1.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    )
                                      .replace(
                                        "GATE_NAME",
                                        _vm.gateAddFail.toString()
                                      )
                                      .replace(
                                        "CARD_NAME",
                                        _vm.pCodeAddFail.toString()
                                      )
                                  )
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_ADD_USER_1.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    )
                                      .replace(
                                        "GATE_NAME",
                                        _vm.gateAddFail.toString()
                                      )
                                      .replace(
                                        "CARD_NAME",
                                        _vm.pCodeAddFail.toString()
                                      )
                                  )
                                }
                              })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length > 0 &&
                  _vm.pCodeAddFail.length === 0 &&
                  _vm.gateAddFail.length === 0
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "mb-4 font-weight-bold mt-3 bg-error messageRow",
                          attrs: { cols: "12", align: "left" }
                        },
                        [
                          _vm.isEdit
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_EDIT_USER_2.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    )
                                  )
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_ADD_USER_2.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    )
                                  )
                                }
                              })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length === 0 &&
                  _vm.pCodeAddFail.length === 0 &&
                  _vm.gateAddFail.length > 0
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "mb-4 font-weight-bold mt-3 bg-error messageRow",
                          attrs: { cols: "12", align: "left" }
                        },
                        [
                          _vm.isEdit
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_EDIT_USER_3.replace(
                                      "GATE_NAME",
                                      _vm.gateAddFail.toString()
                                    )
                                  )
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_ADD_USER_3.replace(
                                      "GATE_NAME",
                                      _vm.gateAddFail.toString()
                                    )
                                  )
                                }
                              })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length === 0 &&
                  _vm.pCodeAddFail.length > 0 &&
                  _vm.gateAddFail.length === 0
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "mb-4 font-weight-bold mt-3 bg-error messageRow",
                          attrs: { cols: "12", align: "left" }
                        },
                        [
                          _vm.isEdit
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_EDIT_USER_4.replace(
                                      "CARD_NAME",
                                      _vm.pCodeAddFail.toString()
                                    )
                                  )
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_ADD_USER_4.replace(
                                      "CARD_NAME",
                                      _vm.pCodeAddFail.toString()
                                    )
                                  )
                                }
                              })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length === 0 &&
                  _vm.pCodeAddFail.length > 0 &&
                  _vm.gateAddFail.length > 0
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "mb-4 font-weight-bold mt-3 bg-error messageRow",
                          attrs: { cols: "12", align: "left" }
                        },
                        [
                          _vm.isEdit
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_EDIT_USER_5.replace(
                                      "GATE_NAME",
                                      _vm.gateAddFail.toString()
                                    ).replace(
                                      "CARD_NAME",
                                      _vm.pCodeAddFail.toString()
                                    )
                                  )
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_ADD_USER_5.replace(
                                      "GATE_NAME",
                                      _vm.gateAddFail.toString()
                                    ).replace(
                                      "CARD_NAME",
                                      _vm.pCodeAddFail.toString()
                                    )
                                  )
                                }
                              })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length > 0 &&
                  _vm.pCodeAddFail.length > 0 &&
                  _vm.gateAddFail.length === 0
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "mb-4 font-weight-bold mt-3 bg-error messageRow",
                          attrs: { cols: "12", align: "left" }
                        },
                        [
                          _vm.isEdit
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_EDIT_USER_6.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    ).replace(
                                      "CARD_NAME",
                                      _vm.pCodeAddFail.toString()
                                    )
                                  )
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_ADD_USER_6.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    ).replace(
                                      "CARD_NAME",
                                      _vm.pCodeAddFail.toString()
                                    )
                                  )
                                }
                              })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length > 0 &&
                  _vm.pCodeAddFail.length === 0 &&
                  _vm.gateAddFail.length > 0
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "mb-4 font-weight-bold mt-3 bg-error messageRow",
                          attrs: { cols: "12", align: "left" }
                        },
                        [
                          _vm.isEdit
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_EDIT_USER_7.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    ).replace(
                                      "GATE_NAME",
                                      _vm.gateAddFail.toString()
                                    )
                                  )
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_ADD_USER_7.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    ).replace(
                                      "GATE_NAME",
                                      _vm.gateAddFail.toString()
                                    )
                                  )
                                }
                              })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length === 0 &&
                  _vm.pCodeAddFail.length === 0 &&
                  _vm.gateAddFail.length === 0
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "mb-4 font-weight-bold mt-3 bg-success messageRow",
                          attrs: { cols: "12", align: "left" }
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.successMessageLogs)
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errorMessageLogs !== ""
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "mb-4 font-weight-bold mt-3 bg-error messageRow",
                          attrs: { cols: "12", align: "left" }
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.errorMessageLogs)
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-subtitle-2 border-left-blue font-weight-bold",
                  class: _vm.isModal ? "ml-2" : ""
                },
                [_vm._v("\n          ユーザー情報\n        ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.isModal ? "round-content-scroll" : "" },
            [
              _c(
                "v-card",
                { staticClass: "pa-6 pr-10 mb-5" },
                [
                  _c(
                    "v-form",
                    { ref: "UserAddForm", attrs: { "lazy-validation": "" } },
                    [
                      _c("input-form", {
                        attrs: {
                          title:
                            "ユーザーID <span class='required'>※必須</span>",
                          inputType: "text",
                          disabled: _vm.isEdit,
                          rules: [
                            _vm.rulesValidate.requiredUserId,
                            _vm.rulesValidate.validateUserId
                          ]
                        },
                        model: {
                          value: _vm.userInfo.userId,
                          callback: function($$v) {
                            _vm.$set(_vm.userInfo, "userId", $$v)
                          },
                          expression: "userInfo.userId"
                        }
                      }),
                      _vm._v(" "),
                      _c("input-form", {
                        attrs: {
                          title:
                            "ユーザー名 <span class='required'>※必須</span>",
                          inputType: "text",
                          rules: [
                            _vm.rulesValidate.requiredName,
                            _vm.rulesValidate.validateName
                          ]
                        },
                        model: {
                          value: _vm.userInfo.name,
                          callback: function($$v) {
                            _vm.$set(_vm.userInfo, "name", $$v)
                          },
                          expression: "userInfo.name"
                        }
                      }),
                      _vm._v(" "),
                      _c("input-form", {
                        attrs: {
                          title: "ふりがな",
                          inputType: "text",
                          rules: [_vm.rulesValidate.validateFurigana]
                        },
                        model: {
                          value: _vm.userInfo.furigana,
                          callback: function($$v) {
                            _vm.$set(_vm.userInfo, "furigana", $$v)
                          },
                          expression: "userInfo.furigana"
                        }
                      }),
                      _vm._v(" "),
                      _c("input-form", {
                        attrs: {
                          title: "メールアドレス",
                          inputType: "text",
                          rules: [_vm.rulesValidate.validateEmail]
                        },
                        model: {
                          value: _vm.userInfo.email,
                          callback: function($$v) {
                            _vm.$set(_vm.userInfo, "email", $$v)
                          },
                          expression: "userInfo.email"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-subheader", [
                                    _vm._v("所属選択 "),
                                    _vm.authority.isAdminToViewUser === true &&
                                    _vm.authority.isAdmin === false
                                      ? _c(
                                          "span",
                                          { staticClass: "required" },
                                          [_vm._v("※必須")]
                                        )
                                      : _vm._e()
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            [
                              _vm.isEdit
                                ? _c("v-select", {
                                    attrs: {
                                      items: _vm.getDepartments,
                                      "item-text": "text",
                                      "item-value": "value",
                                      "single-line": "",
                                      color: "green",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: "",
                                      "no-data-text": "",
                                      rules: [
                                        _vm.rulesValidate.requiredDepartment(
                                          _vm.userDepartmentId,
                                          _vm.authority,
                                          _vm.userInfo.isAdmin,
                                          _vm.listAff
                                        )
                                      ],
                                      disabled:
                                        _vm.isGetDepartmentAll === true ||
                                        (_vm.authority.isAdmin === false &&
                                          _vm.authority.isAdminToViewUser ===
                                            true),
                                      id: "departmentId",
                                      "menu-props": {
                                        contentClass: "v-select-item "
                                      }
                                    },
                                    model: {
                                      value: _vm.userDepartmentId,
                                      callback: function($$v) {
                                        _vm.userDepartmentId = $$v
                                      },
                                      expression: "userDepartmentId"
                                    }
                                  })
                                : _c("v-select", {
                                    attrs: {
                                      items: _vm.getDepartments,
                                      "item-text": "text",
                                      "item-value": "value",
                                      "single-line": "",
                                      color: "green",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: "",
                                      "no-data-text": "",
                                      rules: [
                                        _vm.rulesValidate.requiredDepartment(
                                          _vm.userInfo.departmentId,
                                          _vm.authority,
                                          _vm.userInfo.isAdmin,
                                          _vm.listAff
                                        )
                                      ],
                                      disabled: _vm.isGetDepartmentAll,
                                      id: "departmentId",
                                      "menu-props": {
                                        contentClass: "v-select-item "
                                      }
                                    },
                                    model: {
                                      value: _vm.userInfo.departmentId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.userInfo,
                                          "departmentId",
                                          $$v
                                        )
                                      },
                                      expression: "userInfo.departmentId"
                                    }
                                  })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isEdit
                        ? _c(
                            "v-row",
                            [
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c("v-subheader", [
                                        _vm._v(
                                          "\n              スマートフォン\n            "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c("v-combobox", {
                                    staticClass: "list-phone",
                                    attrs: {
                                      items: _vm.phoneItems,
                                      multiple: "",
                                      "deletable-chips": "",
                                      chips: "",
                                      autocomplete: "off",
                                      color: "green",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: "",
                                      "search-input": _vm.searchInput,
                                      "menu-props": {
                                        contentClass: "v-select-item "
                                      }
                                    },
                                    on: {
                                      "update:searchInput": function($event) {
                                        _vm.searchInput = $event
                                      },
                                      "update:search-input": function($event) {
                                        _vm.searchInput = $event
                                      },
                                      change: function($event) {
                                        return _vm.updatePhoneTags($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.userInfo.phoneIds,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userInfo, "phoneIds", $$v)
                                      },
                                      expression: "userInfo.phoneIds"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.authority.isAdmin === true ||
                      _vm.checkShowGate() === true
                        ? _c(
                            "v-row",
                            [
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c("v-subheader", [
                                        _vm._v(
                                          "\n              所有カード\n            "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c("v-combobox", {
                                    staticClass: "list-card",
                                    attrs: {
                                      id: "id-list-card",
                                      items: _vm.pCodeOptions,
                                      multiple: "",
                                      "deletable-chips": "",
                                      chips: "",
                                      autocomplete: "off",
                                      color: "green",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: "",
                                      "search-input": _vm.searchInput,
                                      "menu-props": {
                                        contentClass: "v-select-item "
                                      }
                                    },
                                    on: {
                                      "update:searchInput": function($event) {
                                        _vm.searchInput = $event
                                      },
                                      "update:search-input": function($event) {
                                        _vm.searchInput = $event
                                      },
                                      change: function($event) {
                                        return _vm.updateTags($event)
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function(data) {
                                            return [
                                              _c(
                                                "v-chip",
                                                _vm._b(
                                                  {
                                                    key: JSON.stringify(
                                                      data.item
                                                    ),
                                                    attrs: {
                                                      "input-value":
                                                        data.selected,
                                                      disabled: data.disabled,
                                                      close: ""
                                                    },
                                                    on: {
                                                      "click:close": function(
                                                        $event
                                                      ) {
                                                        return data.parent.selectItem(
                                                          data.item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  "v-chip",
                                                  data.attrs,
                                                  false
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(data.item.text) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "no-data",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          "\n                  選択できるカードがありません。番号を入力後、Enterキー押下で「NFCカード」「テンキー入力」を選択で新規カード追加ができます。\n                "
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1294057616
                                    ),
                                    model: {
                                      value: _vm.userInfo.pCodeIds,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userInfo, "pCodeIds", $$v)
                                      },
                                      expression: "userInfo.pCodeIds"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.authority.isAdmin
                        ? _c(
                            "v-row",
                            [
                              _c("v-col", { staticClass: "col-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "v-subheader theme--light ",
                                    attrs: { "data-v-f4f20dd2": "" }
                                  },
                                  [_vm._v(" 権限")]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.authorityItems,
                                      "item-text": "text",
                                      "item-value": "value",
                                      "single-line": "",
                                      disabled: _vm.sameUser,
                                      color: "green",
                                      "hide-details": "auto",
                                      "no-data-text": "",
                                      outlined: "",
                                      dense: "",
                                      "menu-props": {
                                        contentClass: "v-select-item "
                                      },
                                      id: "isAdmin"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.changeisAdmin(
                                          _vm.userInfo.isAdmin
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.userInfo.isAdmin,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userInfo, "isAdmin", $$v)
                                      },
                                      expression: "userInfo.isAdmin"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.userInfo.isAdmin === null &&
                      _vm.authority.isAdmin === true
                        ? _c(
                            "div",
                            [
                              _vm.erroMessage.isAff
                                ? _c(
                                    "v-row",
                                    { staticClass: "error-isAff" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "col-2 mt-0 mb-0 pb-0 pt-0"
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "v-subheader theme--light ",
                                            attrs: { "data-v-f4f20dd2": "" }
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { staticClass: "mt-0 mb-0 pb-0 pt-0" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "v-messages error--text error-customize"
                                            },
                                            [
                                              _vm._v(
                                                "\n                カスタマイズ権限の時は一つ以上入力してください。\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", {
                                    staticClass: "col-2 mb-0 pb-0 pt-0"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { staticClass: "col-2 mb-0 pb-0 pt-0" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "v-subheader theme--light ml-0 pa-0 mt-0 mb-0"
                                        },
                                        [_vm._v("所属")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.listAff, function(item, i) {
                                return _c(
                                  "div",
                                  { key: i + 1, staticClass: "mt-0" },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-col", { staticClass: "col-2" }, [
                                          _c("div", {
                                            staticClass:
                                              "v-subheader theme--light"
                                          })
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "col-2 mt-0",
                                            attrs: { sm: "10", lg: "2" }
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "2" } },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          al: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.deleteAff(
                                                              i
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "gray--text",
                                                            attrs: {
                                                              "align-self":
                                                                "ceb="
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-minus-circle"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "10" } },
                                                  [
                                                    _c("v-combobox", {
                                                      attrs: {
                                                        items:
                                                          _vm.dataAllow
                                                            .department,
                                                        autocomplete: "off",
                                                        color: "green",
                                                        "hide-details": "auto",
                                                        "no-data-text": "",
                                                        "item-text": "text",
                                                        "item-value": "value",
                                                        outlined: "",
                                                        dense: "",
                                                        disabled:
                                                          _vm.isGetDepartment ===
                                                            true ||
                                                          item.disabled ===
                                                            true,
                                                        "search-input":
                                                          item.searchInput,
                                                        "menu-props": {
                                                          contentClass:
                                                            "v-select-item v-select-item-disabled"
                                                        }
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.changeDepartment,
                                                        "update:searchInput": function(
                                                          $event
                                                        ) {
                                                          return _vm.$set(
                                                            item,
                                                            "searchInput",
                                                            $event
                                                          )
                                                        },
                                                        "update:search-input": function(
                                                          $event
                                                        ) {
                                                          return _vm.$set(
                                                            item,
                                                            "searchInput",
                                                            $event
                                                          )
                                                        },
                                                        blur:
                                                          _vm.changeDepartmentInput
                                                      },
                                                      model: {
                                                        value:
                                                          item.departmentId,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "departmentId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.departmentId"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        !_vm.$vuetify.breakpoint.lgAndUp
                                          ? _c("v-col", {
                                              attrs: { cols: "2" }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "col-8",
                                            attrs: { sm: "8", lg: "8" }
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass: "item-check-box "
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: { sm: "6", lg: "3" }
                                                  },
                                                  [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        disabled: "",
                                                        color: "grey",
                                                        dense: "",
                                                        "hide-details": "",
                                                        label:
                                                          "ユーザー閲覧権限"
                                                      },
                                                      model: {
                                                        value:
                                                          item.isAdminToView,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "isAdminToView",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.isAdminToView"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: { sm: "6", lg: "3" }
                                                  },
                                                  [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        color: "green",
                                                        dense: "",
                                                        "hide-details": "",
                                                        label:
                                                          "ユーザー追加権限"
                                                      },
                                                      model: {
                                                        value:
                                                          item.isAdminToAddUser,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "isAdminToAddUser",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.isAdminToAddUser"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: { sm: "6", lg: "3" }
                                                  },
                                                  [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        color: "green",
                                                        dense: "",
                                                        "hide-details": "",
                                                        label:
                                                          "ユーザー編集権限"
                                                      },
                                                      model: {
                                                        value:
                                                          item.isAdminToEditUser,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "isAdminToEditUser",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.isAdminToEditUser"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: { sm: "6", lg: "3" }
                                                  },
                                                  [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        color: "green",
                                                        dense: "",
                                                        "hide-details": "",
                                                        label: "カード設定権限"
                                                      },
                                                      model: {
                                                        value:
                                                          item.isAdminToSetPcodesToUser,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "isAdminToSetPcodesToUser",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.isAdminToSetPcodesToUser"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "col-2 mt-0 mb-0 pt-0 pb-0",
                                            attrs: { cols: "12" }
                                          },
                                          [
                                            _vm.isShowMsg(
                                              _vm.erroMessage.messageAff,
                                              i
                                            )
                                              ? _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "col-2 mt-0 mb-0 pt-0 pb-0"
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "v-subheader theme--light"
                                                        })
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "col-2 mt-0 mb-0 pt-0 pb-0",
                                                        attrs: { cols: "10" }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "v-messages error--text"
                                                          },
                                                          [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML: _vm._s(
                                                                  _vm.isMsgDepartment(
                                                                    _vm
                                                                      .erroMessage
                                                                      .messageAff,
                                                                    i
                                                                  )
                                                                )
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c("v-col", { staticClass: "col-2 pt-0" }),
                                  _vm._v(" "),
                                  _c("v-col", { staticClass: "col-2 pt-0" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "v-subheader theme--light ml-0 pa-0 mt-0 add-button",
                                        on: { click: _vm.addAffiliation }
                                      },
                                      [_vm._v(" + 追加する ")]
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "pt-0 mt-0" },
                                [
                                  _c("v-col", {
                                    staticClass: "col-2 mb-0 pb-0 pt-0"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { staticClass: "col-2 mb-0 pb-0 pt-0" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "v-subheader theme--light ml-0 pa-0 mt-0 mb-0"
                                        },
                                        [_vm._v(" 通行権限グループ")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.listGroup, function(item, j) {
                                return _c(
                                  "v-row",
                                  { key: j + 1000, staticClass: "mt-0" },
                                  [
                                    _c("v-col", { staticClass: "col-2" }, [
                                      _c("div", {
                                        staticClass: "v-subheader theme--light",
                                        attrs: { "data-v-f4f20dd2": "" }
                                      })
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "col-2",
                                        attrs: { sm: "10", lg: "2" }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: { icon: "", al: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteGroup(
                                                          j
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "gray--text",
                                                        attrs: {
                                                          "align-self": "ceb="
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-minus-circle"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "10" } },
                                              [
                                                _c("v-combobox", {
                                                  attrs: {
                                                    items: _vm.dataAllow.group,
                                                    autocomplete: "off",
                                                    color: "green",
                                                    "hide-details": "auto",
                                                    "no-data-text": "",
                                                    "item-text": "text",
                                                    "item-value": "value",
                                                    outlined: "",
                                                    dense: "",
                                                    disabled:
                                                      _vm.isDisabledGroupOptions ===
                                                        true ||
                                                      item.disabled === true,
                                                    "search-input":
                                                      item.searchInput,
                                                    "menu-props": {
                                                      contentClass:
                                                        "v-select-item-group-permisson"
                                                    }
                                                  },
                                                  on: {
                                                    change: _vm.changeGroup,
                                                    "update:searchInput": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        item,
                                                        "searchInput",
                                                        $event
                                                      )
                                                    },
                                                    "update:search-input": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        item,
                                                        "searchInput",
                                                        $event
                                                      )
                                                    },
                                                    blur: _vm.changeGroupInput
                                                  },
                                                  model: {
                                                    value: item.groupId,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "groupId",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.groupId"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    !_vm.$vuetify.breakpoint.lgAndUp
                                      ? _c("v-col", { attrs: { cols: "2" } })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "col-8",
                                        attrs: { sm: "8", lg: "8" }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          { staticClass: "item-check-box" },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "6", lg: "2" } },
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    disabled: "",
                                                    color: "grey",
                                                    dense: "",
                                                    "hide-details": "",
                                                    label: "ゲート閲覧権限"
                                                  },
                                                  model: {
                                                    value: item.isAdminToView,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "isAdminToView",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.isAdminToView"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "6", lg: "4" } },
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    color: "green",
                                                    dense: "",
                                                    "hide-details": "",
                                                    label:
                                                      "通行可能所属/ユーザーの設定権限"
                                                  },
                                                  model: {
                                                    value:
                                                      item.isAdminOfGroupPassages,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "isAdminOfGroupPassages",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.isAdminOfGroupPassages"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "6", lg: "4" } },
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    color: "green",
                                                    dense: "",
                                                    "hide-details": "",
                                                    label:
                                                      "一時通行可能ユーザーの設定権限"
                                                  },
                                                  model: {
                                                    value:
                                                      item.isAdminOfGatePassages,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "isAdminOfGatePassages",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.isAdminOfGatePassages"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "6", lg: "2" } },
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    color: "green",
                                                    dense: "",
                                                    "hide-details": "",
                                                    label: "遠隔解錠権限"
                                                  },
                                                  model: {
                                                    value:
                                                      item.isAdminOfRemoteUnlock,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "isAdminOfRemoteUnlock",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.isAdminOfRemoteUnlock"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "col-2 mt-0 mb-0 pt-0 pb-0",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _vm.isShowMsg(
                                          _vm.erroMessage.messageGroup,
                                          j
                                        )
                                          ? _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-2 mt-0 mb-0 pt-0 pb-0"
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "v-subheader theme--light"
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-2 mt-0 mb-0 pt-0 pb-0",
                                                    attrs: { cols: "10" }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "v-messages error--text"
                                                      },
                                                      [
                                                        _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.isMsgGroup(
                                                                _vm.erroMessage
                                                                  .messageGroup,
                                                                j
                                                              )
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "mt-0 pt-0 mb-0 pb-0" },
                                [
                                  _c("v-col", {
                                    staticClass: "col-2 pt-0 pb-0"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { staticClass: "col-2 pt-0 pb-0" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "v-subheader theme--light ml-0 pa-0 mt-0 add-button",
                                          on: { click: _vm.addGroup }
                                        },
                                        [_vm._v(" + 追加する ")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.authority.isAdmin
                        ? _c("input-form", {
                            staticClass: "v-input--reverse swicth-all",
                            attrs: {
                              title: "ゲート設定権限",
                              inputType: "switch",
                              disabled: _vm.sameUser
                            },
                            model: {
                              value: _vm.userInfo.isPhoneAdmin,
                              callback: function($$v) {
                                _vm.$set(_vm.userInfo, "isPhoneAdmin", $$v)
                              },
                              expression: "userInfo.isPhoneAdmin"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input-form", {
                        staticClass: "v-input--reverse swicth-all",
                        attrs: {
                          title: "アプリへのログイン",
                          inputType: "switch",
                          disabled: _vm.sameUser
                        },
                        model: {
                          value: _vm.userInfo.isPasswordLoginValid,
                          callback: function($$v) {
                            _vm.$set(_vm.userInfo, "isPasswordLoginValid", $$v)
                          },
                          expression: "userInfo.isPasswordLoginValid"
                        }
                      }),
                      _vm._v(" "),
                      _vm.isNoPasswordUser === true &&
                      _vm.userInfo.isPasswordLoginValid === true
                        ? _c("input-form", {
                            attrs: {
                              title:
                                "パスワード <span class='required'>※必須</span>",
                              inputType: "password",
                              rules: [
                                _vm.rulesValidate.validatePassword(
                                  _vm.userInfo.password,
                                  _vm.userInfo.isPasswordLoginValid
                                )
                              ]
                            },
                            model: {
                              value: _vm.userInfo.password,
                              callback: function($$v) {
                                _vm.$set(_vm.userInfo, "password", $$v)
                              },
                              expression: "userInfo.password"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "expand-color" },
                        [
                          _vm.isModal === false
                            ? _c("v-col", { staticClass: "col-2" })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("v-col", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "allig-text-link fontsize-title-expand",
                                class: _vm.isModal ? "ml-5" : "",
                                on: {
                                  click: function() {
                                    _vm.isClickExpandArea = !_vm.isClickExpandArea
                                  }
                                }
                              },
                              [
                                _vm._v("\n            詳細設定\n            "),
                                _vm.isClickExpandArea
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "green", size: "15" } },
                                      [_vm._v("mdi-delta")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isClickExpandArea
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "green", size: "15" } },
                                      [_vm._v("mdi-details")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _vm.isModal === false
                            ? _c("v-col", { staticClass: "col-2" })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            [
                              _vm.isClickExpandArea
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "padding-row-expand",
                                      class: _vm.isModal ? "ml-5" : ""
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _vm.$vuetify.breakpoint.mdAndUp
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "2" } },
                                                [
                                                  _c("v-subheader", [
                                                    _vm._v(
                                                      "Web管理画面ログイン時のSMS認証"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-switch", {
                                                class: _vm.$vuetify.breakpoint
                                                  .mdAndUp
                                                  ? "mt-2 v-input--reverse swicth-all input-sms"
                                                  : "mt-0 v-input--reverse swicth-all",
                                                attrs: {
                                                  color: "green",
                                                  "hide-details": "auto",
                                                  inset: "",
                                                  label:
                                                    _vm.userInfo.isMfaSms ===
                                                    true
                                                      ? "ON"
                                                      : "OFF",
                                                  disabled: _vm.isMfa
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.ChangeValue(
                                                      $event
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: _vm.userInfo.isMfaSms,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.userInfo,
                                                      "isMfaSms",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userInfo.isMfaSms"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        [
                                          _vm.$vuetify.breakpoint.mdAndUp
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "2" } },
                                                [
                                                  _c("v-subheader", [
                                                    _vm._v("電話番号"),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "required"
                                                      },
                                                      [_vm._v("※必須")]
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  color: "green",
                                                  "hide-details": "auto",
                                                  outlined: "",
                                                  dense: "",
                                                  disabled: !_vm.checkSMS,
                                                  rules: [
                                                    _vm.rulesValidate.validatePhone(
                                                      _vm.userInfo.phoneNumber,
                                                      _vm.checkSMS
                                                    )
                                                  ]
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "append",
                                                      fn: function() {
                                                        return [
                                                          _vm._t("text-append")
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    _vm.userInfo.phoneNumber,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.userInfo,
                                                      "phoneNumber",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userInfo.phoneNumber"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        [
                                          _vm.$vuetify.breakpoint.mdAndUp
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "2" } },
                                                [
                                                  _c(
                                                    "v-subheader",
                                                    [
                                                      _vm._v(
                                                        "解錠時の二段階認証"
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        "パスワード \n                    "
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                ""
                                                                            }
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-help-circle"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            2569386827
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              " 二段階認証パスワードは、カード＋テンキーによる二段階の解錠用パスワードです。"
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              "Lock Proでご利用いただくには、リーダーの設定変更が必要です。"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  color: "green",
                                                  "hide-details": "auto",
                                                  outlined: "",
                                                  dense: "",
                                                  rules: [
                                                    _vm.rulesValidate.validatePassCode(
                                                      _vm.userInfo.passCode
                                                    )
                                                  ]
                                                },
                                                model: {
                                                  value: _vm.userInfo.passCode,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.userInfo,
                                                      "passCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userInfo.passCode"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.isEdit &&
                                      _vm.authority.isAdmin === true
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "pa-0 mt-0" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col col-3 pd-0 mt-0"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "v-subheader theme--light font-weight-bold"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "ログインパスワード変更"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("input-form", {
                                                attrs: {
                                                  title: "新しいパスワード",
                                                  inputType: "password",
                                                  rules: [
                                                    _vm.rulesValidate
                                                      .validateNewPassword
                                                  ],
                                                  disabled: _vm.sameUser
                                                },
                                                model: {
                                                  value:
                                                    _vm.userInfo.newPassword,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.userInfo,
                                                      "newPassword",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userInfo.newPassword"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("input-form", {
                                                attrs: {
                                                  title:
                                                    "新しいパスワード再入力",
                                                  inputType: "password",
                                                  rules: [
                                                    _vm.rulesValidate.validateConfirmPass(
                                                      _vm.userInfo
                                                        .confirmPassword,
                                                      _vm.userInfo.newPassword
                                                    )
                                                  ],
                                                  disabled: _vm.sameUser
                                                },
                                                model: {
                                                  value:
                                                    _vm.userInfo
                                                      .confirmPassword,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.userInfo,
                                                      "confirmPassword",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userInfo.confirmPassword"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { staticClass: "pd-0 mt-0" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col col-2 pa-0 mt-0"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "v-subheader theme--light"
                                                        },
                                                        [_c("span")]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col pa-0 mt-0"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "v-subheader theme--light"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _c("p", [
                                                              _vm._v(
                                                                "ログインパスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。"
                                                              )
                                                            ])
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("input-form", {
                                        staticClass:
                                          "v-input--reverse swicth-all",
                                        attrs: {
                                          title: "鍵共有権限",
                                          inputType: "switch",
                                          disabled: _vm.sameUser
                                        },
                                        model: {
                                          value: _vm.userInfo.shareKeyBeValid,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.userInfo,
                                              "shareKeyBeValid",
                                              $$v
                                            )
                                          },
                                          expression: "userInfo.shareKeyBeValid"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("input-form", {
                                        attrs: {
                                          title: "備考.1",
                                          inputType: "text",
                                          rules: [
                                            _vm.rulesValidate.validateOption1
                                          ]
                                        },
                                        model: {
                                          value: _vm.userInfo.option1,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.userInfo,
                                              "option1",
                                              $$v
                                            )
                                          },
                                          expression: "userInfo.option1"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("input-form", {
                                        attrs: {
                                          title: "備考.2",
                                          inputType: "text",
                                          rules: [
                                            _vm.rulesValidate.validateOption2
                                          ]
                                        },
                                        model: {
                                          value: _vm.userInfo.option2,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.userInfo,
                                              "option2",
                                              $$v
                                            )
                                          },
                                          expression: "userInfo.option2"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.authority.isAdmin
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "text-subtitle-2 border-left-blue font-weight-bold"
                            },
                            [_vm._v("\n        通行可能なゲート\n        ")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isEdit
                        ? _c(
                            "v-card",
                            { staticClass: "pt-2 mb-5" },
                            [
                              _vm.loading === false
                                ? _c("ModalPageGroupGate", {
                                    attrs: {
                                      userName: _vm.userInfo.name,
                                      isPage: "detail-user-group-gate",
                                      userId: _vm.userInfo.userId
                                    },
                                    on: {
                                      "change-page-group": function($event) {
                                        return _vm.$emit(
                                          "change-page-group",
                                          $event
                                        )
                                      },
                                      loading: function($event) {
                                        return _vm.$emit(
                                          "loading-from-child",
                                          $event
                                        )
                                      },
                                      "close-popup": function($event) {
                                        return _vm.$emit("close-popup", $event)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _c(
                            "v-card",
                            { staticClass: "pt-2 mb-5" },
                            [
                              _vm.loading === false
                                ? _c("ModalPageGroupGate", {
                                    attrs: {
                                      isPage: "add-user-group-gate",
                                      userId: "",
                                      userName: _vm.userInfo.name
                                    },
                                    on: {
                                      "change-page-group": function($event) {
                                        return _vm.$emit(
                                          "change-page-group",
                                          $event
                                        )
                                      },
                                      loading: function($event) {
                                        return _vm.$emit(
                                          "loading-from-child",
                                          $event
                                        )
                                      },
                                      "select-Ids": function(Ids) {
                                        _vm.selectedIds = Ids
                                      },
                                      "select-data-add": function(Ids) {
                                        _vm.addIds = Ids
                                      },
                                      "close-popup": function($event) {
                                        return _vm.$emit("close-popup", $event)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "text-subtitle-2 border-left-blue font-weight-bold"
                            },
                            [
                              _vm._v(
                                "\n            期限付き解錠可能ゲート\n          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { staticClass: "pa-6 pr-10 mb-5" },
                        [
                          _c(
                            "v-data-table",
                            {
                              staticClass: "ml-1 border-table table-gate-time",
                              attrs: {
                                "must-sort": "",
                                "custom-sort": _vm.customSort,
                                headers: _vm.headers,
                                items: _vm.realtionsGateList,
                                "items-per-page": 1000,
                                "no-data-text": "データーはありません",
                                "loading-text": "データを読み込中です",
                                "hide-default-footer": "",
                                dense: "",
                                "item-value": "value"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function(row) {
                                      return [
                                        _c("tr", [
                                          _c("td", [
                                            row.item.gate
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(row.item.gate.text)
                                                  )
                                                ])
                                              : _vm._e()
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatDateTime(
                                                    new Date(
                                                      row.item.notBefore * 1000
                                                    )
                                                  )
                                                ) +
                                                " ~ " +
                                                _vm._s(
                                                  _vm.formatDateTime(
                                                    new Date(
                                                      row.item.notAfter * 1000
                                                    )
                                                  )
                                                )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "last-col" },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  attrs: { justify: "center" }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        al: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteGate(
                                                            row.index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "gray--text",
                                                          attrs: {
                                                            "align-self": "ceb="
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-trash-can-outline"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3227956197
                              )
                            },
                            [
                              _c("template", { slot: "body.prepend" }, [
                                _c("tr", { staticClass: "mt-4" }, [
                                  _c(
                                    "td",
                                    [
                                      _c("v-autocomplete", {
                                        staticClass: "input-add",
                                        attrs: {
                                          items: _vm.gateList,
                                          "return-object": "",
                                          color: "green",
                                          outlined: "",
                                          dense: "",
                                          "hide-details": "auto",
                                          "no-data-text": "",
                                          "menu-props": {
                                            contentClass: "v-select-item"
                                          }
                                        },
                                        model: {
                                          value: _vm.itemGate,
                                          callback: function($$v) {
                                            _vm.itemGate = $$v
                                          },
                                          expression: "itemGate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("DateTimePicker", {
                                        attrs: {
                                          errorAddListGate:
                                            _vm.errorAddListGate,
                                          isUserPage: true,
                                          dateStartValue: _vm.dateStartGate,
                                          dateEndValue: _vm.dateEndGate,
                                          timeSValue: _vm.timeStartGate,
                                          timeEValue: _vm.timeEndGate
                                        },
                                        on: {
                                          getNewTimeStart: _vm.getNewTimeStart,
                                          getNewTimeEnd: _vm.getNewTimeEnd,
                                          getNewDateStart: _vm.getNewDateStart,
                                          getNewDateEnd: _vm.getNewDateEnd
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "align-self-center" },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "center" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "btn-primary",
                                              attrs: {
                                                disabled:
                                                  _vm.gateList.length > 0
                                                    ? false
                                                    : true,
                                                outlined: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addGate()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      追加\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.errorAddListGate !== "" &&
                                _vm.errorGate === "" &&
                                _vm.errorTime === ""
                                  ? _c("tr", [
                                      _c("td", { attrs: { colspan: "3" } }, [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "error-special add hours"
                                          },
                                          [_vm._v(_vm._s(_vm.errorAddListGate))]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.errorAddListGate === "" &&
                                (_vm.errorGate !== "" || _vm.errorTime !== "")
                                  ? _c("tr", [
                                      _c("td", [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "error-special add hours"
                                          },
                                          [_vm._v(_vm._s(_vm.errorGate))]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "error-special add end-time"
                                          },
                                          [_vm._v(_vm._s(_vm.errorTime))]
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0",
                      attrs: { align: "right", cols: "12" }
                    },
                    [
                      _vm.isEdit
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: {
                                outlined: "",
                                disabled: _vm.isDisable()
                              },
                              on: {
                                click: function($event) {
                                  return _vm.clickUpdateUser()
                                }
                              }
                            },
                            [_vm._v("\n            保存する\n          ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: {
                                outlined: "",
                                disabled: _vm.isDisableRegister()
                              },
                              on: {
                                click: function($event) {
                                  return _vm.clickSaveUser()
                                }
                              }
                            },
                            [_vm._v("\n            登録\n          ")]
                          ),
                      _vm._v(" "),
                      _vm.isModal === false
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("backUserList", $event)
                                }
                              }
                            },
                            [_vm._v("\n          キャンセル\n          ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-row", { attrs: { justify: "end" } }, [
                _vm.successMessageLogs !== "" && _vm.loading === false
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "font-weight-bold bg-success bg-no align-self-center mr-2 mt-5"
                      },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.successMessageLogs)
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.errorMessageLogs !== "" && _vm.loading === false
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "font-weight-bold bg-error bg-no align-self-center mr-2 mt-5"
                      },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.errorMessageLogs) }
                        })
                      ]
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Modal", {
        attrs: {
          title: "カード種別の選択",
          smallScreen: "600px",
          className: "common-modal"
        },
        scopedSlots: _vm._u([
          {
            key: "modal-body",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "pa-5" },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "margin-row-option",
                        attrs: { justify: "center" }
                      },
                      [
                        _c(
                          "v-radio-group",
                          {
                            attrs: { row: "" },
                            model: {
                              value: _vm.type,
                              callback: function($$v) {
                                _vm.type = $$v
                              },
                              expression: "type"
                            }
                          },
                          [
                            _c("v-radio", {
                              staticClass: "radio-item ml-2",
                              attrs: {
                                color: "green-primary",
                                label: "NFCカード",
                                value: "0"
                              }
                            }),
                            _vm._v(" "),
                            _c("v-radio", {
                              staticClass: "radio-item",
                              attrs: {
                                color: "green-primary",
                                label: "テンキー入力",
                                value: "1"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c("v-spacer"),
                _vm._v(" "),
                _c("success-button", {
                  attrs: { text: "OK" },
                  on: {
                    click: function($event) {
                      return _vm.addPcode()
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.showModal,
          callback: function($$v) {
            _vm.showModal = $$v
          },
          expression: "showModal"
        }
      }),
      _vm._v(" "),
      _c("Modal", {
        attrs: {
          title: _vm.isEdit
            ? "編集したユーザーのメールアドレスへ通知メールを送信しますか？"
            : "作成したユーザーのメールアドレスへ通知メールを送信しますか？",
          smallScreen: "800px",
          className: "common-modal"
        },
        scopedSlots: _vm._u([
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c("v-spacer"),
                _vm._v(" "),
                _c("success-button", {
                  staticClass: "mr-2",
                  attrs: { text: "送信する" },
                  on: {
                    click: function($event) {
                      return _vm.yesChoice()
                    }
                  }
                }),
                _vm._v(" "),
                _c("cancel-button", {
                  attrs: { text: "送信しない" },
                  on: {
                    click: function($event) {
                      return _vm.noChoice()
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.showConfirm,
          callback: function($$v) {
            _vm.showConfirm = $$v
          },
          expression: "showConfirm"
        }
      }),
      _vm._v(" "),
      _c("Modal", {
        attrs: {
          title: "カード登録",
          smallScreen: "600px",
          className: "common-modal"
        },
        scopedSlots: _vm._u([
          {
            key: "modal-body",
            fn: function() {
              return [
                _c("div", { staticClass: "pa-5" }, [
                  _c("div", { staticClass: "bock-info" }, [
                    _c("div", { staticClass: "alert alert-info col" }, [
                      _vm._v(
                        "\n                  カード番号に半角英字の小文字が含まれております。"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                  小文字が含まれているとカードが正常に読み込まれません。"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                  小文字が含まれているかを確認してください。\n                "
                      )
                    ])
                  ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c("v-spacer"),
                _vm._v(" "),
                _c("success-button", {
                  attrs: { text: "見直す" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialogConfirm()
                    }
                  }
                }),
                _vm._v(" "),
                _c("error-button", {
                  staticClass: "mr-1 ml-2",
                  attrs: { text: "小文字が含まれていても登録する" },
                  on: {
                    click: function($event) {
                      return _vm.yesChoiceLowerCase()
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.isHasLowerCaseCardNumber,
          callback: function($$v) {
            _vm.isHasLowerCaseCardNumber = $$v
          },
          expression: "isHasLowerCaseCardNumber"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }