













































































import Vue, { PropType } from 'vue';
import { SelectItem } from '@/components/molecules/InputForm/InputForm.vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import Alert from '@/components/molecules/Alert/Alert.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Tabs, { TabItem } from '@/components/molecules/Tabs/Tabs.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import ZenescanFaceDbList from '@/components/organisms/ZenescanFaceDbList/ZenescanFaceDbList.vue';
import ZenescanFaceImageList from '@/components/organisms/ZenescanFaceImageList/ZenescanFaceImageList.vue';
import { ZenescanFaceDb, FaceDb, FaceImage, PCode, User, ProgressModalInfo, ZenescanRequestLogin } from '@/types/alligate-zenescan';
import { SearchValue } from '@/components/molecules/Search/Search.vue';

export const TAB_VALUE_ZENESACAN_FACE_DB = 'zenescan-face-db';
export const TAB_VALUE_ZENESACAN_FACE_IMAGE = 'zenescan-face-image';

export interface DataType {
  showSuccessMessages: boolean[];
  breadcrumbs: BreadcrumbItem[];
  tabItems: TabItem[];
}

interface TypeSelect {
  text: string;
  value: string;
}

export default Vue.extend({
  name: 'ZenescanTemplate',
  components: {
    Loading,
    Alert,
    Breadcrumbs,
    Modal,
    Tabs,
    CancelButton,
    SuccessButton,
    ZenescanFaceDbList,
    ZenescanFaceImageList,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    successMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    tab: {
      type: String,
      default: TAB_VALUE_ZENESACAN_FACE_IMAGE,
    },
    zenescanFaceDbLoading: {
      type: Boolean,
      default: false,
    },
    zenescanFaceDbs: {
      type: Array as PropType<ZenescanFaceDb[]>,
      default: () => [],
    },
    faceDbs: {
      type: Array as PropType<FaceDb[]>,
      default: () => [],
    },
    showSelectZenescanFaceDb: {
      type: Boolean,
      default: false,
    },
    zenescanFaceImageLoading: {
      type: Boolean,
      default: false,
    },
    faceImages: {
      type: Array as PropType<FaceImage[]>,
      default: () => [],
    },
    showSelectZenescanFaceImage: {
      type: Boolean,
      default: true,
    },
    typeItems: {
      type: Array as PropType<TypeSelect[]>,
      default: () => [],
    },
    pCodeItems: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    userItems: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    pCodes: {
      type: Array as PropType<PCode[]>,
      default: () => [],
    },
    users: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
    faceImageProgressModalInfo: {
      type: Object as PropType<ProgressModalInfo>,
      default: () => {
        return {
          showModal: false,
          indeterminate: false,
          title: '',
          message: '',
          errorMessage: '',
          totalCount: 0,
          finishedCount: 0,
          errorCount: 0,
          errorContents: [],
        };
      },
    },
    faceImageSearchValue: {
      type: Object as PropType<SearchValue>,
      default: () => ({
        text: '',
        targets: ['pCodeId', 'userId', 'userName'],
      }),
    },
    isSelectedClearFaceImage: {
      type: Boolean,
      default: () => false,
    },
    faceImageTotalCount: {
      type: Number,
      default: 0,
    },
    faceImagePage: {
      type: Number,
      default: 1,
    },
    faceImagePerPage: {
      type: Number,
      default: 25,
    },
    isDisabledPaginationFaceImage: {
      type: Boolean,
      default: () => true,
    },
  },
  data: (): DataType => ({
    showSuccessMessages: [],
    breadcrumbs: [
      { text: '顔認証連携設定', disabled: true },
    ],
    tabItems: [
      { text: '顔写真リスト', value: TAB_VALUE_ZENESACAN_FACE_IMAGE },
      // { text: 'Znescan設定', value: TAB_VALUE_ZENESACAN_FACE_DB },
    ],
  }),
  computed: {
    innerTab: {
      get(): string {
        return this.tab;
      },
      set(val: string) {
        this.$emit('input-tab', val);
      },
    },
    innerFaceImageSearchValue: {
      get(): SearchValue {
        return this.faceImageSearchValue;
      },
      set(val: SearchValue): void {
        this.$emit('input-search-value', val);
        return;
      },
    },
  },
});
