












import {Vue } from 'vue-property-decorator';
import KeyAddTemplate from '@/components/templates/KeyTemplate/KeyAddTemplate.vue';
import * as Config from '@/config';
import moment from 'moment';
export interface DataType {
  loading: boolean;
  successMessageLogs: string;
  errorMessageLogs: string;
  passagesData: any;
  listTimes: any;
  existGateId: any;
  listGatePassages: any;
  listGroupPassages: any;
  listrelationsGateGroup: any;
  tempGroupPassages: any;
  currentTime: any;
}
export default Vue.extend({
  name: 'KeyAddPage',
  components: {
    KeyAddTemplate,
  },
  data: (): DataType => ({
    loading: false,
    passagesData: {
        gateId: '0',
        terms: '0',
        isOnce: false,
        result: '',
      },
    listTimes: [
      { text: '1時間', value: 1 },
      { text: '2時間', value: 2 },
      { text: '3時間', value: 3 },
      { text: '4時間', value: 4 },
      { text: '5時間', value: 5 },
      { text: '6時間', value: 6 },
      { text: '7時間', value: 7 },
      { text: '8時間', value: 8 },
      { text: '9時間', value: 9 },
      { text: '10時間', value: 10 },
      { text: '11時間', value: 11 },
      { text: '12時間', value: 12 },
      { text: '13時間', value: 13 },
      { text: '14時間', value: 14 },
      { text: '15時間', value: 15 },
      { text: '16時間', value: 16 },
      { text: '17時間', value: 17 },
      { text: '18時間', value: 18 },
      { text: '19時間', value: 19 },
      { text: '20時間', value: 20 },
      { text: '21時間', value: 21 },
      { text: '22時間', value: 22 },
      { text: '23時間', value: 23 },
      { text: '24時間', value: 24},
    ],
    existGateId: [],
    listGatePassages: null,
    listGroupPassages: [],
    listrelationsGateGroup: null,
    tempGroupPassages: null,
    currentTime: '',
    successMessageLogs: '',
    errorMessageLogs: '',
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  created() {
    //
    this.currentTime = Math.round(+new Date() / 1000);
    this.getInforGatePassage(localStorage.userProfileId);
    this.getInforGroupPassage(localStorage.userProfileId);
  },
  methods: {
    generateKey(data: any) {
      this.loading = true;
      const params = {
        gateId: data.gateId,
        terms: data.terms,
        isOnce: data.isOnce,
      };
      this.$http.post(Config.KEY_SHARE_API, JSON.stringify(params))
        .then((response: any) => {
          this.loading = false;
          this.passagesData.result = response.body.url;
          this.successMessageLogs = '一時キー発行されました。';
        })
        .then( null, (error: any) => {
          this.loading = false;
          this.errorMessageLogs = Config.ERROR_503;
        });
    },
    // ゲート取得
    getInforGate(arrayDataName: any, userId: any, gateId: any, i: any) {
      const obj = this.existGateId.find((o: any) => o.gateId === gateId);
      if (obj === undefined && arrayDataName[i].gate.isValid === true && arrayDataName[i].gate.isVisitor === 0
      && arrayDataName[i].gate.makerCode !== 91 && arrayDataName[i].gate.gateModelCode !== 'ALIG-030') {
        this.existGateId.push({
          gateId: arrayDataName[i].gate.gateId,
          gateName: arrayDataName[i].gate.name !== undefined ? arrayDataName[i].gate.name :
          arrayDataName[i].gate.gateName,
        });
      }
    },
    // 期限付きゲート
    getInforGatePassage(userId: any) {
      this.$http.get(Config.GATES_PASSAGE_API + '?userId=' + encodeURIComponent(userId) +
      '&notBefore=' + this.currentTime + '&notAfter=' + this.currentTime + '&embed=gate')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            const gatePassages = response.body.passages;
            for (let i = 0; i < gatePassages.length; i++) {
                this.getInforGate(gatePassages, gatePassages[i].userId,
                gatePassages[i].gateId, i);
            }
          }
        })
        .then( null, (error: any) => {
          // get error
        });
    },
    // ゲート&グループ連関取得
    getGateGroup(userId: any, groupId: any, i: any) {
      this.$http.get(Config.RELATION_GATE_GROUP_API + '?groupId=' + encodeURIComponent(groupId) + '&embed=gate,group')
        .then((response: any) => {
          const gateGroup = response.body.relationsGateGroup;
          for (let j = 0; j < gateGroup.length; j++) {
            if (gateGroup[j].group.isValid) {
              this.getInforGate(gateGroup, userId, gateGroup[j].gateId, j);
            }
          }
        })
        .then( null, (error: any) => {
          // get error
        });
    },
    // ゲートグループ通行権限取得
    getInforGroupPassage(userId: any) {
      this.$http.get(Config.GROUPS_PASSAGE_API + '?userId=' + encodeURIComponent(userId))
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            const groupPassages = response.body.groupPassages;
            for (let i = 0; i < groupPassages.length; i++) {
              this.getGateGroup(groupPassages[i].userId, groupPassages[i].groupId, i);
            }
          }
        })
        .then( null, (error: any) => {
          // get error
        });
    },
    converDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm');
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
  },
});
