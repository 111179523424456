import Vue from 'vue';
import * as Config from '@/config';
import { PCode } from '@/types/alligate';

export interface PutPcodeBody {
  name?: string;
  userId?: string;
  isValid: boolean;
}

export interface PostPcodeBody {
  pCodeId: string;
  type: number;
  isValid: boolean;
  name?: string;
  userId?: string;
}

/**
 * 全個人コードを取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<PCode[]>} 取得した全ユーザ
 */
export async function getAllPcodes(vue: Vue): Promise<PCode[]> {
  try {
    const pcodes = await getAllPcodesIter(vue, 1);
    return pcodes;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('個人コード情報の取得に失敗しました。ユーザー情報を取得する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 全個人コードを取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<PCode[]>} 取得した全ユーザ
 */
async function getAllPcodesIter(vue: Vue, page: number): Promise<PCode[]> {
  const query = `?limit=1000&page=${page}&sortKey=${Config.DESC}:${Config.REGISTERED}`;
  try {
    const response = await vue.$axios.get(Config.CARD_LIST_API + query);
    if (response.data.endRecord === response.data.maxRecords) {
      return response.data.pCodes;
    } else {
      const nextUsers = await getAllPcodesIter(vue, page + 1);
      return response.data.pCodes.concat(nextUsers);
    }
  } catch (err) {
    throw err;
  }
}

/**
 * 指定したIDの個人コード情報の変更を行う。
 * @param {Vue} vue Vueインスタンス
 * @param {String} ID 変更対象のpCodeId
 * @param {putPcodeBody} body 登録する個人コードの内容
 * @returns {Promise<any>} PUT /pcode/{ID} のレスポンス
 */
export async function putPcode(vue: Vue, ID: string, body: PutPcodeBody): Promise<any> {
  try {
    const response = await vue.$axios.put(Config.PCODE_LIST_API + `/${encodeURIComponent(ID)}`, body);
    return response.data;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('個人コード情報の変更に失敗しました。個人コード情報を変更する権限がありません。'));
      case 404:
        throw(new Error('個人コード情報の変更に失敗しました。個人コード情報は既に削除されています。'));
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_PUT);
    }
  }
}

/**
 * 個人コード情報の追加を行う。
 * @param {Vue} vue Vueインスタンス
 * @param {PostPcodeBody} body 登録する個人コード
 * @returns {Promise<any>} POST /notice-setting のレスポンス
 */
export async function postPcode(vue: Vue, body: PostPcodeBody): Promise<any> {
  try {
    const response = await vue.$axios.post(Config.PCODE_LIST_API, body);
    return response.data;
  } catch (err) {
    switch ((err as any).response.status) {
      case 400:
        throw(new Error('個人コード情報の追加に失敗しました。'));
      case 403:
        throw(new Error('個人コード情報の追加に失敗しました。個人コード情報を追加する権限がありません。'));
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_POST);
    }
  }
}

/**
 * 指定の個人コードを１件取得する
 * @param {Vue} vue Vueインスタンス
 * @param {string} pCodeId 個人コード
 * @return {Promise<PCode[]>} 取得した個人コード
 */
export async function getPcode(vue: Vue, pCodeId: string): Promise<PCode[]> {
  const query = `?embed=user&pCodeId=${pCodeId}`;
  try {
    const response = await vue.$axios.get(Config.CARD_LIST_API + query);
    return response.data.pCodes[0];
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('個人コード情報の取得に失敗しました。ユーザ情報を取得する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * リストで指定した個人コードを取得する
 * @param {Vue} vue Vueインスタンス
 * @param {string[]} pCodeIds 個人コードリスト
 * @return {Promise<PCode[]>} 取得した個人コード
 */
export async function getTargetPcodes(vue: Vue, pCodeIds: string[]): Promise<PCode[]> {
  try {
    const pcodes = await getTargetPcodesIter(vue, pCodeIds, 0);
    return pcodes;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('個人コード情報の取得に失敗しました。ユーザ情報を取得する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * リストで指定した個人コードを取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {string[]} pCodeIds 個人コードリスト
 * @param {number} index 対象index
 * @return {Promise<PCode[]>} 取得した全個人コード
 */
async function getTargetPcodesIter(vue: Vue, pCodeIds: string[], index: number): Promise<PCode[]> {

  const query = `?embed=user&pCodeId=${pCodeIds[index]}`;
  try {
    const response = await vue.$axios.get(Config.CARD_LIST_API + query);
    if (pCodeIds.length === index + 1) {
      return response.data.pCodes;
    } else {
      const nextUsers = await getTargetPcodesIter(vue, pCodeIds, index + 1);
      return response.data.pCodes.concat(nextUsers);
    }
  } catch (err) {
    throw err;
  }
}

/**
 * 全個人コード(ユーザー情報付き)を取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<PCode[]>} 取得した全個人コード
 */
export async function getAllPcodesEmbedUser(vue: Vue): Promise<PCode[]> {
  try {
    const pcodes = await getAllPcodesEmbedUserIter(vue, 1);
    return pcodes;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('個人コード情報の取得に失敗しました。ユーザ情報を取得する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 全個人コード(ユーザー情報付き)を取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<PCode[]>} 取得した全ユーザ
 */
async function getAllPcodesEmbedUserIter(vue: Vue, page: number): Promise<PCode[]> {
  const query = `?embed=user&limit=1000&page=${page}&sortKey=${Config.DESC}:${Config.REGISTERED}`;
  try {
    const response = await vue.$axios.get(Config.CARD_LIST_API + query);
    if (response.data.endRecord === response.data.maxRecords) {
      return response.data.pCodes;
    } else {
      const nextUsers = await getAllPcodesEmbedUserIter(vue, page + 1);
      return response.data.pCodes.concat(nextUsers);
    }
  } catch (err) {
    throw err;
  }
}
