



































































































import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';

export default Vue.extend({
  name: 'LogLabelSetting',
  components: {
    ShowPerPage,
    SelectPerPage,
    Pagination,
  },
  props: {
    listConfifLog: {
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    pages: {
      type: Number,
      default: 1,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    maxRecord: {
      type: Number,
      default: 0,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '照合ログID',
            align: 'center',
            sortable: true,
            width: '150px',
            value: 'accessLogId',
          },
        ];
        headers.push({
          text: 'ログ名称',
          align: 'start',
          sortable: true,
          value: 'logLabel',
        });
        headers.push({
          text: 'アクション',
          align: 'center',
          sortable: false,
          width: '200px',
          value: 'actionEdit',
        });
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', {page: val, change: 'log'});
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
});
