
































import Vue, { PropType } from 'vue';
import ControlPanelAddForm from '@/components/organisms/ControlPanelAddForm/ControlPanelAddForm.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import ModalLoading from '@/components/molecules/ModalTitle/ModalLoading.vue';

interface TypeSelect {
  text: string;
  value: string;
}
export default Vue.extend({
  name: 'ControlPanelAddTemplate',
  components: {
    ControlPanelAddForm,
    Breadcrumbs,
    ModalLoading,
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeSelect[]>,
      default: () => [],
    },
    loadingChild: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    dataControlPanels: {
      default: {},
    },
    dataAllGates: {
      default: [],
    },
    controllerModel: {
      default: [],
    },
    gateList: {
      default: [],
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: this.isEdit ? '制御盤編集' : '制御盤追加', disabled: true },
        ];
      },
    },
  },
});
