

















import {Vue } from 'vue-property-decorator';
import LayoutTemplate from '@/components/templates/LayoutTemplate/LayoutTemplate.vue';
import auth from '@/auth';
import { PERSONAL_SETTING, USER_CHANGE_PASSWORD } from '@/config';
import * as Config from '@/config';
import store from '@/store';
export default Vue.extend({
  name: 'LayoutTemplatePage',
  components: {
    LayoutTemplate,
  },
  data() {
    return {
      pathName : '',
      isAdmin : false,
      selectedItemNav: 0,
      authority: {},
      showSubMenu: false,
      loading: false,
    };
  },
  async created() {
    console.log = (mgs: any, val: any) => {
      if (mgs !== 'err:' || mgs === '') {
        return null;
      }
    };
    const dataAuth = store.state.auth;
    if (dataAuth.isAdmin === undefined || dataAuth.isAdmin === null) {
      this.loading = true;
      await this.getPlan();
      this.getProfile();
    } else {
      this.isAdmin = dataAuth.isAdmin;
      this.authority = store.state.auth;
    }
  },
  mounted() {
    if (typeof localStorage.selectedItemNav === 'undefined') {
      localStorage.setItem('selectedItemNav', '0');
      this.selectedItemNav = 0;
    } else {
      this.selectedItemNav = parseInt(localStorage.selectedItemNav);
    }
  },
  methods: {
    activeMenu(menuName: any) {
      if (menuName !== 'in-room-management') {
        this.showSubMenu = false;
      }

      switch (menuName) {
        case 'user' : this.selectedItemNav = 1; break;
        case 'groups' : this.selectedItemNav = 2; break;
        case 'cards' : this.selectedItemNav = 3; break;
        case 'gates' : this.selectedItemNav = 4; break;
        case 'log' : this.selectedItemNav = 5; break;
        case 'setting' : this.selectedItemNav = 7; break;
        case 'reflection-status' : this.selectedItemNav = 8; break;
        case 'external' : this.selectedItemNav = 9; break;
        case 'safie' : this.selectedItemNav = 9; break;
        case 'in-room-management' : this.showSubMenu = !this.showSubMenu; this.selectedItemNav = 12; break;
        case 'in-room-management/room' : this.selectedItemNav = 10; break;
        case 'in-room-management/user' : this.selectedItemNav = 11; break;
        case 'key-add' : this.selectedItemNav = 13; break;
        default: this.selectedItemNav = 0; break;
      }
      this.pathName = menuName;
      localStorage.setItem('selectedItemNav', this.selectedItemNav.toString());
    },
    menuClick(menuName: string) {
      if (menuName !== 'in-room-management') {
        this.showSubMenu = false;
      }

      this.pathName = menuName;
      const pathCurrent = `/${menuName}`;
      console.log('menuName', menuName);
      switch (menuName) {
        case 'user' : this.selectedItemNav = 1; break;
        case 'groups' : this.selectedItemNav = 2; break;
        case 'cards' : this.selectedItemNav = 3; break;
        case 'gates' : this.selectedItemNav = 4; break;
        case 'log' : this.selectedItemNav = 5; break;
        case 'in-room-management' : this.showSubMenu = !this.showSubMenu; this.selectedItemNav = 12; break;
        case 'in-room-management/room' : this.selectedItemNav = 10; break;
        case 'in-room-management/user' : this.selectedItemNav = 11; break;
        case 'setting' : this.selectedItemNav = 7; break;
        case 'reflection-status' : this.selectedItemNav = 8; break;
        case 'external' : this.selectedItemNav = 9; break;
        case 'safie' : this.selectedItemNav = 9; break;
        case 'key-add' : this.selectedItemNav = 13; break;
        default: this.selectedItemNav = 0; break;
      }
      localStorage.setItem('selectedItemNav', this.selectedItemNav.toString());
      if ((menuName === 'in-room-management/room' ||  menuName === 'in-room-management/user')) {
        if (menuName === 'in-room-management/user' && this.$route.query.userId !== undefined) {
          this.$router.push({ path: pathCurrent});
        } else if (this.$route.path !== pathCurrent) {
          this.$router.push({ path: pathCurrent});
        }
        // this.$router.go(0);
      } else if (menuName === 'in-room-management') {
        return;
      } else if (this.$route.path !== pathCurrent) {
        this.$router.push({ path: pathCurrent});
      } else {
        this.$router.go(0);
      }
    },
    logout(): void {
      auth.logout();
    },
    personal(): void {
      const url = '/user/edit?userId=' + localStorage.userProfileId + '&goBack=personal';
      if (url !== this.$route.fullPath && this.$route.fullPath !== '/personal') {
        localStorage.setItem('goBack', this.$route.fullPath);
      }
      this.$router.push({ path: PERSONAL_SETTING});
    },
    userChangePasswordClick(): void {
      this.$router.push({ path: USER_CHANGE_PASSWORD});
    },
    clickNotice(): void {
      window.open('https://support.alligate.me/hc/ja', '_blank');
    },
    getPlan() {
      return new Promise((resolve, reject) => {
        localStorage.setItem('isMfa', 'false');
        this.$http.get(Config.PLAN_API)
          .then((response: any) => {
            if (response.body.maxRecords > 0) {
              localStorage.setItem('isMfa', response.body.plans[0].isMfa);
              localStorage.setItem('isCustomUserAuthorityService', response.body.plans[0].isCustomUserAuthorityService
              !== undefined ? response.body.plans[0].isCustomUserAuthorityService : '');
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            resolve(false);
          }); // catch
      });
    },
    getProfile() {
      this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(localStorage.userProfileId))
        .then(async (response: any) => {
          console.log(response);
          const dataAuth = store.state.auth;
          dataAuth.isAdmin = response.body.users[0].isAdmin;
          dataAuth.isCustomUserAuthorityService = localStorage.isCustomUserAuthorityService === 'true' ? true : false;
          dataAuth.isAdminToViewGroup = false;
          dataAuth.isAdminOfGroupPassages = false;
          dataAuth.isAdminOfGatePassages = false;
          dataAuth.isAdminOfRemoteUnlock = false;
          dataAuth.isAdminToViewUser = false;
          dataAuth.isAdminToEditUser = false;
          dataAuth.isAdminToAddUser = false;
          dataAuth.isAdminToSetPcodesToUser = false;
          localStorage.setItem('ownerId', response.body.users[0].owner.ownerId);
          localStorage.setItem('isAdmin', response.body.users[0].isAdmin);
          if (localStorage.isCustomUserAuthorityService === 'true') {
            let isAdminOfGroupPassages = false;
            let isAdminOfGatePassages = false;
            let isAdminOfRemoteUnlock = false;

            const group: any = await this.getGroupAuth();

            if (group !== false) {
              group.forEach((element: any) => {
                isAdminOfGroupPassages = element.isAdminOfGroupPassages || isAdminOfGroupPassages;
                isAdminOfGatePassages = element.isAdminOfGatePassages || isAdminOfGatePassages;
                isAdminOfRemoteUnlock = element.isAdminOfRemoteUnlock || isAdminOfRemoteUnlock;
              });

              dataAuth.isAdminToViewGroup = true;
              dataAuth.isAdminOfGroupPassages = this.checkPermisson(isAdminOfGroupPassages);
              dataAuth.isAdminOfGatePassages = this.checkPermisson(isAdminOfGatePassages);
              dataAuth.isAdminOfRemoteUnlock = this.checkPermisson(isAdminOfRemoteUnlock);
            }

            let isAdminToEditUser = false;
            let isAdminToAddUser = false;
            let isAdminToSetPcodesToUser = false;

            const department: any = await this.getDepartmentAuth();

            if (department !== false) {
              department.forEach((element: any) => {
                isAdminToEditUser = element.isAdminToEditUser || isAdminToEditUser;
                isAdminToAddUser = element.isAdminToAddUser || isAdminToAddUser;
                isAdminToSetPcodesToUser = element.isAdminToSetPcodesToUser || isAdminToSetPcodesToUser;
              });

              dataAuth.isAdminToViewUser = true;
              dataAuth.isAdminToEditUser = this.checkPermisson(isAdminToEditUser);
              dataAuth.isAdminToAddUser = this.checkPermisson(isAdminToAddUser);
              dataAuth.isAdminToSetPcodesToUser = this.checkPermisson(isAdminToSetPcodesToUser);
            }
          }
          store.commit('UPDATE_AUTH', auth);
          // localStorage.setItem('authority', '2');
          this.isAdmin = response.body.users[0].isAdmin;
          this.authority = store.state.auth;
          localStorage.setItem('shareKeyBeValid', response.body.users[0].shareKeyBeValid);
          console.log(localStorage.isAdmin);
          this.loading = false;
        }).
        then( null, (err: any) => {
          console.log(err);
          this.loading = false;
        });
    },
    getGroupAuth() {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.GROUP_ADMINISTRATOR_API + '?limit=1000&userId=' + (localStorage.userProfileId))
        .then((response: any) => {
          // ユーザアカウントでリモート解除権限が取得できているのか確認
          localStorage.setItem('remoteUnlock', response.body.groupAdministrators[0].isAdminOfRemoteUnlock);
          resolve(response.body.groupAdministrators);
        }).
        then( null, (err: any) => {
          resolve(false);
        });
      });
    },
    getDepartmentAuth() {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.DEPARTMENT_ADMINISTRATOR_API + '?limit=1000&userId=' + (localStorage.userProfileId))
        .then((response: any) => {
          resolve(response.body.departmentAdministrators);
        }).
        then( null, (err: any) => {
          resolve(false);
        });
      });
    },
    checkPermisson(val: any) {
      if (val !== undefined && val !== null && val !== '') {
        return val ? val : false;
      }
    },
  },
});
