import { render, staticRenderFns } from "./CardLink.vue?vue&type=template&id=24a8f5a6&scoped=true&"
import script from "./CardLink.vue?vue&type=script&lang=ts&"
export * from "./CardLink.vue?vue&type=script&lang=ts&"
import style0 from "./CardLink.vue?vue&type=style&index=0&id=24a8f5a6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24a8f5a6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\003742\\Documents\\Alligate_join\\03_code\\Update\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24a8f5a6')) {
      api.createRecord('24a8f5a6', component.options)
    } else {
      api.reload('24a8f5a6', component.options)
    }
    module.hot.accept("./CardLink.vue?vue&type=template&id=24a8f5a6&scoped=true&", function () {
      api.rerender('24a8f5a6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/CardLink/CardLink.vue"
export default component.exports