






















































import Vue, { PropType } from 'vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import InputForm, { SelectItem } from '@/components/molecules/InputForm/InputForm.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import ErrorButton from '@/components/molecules/ErrorButton/ErrorButton.vue';
import IconButton from '@/components/molecules/IconButton/IconButton.vue';
import Alert, { AlertType } from '@/components/molecules/Alert/Alert.vue';
import { SafieRelationGateDevice } from '@/types/alligate';

interface DataType {
  beforeNotes: string;
  beforeGateId: string;
  beforeDeviceId: string;
}


export default Vue.extend({
  name: 'RelationSafieTarget',
  components: {
    Modal,
    Loading,
    InputForm,
    SuccessButton,
    ErrorButton,
    IconButton,
    Alert,
  },
  data: (): DataType => {
    return {
      beforeDeviceId: '',
      beforeGateId: '',
      beforeNotes: '',
    };
  },
  props: {
    index: {
      type: Number,
      default: () => 0,
    },
    value: {
      type: Object as PropType<SafieRelationGateDevice>,
      default: () => {
        return {
          relationId: '',
          gateId: '',
          deviceId: '',
          notes: '',
        };
      },
    },
    configurableGates: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    // 変更前かどうかチェックするためにマウント時に値の保存
    this.beforeGateId = JSON.parse(JSON.stringify(this.value.gateId));
    this.beforeDeviceId = JSON.parse(JSON.stringify(this.value.deviceId));
    this.beforeNotes = JSON.parse(JSON.stringify(this.value.notes));
  },
  computed: {
    isRelationIdLengthZero(): boolean {
      window.console.log(this.value.relationId.length);
      return this.value.relationId.length === 0;
    },
    gateId: {
      get(): string {
        return this.value.gateId;
      },
      set(val) {
        this.$emit('input', { ...this.value, gateId: val });
      },
    },
    notes: {
      get(): string {
        return this.value.notes;
      },
      set(val) {
        this.$emit('input', { ...this.value, notes: val });
      },
    },
    deviceId: {
      get(): string {
        return this.value.deviceId;
      },
      set(val) {
        this.$emit('input', { ...this.value, deviceId: val });
      },
    },
    isModified(): boolean {
      return Boolean(
        this.beforeNotes !== this.value.notes
        || this.beforeGateId !== this.value.gateId
        || this.beforeDeviceId !== this.value.deviceId,
      );
    },
  },
  methods: {
    isValidate(): boolean {
      return this.isValidateHalfAlphaNumeric(this.value.deviceId) === true
      && this.isLengthOneMore(this.value.deviceId) === true
      && this.isGateIdChoice() === true;
    },
    isLengthOneMore(value: string): boolean {
      if (value && value.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    isValidateLengthOneMore(value: string): string | boolean {
      if (this.isLengthOneMore(value)) {
        return true;
      } else {
        return '必須項目のため、一つ以上の指定が必須です。';
      }
    },
    isValidateHalfAlphaNumeric(value: string): boolean | string {
      if (value && value.match(/^[a-zA-Z0-9]{1,40}$/)) {
        return true;
      } else {
        return '半角英数のみの指定が可能です。';
      }
    },
    // 現在指定している値が選択肢のどれか選択しているかどうか
    isGateIdChoice(): boolean {
      return this.configurableGates.map((item) => item.value).includes(this.value.gateId) === true;
    },
    postRecord(): void {
      if (this.isGateIdChoice() && this.isLengthOneMore(this.value.deviceId)) {
        this.$emit('post-record', this.value);
      }
    },
    putRecord(): void {
      if (this.isModified === true && this.isGateIdChoice() && this.isLengthOneMore(this.value.deviceId)) {
        this.$emit('put-record', this.value);
        this.beforeDeviceId = this.value.deviceId;
        this.beforeGateId = this.value.gateId;
        this.beforeNotes = this.value.notes;
      }
    },
    delRecord(e: any): void {
      const selectedGate: SelectItem | undefined = this.configurableGates
        .find((gate) => gate.value === this.beforeGateId);
      if (selectedGate === undefined
        && window.confirm('追加せずに削除しますか？')
      ) {
        this.$emit('delete-record', this.value.relationId);
      } else if (selectedGate
        && window.confirm(`SafieDevice(${this.beforeDeviceId})と${selectedGate.text}の紐づけを解除しますか？`)
      ) {
        this.$emit('delete-record', this.value.relationId);
      }
    },
    linkTo(deviceId: string): void {
      window.open(`https://safie.link/app/streaming/${deviceId}`, '_blank');
    },
  },
});
