var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "pt-0" }, [
    _c(
      "div",
      { staticClass: "allig-reflection-card-table" },
      [
        _c(
          "div",
          { staticClass: "round-action" },
          [
            _c(
              "v-row",
              { staticClass: "btn-div", attrs: { justify: "end" } },
              [
                _c("v-col", { attrs: { md: "4", xl: "3" } }, [
                  _c("div", { staticClass: "area-search-box" })
                ]),
                _vm._v(" "),
                _c(
                  "v-col",
                  [
                    _c(
                      "v-row",
                      { staticClass: "btn-div", attrs: { justify: "end" } },
                      [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "btn-add pt-0" },
                              [
                                _vm.authority.isAdmin
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "btn-primary",
                                        attrs: { outlined: "" },
                                        on: { click: _vm.clickEditGate }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    通行可能時間帯を変更する\n                  "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.passableName !== undefined
              ? _c("p", { staticClass: "mb-0 passableName" }, [
                  _vm._v(_vm._s(_vm.passableName))
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-row",
          {
            staticClass: "btn-div area-search-box pl-0",
            attrs: { justify: "end" }
          },
          [
            _c("v-col", { attrs: { sm: "12" } }, [
              _c(
                "div",
                { staticClass: "round-data table-affiliation time-period" },
                [
                  _c("v-data-table", {
                    staticClass:
                      "border-table scroll-table table-no-bg customize-table-scroll",
                    attrs: {
                      "mobile-breakpoint": 0,
                      "must-sort": "",
                      headers: _vm.headers,
                      items: _vm.dataList,
                      "items-per-page": -1,
                      "item-key": "dayTypeName",
                      "no-data-text": "データーはありません",
                      "loading-text": "データを読み込中です",
                      loading: _vm.loading,
                      "hide-default-footer": "",
                      "custom-sort": _vm.customSort
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.dayTypeName",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", { staticClass: "item-user-id" }, [
                                _vm._v(_vm._s(item.dayTypeName))
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.settingTime",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              item.allDay === 1
                                ? _c("span", [_vm._v("終日可能")])
                                : item.allDay === 2
                                ? _c("span", [_vm._v("終日不可")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(item.startTime) +
                                        " ~ " +
                                        _vm._s(item.endTime)
                                    )
                                  ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.cardSelected,
                      callback: function($$v) {
                        _vm.cardSelected = $$v
                      },
                      expression: "cardSelected"
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("v-spacer"),
        _vm._v(" "),
        _c("Modal", {
          attrs: {
            title: "通行権限グループ情報編集",
            "no-footer": "",
            smallScreen: "1200px",
            className: "common-padding"
          },
          on: {
            close: function($event) {
              _vm.isGroupEditModalShow = false
              _vm.closeModal(_vm.isReloadData)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-body",
              fn: function() {
                return [
                  _vm.isGroupEditModalShow
                    ? _c("GroupAddPage", {
                        on: {
                          loadingCircle: function($event) {
                            return _vm.$emit("loadingCircle", $event)
                          },
                          textCircle: function($event) {
                            return _vm.$emit("textCircle", $event)
                          },
                          success: function($event) {
                            return _vm.getReload($event)
                          },
                          "go-time-settings": function($event) {
                            return _vm.$emit("go-time-settings", $event)
                          },
                          deleteBridgeProperty: _vm.deleteBridgeProperty,
                          loading: function($event) {
                            return _vm.$emit("loading-from-child", $event)
                          }
                        }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.isGroupEditModalShow,
            callback: function($$v) {
              _vm.isGroupEditModalShow = $$v
            },
            expression: "isGroupEditModalShow"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }