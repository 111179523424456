import { render, staticRenderFns } from "./ExternalListTemplate.vue?vue&type=template&id=db84fe4e&scoped=true&"
import script from "./ExternalListTemplate.vue?vue&type=script&lang=ts&"
export * from "./ExternalListTemplate.vue?vue&type=script&lang=ts&"
import style0 from "./ExternalListTemplate.vue?vue&type=style&index=0&id=db84fe4e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db84fe4e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VContainer,VImg,VRow})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\003742\\Documents\\Alligate_join\\03_code\\Update\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('db84fe4e')) {
      api.createRecord('db84fe4e', component.options)
    } else {
      api.reload('db84fe4e', component.options)
    }
    module.hot.accept("./ExternalListTemplate.vue?vue&type=template&id=db84fe4e&scoped=true&", function () {
      api.rerender('db84fe4e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/templates/ExternalListTemplate/ExternalListTemplate.vue"
export default component.exports