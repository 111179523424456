





























import Vue, { PropType } from 'vue';

export interface ActionItem {
  title: string;
  emitName: string;
}
export default Vue.extend({
  name: 'ActionButton',
  props: {
    text: {
      type: String,
      default: 'アクション',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    actionItems: {
      type: Array as PropType<ActionItem[]>,
      default: () => [],
    },
  },
});
