



























































































import Vue from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';

export interface DataType {
  isDisableDefaultTrue: boolean;
}

export default Vue.extend({
  name: 'LogLabelSettingEdit',
  components: {
    InputForm,
  },
  data: (): DataType => ({
    isDisableDefaultTrue: true,
  }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    dataEditLogLabel: {
      type: Object,
      default: {},
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    logLabelErr: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
  },
  methods: {
    inputLogLabel() {
      this.isDisableDefaultTrue = false;
    },
    clickBackLogLabel() {
      this.isDisableDefaultTrue = true;
      this.$emit('clickBackLogLabel');
    },
  },
});
