var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-gate-table" },
    [
      _c(
        "v-container",
        [
          _vm.errorName !== "" && _vm.loading === false
            ? _c("v-col", { staticClass: "mb-8 font-weight-bold bg-error" }, [
                _vm._v("\n      " + _vm._s(_vm.errorName) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "item-show-page",
              attrs: { "no-gutters": "", justify: "space-between" }
            },
            [
              _c("show-per-page", {
                staticClass: "ml-4 mb-2",
                attrs: {
                  "per-page": 25,
                  page: _vm.pageGroup,
                  "items-length": _vm.groupTotalCount
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "border-table table-shadow",
            attrs: {
              "must-sort": "",
              "custom-sort": _vm.customSort,
              loading: _vm.loading,
              headers: _vm.headers,
              items: _vm.ExpandedGroup,
              "items-per-page": 25,
              "no-data-text": "データーはありません",
              "loading-text": "データーはありません",
              "hide-default-footer": "",
              dense: "",
              "show-select": _vm.ExpandedGroup.length > 0 ? true : false,
              "item-key": "sub",
              "hide-default-header": ""
            },
            on: {
              "item-expanded": function($event) {
                return _vm.$emit("change-expanded-group", {
                  event: $event,
                  expanded: "group"
                })
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function(ref) {
                    var headers = ref.props.headers
                    return [
                      _c("thead", [
                        _c(
                          "tr",
                          _vm._l(headers, function(h) {
                            return _c(
                              "th",
                              { key: h.value, attrs: { width: h.width } },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(h.text) +
                                    "\n          "
                                ),
                                h.value === "groupName"
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        { attrs: { icon: "" } },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-help-circle"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              "所属の通行権限グループ、または、ユーザー個人に <br /> 設定された通行権限グループが表示されます。<br />  両方が設定されている場合、<br /> 個人の通行権限グループの設定が優先されます。"
                                            )
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.data-table-select",
                  fn: function(ref) {
                    var item = ref.item
                    var isSelected = ref.isSelected
                    var select = ref.select
                    return [
                      item.isPassageId === true &&
                      _vm.authority.isAdmin === true
                        ? _c("v-simple-checkbox", {
                            attrs: { value: isSelected, ripple: false },
                            on: {
                              input: function($event) {
                                select($event)
                                _vm.getItem(item, $event)
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.groupName",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "span",
                        { staticClass: "group-inline" },
                        [
                          _vm.authority.isAdmin === true ||
                          item.isClick === true
                            ? _c("text-link", {
                                staticClass: "item-user-name",
                                attrs: { text: item.group.name },
                                on: {
                                  click: function($event) {
                                    return _vm.clickDetail(item.group.groupId)
                                  }
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(item.group.name) + "\n    ")
                              ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isPage !== "add-user-group-gate" &&
                      item.isGroupPassableGate === false
                        ? _c(
                            "span",
                            { staticClass: "group-inline button-settings" },
                            [
                              _c(
                                "span",
                                { staticClass: "btn-primary no-click" },
                                [
                                  _vm._v(
                                    "\n                所属通行権限\n      "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.passableTimeSettingName",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.gateModelCode === "ALIG-030"
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        item.group.passableTimeSetting !==
                                          undefined &&
                                        item.group.passableTimeSetting.name !==
                                          undefined
                                          ? _c(
                                              "span",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "item-user-id passable-time-setting"
                                                  },
                                                  "span",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n            " +
                                                    _vm._s(
                                                      item.group
                                                        .passableTimeSetting
                                                        .name
                                                    ) +
                                                    "\n            "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm._v(" "),
                              _vm.checkPassableTimeSetting(item.group)
                                ? _c(
                                    "span",
                                    _vm._l(
                                      item.group.passableTimeSetting
                                        .timeSettings,
                                      function(row, index) {
                                        return _c("div", { key: index }, [
                                          _vm._v(
                                            _vm._s(row.dayTypeName) +
                                              "　" +
                                              _vm._s(_vm.showTime(row))
                                          )
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedItem,
              callback: function($$v) {
                _vm.selectedItem = $$v
              },
              expression: "selectedItem"
            }
          }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "mt-3",
              attrs: { "no-gutters": "", justify: "space-between" }
            },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _vm.loading === false && _vm.ExpandedGroup.length > 0
                ? _c("pagination", {
                    staticClass: "mb-1",
                    attrs: {
                      "page-length": Math.ceil(_vm.groupTotalCount / 25),
                      disabled: _vm.loading
                    },
                    model: {
                      value: _vm.innerPage,
                      callback: function($$v) {
                        _vm.innerPage = $$v
                      },
                      expression: "innerPage"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showConfirm,
            callback: function($$v) {
              _vm.showConfirm = $$v
            },
            expression: "showConfirm"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v(
                  _vm._s(_vm.userId) +
                    "が新たに所属する通行権限を選択してください。"
                )
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _vm.messageSetting.success === "" &&
                  _vm.messageSetting.error === ""
                    ? _c("v-autocomplete", {
                        staticClass: "input-search mt-2",
                        attrs: {
                          clearable: "",
                          label: "'適用されている通行権限グループ'",
                          solo: "",
                          "hide-details": "",
                          "no-data-text": "",
                          items: _vm.groupPassage
                        },
                        model: {
                          value: _vm.groupItem,
                          callback: function($$v) {
                            _vm.groupItem = $$v
                          },
                          expression: "groupItem"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.messageSetting.success !== "" && _vm.loading === false
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mt-5 mb-4 font-weight-bold mt-1 bg-success"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.messageSetting.success) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.messageSetting.error !== "" && _vm.loading === false
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mt-5 mb-4 font-weight-bold mt-1 bg-error"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.messageSetting.error) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.messageSetting.success === "" &&
              _vm.messageSetting.error === ""
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("add-group-pass", {
                                    groupId: _vm.groupItem,
                                    userId: _vm.userId
                                  })
                                }
                              }
                            },
                            [_vm._v("選択した通行権限グループに所属する")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  _vm.showConfirm = false
                                }
                              }
                            },
                            [_vm._v("\n              キャンセル\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  _vm.showConfirm = false
                                }
                              }
                            },
                            [_vm._v("\n              OK\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }