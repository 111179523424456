var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout-template", {
    attrs: {
      pathName: _vm.pathName,
      isAdmin: _vm.isAdmin,
      authority: _vm.authority,
      selectedItemNav: _vm.selectedItemNav,
      showSubMenu: _vm.showSubMenu,
      loadingParent: _vm.loading
    },
    on: {
      menuClick: function($event) {
        return _vm.menuClick($event)
      },
      activeMenu: function($event) {
        return _vm.activeMenu($event)
      },
      "click-user-setting": _vm.personal,
      "click-user-change-password": _vm.userChangePasswordClick,
      "click-logout": _vm.logout,
      "click-notice": _vm.clickNotice
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }