var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-gate-table" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            {
              staticClass: "mt-2 mb-1 ml-5 mr-5",
              attrs: { justify: "space-between" }
            },
            [
              _vm.gateExpanedGroup.has(_vm.gateId)
                ? _c("div", { staticClass: "allig-title mt-1" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          "通行権限グループ一覧(全" +
                            _vm.gateExpanedGroup.get(_vm.gateId).data.length +
                            "件)"
                        ) +
                        "\n      "
                    )
                  ])
                : _c("div", { staticClass: "allig-title mt-1" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s("通行権限グループ一覧(全0件)") +
                        "\n      "
                    )
                  ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          on: {
                            click: function($event) {
                              return _vm.clickJoinGroup(_vm.gateId)
                            }
                          }
                        },
                        [_vm._v("\n            関連付け追加\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { disabled: _vm.selectedItem.length === 0 },
                          on: {
                            click: function($event) {
                              return _vm.$emit("clickConfirm", {
                                data: _vm.selectedItem,
                                type: 0,
                                selectedId: _vm.gateId
                              })
                            }
                          }
                        },
                        [_vm._v("\n            関連付け解除\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "space-between" } },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("show-per-page", {
                staticClass: "mt-4  ml-4 mb-2",
                attrs: {
                  "per-page": 25,
                  page: _vm.gateExpanedGroup.has(_vm.gateId)
                    ? _vm.gateExpanedGroup.get(_vm.gateId).currPage
                    : 1,
                  "items-length": _vm.gateExpanedGroup.has(_vm.gateId)
                    ? _vm.gateExpanedGroup.get(_vm.gateId).totalCount
                    : 0
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "border-table scroll-table",
            attrs: {
              "must-sort": "",
              "custom-sort": _vm.customSort,
              loading: _vm.gateExpanedGroup.has(_vm.gateId)
                ? _vm.gateExpanedGroup.get(_vm.gateId).isGetData
                : false,
              headers: _vm.headers,
              items: _vm.gateExpanedGroup.has(_vm.gateId)
                ? _vm.gateExpanedGroup.get(_vm.gateId).data
                : [],
              "items-per-page": 25,
              "no-data-text": "該当する通行権限グループがありません",
              "loading-text": "データを読み込中です",
              "hide-default-footer": "",
              dense: "",
              "show-select": true,
              "item-key": "relationId",
              "show-expand": "",
              "single-expand": ""
            },
            on: {
              "item-expanded": function($event) {
                return _vm.$emit("change-expanded-group", $event)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.group.name",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("text-link", {
                        staticClass: "item-user-name",
                        attrs: { text: item.group.name },
                        on: {
                          click: function($event) {
                            return _vm.clickDetail(item.group.groupId)
                          }
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.data-table-expand",
                  fn: function(ref) {
                    var expand = ref.expand
                    var item = ref.item
                    var isExpanded = ref.isExpanded
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", disabled: isExpanded },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: "white",
                                              elevation: "0"
                                            },
                                            on: {
                                              click: function($event) {
                                                return expand(!isExpanded)
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "item-count" },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-account")
                                            ]),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.group.userCount) +
                                                "\n              "
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", { staticClass: "text-caption" }, [
                            _vm._v("ユーザー数")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "transition",
                        { attrs: { name: "expand-icon" } },
                        [
                          isExpanded
                            ? _c("v-icon", [_vm._v("mdi-chevron-down")])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "expanded-item",
                  fn: function(ref) {
                    var headers = ref.headers
                    var item = ref.item
                    return [
                      _c(
                        "td",
                        { attrs: { colspan: headers.length } },
                        [
                          _c("user-of-gate-table", {
                            attrs: {
                              gateExpanedUser: _vm.gateExpanedUser,
                              gateId: _vm.gateId,
                              groupId:
                                item.group !== undefined
                                  ? item.group.groupId
                                  : "",
                              isReRenderUser: _vm.isReRenderUser,
                              isRemoveUserSelected: _vm.isRemoveUserSelected
                            },
                            on: {
                              clickConfirm: function($event) {
                                return _vm.$emit("clickConfirm", $event)
                              },
                              "change-page-user": function($event) {
                                return _vm.$emit("change-page-user", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedItem,
              callback: function($$v) {
                _vm.selectedItem = $$v
              },
              expression: "selectedItem"
            }
          }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "mt-3",
              attrs: { "no-gutters": "", justify: "space-between" }
            },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("pagination", {
                staticClass: "mb-1",
                attrs: {
                  "page-length": Math.ceil(
                    (_vm.gateExpanedGroup.has(_vm.gateId)
                      ? _vm.gateExpanedGroup.get(_vm.gateId).totalCount
                      : 0) / 25
                  ),
                  disabled: _vm.gateExpanedGroup.has(_vm.gateId)
                    ? _vm.gateExpanedGroup.get(_vm.gateId).isGetData
                    : false
                },
                on: {
                  input: function($event) {
                    _vm.changePage(
                      _vm.gateExpanedGroup.get(_vm.gateId).currPage
                    )
                  }
                },
                model: {
                  value: _vm.gateExpanedGroup.get(_vm.gateId).currPage,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.gateExpanedGroup.get(_vm.gateId),
                      "currPage",
                      $$v
                    )
                  },
                  expression: "gateExpanedGroup.get(gateId).currPage"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }