var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "room-form" },
    [
      _c("loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.roomInfoLoading,
            expression: "roomInfoLoading"
          }
        ]
      }),
      _vm._v(" "),
      _vm.authority.isAdmin === true || _vm.authority.isAdminToViewUser === true
        ? _c(
            "v-row",
            { staticClass: "btn-div mt-3 mb-3", attrs: { justify: "start" } },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "btn-add text-subtitle-2 border-left-blue font-weight-bold text-left pt-0 pb-0 ma-0 mt-2",
                  attrs: { cols: "6", justify: "start" }
                },
                [_vm._v("\n        部屋情報\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "btn-add pt-0 pb-0 ma-0", attrs: { cols: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-secondary",
                      attrs: { outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("click-edit-room", _vm.roomInfo)
                        }
                      }
                    },
                    [_vm._v("\n          部屋情報を編集する\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-card", { staticClass: "pa-6 pt-4 pb-0 pr-10 mb-5" }, [
        _c(
          "div",
          { staticClass: "page-detail mb-10" },
          [
            _c(
              "v-row",
              [
                _c("v-col", { staticClass: "col-12 pl-0" }, [
                  _c("div", { staticClass: "title-display-block" }, [
                    _vm._v("部屋名 ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "weight-bold " }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.roomInfo.name) +
                        "\n            "
                    )
                  ])
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "col-12 pl-0" },
                  [
                    _c("div", { staticClass: "title-display-block" }, [
                      _vm._v("ゲート ")
                    ]),
                    _vm._v(" "),
                    _vm.gateItems.length > 0
                      ? _c(
                          "v-row",
                          _vm._l(_vm.gateItems, function(gate, index) {
                            return _c(
                              "v-col",
                              {
                                key: index,
                                attrs: { md: "3", sm: "6", cols: "12" }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(gate.title) +
                                    "\n            "
                                )
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }