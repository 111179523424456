var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPage !== "detail"
    ? _c(
        "div",
        { staticClass: "allig-group-list-template" },
        [
          _c(
            "v-container",
            { staticClass: "mt-0 pt-0", staticStyle: { position: "relative" } },
            [
              _c("ModalLoading", { attrs: { loading: _vm.loadingChild } }),
              _vm._v(" "),
              _c("div", { staticClass: "container-user mt-0 pt-0" }, [
                _c(
                  "div",
                  { staticClass: "round-action" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "btn-div", attrs: { justify: "end" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "btn-div",
                                attrs: { justify: "end" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "text-center mr-1" },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "btn-add pt-0" },
                                      [
                                        _vm.authority.isAdmin === true
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "btn-primary",
                                                attrs: { outlined: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addAff()
                                                  }
                                                }
                                              },
                                              [_vm._v("新しい所属を作成する")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "pt-0 pb-0", attrs: { cols: "12" } },
                          [
                            _c(
                              "div",
                              { staticClass: "area-search-box" },
                              [
                                _c(
                                  "v-row",
                                  {
                                    attrs: {
                                      "no-gutters": "",
                                      justify: "space-between"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("searchbox", {
                                          staticClass:
                                            "input-search btn-user-search",
                                          attrs: {
                                            searchTargetItems:
                                              _vm.searchTargetItems,
                                            title: "所属を検索"
                                          },
                                          on: {
                                            search: function($event) {
                                              return _vm.$emit(
                                                "search-group",
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "round-action" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { sm: "12" } },
                          [
                            _c("AffiliationTable", {
                              attrs: {
                                loadingChild: _vm.loadingChild,
                                authority: _vm.authority,
                                dataGroupsModal: _vm.dataGroupsModal,
                                page: _vm.page,
                                perPage: _vm.perPage,
                                loading: _vm.loading,
                                expandData: _vm.expandData,
                                dataRelationUser: _vm.dataRelationUser,
                                dataRelationGroup: _vm.dataRelationGroup,
                                userItems: _vm.userItems,
                                successMessageLogs: _vm.successMessageLogs,
                                errorMessageLogs: _vm.errorMessageLogs,
                                isPage: _vm.isPage,
                                departmentItem: _vm.department,
                                isGetUser: _vm.isGetUser
                              },
                              on: {
                                "change-per-page": function($event) {
                                  return _vm.$emit("changePerPage", $event)
                                },
                                "change-page": function($event) {
                                  return _vm.$emit("changePage", $event)
                                },
                                changeTab: function($event) {
                                  return _vm.$emit("changeTab", $event)
                                },
                                "select-groups": function(groupIds) {
                                  _vm.selectedGroupIds = groupIds
                                },
                                "expand-gate-group": function($event) {
                                  return _vm.$emit("expand-gate-group", $event)
                                },
                                "click-delete-item": function($event) {
                                  return _vm.clickDeleteItem($event)
                                },
                                "change-page-user": function($event) {
                                  return _vm.$emit("change-page-user", $event)
                                },
                                "change-per-page-user": function($event) {
                                  return _vm.$emit(
                                    "change-per-page-user",
                                    $event
                                  )
                                },
                                "search-user": function($event) {
                                  return _vm.$emit("search-user", $event)
                                },
                                "load-list-users": function($event) {
                                  return _vm.$emit("load-list-users", $event)
                                },
                                "add-user": function($event) {
                                  return _vm.$emit("add-user", $event)
                                },
                                "change-page-user-all": function($event) {
                                  return _vm.$emit(
                                    "change-page-user-all",
                                    $event
                                  )
                                },
                                "change-per-page-user-all": function($event) {
                                  return _vm.$emit(
                                    "change-per-page-user-all",
                                    $event
                                  )
                                },
                                "remove-user": function($event) {
                                  return _vm.$emit("remove-user", $event)
                                },
                                "load-users": function($event) {
                                  return _vm.$emit("load-users", $event)
                                },
                                "change-page-group": function($event) {
                                  return _vm.$emit("change-page-group", $event)
                                },
                                "change-page-gate-group": function($event) {
                                  return _vm.$emit(
                                    "change-page-gate-group",
                                    $event
                                  )
                                },
                                "remove-group": function($event) {
                                  return _vm.$emit("remove-group", $event)
                                },
                                "load-list-groups": function($event) {
                                  return _vm.$emit("load-list-groups", $event)
                                },
                                "add-group": function($event) {
                                  return _vm.$emit("add-group", $event)
                                },
                                "change-page-group-all": function($event) {
                                  return _vm.$emit(
                                    "change-page-group-all",
                                    $event
                                  )
                                },
                                "change-per-page-group-all": function($event) {
                                  return _vm.$emit(
                                    "change-per-page-group-all",
                                    $event
                                  )
                                },
                                "load-aff-detail": function($event) {
                                  return _vm.$emit("load-aff-detail", $event)
                                },
                                "remove-detail-aff": function($event) {
                                  return _vm.$emit("remove-detail-aff", $event)
                                },
                                "load-detail-aff-again": function($event) {
                                  return _vm.$emit(
                                    "load-detail-aff-again",
                                    $event
                                  )
                                },
                                "update-aff": function($event) {
                                  return _vm.$emit("update-aff", $event)
                                },
                                "loading-from-child": function($event) {
                                  return _vm.$emit("loading-from-child", $event)
                                },
                                "close-page-modal": function($event) {
                                  return _vm.$emit("close-page-modal")
                                },
                                "search-group-department": function($event) {
                                  return _vm.$emit(
                                    "search-group-department",
                                    $event
                                  )
                                },
                                "get-list-group-modal": function($event) {
                                  return _vm.$emit("get-list-group-modal")
                                },
                                "get-list-user": function($event) {
                                  return _vm.$emit("get-list-user")
                                },
                                "reload-data-group": function($event) {
                                  return _vm.$emit("reload-data-group", $event)
                                },
                                getDepartmentUserValid: function($event) {
                                  return _vm.$emit(
                                    "getDepartmentUserValid",
                                    $event
                                  )
                                },
                                "change-expanded": function($event) {
                                  return _vm.$emit("change-expanded", $event)
                                },
                                "load-list-users-again": function($event) {
                                  return _vm.$emit(
                                    "load-list-users-again",
                                    $event
                                  )
                                },
                                getReload: function($event) {
                                  return _vm.$emit("getReload", $event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("Modal", {
            attrs: {
              title:
                _vm.typeAction === 0
                  ? "通行権限グループを有効化"
                  : _vm.typeAction === 1
                  ? "通行権限グループを無効化"
                  : "所属一覧",
              smallScreen: "800px",
              text:
                _vm.typeAction === 0
                  ? "チェックした通行権限グループを有効化します。"
                  : _vm.typeAction === 1
                  ? "チェックした通行権限グループを無効化します。"
                  : _vm.typeAction === 3
                  ? _vm.isAlert()
                    ? "削除しようとしている所属に、ユーザーが所属しております。所属を削除した場合、ユーザーの所属先が解除され、無所属状態となります。削除してもよろしいでしょうか？"
                    : "関連付けを解除しますか？"
                  : "",
              className: "common-modal"
            },
            on: {
              close: function($event) {
                _vm.showConfirm = false
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "modal-footer",
                  fn: function() {
                    return [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _vm.typeAction === 3
                        ? _c("success-button", {
                            attrs: { text: "OK" },
                            on: {
                              click: function($event) {
                                return _vm.clickDeleteDepartment()
                              }
                            }
                          })
                        : _c("success-button", {
                            attrs: { text: "OK" },
                            on: {
                              click: function($event) {
                                return _vm.clickGroupsAction()
                              }
                            }
                          })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2316490125
            ),
            model: {
              value: _vm.showConfirm,
              callback: function($$v) {
                _vm.showConfirm = $$v
              },
              expression: "showConfirm"
            }
          }),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { width: "800", persistent: "" },
              model: {
                value: _vm.showProgress,
                callback: function($$v) {
                  _vm.showProgress = $$v
                },
                expression: "showProgress"
              }
            },
            [
              _vm.typeAction === 3
                ? _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("所属一覧")]
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "pa-4" }, [
                        _vm.errorMessageLogs === "" && _vm.loading === false
                          ? _c("p", [
                              _vm._v(
                                _vm._s(_vm.departmentName) + "を削除します。"
                              )
                            ])
                          : _c("p", [_vm._v(_vm._s(_vm.errorMessageLogs))])
                      ]),
                      _vm._v(" "),
                      _vm.loading === false
                        ? _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-2",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c("success-button", {
                                    attrs: { text: "OK" },
                                    on: {
                                      click: function($event) {
                                        return _vm.closeModalProgressDelete()
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    [
                      _vm.typeAction === 0
                        ? _c(
                            "v-card-title",
                            { staticClass: "background-color-green" },
                            [_vm._v("通行権限グループを有効化")]
                          )
                        : _vm.typeAction === 1
                        ? _c(
                            "v-card-title",
                            { staticClass: "background-color-green" },
                            [_vm._v("通行権限グループを無効化")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c("v-row", {
                        staticStyle: { height: "5px" },
                        attrs: { "no-gutters": "" }
                      }),
                      _vm._v(" "),
                      _vm.totalDataRowProcess > 0
                        ? _c(
                            "v-card-text",
                            { staticClass: "pa-4" },
                            [
                              _c(
                                "v-progress-linear",
                                {
                                  staticStyle: { "pointer-events": "none" },
                                  attrs: {
                                    height: "25",
                                    color: "green darken-1",
                                    striped: ""
                                  },
                                  model: {
                                    value: _vm.progress,
                                    callback: function($$v) {
                                      _vm.progress = $$v
                                    },
                                    expression: "progress"
                                  }
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(Math.round(_vm.progress)) + "%"
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _vm.successItemProcess > 0 && _vm.typeAction === 0
                                ? _c("span", { staticClass: "success-label" }, [
                                    _vm._v(
                                      _vm._s(_vm.totalDataRowProcess) +
                                        "件中" +
                                        _vm._s(_vm.successItemProcess) +
                                        "件を有効化しました。"
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.successItemProcess > 0 && _vm.typeAction === 1
                                ? _c("span", { staticClass: "success-label" }, [
                                    _vm._v(
                                      _vm._s(_vm.totalDataRowProcess) +
                                        "件中" +
                                        _vm._s(_vm.successItemProcess) +
                                        "件を無効化しました。"
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.errorMessageProcess.length > 0
                                ? _c("div", [
                                    _vm.typeAction === 0
                                      ? _c(
                                          "span",
                                          { staticClass: "error-box-title" },
                                          [_vm._v("有効化失敗")]
                                        )
                                      : _vm.typeAction === 1
                                      ? _c(
                                          "span",
                                          { staticClass: "error-box-title" },
                                          [_vm._v("無効化失敗")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "error-box" },
                                      _vm._l(_vm.errorMessageProcess, function(
                                        item
                                      ) {
                                        return _c("p", {
                                          key: item,
                                          domProps: { innerHTML: _vm._s(item) }
                                        })
                                      }),
                                      0
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isFinishProcess
                        ? _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-2",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c("success-button", {
                                    attrs: { text: "OK" },
                                    on: {
                                      click: function($event) {
                                        return _vm.closeModalProgress()
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          ),
          _vm._v(" "),
          _c("Modal", {
            attrs: {
              title: "所属追加",
              "no-footer": "",
              smallScreen: "1200px",
              className: "common-padding"
            },
            on: { close: _vm.clickBack },
            scopedSlots: _vm._u(
              [
                {
                  key: "modal-body",
                  fn: function() {
                    return [
                      _vm.isShow
                        ? _c("AffiliationAdd", {
                            attrs: {
                              authority: _vm.authority,
                              expandData: _vm.expandData,
                              userItems: _vm.userItems,
                              successMessageLogs: _vm.successMessageLogs,
                              errorMessageLogs: _vm.errorMessageLogs
                            },
                            on: {
                              "load-list-groups": function($event) {
                                return _vm.$emit("load-list-groups", $event)
                              },
                              "expand-gate-group": function($event) {
                                return _vm.$emit("expand-gate-group", $event)
                              },
                              "change-page-group-all": function($event) {
                                return _vm.$emit(
                                  "change-page-group-all",
                                  $event
                                )
                              },
                              "change-per-page-group-all": function($event) {
                                return _vm.$emit(
                                  "change-per-page-group-all",
                                  $event
                                )
                              },
                              "change-page-gate-group": function($event) {
                                return _vm.$emit(
                                  "change-page-gate-group",
                                  $event
                                )
                              },
                              "register-aff": function($event) {
                                return _vm.$emit("register-aff", $event)
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3578008600
            ),
            model: {
              value: _vm.isShow,
              callback: function($$v) {
                _vm.isShow = $$v
              },
              expression: "isShow"
            }
          })
        ],
        1
      )
    : _c(
        "div",
        [
          _c("AffiliationTable", {
            attrs: {
              loadingChild: _vm.loadingChild,
              authority: _vm.authority,
              dataGroupsModal: _vm.dataGroupsModal,
              page: _vm.page,
              perPage: _vm.perPage,
              loading: _vm.loading,
              expandData: _vm.expandData,
              dataRelationUser: _vm.dataRelationUser,
              dataRelationGroup: _vm.dataRelationGroup,
              userItems: _vm.userItems,
              successMessageLogs: _vm.successMessageLogs,
              errorMessageLogs: _vm.errorMessageLogs,
              isPage: _vm.isPage,
              departmentItem: _vm.department,
              isGetUser: _vm.isGetUser
            },
            on: {
              "change-per-page": function($event) {
                return _vm.$emit("changePerPage", $event)
              },
              "change-page": function($event) {
                return _vm.$emit("changePage", $event)
              },
              changeTab: function($event) {
                return _vm.$emit("changeTab", $event)
              },
              "select-groups": function(groupIds) {
                _vm.selectedGroupIds = groupIds
              },
              "expand-gate-group": function($event) {
                return _vm.$emit("expand-gate-group", $event)
              },
              "click-delete-item": function($event) {
                return _vm.clickDeleteItem($event)
              },
              "change-page-user": function($event) {
                return _vm.$emit("change-page-user", $event)
              },
              "change-per-page-user": function($event) {
                return _vm.$emit("change-per-page-user", $event)
              },
              "search-user": function($event) {
                return _vm.$emit("search-user", $event)
              },
              "load-list-users": function($event) {
                return _vm.$emit("load-list-users", $event)
              },
              "add-user": function($event) {
                return _vm.$emit("add-user", $event)
              },
              "change-page-user-all": function($event) {
                return _vm.$emit("change-page-user-all", $event)
              },
              "change-per-page-user-all": function($event) {
                return _vm.$emit("change-per-page-user-all", $event)
              },
              "remove-user": function($event) {
                return _vm.$emit("remove-user", $event)
              },
              "load-users": function($event) {
                return _vm.$emit("load-users", $event)
              },
              "change-page-group": function($event) {
                return _vm.$emit("change-page-group", $event)
              },
              "change-page-gate-group": function($event) {
                return _vm.$emit("change-page-gate-group", $event)
              },
              "remove-group": function($event) {
                return _vm.$emit("remove-group", $event)
              },
              "load-list-groups": function($event) {
                return _vm.$emit("load-list-groups", $event)
              },
              "add-group": function($event) {
                return _vm.$emit("add-group", $event)
              },
              "change-page-group-all": function($event) {
                return _vm.$emit("change-page-group-all", $event)
              },
              "change-per-page-group-all": function($event) {
                return _vm.$emit("change-per-page-group-all", $event)
              },
              "load-aff-detail": function($event) {
                return _vm.$emit("load-aff-detail", $event)
              },
              "remove-detail-aff": function($event) {
                return _vm.$emit("remove-detail-aff", $event)
              },
              "load-detail-aff-again": function($event) {
                return _vm.$emit("load-detail-aff-again", $event)
              },
              "update-aff": function($event) {
                return _vm.$emit("update-aff", $event)
              },
              "loading-from-child": function($event) {
                return _vm.$emit("loading-from-child", $event)
              },
              "close-page-modal": function($event) {
                return _vm.$emit("close-page-modal")
              },
              "search-group-department": function($event) {
                return _vm.$emit("search-group-department", $event)
              },
              "get-list-group-modal": function($event) {
                return _vm.$emit("get-list-group-modal")
              },
              "get-list-user": function($event) {
                return _vm.$emit("get-list-user")
              },
              "back-group-detail": function($event) {
                return _vm.$emit("back-group-detail", $event)
              },
              getDepartmentUserValid: function($event) {
                return _vm.$emit("getDepartmentUserValid", $event)
              },
              "change-expanded": function($event) {
                return _vm.$emit("change-expanded", $event)
              },
              getReload: function($event) {
                return _vm.$emit("getReload", $event)
              }
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }