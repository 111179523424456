var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-add-form modal-control" },
    [
      _c(
        "v-container",
        { staticClass: "pt-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-subtitle-2 border-left-blue font-weight-bold mb-5 mt-5 ml-3"
                },
                [_vm._v("\n          通行権限グループ情報\n        ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "round-content-scroll" },
            [
              _c(
                "v-form",
                {
                  ref: "formGroup",
                  staticStyle: { position: "relative" },
                  attrs: { "lazy-validation": "" }
                },
                [
                  _c(
                    "v-overlay",
                    {
                      staticClass: "overlay-group-tab",
                      attrs: {
                        absolute: "",
                        value: _vm.isGetGroup,
                        color: "white"
                      }
                    },
                    [
                      _c("v-progress-circular", {
                        staticClass: "user-loading-progress",
                        attrs: { indeterminate: "", color: "green" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "pa-6 pr-10 mb-5 pt-5" },
                    [
                      _c("input-form", {
                        attrs: {
                          isShow: "vertical",
                          rules: [_vm.rulesValidate.validateGroupName],
                          title:
                            "通行権限グループ名 <span class='required'>※必須</span>",
                          inputType: "text"
                        },
                        model: {
                          value: _vm.groupNameValue,
                          callback: function($$v) {
                            _vm.groupNameValue = $$v
                          },
                          expression: "groupNameValue"
                        }
                      }),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _vm.authority.isCustomUserAuthorityService
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                { staticClass: "col-vertical mt-5" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "col-12 mb-0 pb-0" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "title-display-block ml-0 pa-0 mt-0 mb-0"
                                        },
                                        [_vm._v(" 通行権限グループ管理者")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.listGroup, function(item, i) {
                                return _c(
                                  "div",
                                  { key: i },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-5 col-vertical" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              md: "3",
                                              sm: "12",
                                              cols: "12"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "2" } },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          al: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.deleteItem(
                                                              i
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "gray--text",
                                                            attrs: {
                                                              "align-self":
                                                                "ceb="
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-minus-circle"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                item.Ids.text
                                                  ? _c(
                                                      "v-col",
                                                      { attrs: { cols: "10" } },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-combobox",
                                                                            {
                                                                              staticClass:
                                                                                "item-ellipsis",
                                                                              attrs: {
                                                                                items:
                                                                                  _vm.dataAllow,
                                                                                autocomplete:
                                                                                  "off",
                                                                                color:
                                                                                  "green",
                                                                                "hide-details":
                                                                                  "auto",
                                                                                "no-data-text":
                                                                                  "",
                                                                                outlined:
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                disabled:
                                                                                  item.disabled,
                                                                                "menu-props": {
                                                                                  contentClass:
                                                                                    "v-select-item "
                                                                                },
                                                                                attach: true
                                                                              },
                                                                              on: {
                                                                                change:
                                                                                  _vm.changeData
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  item.Ids,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "Ids",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "item.Ids"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            item.Ids.text
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.Ids
                                                                        .text
                                                                    )
                                                                  )
                                                                ])
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "v-col",
                                                      { attrs: { cols: "10" } },
                                                      [
                                                        _c("v-combobox", {
                                                          staticClass:
                                                            "item-ellipsis",
                                                          attrs: {
                                                            items:
                                                              _vm.dataAllow,
                                                            autocomplete: "off",
                                                            color: "green",
                                                            "hide-details":
                                                              "auto",
                                                            "no-data-text": "",
                                                            outlined: "",
                                                            dense: "",
                                                            disabled:
                                                              item.disabled,
                                                            "menu-props": {
                                                              contentClass:
                                                                "v-select-item v-select-item-disabled"
                                                            },
                                                            attach: true
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.changeData
                                                          },
                                                          model: {
                                                            value: item.Ids,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "Ids",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.Ids"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              md: "9",
                                              sm: "12",
                                              cols: "12"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { staticClass: "item-check-box" },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "3" } },
                                                  [
                                                    _vm.isEdit === true
                                                      ? _c("v-checkbox", {
                                                          attrs: {
                                                            disabled: "",
                                                            color: "grey",
                                                            dense: "",
                                                            "hide-details": "",
                                                            label:
                                                              "ゲート閲覧権限"
                                                          },
                                                          model: {
                                                            value:
                                                              item.isAdminToView,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "isAdminToView",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.isAdminToView"
                                                          }
                                                        })
                                                      : _c("v-checkbox", {
                                                          attrs: {
                                                            color: "grey",
                                                            disabled: "",
                                                            dense: "",
                                                            "hide-details": "",
                                                            label:
                                                              "ゲート閲覧権限"
                                                          },
                                                          model: {
                                                            value:
                                                              item.isAdminToView,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "isAdminToView",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.isAdminToView"
                                                          }
                                                        })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "3" } },
                                                  [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        color: "green",
                                                        dense: "",
                                                        "hide-details": "",
                                                        label:
                                                          "通行可能所属/ユーザーの設定権限"
                                                      },
                                                      model: {
                                                        value:
                                                          item.isAdminOfGroupPassages,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "isAdminOfGroupPassages",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.isAdminOfGroupPassages"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "3" } },
                                                  [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        color: "green",
                                                        dense: "",
                                                        "hide-details": "",
                                                        label:
                                                          "一時通行可能ユーザーの設定権限"
                                                      },
                                                      model: {
                                                        value:
                                                          item.isAdminOfGatePassages,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "isAdminOfGatePassages",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.isAdminOfGatePassages"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "3" } },
                                                  [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        color: "green",
                                                        dense: "",
                                                        "hide-details": "",
                                                        label: "遠隔解錠権限"
                                                      },
                                                      model: {
                                                        value:
                                                          item.isAdminOfRemoteUnlock,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "isAdminOfRemoteUnlock",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.isAdminOfRemoteUnlock"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c("v-col", { staticClass: "col-12 pt-0" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "v-subheader theme--light ml-0 pa-0 mt-0 add-button",
                                        on: { click: _vm.addGroup }
                                      },
                                      [_vm._v(" + 追加する ")]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "input-form mt-3" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "col-vertical" },
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "title-display-block ml-0 pa-0 mt-0 mb-0"
                                  },
                                  [_vm._v("ゲート")]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.dataAllGates,
                                      color: "green",
                                      "return-object": "",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: "",
                                      "no-data-text": "",
                                      multiple: "",
                                      chips: "",
                                      "deletable-chips": "",
                                      "menu-props": {
                                        contentClass: "class-v-select-item "
                                      },
                                      attach: true
                                    },
                                    model: {
                                      value: _vm.gateValue,
                                      callback: function($$v) {
                                        _vm.gateValue = $$v
                                      },
                                      expression: "gateValue"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "col-vertical mt-5" },
                        [
                          _c("v-col", { attrs: { sm: "3", xl: "2" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "title-display-block ml-0 pa-0 mt-3 mb-0"
                              },
                              [_vm._v("通行可能時間設定")]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-subheader",
                                [
                                  _c("text-link", {
                                    staticClass: "item-user-name block",
                                    attrs: {
                                      text:
                                        "通行可能時間設定を新規作成/編集する"
                                    },
                                    on: { click: _vm.clickTimeSettings }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.dataAllTimes,
                                  color: "green",
                                  "return-object": "",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                  "no-data-text": "",
                                  clearable: "",
                                  disabled: _vm.isGateLock === 2,
                                  "menu-props": {
                                    contentClass: "class-v-select-item "
                                  },
                                  attach: true
                                },
                                model: {
                                  value: _vm.inputTime,
                                  callback: function($$v) {
                                    _vm.inputTime = $$v
                                  },
                                  expression: "inputTime"
                                }
                              }),
                              _vm._v(" "),
                              _vm.isGateLock === 1
                                ? _c(
                                    "p",
                                    { staticClass: "red--text mgs-gate-lock" },
                                    [
                                      _vm._v(
                                        "※通行可能時間設定は、ALLIGATE Lock Pro のゲートのみ設定が反映されます"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isEdit === true
                    ? _c(
                        "v-row",
                        { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
                        [
                          _vm.successMessageLogs !== "" &&
                          _vm.GateSubmittedFail.length === 0 &&
                          _vm.UserSubmittedFail.length === 0 &&
                          _vm.administratorsAddFail.length === 0 &&
                          _vm.loading === false
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-4 bg-no bg-success"
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.successMessageLogs) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.successMessageLogs !== "" &&
                          _vm.GateSubmittedFail.length === 0 &&
                          _vm.UserSubmittedFail.length === 0 &&
                          _vm.administratorsAddFail.length > 0 &&
                          _vm.loading === false
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-4 bg-no bg-error"
                                },
                                [
                                  _vm.isEdit
                                    ? _c("span", [
                                        _vm._v(
                                          "通行権限グループが編集されました。"
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "通行権限グループが追加されました。"
                                        )
                                      ]),
                                  _vm._v(
                                    "通行権限グループ管理者紐づけに失敗しています。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            通行権限グループ管理者: " +
                                      _vm._s(
                                        _vm.administratorsAddFail.toString()
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.successMessageLogs !== "" &&
                          _vm.GateSubmittedFail.length > 0 &&
                          _vm.UserSubmittedFail.length > 0 &&
                          _vm.loading === false
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                                  attrs: { cols: "10", align: "right" }
                                },
                                [
                                  _vm.isEdit
                                    ? _c("span", [
                                        _vm._v(
                                          "通行権限グループが編集されました。"
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "通行権限グループが追加されました。"
                                        )
                                      ]),
                                  _vm._v(
                                    "ゲートとユーザーの紐づけに失敗しています。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            通行権限グループ編集より、ゲートとユーザーの確認をしてください。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            別の通行可能時間帯が設定されている通行権限グループに紐づいている場合、追加することができません。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            登録失敗ゲート名: " +
                                      _vm._s(_vm.GateSubmittedFail.toString()) +
                                      " "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            登録失敗ユーザー名: " +
                                      _vm._s(_vm.UserSubmittedFail.toString()) +
                                      "\n            "
                                  ),
                                  _vm.administratorsAddFail.length > 0
                                    ? _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          " 登録失敗ユーザー名: " +
                                            _vm._s(
                                              _vm.administratorsAddFail.toString()
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.successMessageLogs !== "" &&
                          _vm.GateSubmittedFail.length > 0 &&
                          _vm.UserSubmittedFail.length === 0 &&
                          _vm.loading === false
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                                  attrs: { cols: "10", align: "right" }
                                },
                                [
                                  _vm.isEdit
                                    ? _c("span", [
                                        _vm._v(
                                          "通行権限グループが編集されました。"
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "通行権限グループが追加されました。"
                                        )
                                      ]),
                                  _vm._v("ゲートの紐づけに失敗しています。"),
                                  _c("br"),
                                  _vm._v(
                                    "\n            通行権限グループ編集より、ゲートの確認をしてください。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            別の通行可能時間帯が設定されている通行権限グループに紐づいている場合、追加することができません。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            登録失敗ゲート名: " +
                                      _vm._s(_vm.GateSubmittedFail.toString()) +
                                      "\n            "
                                  ),
                                  _vm.administratorsAddFail.length > 0
                                    ? _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          " 登録失敗ユーザー名: " +
                                            _vm._s(
                                              _vm.administratorsAddFail.toString()
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.successMessageLogs !== "" &&
                          _vm.GateSubmittedFail.length === 0 &&
                          _vm.UserSubmittedFail.length > 0 &&
                          _vm.loading === false
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                                  attrs: { cols: "10", align: "right" }
                                },
                                [
                                  _vm.isEdit
                                    ? _c("span", [
                                        _vm._v(
                                          "通行権限グループが編集されました。"
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "通行権限グループが追加されました。"
                                        )
                                      ]),
                                  _vm._v("ユーザーの紐づけに失敗しています。"),
                                  _c("br"),
                                  _vm._v(
                                    "\n            通行権限グループ編集より、ユーザーの確認をしてください。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            別の通行可能時間帯が設定されている通行権限グループに紐づいている場合、追加することができません。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            登録失敗ユーザー名: " +
                                      _vm._s(_vm.UserSubmittedFail.toString()) +
                                      "\n            "
                                  ),
                                  _vm.administratorsAddFail.length > 0
                                    ? _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          " 登録失敗ユーザー名: " +
                                            _vm._s(
                                              _vm.administratorsAddFail.toString()
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errorMessageLogs !== "" && _vm.loading === false
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                                  attrs: { cols: "10", align: "right" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.errorMessageLogs) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "messageRow",
                              attrs: { cols: "2", align: "right" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-primary",
                                  attrs: {
                                    disabled:
                                      _vm.groupDetail.groupId === null ||
                                      _vm.groupDetail.groupId === undefined ||
                                      _vm.groupDetail.groupId === "",
                                    outlined: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.clickUpdateGroup()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              保存する\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
                        [
                          _vm.successMessageLogs !== "" &&
                          _vm.GateSubmittedFail.length === 0 &&
                          _vm.UserSubmittedFail.length === 0 &&
                          _vm.administratorsAddFail.length === 0 &&
                          _vm.loading === false
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-4 bg-no bg-success"
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.successMessageLogs) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.successMessageLogs !== "" &&
                          _vm.GateSubmittedFail.length === 0 &&
                          _vm.UserSubmittedFail.length === 0 &&
                          _vm.administratorsAddFail.length > 0 &&
                          _vm.loading === false
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-4 bg-no bg-error"
                                },
                                [
                                  _vm.isEdit
                                    ? _c("span", [
                                        _vm._v(
                                          "通行権限グループが編集されました。"
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "通行権限グループが追加されました。"
                                        )
                                      ]),
                                  _vm._v(
                                    "通行権限グループ管理者紐づけに失敗しています。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            通行権限グループ管理者: " +
                                      _vm._s(
                                        _vm.administratorsAddFail.toString()
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.successMessageLogs !== "" &&
                          _vm.GateSubmittedFail.length > 0 &&
                          _vm.UserSubmittedFail.length > 0 &&
                          _vm.loading === false
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                                  attrs: { cols: "11", align: "right" }
                                },
                                [
                                  _vm.isEdit
                                    ? _c("span", [
                                        _vm._v(
                                          "通行権限グループが編集されました。"
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "通行権限グループが追加されました。"
                                        )
                                      ]),
                                  _vm._v(
                                    "ゲートとユーザーの紐づけに失敗しています。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            通行権限グループ編集より、ゲートとユーザーの確認をしてください。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            別の通行可能時間帯が設定されている通行権限グループに紐づいている場合、追加することができません。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            登録失敗ゲート名: " +
                                      _vm._s(_vm.GateSubmittedFail.toString()) +
                                      " "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            登録失敗ユーザー名: " +
                                      _vm._s(_vm.UserSubmittedFail.toString()) +
                                      "\n            "
                                  ),
                                  _vm.administratorsAddFail.length > 0
                                    ? _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          " 登録失敗ユーザー名: " +
                                            _vm._s(
                                              _vm.administratorsAddFail.toString()
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.successMessageLogs !== "" &&
                          _vm.GateSubmittedFail.length > 0 &&
                          _vm.UserSubmittedFail.length === 0 &&
                          _vm.loading === false
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                                  attrs: { cols: "11", align: "right" }
                                },
                                [
                                  _vm.isEdit
                                    ? _c("span", [
                                        _vm._v(
                                          "通行権限グループが編集されました。"
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "通行権限グループが追加されました。"
                                        )
                                      ]),
                                  _vm._v("ゲートの紐づけに失敗しています。"),
                                  _c("br"),
                                  _vm._v(
                                    "\n            通行権限グループ編集より、ゲートの確認をしてください。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            別の通行可能時間帯が設定されている通行権限グループに紐づいている場合、追加することができません。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            登録失敗ゲート名: " +
                                      _vm._s(_vm.GateSubmittedFail.toString()) +
                                      "\n            "
                                  ),
                                  _vm.administratorsAddFail.length > 0
                                    ? _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          " 登録失敗ユーザー名: " +
                                            _vm._s(
                                              _vm.administratorsAddFail.toString()
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.successMessageLogs !== "" &&
                          _vm.GateSubmittedFail.length === 0 &&
                          _vm.UserSubmittedFail.length > 0 &&
                          _vm.loading === false
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                                  attrs: { cols: "11", align: "right" }
                                },
                                [
                                  _vm.isEdit
                                    ? _c("span", [
                                        _vm._v(
                                          "通行権限グループが編集されました。"
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "通行権限グループが追加されました。"
                                        )
                                      ]),
                                  _vm._v("ユーザーの紐づけに失敗しています。"),
                                  _c("br"),
                                  _vm._v(
                                    "\n            通行権限グループ編集より、ユーザーの確認をしてください。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            別の通行可能時間帯が設定されている通行権限グループに紐づいている場合、追加することができません。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            登録失敗ユーザー名: " +
                                      _vm._s(_vm.UserSubmittedFail.toString()) +
                                      "\n            "
                                  ),
                                  _vm.administratorsAddFail.length > 0
                                    ? _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          " 登録失敗ユーザー名: " +
                                            _vm._s(
                                              _vm.administratorsAddFail.toString()
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errorMessageLogs !== "" && _vm.loading === false
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                                  attrs: { cols: "11", align: "right" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.errorMessageLogs) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "messageRow",
                              attrs: { cols: "1", align: "right" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-primary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.clickSaveGroup()
                                    }
                                  }
                                },
                                [_vm._v("\n            登録\n            ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }