var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _c(
      "div",
      {
        staticClass: "allig-reflection-card-table",
        staticStyle: { position: "relative" }
      },
      [
        _c("ModalLoading", { attrs: { loading: _vm.loadingAff } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "round-action" },
          [
            _c(
              "v-row",
              { staticClass: "btn-div", attrs: { justify: "end" } },
              [
                _c("v-col", {
                  staticClass: "pt-0",
                  attrs: { xl: "7", sm: "8" }
                }),
                _vm._v(" "),
                _c(
                  "v-col",
                  [
                    _c(
                      "v-row",
                      { staticClass: "btn-div", attrs: { justify: "end" } },
                      [
                        _c(
                          "div",
                          { staticClass: "text-center " },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "btn-add pt-0",
                                attrs: { cols: "12" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "btn-primary",
                                    attrs: {
                                      outlined: "",
                                      disabled:
                                        _vm.checkItem(_vm.cardSelected)
                                          .length === 0
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.$emit(
                                          "add-aff-to-group",
                                          _vm.checkItem(_vm.cardSelected)
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    他の所属を追加する\n                  "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              {
                staticClass: "mt-3",
                attrs: {
                  "no-gutters": "",
                  justify: "space-between",
                  "align-content": "center"
                }
              },
              [
                _c("show-per-page", {
                  attrs: {
                    "per-page": _vm.perPage,
                    page: _vm.page,
                    "items-length": _vm.cardTotalCount
                  }
                }),
                _vm._v(" "),
                _c("v-spacer")
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "round-data" },
          [
            _c("v-data-table", {
              class:
                _vm.isPage === "group-aff"
                  ? "modal-table border-table scroll-table"
                  : "border-table scroll-table",
              attrs: {
                "must-sort": "",
                headers: _vm.headers,
                items: _vm.getData,
                "items-per-page": _vm.perPage,
                "item-key": "departmentId",
                "no-data-text": "データーはありません",
                "loading-text": "データを読み込中です",
                loading: _vm.loadingAff,
                "hide-default-footer": "",
                "show-select": true,
                "custom-sort": _vm.customSort
              },
              on: {
                "toggle-select-all": function($event) {
                  return _vm.handleToggleAll($event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item.icon",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "span",
                          [
                            _c("v-icon", [_vm._v("mdi-account")]),
                            _vm._v(
                              "\n                " +
                                _vm._s(item.userCount) +
                                "\n              "
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "mr-5 item-count" },
                          [
                            _c("v-icon", [_vm._v("mdi-domain")]),
                            _vm._v(
                              "\n                " +
                                _vm._s(item.groupCount) +
                                "\n              "
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "item.name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.name !== undefined
                          ? _c("span", { staticClass: "item-user-id" }, [
                              _vm._v(_vm._s(item.name))
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  !_vm.checkAll
                    ? {
                        key: "header.data-table-select",
                        fn: function(ref) {
                          var on = ref.on
                          var props = ref.props
                          return [
                            _c(
                              "v-simple-checkbox",
                              _vm._g(
                                _vm._b(
                                  { attrs: { color: "purple", disabled: "" } },
                                  "v-simple-checkbox",
                                  props,
                                  false
                                ),
                                on
                              )
                            )
                          ]
                        }
                      }
                    : null,
                  {
                    key: "item.data-table-select",
                    fn: function(ref) {
                      var item = ref.item
                      var isSelected = ref.isSelected
                      var select = ref.select
                      return [
                        item.disable
                          ? _c("v-simple-checkbox", {
                              attrs: {
                                value: true,
                                readonly: item.disable,
                                disabled: item.disable,
                                ripple: false
                              },
                              on: {
                                input: function($event) {
                                  return select($event)
                                }
                              }
                            })
                          : _c("v-simple-checkbox", {
                              attrs: {
                                value: isSelected,
                                readonly: item.disabled,
                                disabled: item.disabled,
                                ripple: false
                              },
                              on: {
                                input: function($event) {
                                  return select($event)
                                }
                              }
                            })
                      ]
                    }
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.cardSelected,
                callback: function($$v) {
                  _vm.cardSelected = $$v
                },
                expression: "cardSelected"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("v-row", {
          staticStyle: { height: "20px" },
          attrs: { "no-gutters": "" }
        }),
        _vm._v(" "),
        _c("v-spacer"),
        _vm._v(" "),
        _c(
          "v-row",
          {
            staticClass: "mt-3",
            attrs: { "no-gutters": "", justify: "space-between" }
          },
          [
            _c("v-spacer"),
            _vm._v(" "),
            _c("v-col", { staticClass: "mr-5 mb-2" }),
            _vm._v(" "),
            _c("pagination", {
              staticClass: "mb-1",
              attrs: {
                "page-length": Math.ceil(_vm.cardTotalCount / _vm.perPage),
                disabled: _vm.loadingAff
              },
              model: {
                value: _vm.innerPage,
                callback: function($$v) {
                  _vm.innerPage = $$v
                },
                expression: "innerPage"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }