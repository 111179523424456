var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "room-form" },
    [
      _c("loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ]
      }),
      _vm._v(" "),
      _vm._l(_vm.successMessages, function(message, index) {
        return _c("alert", {
          key: index,
          attrs: { type: "success", text: message, dismissible: false },
          model: {
            value: _vm.showSuccessMessages[index],
            callback: function($$v) {
              _vm.$set(_vm.showSuccessMessages, index, $$v)
            },
            expression: "showSuccessMessages[index]"
          }
        })
      }),
      _vm._v(" "),
      _vm._l(_vm.errorMessages, function(message) {
        return _c("alert", {
          key: message,
          attrs: { value: message !== "", type: "error", text: message }
        })
      }),
      _vm._v(" "),
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.noValidationError,
            callback: function($$v) {
              _vm.noValidationError = $$v
            },
            expression: "noValidationError"
          }
        },
        [
          _c("input-form", {
            attrs: { title: "部屋名", inputType: "text", rules: _vm.nameRules },
            model: {
              value: _vm.innerRoomName,
              callback: function($$v) {
                _vm.innerRoomName = $$v
              },
              expression: "innerRoomName"
            }
          }),
          _vm._v(" "),
          _c("input-form", {
            attrs: {
              title: "ゲート",
              inputType: "multi-select",
              "select-items": _vm.gateItems,
              rules: _vm.relationsRoomGateRules,
              classMore: "v-select-item"
            },
            model: {
              value: _vm.innerSelectedGateIds,
              callback: function($$v) {
                _vm.innerSelectedGateIds = $$v
              },
              expression: "innerSelectedGateIds"
            }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mt-4", attrs: { "no-gutters": "" } },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("cancel-button", {
                staticClass: "mr-1",
                attrs: { text: "閉じる" },
                on: {
                  click: function($event) {
                    return _vm.$emit("cancel")
                  }
                }
              }),
              _vm._v(" "),
              _c("success-button", {
                attrs: {
                  text: _vm.isEdit ? "保存する" : "部屋を作成する",
                  disabled: _vm.loading
                },
                on: { click: _vm.clickSave }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }