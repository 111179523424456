



























































































































































































































































































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import ModalTitle from '@/components/molecules/ModalTitle/ModalTitle.vue';
import {Group} from '@/types/alligate';
import { Emit } from 'vue-property-decorator';
import Modal from '@/components/molecules/Modal/Modal.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
export interface TypeItem {
  title: string;
}
interface Type {
  pCodeId: string;
  type: number;
  userId: string;
  isValid: boolean;
  detail: string;
}
export interface DataType {
  userIds: any;
  searchInput: string;
}
export default Vue.extend({
  name: 'ControlPanelDetail',
  components: {
    TextLink,
    ModalTitle,
    Modal,
    SuccessButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    showModalClear: {
      type: Boolean,
      default: false,
    },
    controlPanelDetail: {
      default: {},
    },
    gateList: {
      default: () => [],
    },
    gateListName: {
      default: () => [],
    },
    dataAllUses: {
      default: () => [],
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageModal: {
      type: String,
      default: '',
    },
    successMessageModal: {
      type: String,
      default: '',
    },
    errorMessageProcess: {
      default: [],
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    countUsers: {
      type: Number,
      default: 0,
    },
    authority: {
      type: Object,
      default: ({}),
    },
  },
  data: (): DataType => ({
    userIds: [],
    searchInput: '',
  }),
  computed: {
    isModalClear: {
      get(): boolean {
        return this.showModalClear;
      },
      set(val: boolean): void {
        if (val === false) {
          this.$emit('closeModalClear');
        }
        return;
      },
    },
  },
  methods: {
    convertStatus(data: any) {
      let status = '登録済み';
      if (data.phoneId === '') {
        status = '未登録';
      }
      return status;
    },
    clickEditController(value: any) {
      // this.$router.push({ path: Config.CONTROL_PANEL_EDIT, query: {controllerId: value}});
      // ゲート編集画面遷移イベント
    },
    clickAddUser(value: any) {
      this.$router.push({ path: Config.GATE_ADD_USER, query: {gateId: value}});
    },
    updateUserTags() {
      this.searchInput = '';
    },
    isObject(val: any) {
      return val instanceof Object;
    },
  },
  watch: {
    loading(val: any) {
      if (val === false) {
        this.userIds = [];
      }
    },
  },
});
