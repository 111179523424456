import Vue from 'vue';
import * as Config from '@/config';
import { NoticeSetting } from '@/types/alligate';

/**
 * 設定済みの通知設定を取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<NoticeSetting[]>} 取得した全ユーザ
 */
export async function getAllNoticeSettings(vue: Vue): Promise<NoticeSetting[]> {
  try {
    const noticeSettings = await getAllNoticeSettingsIter(vue, 1);
    return noticeSettings;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('ユーザー情報の取得に失敗しました。ユーザー情報を取得する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 全通知設定を取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<NoticeSetting[]>} 取得した全通知設定
 */
async function getAllNoticeSettingsIter(vue: Vue, page: number): Promise<NoticeSetting[]> {
  const query = `?limit=1000&page=${page}&sortKey=${Config.DESC}:${Config.REGISTERED}`;
  try {
    const response = await vue.$axios.get(Config.NOTICE_SETTING_API + query);
    if (response.data.endRecord === response.data.maxRecords) {
      return response.data.noticeSettings;
    } else {
      const nextNoticeSettings = await getAllNoticeSettingsIter(vue, page + 1);
      return response.data.noticeSettings.concat(nextNoticeSettings);
    }
  } catch (err) {
    throw err;
  }
}

/**
 * 指定したIDの削除を行う。
 * @param {Vue} vue Vueインスタンス
 * @param {String} ID 削除対象のnoticeSettingId
 * @returns {Promise<any>} DELETE /notice-setting/{ID} のレスポンス
 */
export async function deleteNoticeSetting(vue: Vue, ID: string): Promise<any> {
  try {
    const response = await vue.$axios.delete(Config.NOTICE_SETTING_API + `/${encodeURIComponent(ID)}`);
    return response.data;
  } catch (err) {
    switch ((err as any).response.status) {
      case 400:
        throw(new Error('通知設定の削除に失敗しました。'));
      case 401:
        throw(new Error('通知設定の削除に失敗しました。通知設定を削除する権限がありません。'));
      case 403:
        throw(new Error('通知設定の削除に失敗しました。通知設定を削除する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return {};
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_DELETE);
    }
  }
}

/**
 * 指定したIDの設定変更を行う。
 * @param {Vue} vue Vueインスタンス
 * @param {String} ID 変更対象のnoticeSettingId
 * @param {NoticeSetting} body 登録する通知設定
 * @returns {Promise<any>} PUT /notice-setting/{ID} のレスポンス
 */
export async function putNoticeSetting(vue: Vue, ID: string, body: NoticeSetting): Promise<any> {
  try {
    const response = await vue.$axios.put(Config.NOTICE_SETTING_API + `/${encodeURIComponent(ID)}`, body);
    return response.data;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('通知設定の変更に失敗しました。通知設定を変更する権限がありません。'));
      case 404:
        throw(new Error('通知設定の変更に失敗しました。通知設定は既に削除されています。'));
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_PUT);
    }
  }
}

/**
 * 通知設定の追加を行う。
 * @param {Vue} vue Vueインスタンス
 * @param {NoticeSetting} body 登録する通知設定
 * @returns {Promise<any>} POST /notice-setting のレスポンス
 */
export async function postNoticeSetting(vue: Vue, body: NoticeSetting): Promise<any> {
  try {
    const response = await vue.$axios.post(Config.NOTICE_SETTING_API, body);
    return response.data;
  } catch (err) {
    switch ((err as any).response.status) {
      case 400:
        throw(new Error('通知設定の追加に失敗しました。'));
      case 403:
        throw(new Error('通知設定の追加に失敗しました。通知設定を追加する権限がありません。'));
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_POST);
    }
  }
}
