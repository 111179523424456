
















































































import {Vue } from 'vue-property-decorator';
import GroupListTemplate from '@/components/templates/GroupListTemplate/GroupListTemplate.vue';
import * as Config from '@/config';
import store from '@/store';
import moment from 'moment';
export interface DataType {
  isGetDataList: boolean;
  dataGroups: any;
  perPage: number;
  currPage: number;
  keyword: string;
  scorp: any;
  groupTotalCount: number;
  loading: boolean;
  loadingAff: boolean;
  errorMessageLogs: string;
  successMessageLogs: string;
  showProgress: boolean;
  rowinErrDialog: number;
  errorMessageProcess: any;
  successItemProcess: number;
  totalDataRowProcess: number;
  progress: number;
  isFinishProcess: boolean;
  typeAction: number;
  dataAction: any;
  groupIdAction: string;
  showConfirm: boolean;

  groupExpanedGate: Map<string, GroupGate>; // data Gate of Group
  isReRenderGate: number;
  isRemoveGateSelected: number;

  groupExpanedUser: Map<string, GroupUser>; // data User of Group
  isReRenderUser: number;
  isRemoveUserSelected: number;

  isRemoveGroupSelected: number;
  dataGroup: any;
  groupList: any;
  userList: any;
  gateList: any;
  departments: any;
  users: any;
  dataRelationDepartmentGroup: any;
  dataRelationUserGroup: any;
  dataGatePass: any;
  authority: any;
  optionDepartments: any;
  groupId: any;
  tabClicked: any;
}
export interface GroupGate {
  currPageGate?: number;
  totalGateCount?: number;
  isGetDataGate?: boolean;
  data?: any;
}

export interface GroupUser {
  currPageUser?: number;
  totalUserCount?: number;
  isGetDataUser?: boolean;
  data?: any;
}

export default Vue.extend({
  name: 'GroupListPage',
  components: {
    GroupListTemplate,
  },
  data: (): DataType => ({
    isGetDataList: false,
    perPage: Config.PAGE_DEFAULT,
    currPage: 1,
    scorp: ['groupName'],
    keyword: '',
    groupTotalCount: 0,
    dataGroups: [],
    loading: false,
    loadingAff: false,
    errorMessageLogs: '',
    successMessageLogs: '',
    showProgress: false,
    rowinErrDialog: 0,
    errorMessageProcess: [],
    successItemProcess: 0,
    totalDataRowProcess: 0,
    progress: 0,
    isFinishProcess: false,
    typeAction: 0,
    dataAction: [],
    groupIdAction: '',
    showConfirm: false,

    groupExpanedGate: new Map(),
    isReRenderGate: 0,
    isRemoveGateSelected: 0,

    groupExpanedUser: new Map(),
    isReRenderUser: 0,
    isRemoveUserSelected : 0,

    isRemoveGroupSelected: 0,
    dataGroup: {
      passableTimeSetting: [],
      passableName: '',
      department: {
        data: [],
        loading: false,
      },
      loading: false,
      groupPassages: {
        data: [],
        dataOri: [],
        loading: false,
        total: 0,
        page: 1,
        perPage: 25,
        keyword: '',
        scorp: [],
      },
      realtionsGateList: {
        data: [],
        loading: false,
      },
      realtionsGateGroup: {
        data: [],
        loading: false,
      },
      isAdminOfRemoteUnlock: false,
      isAdminOfGroupPassages: false,
      isAdminOfGatePassages: false,
    },
    groupList: [],
    userList: [],
    gateList: [],
    departments: [],
    users: [],
    dataRelationDepartmentGroup: {
      totalDataRowProcess: 0,
      progress: 0,
      successItemProcess: '',
      errorMessageProcess: [],
      isFinishProcess: false,
    },
    dataRelationUserGroup: {
      totalDataRowProcess: 0,
      progress: 0,
      successItemProcess: '',
      errorMessageProcess: [],
      isFinishProcess: false,
    },
    dataGatePass: {
      totalDataRowProcess: 0,
      progress: 0,
      successItemProcess: '',
      errorMessageProcess: [],
      isFinishProcess: false,
    },
    authority: store.state.auth,
    optionDepartments: {
      page: 1,
      total: 0,
    },
    groupId: '',
    tabClicked: {
      tab0: false,
      tab1: false,
      tab2: false,
      tab3: false,
    },
  }),
  async created() {
    localStorage.setItem('userValid', 'true');
    localStorage.setItem('isShowValidUser', 'true');
    if (typeof localStorage.isVaildDataGroup === 'undefined') {
      localStorage.setItem('isVaildDataGroup', 'true');
    }
    if (typeof localStorage.groupSortKey === 'undefined') {
      localStorage.setItem('groupSortKey', 'registered');
    }
    if (typeof localStorage.groupSortName === 'undefined') {
      localStorage.setItem('groupSortName', 'desc');
    }
    if (typeof localStorage.pageSizeGroup === 'undefined') {
      localStorage.setItem('pageSizeGroup', Config.PAGE_DEFAULT.toString());
      this.perPage = Config.PAGE_DEFAULT;
    } else {
      this.perPage = parseInt(localStorage.pageSizeGroup);
    }
    const groupId = this.$route.query.groupId;
    if (groupId !== null && groupId !== undefined && groupId !== '') {
      localStorage.setItem('prevRoute', Config.LOG);
      localStorage.setItem('groupId1', groupId.toString());
      this.groupId = groupId;
      if (localStorage.isLoad !== undefined && localStorage.isLoad === '1'
      && typeof localStorage.groupInfo !== 'undefined' ) {
        // not load
          this.dataGroup.loading = true;
          this.groupList = [];
          localStorage.setItem('isLoad', '0');
          await this.getGroupAuth(groupId);
          setTimeout(() => {
            this.groupList = [JSON.parse(localStorage.groupInfo)];
            this.dataGroup.loading = false;
          }, 300);
      } else {
        this.getGroups(1);
      }
      this.tabClicked.tab0 = true;
    }
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  mounted() {
    this.$emit('activeMenu', 'groups');
  },
  methods: {
    getUserAgain(groupId: any) {
      this.dataGroup.groupPassages.loading = true;
      this.dataGroup.groupPassages.total = 0;
      this.dataGroup.groupPassages.dataOri = [];
      this.dataGroup.groupPassages.data = [];
      this.dataGroup.groupPassages.page = 1;
      this.getUser(groupId, 1);
    },
    clickAddGroup() {
      this.$router.push({ path: Config.GROUP_ADD});
    },
    clickEditGroup(value: any) {
      this.$router.push({ path: Config.GROUP_EDIT, query: {groupId: value}});
    },
    clickGroupDetail(value: any) {
      this.$router.push({ path: Config.GROUP_DETAIL, query: {groupId: value}});
    },
    closeModalProgress() {
      this.showProgress = false;
      if (this.successItemProcess > 0) {
        if (this.typeAction === 0 || this.typeAction === 1 || this.typeAction === 2) {
          this.getListGroups();
        } else if (this.typeAction === 3) {
          // gate
          this.removegateSelected();
          const gate: GroupGate = {
            isGetDataGate: false,
            currPageGate: 1,
            totalGateCount: 0,
            data: [],
          };
          this.groupExpanedGate.set(this.groupIdAction, gate);
          this.getGateOfGroup(this.groupIdAction, 1);
          this.getListGroups();
        } else if (this.typeAction === 4) {
          // user
          this.removeUserSelected();
          this.groupExpanedUser = new Map();
          const user: GroupUser = {
            isGetDataUser: false,
            currPageUser: 1,
            totalUserCount: 0,
            data: [],
          };
          this.groupExpanedUser.set(this.groupIdAction, user);
          this.getUserOfGroup(this.groupIdAction, 1);
          this.getListGroups();
        }
      }
    },

    showDialogConfirm(value: any) {
      this.typeAction = value.type;
      this.dataAction = value.data;
      if (value.groupId !== undefined) {
        this.groupIdAction = value.groupId;
      }
      this.showConfirm = true;
    },

    closeDialogConfirm() {
      this.showConfirm = false;
    },

    async clickGroupsAction() {
      this.showConfirm = false;
      this.showProgress = true;
      this.rowinErrDialog = 0;
      this.rowinErrDialog = 0;
      this.errorMessageProcess = [];
      this.successItemProcess = 0;
      this.progress = 0;
      this.isFinishProcess = false;

      this.totalDataRowProcess = this.dataAction.length;
      const percent = (100 / this.totalDataRowProcess);

      for (const item of this.dataAction) {
        let result;
        if (this.typeAction === 0) {
          //  enable
          result = await this.enableOrDisableGroup(item, true);
        } else if (this.typeAction === 1) {
          //  disable
          result = await this.enableOrDisableGroup(item, false);
        } else if (this.typeAction === 2) {
          //  delete
          let resultDel;
          let resultdeleteRe;
          resultDel = await this.deleteGroup(item);
          if (resultDel) {
            resultdeleteRe = await this.deleteRelationGateGroup(item);
            if (resultdeleteRe) {
              result = await this.putGroup(item);
            }
          }
        } else if (this.typeAction === 3) {
          // remove from to group
          result = await this.removeGateFromGroup(item.relationId, item.gate.gateId);
        } else if (this.typeAction === 4) {
          // remove user from group
          result = await this.removeUserFromGroup(item.groupPassageId, item.user.userId);
        }
        if (result) {
          this.successItemProcess++;
        }
        this.progress += percent;
      }
      this.isFinishProcess = true;
    },
    changePerPage(value: any): any {
      this.perPage = value;
      localStorage.setItem('pageSizeGroup', value);
      this.getListGroups();
    },
    changePage(value: any): any {
      this.currPage = value;
      this.getListGroups();
    },
    searchGroup(value: any): any {
      this.keyword = value.text;
      this.scorp = value.targets;
      this.getListGroups();
    },
    enableOrDisableGroup(groupId: string, isEnable: boolean) {
      return new Promise((resolve) => {
        const data = {
          isValid: isEnable,
        };
        this.$http.put(Config.GROUP_LIST_API + '/' + encodeURIComponent(groupId), data)
        .then((response: any) => {
          console.log(response);
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = 'groupIdが存在しない。';
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = groupId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        });
      });
    },
    putGroup(groupId: any) {
      return new Promise((resolve) => {
        let data;
        data = {
          isValid: false,
        };
        this.$http.put(Config.GROUP_LIST_API + '/' + encodeURIComponent(groupId), data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              // this.successMessageLogs = Config.DELETE_API_SUCCESS;
              resolve(true);
            } else {
              const rowstatus = groupId + '>><span>' + Config.ERROR_DEFAULT + '</span>';
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            let errorMessageLogs = '';
            switch (err.status) {
              case 400:
                errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                errorMessageLogs = Config.ERROR_403;
                break;
              case 409:
                errorMessageLogs = '入力された通行権限グループ名は既に使用されています。他の通行権限グループ名を入力してください。';
                break;
              case 500:
                errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                errorMessageLogs = Config.ERROR_503;
                break;
              default:
                errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = groupId + '>><span>' + errorMessageLogs + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          });
      });
    },
    deleteRelationGateGroupItem(relationId: any) {
      return new Promise((resolve) => {
        this.$http.delete(Config.RELATION_GATE_GROUP + '/' + encodeURIComponent(relationId))
          .then((res: any) => {
            if (res.body.message === 'Success') {
              resolve(true);
            } else {
              const rowstatus = relationId + '>><span>' + Config.ERROR_DEFAULT + '</span>';
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            let errorMessageLogs = '';
            switch (err.status) {
              case 400:
                errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorMessageLogs = Config.ERROR_403;
                } else {
                  errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                errorMessageLogs = Config.ERROR_503;
                break;
              default:
                errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = relationId + '>><span>' + errorMessageLogs + '</span>';
            if (err.status === 404) {
              resolve(true);
            } else {
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          });
      });
    },
    deleteRelationGateGroup(groupId: any) {
      return new Promise((resolve) => {
        this.$http.get(Config.RELATION_GATE_GROUP + '?groupId=' + encodeURIComponent(groupId))
          .then(async (response: any) => {
            if (response.body.maxRecords > 0) {
              // const relationId = response.body.relationsGateGroup[0].relationId;
              let result;
              for (const item of response.body.relationsGateGroup) {
                result = await this.deleteRelationGateGroupItem(item.relationId);
                if (!result) {
                  resolve(false);
                }
              }
              resolve(true);
            } else {
              const rowstatus = groupId + '>><span>' + Config.ERROR_404 + '</span>';
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            let errorMessageLogs = '';
            switch (err.status) {
              case 400:
                errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorMessageLogs = Config.ERROR_403;
                } else {
                  errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                errorMessageLogs = Config.ERROR_503;
                break;
              default:
                errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = groupId + '>><span>' + errorMessageLogs + '</span>';
            if (err.status === 404) {
              resolve(true);
            } else {
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          }); // catch
      });
    },
    deleteGroupItem(groupPassageId: any) {
      return new Promise((resolve) => {
        this.$http.delete(Config.GROUP_PASSAGE + '/' + encodeURIComponent(groupPassageId))
          .then((res: any) => {
            if (res.body.message === 'Success') {
              resolve(true);
            } else {
              const rowstatus = groupPassageId + '>><span>' + Config.ERROR_DEFAULT + '</span>';
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = groupPassageId + '>><span>' + errorName + '</span>';
            if (err.status === 404) {
              resolve(true);
            } else {
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          }); // catch
      });
    },
    deleteGroup(groupId: string) {
      return new Promise((resolve) => {
        this.$http.get(Config.GROUP_PASSAGE + '?groupId=' + encodeURIComponent(groupId))
        .then(async (response: any) => {
          if (response.body.maxRecords > 0) {
            // const groupPassageId = response.body.groupPassages[0].groupPassageId;
            let result;
            for (const item of response.body.groupPassages) {
              result = await this.deleteGroupItem(item.groupPassageId);
              if (!result) {
                resolve(false);
              }
            }
            resolve(true);
          } else {
            const rowstatus = groupId + '>><span>' + Config.ERROR_404 + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = groupId + '>><span>' + errorName + '</span>';
          if (err.status === 404) {
            resolve(true);
          } else {
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }
          }); // catch
      });
    },

    removeGateFromGroup(relationId: string, gateId: string) {
      return new Promise((resolve) => {
        this.$http.delete(Config.RELATION_GATE_GROUP +
          '/' + encodeURIComponent(relationId))
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = gateId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        }); // catch
      });
    },

    removeUserFromGroup(groupPassageId: string, userId: string) {
      return new Promise((resolve) => {
        this.$http.delete(Config.GROUP_PASSAGE +
          '/' + encodeURIComponent(groupPassageId))
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = '該当する通行権限グループがありません。';
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = userId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        }); // catch
      });
    },
    ShowOnlyIsVaild(data: any) {
      localStorage.setItem('isVaildDataGroup', data.toString());
      this.getListGroups();
    },
    getListGroups(): any {
      this.isGetDataList = true;
      this.removeGroupSelected();
      let apiLink = Config.GROUP_LIST_API + '?limit=' + localStorage.pageSizeGroup +
      '&page=' + this.currPage + '&sortKey=' +
      localStorage.groupSortName + ':' + localStorage.groupSortKey;
      if (this.keyword !== null && this.keyword !== undefined && this.keyword !== '') {
        apiLink += '&keyword=' + this.keyword + '&scorp=' + this.scorp;
      }
      if (localStorage.isVaildDataGroup === 'true') {
        apiLink += '&isValid=true';
      }
      apiLink += '&embed=passableTimeSetting';
      this.$http.get(apiLink)
        .then((response: any) => {
          this.resetData();
          if (!this.isEmpty(response.body.groups)) {
            this.dataGroups = response.body.groups;
            this.groupTotalCount = response.body.maxRecords;
            this.currPage = Math.ceil(response.body.startRecord / this.perPage);
          } else {
            this.groupTotalCount = 0;
            this.currPage = 1;
          }
          this.isGetDataList = false;
        })
        .then( null, (err: any) => {
          this.isGetDataList = false;
          this.resetData();
          switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
        }); // catch
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.dataGroups = [];
      this.errorMessageLogs = '';
    },

    changeExpandedGroup(event: any) {
      if  (event.value) {
        const data = event.item;

        this.groupExpanedGate = new Map();
        const gate: GroupGate = {
          isGetDataGate: false,
          currPageGate: 1,
          totalGateCount: 0,
          data: [],
        };
        this.groupExpanedGate.set(data.groupId, gate);
        this.getGateOfGroup(data.groupId, 1);

        this.groupExpanedUser = new Map();
        const user: GroupUser = {
          isGetDataUser: false,
          currPageUser: 1,
          totalUserCount: 0,
          data: [],
        };
        this.groupExpanedUser.set(data.groupId, user);
        this.getUserOfGroup(data.groupId, 1);
      }
    },

    getGateOfGroup(groupId: string, page: number) {
      const data = this.groupExpanedGate.get(groupId);
      if (data !== undefined) {
        data.isGetDataGate = true;
        data.currPageGate = page;
        this.reRenderGate();
        const apiLink = Config.RELATION_GATE_GROUP +
          '?groupId=' + encodeURIComponent(groupId) + '&limit=25&page=' + page + '&sortKey=desc:gateId&embed=gate';
        this.$http.get(apiLink)
          .then((response: any) => {
            if (response.body.maxRecords > 0 && !this.isEmpty(response.body.relationsGateGroup)) {
              data.totalGateCount = response.body.maxRecords;
              data.currPageGate = Math.ceil(response.body.startRecord / 25);
              data.data = response.body.relationsGateGroup;
            } else {
              data.totalGateCount = 0;
              data.currPageGate = 1;
            }
            data.isGetDataGate = false;
            this.reRenderGate();
            this.removegateSelected();
          })
          .then( null, (err: any) => {
            data.isGetDataGate = false;
            this.reRenderGate();
            this.removegateSelected();
          }); // catch
      }
    },
    changePageGate(event: any) {
      this.getGateOfGroup(event.groupId, event.page);
    },

    getUserOfGroup(groupId: string, page: number) {
      const data = this.groupExpanedUser.get(groupId);
      if (data !== undefined) {
        data.isGetDataUser = true;
        data.currPageUser = page;
        this.reRenderUser();
        const apiLink = Config.GROUP_PASSAGE +
          '?groupId=' + encodeURIComponent(groupId) + '&limit=25&page=' + page + '&sortKey=desc:userId&embed=user';
        this.$http.get(apiLink)
          .then((response: any) => {
            // response.body = {
            //   message: 'Success',
            //   statementId: 'DEFA7140-1DEC-4642-BAA2-BB0B5B74D212',
            //   startRecord: 1,
            //   endRecord: 1,
            //   maxRecords: 100,
            //   groupPassages: [
            //     {
            //       groupPassageId: 'EA2CE971-F55F-45A6-835B-DE8C5D526E42',
            //       user: {
            //         userId: 'zpsHmwSwxfSw1',
            //         name: 'User 1',
            //       },
            //     },
            //   {
            //       groupPassageId: 'EA2CE971-F55F-45A6-835B-DE8C5D526E43',
            //       user: {
            //         userId: 'zpsHmwSwxfSw2',
            //         name: 'User 2',
            //       },
            //     },
            //   {
            //       groupPassageId: 'EA2CE971-F55F-45A6-835B-DE8C5D526E44',
            //       user: {
            //         userId: 'zpsHmwSwxfSw3',
            //         name: 'User 3',
            //       },
            //     },
            //   {
            //       groupPassageId: 'EA2CE971-F55F-45A6-835B-DE8C5D526E45',
            //       user: {
            //         userId: 'zpsHmwSwxfSw4',
            //         name: 'User 4',
            //       },
            //     },
            //   {
            //       groupPassageId: 'EA2CE971-F55F-45A6-835B-DE8C5D526E46',
            //       user: {
            //         userId: 'zpsHmwSwxfSw5',
            //         name: 'User 5',
            //       },
            //     },
            //   ],
            // };
            if (response.body.maxRecords > 0 && !this.isEmpty(response.body.groupPassages)) {
              data.totalUserCount = response.body.maxRecords;
              data.currPageUser = Math.ceil(response.body.startRecord / 25);
              data.data = response.body.groupPassages;
            } else {
              data.totalUserCount = 0;
              data.currPageUser = 1;
            }
            data.isGetDataUser = false;
            this.reRenderUser();
            this.removeUserSelected();
          })
          .then( null, (err: any) => {
            data.isGetDataUser = false;
            this.reRenderUser();
            this.removeUserSelected();
          }); // catch
      }
    },
    changePageUser(event: any) {
      this.getUserOfGroup(event.groupId, event.page);
    },

    reRenderGate() {
      if (this.isReRenderGate < 100) {
          this.isReRenderGate++;
        } else {
          this.isReRenderGate = 0;
        }
    },

    reRenderUser() {
      if (this.isReRenderUser < 100) {
          this.isReRenderUser++;
        } else {
          this.isReRenderUser = 0;
        }
    },

    removegateSelected() {
      if (this.isRemoveGateSelected < 100) {
        this.isRemoveGateSelected++;
      } else {
        this.isRemoveGateSelected = 0;
      }
    },
    removeUserSelected() {
      if (this.isRemoveUserSelected < 100) {
        this.isRemoveUserSelected++;
      } else {
        this.isRemoveUserSelected = 0;
      }
    },
    removeGroupSelected() {
      if (this.isRemoveGroupSelected < 100) {
        this.isRemoveGroupSelected++;
      } else {
        this.isRemoveGroupSelected = 0;
      }
    },
    async changeTab(data: any) {
      if (data.tab === 0) {
        this.dataGroup.loading = false;
      } else if (data.tab === 1) {
        this.dataGroup.loading = true;
        const result =  this.getDepartmentGroup(data.groupId);
        this.dataGroup.groupPassages.total = 0;
        this.dataGroup.groupPassages.dataOri = [];
        this.dataGroup.groupPassages.data = [];
        this.dataGroup.groupPassages.page = 1;
        this.dataGroup.groupPassages.loading = true;
        this.dataGroup.groupPassages.scorp = [];
        const result2 = this.getUser(data.groupId, 1);
        await Promise.all([result, result2]);
        this.dataGroup.loading = false;
      } else if (data.tab === 2) {
        this.dataGroup.loading = true;
        await this.getDepartmentGroup(data.groupId);
        await this.getGroup(data.groupId);
        this.dataGroup.loading = false;
      } else if (data.tab === 3) {
        this.dataGroup.loading = true;
        this.dataGroup.realtionsGateGroup.data = [];
        this.dataGroup.realtionsGateList.data = [];
        this.gateList = [];
        this.dataGroup.realtionsGateList.loading = true;
        await this.getGateGroup(data.groupId, 1);
        await this.getGatePass(1);
        await this.getAllUsers(1);
        await this.getAllGates(1);
        this.dataGroup.loading = false;
        this.dataGroup.realtionsGateList.loading = false;
      }
      if (data.groupId) {
        localStorage.setItem('groupId1', data.groupId);
      }
    },
    async clickTab(data: any) {
      console.log('tab', data);
      if (data.tab === 0) {
        this.dataGroup.loading = false;
        this.tabClicked.tab0 = true;
      } else if (data.tab === 1) {
        if (!this.tabClicked.tab1) {
          this.dataGroup.loading = true;
          const result =  this.getDepartmentGroup(data.groupId);
          this.dataGroup.groupPassages.total = 0;
          this.dataGroup.groupPassages.dataOri = [];
          this.dataGroup.groupPassages.data = [];
          this.dataGroup.groupPassages.page = 1;
          this.dataGroup.groupPassages.loading = true;
          this.dataGroup.groupPassages.scorp = [];
          const result2 = this.getUser(data.groupId, 1);
          await Promise.all([result, result2]);
          this.dataGroup.loading = false;
        }
        this.tabClicked.tab1 = true;
      } else if (data.tab === 2) {
        if (!this.tabClicked.tab2) {
          this.dataGroup.loading = true;
          await this.getDepartmentGroup(data.groupId);
          await this.getGroup(data.groupId);
          this.dataGroup.loading = false;
        }
        this.tabClicked.tab2 = true;
      } else if (data.tab === 3) {
        if (!this.tabClicked.tab3) {
          this.dataGroup.loading = true;
          this.dataGroup.realtionsGateGroup.data = [];
          this.dataGroup.realtionsGateList.data = [];
          this.gateList = [];
          this.dataGroup.realtionsGateList.loading = true;
          await this.getGateGroup(data.groupId, 1);
          await this.getGatePass(1);
          await this.getAllUsers(1);
          await this.getAllGates(1);
          this.dataGroup.loading = false;
          this.dataGroup.realtionsGateList.loading = false;
        }
        this.tabClicked.tab3 = true;
      }
      if (data.groupId) {
        localStorage.setItem('groupId1', data.groupId);
      }
    },
    changePageUsers(item: any) {
      this.dataGroup.groupPassages.page = item.$event.page;
      this.dataGroup.groupPassages.loading = true;
      this.getUsersChange();
    },
    getDepartmentUserValid(data: any) {
      localStorage.setItem('userValid', data.value.toString());
      localStorage.setItem('isShowValidUser', data.value.toString());
      this.dataGroup.groupPassages.page = 1;
      this.dataGroup.groupPassages.loading = true;
      this.getUsersChange();
    },
    getUsersChange() {
      let dataOri = this.dataGroup.groupPassages.dataOri;
      let check = false;
      if (localStorage.userValid !== undefined && localStorage.userValid !== '' && localStorage.userValid === 'true') {
        dataOri = this.dataGroup.groupPassages.dataOri.filter((element: any) =>
        element.user.isValid === 1);
        check = true;
      }
      if (this.dataGroup.groupPassages.keyword !== '') {
        if (this.dataGroup.groupPassages.scorp.length === 2) {
          dataOri = dataOri.filter((element: any) =>
          element.user.name.toLowerCase().includes(this.dataGroup.groupPassages.keyword.toLowerCase()) === true ||
          element.user.userId.toLowerCase().includes(this.dataGroup.groupPassages.keyword.toLowerCase()) === true);
        } else {
          //
          if (this.dataGroup.groupPassages.scorp[0] === 'name') {
            dataOri = dataOri.filter((element: any) =>
            element.user.name.toLowerCase().includes(this.dataGroup.groupPassages.keyword.toLowerCase()) === true);
          } else {
            dataOri = dataOri.filter((element: any) =>
            element.user.userId.toLowerCase().includes(this.dataGroup.groupPassages.keyword.toLowerCase()) === true);
          }
        }
      }
      const data  = Array.of(...dataOri);
      this.dataGroup.groupPassages.data =
      data.slice((this.dataGroup.groupPassages.page - 1) * 25, this.dataGroup.groupPassages.page * 25);
      setTimeout(() => {
        this.dataGroup.groupPassages.loading = false;
        this.dataGroup.groupPassages.total = (this.dataGroup.groupPassages.keyword !== ''  || check === true ) ?
        this.dataGroup.groupPassages.data.length : this.dataGroup.groupPassages.dataOri.length;
      }, 100);
    },
    getDepartmentGroup(groupId: any) {
      return new Promise((resolve, reject) => {
        this.dataGroup.department.loading = true;
        this.dataGroup.department.data = [];
        this.$http.get(Config.RELATION_DEPARTMENT_GROUP_API + `?limit=1000&groupId=${groupId}&embed=department`)
          .then((response: any) => {
            this.dataGroup.department.data = response.body.relationsDepartmentGroup;
            this.dataGroup.department.loading = false;
            resolve(true);
          })
          .then( null, (err: any) => {
            // this.dataGroup.department.data = [];
            console.log('err: ', err);
            this.dataGroup.department.loading = false;
            resolve(false);
          });
      });
    },
    getDepartmentGroupNoLoading(groupId: any) {
      return new Promise((resolve, reject) => {
        this.dataGroup.department.data = [];
        this.$http.get(Config.RELATION_DEPARTMENT_GROUP_API + `?limit=1000&groupId=${groupId}&embed=department`)
          .then((response: any) => {
            this.dataGroup.department.data = response.body.relationsDepartmentGroup;
            resolve(true);
          })
          .then( null, (err: any) => {
            // this.dataGroup.department.data = [];
            console.log('err: ', err);
            resolve(false);
          });
      });
    },
    getGroup(groupId: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.GROUP_LIST_API + '?groupId=' + encodeURIComponent(groupId) + '&limit=25&page=1&embed=passableTimeSetting')
          .then(async (response: any) => {
            this.dataGroup.passableTimeSetting = response.body.groups[0].passableTimeSetting.timeSettings;
            this.dataGroup.passableName = response.body.groups[0].passableTimeSetting.name;
            this.groupList = response.body.groups;
            await this.getGroupAuth(groupId);
            resolve(true);
          })
          .then( null, (err: any) => {
            this.dataGroup.passableTimeSetting = [];
            this.dataGroup.passableName = '';
            console.log('err: ', err);
            resolve(false);
          });
      });
    },
    getUser(groupId: any , page: any) {
      return new Promise((resolve, reject) => {
          this.$http.get(Config.GROUP_PASSAGE + '?groupId=' +
          encodeURIComponent(groupId) + '&limit=1000&embed=user' + '&page=' + page)
          .then(async (response: any) => {
          if (response.body.maxRecords > 0) {
              this.dataGroup.groupPassages.total = response.body.maxRecords;
              response.body.groupPassages.forEach((element: any) => {
                this.dataGroup.groupPassages.dataOri.push(element);
                this.dataGroup.groupPassages.data.push(element);
              });
              if (response.body.maxRecords > response.body.endRecord) {
                page++;
                resolve(this.getUser(groupId, page));
              } else {
                if (localStorage.userValid !== undefined && localStorage.userValid !== '' && localStorage.userValid === 'true') {
                  this.dataGroup.groupPassages.data = this.dataGroup.groupPassages.dataOri.filter((element: any) =>
                  element.user.isValid === 1);
                  this.dataGroup.groupPassages.total = this.dataGroup.groupPassages.data.length;
                }
                this.dataGroup.groupPassages.loading = false;
                resolve(true);
              }
            } else {
              this.dataGroup.groupPassages.loading = false;
              resolve(false);
            }
        }).then( null, (err: any) => {
          console.log('err: ', err);
          this.dataGroup.groupPassages.loading = false;
          resolve(false);
        }); // catch;
      });
    },
    getGroupAuth(groupId: any) {
        return new Promise((resolve, reject) => {
          const apiLink = Config.GROUP_ADMINISTRATOR_API +
            '?userId=' + encodeURIComponent(localStorage.userProfileId ) +
            '&groupId=' + groupId + '&limit=25&embed=group';
          this.$http.get(apiLink)
            .then(async (response: any) => {
              this.dataGroup.isAdminOfRemoteUnlock = response.body.groupAdministrators[0].isAdminOfRemoteUnlock;
              this.dataGroup.isAdminOfGroupPassages = response.body.groupAdministrators[0].isAdminOfGroupPassages;
              this.dataGroup.isAdminOfGatePassages = response.body.groupAdministrators[0].isAdminOfGatePassages;
              resolve(true);
            })
            .then( null, (err: any) => {
              console.log('err: ', err);
              resolve(false);
            });
        });
    },
    // all group for seach
    getGroups(page: any) {
      this.dataGroup.loading = true;
      this.$http.get(Config.GROUP_LIST_API + '?groupId=' + encodeURIComponent(this.groupId) + '&limit=25&page=1&embed=passableTimeSetting')
        .then(async (response: any) => {
          this.groupList = response.body.groups;
          await this.getGroupAuth(this.groupId);
          this.dataGroup.loading = false;
        })
        .then( null, (err: any) => {
          this.groupList = [];
          console.log('err: ', err);
          this.dataGroup.loading = false;
        }); // catch
    },
    loadListAff(val: any) {
      this.dataRelationDepartmentGroup = {
        total: 0,
        progressLoad: 0,
        successItem: 0,
        errorMessage: [],
        isFinish: false,
      };
      this.loadingAff = true;
      this.optionDepartments = {
        page : val,
        total : 0,
      };
      let apiLink = Config.DEPARTMENT_API + '?limit=25&page=' + val;
      if (this.authority.isAdmin === false) {
        apiLink = Config.DEPARTMENT_SIMPLE_API + '?limit=25&page=' + val;
      }
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            this.departments = response.body.departments;
            this.optionDepartments.total = response.body.maxRecords;
          } else {
            this.departments = [];
          }
          this.loadingAff = false;
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.loadingAff = false;
        }); // catch
    },
    async addAffToGroup(data: any) {
      console.log('data', data);
      this.dataRelationDepartmentGroup = {
        total: 0,
        progressLoad: 0,
        successItem: 0,
        errorMessage: [],
        isFinish: false,
      };
      let rowinErrDialog = 0;
      let successItemProcess = 0;
      let progress = 0;
      let isFinishProcess = false;
      const errorMessageProcess: any = [];
      const totalDataRowProcess = data.aff.length;
      this.dataRelationDepartmentGroup.total = totalDataRowProcess;
      const percent = (100 / totalDataRowProcess);
      for (const item of data.aff) {
        let result;
        result  = await this.postRelationDepartmentGroup(data.groupId, item.departmentId);
        if (result === true) {
          successItemProcess++;
        } else {
          const rowstatus = item.departmentId + '>><span>' + result + '</span>';
          this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
        }
        progress += percent;
        this.dataRelationDepartmentGroup.successItem = successItemProcess;
        this.dataRelationDepartmentGroup.progressLoad = progress;
        this.dataRelationDepartmentGroup.errorMessage = errorMessageProcess;
      }
      isFinishProcess = true;
      this.dataRelationDepartmentGroup.isFinish = isFinishProcess;
    },
    postRelationDepartmentGroup(groupId: string, departmentId: string) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.RELATION_DEPARTMENT_GROUP_API , {departmentId: encodeURIComponent(departmentId),
          groupId: encodeURIComponent(groupId)})
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 409:
                errorName = Config.ERROR_409_DEPARTMENT;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            resolve(errorName);
          }); // catch
      });
    },
    async removeAffToGroup(data: any) {
      console.log('data', data);
      this.dataRelationDepartmentGroup = {
        total: 0,
        progressLoad: 0,
        successItem: 0,
        errorMessage: [],
        isFinish: false,
      };
      let rowinErrDialog = 0;
      let successItemProcess = 0;
      let progress = 0;
      let isFinishProcess = false;
      const errorMessageProcess: any = [];
      const totalDataRowProcess = data.aff.length;
      const percent = (100 / totalDataRowProcess);
      this.dataRelationDepartmentGroup.total = totalDataRowProcess;
      for (const item of data.aff) {
        let result;
        result  = await this.deleteRelationDepartmentGroup(item.relationId);
        if (result === true) {
          successItemProcess++;
        } else {
          const rowstatus = item.departmentId + '>><span>' + result + '</span>';
          this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
        }
        progress += percent;
        this.dataRelationDepartmentGroup.progress = progress;
        this.dataRelationDepartmentGroup.successItem = successItemProcess;
        this.dataRelationDepartmentGroup.progressLoad = progress;
        this.dataRelationDepartmentGroup.errorMessage = errorMessageProcess;
      }
      isFinishProcess = true;
      this.dataRelationDepartmentGroup.isFinish = isFinishProcess;
    },
    deleteRelationDepartmentGroup(relationId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.RELATION_DEPARTMENT_GROUP_API + '/' + encodeURIComponent(relationId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            resolve(errorName);
          }); // catch
      });
    },
    async removeUserToGroup(data: any) {
      console.log('removeUserToGroup', data);
      this.dataRelationUserGroup = {
        total: 0,
        progressLoad: 0,
        successItem: 0,
        errorMessage: [],
        isFinish: false,
      };
      let rowinErrDialog = 0;
      let successItemProcess = 0;
      let progress = 0;
      let isFinishProcess = false;
      const errorMessageProcess: any = [];
      const totalDataRowProcess = data.users.length;
      const percent = (100 / totalDataRowProcess);
      this.dataRelationUserGroup.total = totalDataRowProcess;
      for (const item of data.users) {
        let result;
        result  = await this.deleteGroupPass(item.groupPassageId);
        if (result === true) {
          successItemProcess++;
        } else {
          const rowstatus = item.userId + '>><span>' + result + '</span>';
          this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
        }
        progress += percent;
        this.dataRelationUserGroup.progress = progress;
        this.dataRelationUserGroup.successItem = successItemProcess;
        this.dataRelationUserGroup.progressLoad = progress;
        this.dataRelationUserGroup.errorMessage = errorMessageProcess;
      }
      isFinishProcess = true;
      this.dataRelationUserGroup.isFinish = isFinishProcess;
    },
    deleteGroupPass(groupPassageId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.GROUP_PASSAGE + '/' + encodeURIComponent(groupPassageId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            resolve(errorName);
          }); // catch
      });
    },
    getAllUsers(page: any) {
      return new Promise((resolve, reject) => {
      this.$http.get(Config.USERS_LIST_SIMPLE_API + '?limit=1000&page=' + page + '&isExceptOptionalUser=true&isValid=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.users.forEach((element: any) => {
              this.userList.push({text: element.name !== '' ?
              element.name + '( ' + element.userId + ' )' : element.userId, value: element.userId});
              this.userList.sort((a: any, b: any) => (a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1));
            });
            if (response.body.maxRecords > response.body.endRecord) {
              resolve(this.getAllUsers(page + 1));
            } else {
              resolve(true);
            }
          } else {
            this.userList = [];
            resolve(false);
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          resolve(false);
        }); // catch
      });
    },
    getAllGates(page: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.GATES_LIST_API + '?limit=1000&page=' + page + '&sortKey=asc:gateId&isValid=true')
          .then((response: any) => {
            if (response.body.maxRecords > 0) {
              response.body.gates.forEach((element: any) => {
                if (this.dataGroup.realtionsGateGroup.data.includes(element.gateId)) {
                  this.gateList.push({text: element.gateName, value: element.gateId});
                  this.gateList.sort((a: any, b: any) => (a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1));
                }
              });
              if (response.body.maxRecords > response.body.endRecord) {

                resolve(this.getAllGates(page + 1));
              } else {
                resolve(true);
              }
            } else {
              this.gateList = [];
              resolve(true);
            }
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            resolve(true);
          }); // catch
      });
    },
    getGatePass(page: any) {
      return new Promise((resolve, reject) => {
        this.errorMessageLogs = '';
        this.dataGroup.realtionsGateList.loading = true;
        this.$http.get(Config.GATES_PASSAGE_API + '?limit=25&page=' + page + '&sortKey=desc:notAfter&embed=gate,user&isExceptOptionalGate=true')
          .then(async (response: any) => {
            if (response.body.maxRecords > 0) {
              const data = response.body.passages;
              response.body.passages.forEach((element: any) => {
                if (this.dataGroup.realtionsGateGroup.data.includes(element.gateId)) {
                  element.userName = element.user.name;
                  this.dataGroup.realtionsGateList.data.push(element);
                }
              });
              if (response.body.maxRecords > response.body.endRecord) {
                page++;
                resolve(this.getGatePass(page));
              } else {
                this.dataGroup.realtionsGateList.loading = false;
                resolve(true);
              }
            } else {
              this.dataGroup.realtionsGateList.loading = false;
              resolve(true);
            }
          })
          .then( null, (err: any) => {
            this.dataGroup.realtionsGateList.data = [];
            console.log('err: ', err);
            this.dataGroup.realtionsGateList.loading = false;
            resolve(false);
          });
      });
    },
    getInFoUser(item: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(item.userId))
          .then((response: any) => {
            const user = response.body.users[0];
            const i = this.dataGroup.realtionsGateList.data.map((e: { passageId: any; }) =>
            e.passageId).indexOf(item.passageId);
            if (i >= 0) {
              this.dataGroup.realtionsGateList.data[i].userName = user.name;
            }
            resolve(true);
          })
          .then(null, (error: any) => {
            resolve(false);
          });
      });
    },
    addGate(data: any) {
      console.log('data1', data);
      this.loading = true;
      this.errorMessageLogs = '';
      this.$http.post(Config.GATES_PASSAGE_API, {
        userId: data.userId,
        gateId: data.gate.value,
        notBefore: (data.notBefore),
        notAfter: (data.notAfter),
      })
        .then(async (response: any) => {
          this.dataGroup.realtionsGateList.data = [];
          await this.getGatePass(1);
          this.loading = false;
        })
        .then( null, (err: any) => {
          let errorName = '';
          this.loading = false;
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 409:
              errorName = '有効な時間が設定されていません';
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          this.errorMessageLogs = errorName;
        }); // catch
    },
    async removeGatePass(data: any) {
      console.log('data', data);
      this.dataGatePass = {
        total: 0,
        progressLoad: 0,
        successItem: 0,
        errorMessage: [],
        isFinish: false,
      };
      let rowinErrDialog = 0;
      let successItemProcess = 0;
      let progress = 0;
      let isFinishProcess = false;
      const errorMessageProcess: any = [];
      const totalDataRowProcess = data.length;
      const percent = (100 / totalDataRowProcess);
      this.dataGatePass.total = totalDataRowProcess;
      for (const item of data) {
        let result;
        result  = await this.deleteGatePass(item.passageId);
        if (result === true) {
          successItemProcess++;
        } else {
          const rowstatus = item.passageId + '>><span>' + result + '</span>';
          this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
        }
        progress += percent;
        this.dataGatePass.progressLoad = progress;
        this.dataGatePass.successItem = successItemProcess;
        this.dataGatePass.errorMessage = errorMessageProcess;
      }
      isFinishProcess = true;
      this.dataGatePass.isFinish = isFinishProcess;
    },
    deleteGatePass(passageId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.GATES_PASSAGE_API + '/' + encodeURIComponent(passageId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            resolve(errorName);
          }); // catch
      });
    },
    formatTimestamp(t: string) {
      return t !== '' ? moment(t).unix() : '';
    },
    clickEditGate(id: any) {
      // 通行権限グループ情報編集画面を表示するためにGroupIdを取得する
    },
    changeGroupId(item: any) {
      // this.changeTab(item);
    },
    async loadPassableTime(groupId: any) {
      this.dataGroup.loading = true;
      await this.getGroup(groupId);
      if (groupId) {
        localStorage.setItem('groupId1', groupId);
      }
      this.dataGroup.loading = false;
      this.tabClicked = {
        tab0: false,
        tab1: false,
        tab2: false,
        tab3: false,
      };
    },
    loadGroupId() {
      this.getGroups(1);
    },
    searchUserGroupPassage(item: any) {
      this.dataGroup.groupPassages.scorp = item.data.targets;
      this.dataGroup.groupPassages.keyword = item.data.text;
      this.dataGroup.groupPassages.loading = true;
      this.dataGroup.groupPassages.page = 1;
      this.getUsersChange();
    },
    async closeModalAdd(ID: any) {
      await this.getDepartmentGroup(ID);
      this.loadListAff(1);
    },
    async closeModalAddAff(ID: any) {
      this.loadingAff = true;
      await this.getDepartmentGroupNoLoading(ID);
      this.loadListAff(1);
    },
    getGateGroup(groupId: string, page: any) {
        return new Promise((resolve, reject) => {
          this.dataGroup.realtionsGateGroup.loading = true;
          this.$http.get(Config.RELATION_GATE_GROUP_API + '?groupId=' + encodeURIComponent(groupId) + '&limit=1000&page=' + page + '&isExceptOptionalGate=true')
            .then(async (response: any) => {
              if (response.body.maxRecords > 0) {
                response.body.relationsGateGroup.forEach((element: any) => {
                  this.dataGroup.realtionsGateGroup.data.push(element.gateId);
                });
                if (response.body.maxRecords > response.body.endRecord) {
                  page++;
                  this.getGateGroup(groupId, page);
                } else {
                  this.dataGroup.realtionsGateGroup.loading = false;
                  resolve(true);
                }
              } else {
                this.dataGroup.realtionsGateGroup.loading = false;
                resolve(true);
              }
            })
            .then(null, (error) => {
              console.log(error);
              this.dataGroup.realtionsGateGroup.loading = false;
              resolve(false);
            });
        });
      },
      async loadGatePass(data: any) {
        this.dataGroup.loading = true;
        this.dataGroup.realtionsGateList.data = [];
        await this.getGatePass(data);
        this.dataGroup.loading = false;
      },
  },

});
