import { Group } from '@/types/alligate';
import Vue from 'vue';
import * as Config from '@/config';

/**
 * 全通行権限グループを取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<Group[]>} 取得した全通行権限グループ
 */
export async function getAllGroups(vue: Vue): Promise<Group[]> {
  try {
    const groups = await getAllGroupsIter(vue, 1);
    return groups;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('通行権限グループ情報の取得に失敗しました。通行権限グループ情報を取得する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 全通行権限グループを取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<Group[]>} 取得した全通行権限グループ
 */
async function getAllGroupsIter(vue: Vue, page: number): Promise<Group[]> {
  const query = `?limit=1000&page=${page}&sortKey=${Config.DESC}:${Config.REGISTERED}`;
  try {
    const response = await vue.$axios.get(Config.GROUP_LIST_API + query);
    // API使用上はgroupNameだが、実際はname。型に合わせるため、groupNameにnameの値を代入する。
    const groups = response.data.groups.map((group: any) => {
      group.groupName = group.name;
      return group;
    });
    if (response.data.endRecord === response.data.maxRecords) {
      return groups;
    } else {
      const nextGroups = await getAllGroupsIter(vue, page + 1);
      return groups.concat(nextGroups);
    }
  } catch (err) {
    throw err;
  }
}

/**
 * 指定されたクエリを元に通行権限グループ情報を取得する
 * @param {Vue} vue Vueインスタンス
 * @param {Object} query クエリ (クエリ名がキー、クエリ値がバリューのオブジェクト)
 * @returns {{ maxRecords: number, groups: Group[] }} 通行可能時間帯設定の配列と必要情報
 */
export async function getGroups(vue: Vue, query: { [name: string]: string }):
  Promise<{ maxRecords: number, groups: Group[] }> {
  let q = '';
  Object.keys(query).forEach((queryName, index) => {
    const op = index === 0 ? '?' : '&';
    q += `${op}${queryName}=${query[queryName]}`;
  });
  try {
    const response = await vue.$axios.get(Config.GROUP_LIST_API + q);
    // API使用上はgroupNameだが、実際はname。型に合わせるため、groupNameにnameの値を代入する。
    const groups = response.data.groups.map((group: any) => {
      group.groupName = group.name;
      return group;
    });
    return {
      maxRecords: response.data.maxRecords,
      groups,
    };
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw new Error('通行権限グループ情報を取得する権限がありません。');
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return {
          maxRecords: 0,
          groups: [],
        };
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 複数の通行権限グループを同じ内容で更新する
 * @param vue Vueインスタンス
 * @param groups 通行権限グループの配列
 * @param data 更新内容
 */
export async function putGroups(vue: Vue, groups: Group[], data: { [name: string]: any }) {
  let results: Array<{status: number}> = [];
  try {
    results = await Promise.all(
      groups.map((group) => putGroupsIter(vue, group.groupId, data)),
    );
  } catch (err) {
    // 原因不明のエラーの場合
    throw new Error('通行権限グループの更新に失敗しました。しばらく経ってからやり直してください');
  }
  // 以下、エラーがないか確認し、エラーになっている通行権限グループについてはエラーメッセージを表示する。
  let success = 0;
  let errorMessages403 = '以下の通行権限グループの更新に失敗しました。情報を設定する権限がありません。\n';
  let errorMessages404 = '以下の通行権限グループの更新に失敗しました。情報がクラウドに存在しませんでした。\n';
  let errorMessages = '以下の通行権限グループの更新に失敗しました。しばらく経ってからやり直してください。\n';
  let errors403: number = 0;
  let errors404: number = 0;
  let errors503: number = 0;
  let errors: number = 0;
  results.forEach((result, index) => {
    if (result.status === 200) {
      success++;
    } else {
      const group = groups[index];
      const groupName = group.groupName;
      switch (result.status) {
        case 403:
          errorMessages403 += `・${groupName}\n`;
          errors403++;
          break;
        case 404:
          errorMessages404 += `・${groupName}\n`;
          errors404++;
          break;
        case 503:
          errors503++;
          break;
        default:
          errorMessages += `・${groupName}\n`;
          errors++;
          break;
      }
    }
  });

  if (success === groups.length) {
    // 全て成功ならば、return
    return;
  } else if (errors503 > 0) {
    throw new Error(Config.ERROR_MAINTENANCE);
  } else {
    // その他のエラーがあった場合はそれぞれ表示する
    let message = '';
    if (errors403 > 0) {
      message += errorMessages403 + '\n';
    }
    if (errors404 > 0) {
      message += errorMessages404 + '\n';
    }
    if (errors > 0) {
      message += errorMessages + '\n';
    }
    throw new Error(message);
  }
}

/**
 * putGroupsの処理。1つ1つのputを行う。
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<{status: number}>} 取得した全通行権限グループ
 */
async function putGroupsIter(vue: Vue, groupId: string, data: { [name: string]: any }): Promise<{status: number}> {
  try {
    await vue.$axios.put(`${Config.GROUP_LIST_API}/${groupId}`, data);
    return { status: 200 };
  } catch (err) {
    return { status: (err as any).response.status };
  }
}
