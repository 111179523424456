











import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'isVaildOnly',
  components: {
    //
  },
  props: {
    //
    isVaildOnly: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '有効なカードのみ表示',
    },
  },
  computed: {
    isValid: {
      get(): boolean {
        return this.isVaildOnly;
      },
      set(val: boolean): void {
        this.$emit('show-only-isVaild', val);
        return;
      },
    },
  },
});
