








import {Vue } from 'vue-property-decorator';
import GroupListTemplate from '@/components/templates/GroupListTemplate/GroupDefaultTemplate.vue';
import * as Config from '@/config';
import store from '@/store';
import moment from 'moment';

export default Vue.extend({
  name: 'GroupListPage',
  components: {
    GroupListTemplate,
  },
  created() {
    //
    const groupId = this.$route.query.groupId;
    if (groupId !== null && groupId !== undefined && groupId !== '') {
      localStorage.setItem('prevRoute', Config.LOG);
      localStorage.setItem('groupId1', groupId.toString());
      this.$router.push({ path: Config.GROUP_DETAIL, query: {groupId: groupId.toString()}});
    }
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  mounted() {
    this.$emit('activeMenu', 'groups');
  },
  methods: {
    //
  },

});
