


























































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import { PassableTime } from '@/types/alligate';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import UserGateTable from '@/components/organisms/UserGateTable/UserGateTable.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import GroupOfGateTable from '@/components/organisms/GroupOfGateTable/GroupOfGateTable.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import ControlPanelAddPage from '@/components/pages/controlPanel/ControlPanelAddPage.vue';

import * as Config from '@/config';
import moment from 'moment';

import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';
export interface DataType {
  selectedItem: PassableTime[];
  selectedDisplayOption: string[];
  displayOptionItems: DisplayOptionItem[];
  selectedUserIds: string[];
  showOption: boolean;
  isVaildOnly: boolean;
  isShow: boolean;
  expanded: any;
  actionItems: ActionItem[];
}

export default Vue.extend({
  name: 'GateListTable',
  components: {
    ShowPerPage,
    SuccessButton,
    Pagination,
    SelectPerPage,
    DisplayOption,
    UserGateTable,
    TextLink,
    GroupOfGateTable,
    Searchbox,
    ActionButton,
    ControlPanelAddPage,
  },
  props: {
    inPage: {
      type: String,
      default: '',
    },
    gateTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    searchTargetItems: {
      default: () => [],
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    dataGates: {
      type: Array,
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageModal: {
      type: String,
      default: '',
    },
    successMessageModal: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    gateId: {
      default: '',
    },
    gateName: {
      default: '',
    },
    isReRenderGroup: {
      default: 0,
    },
    isRemoveGroupSelected: {
      default: 0,
    },
    isReRenderUser: {
      default: 0,
    },
    isRemoveUserSelected: {
      default: 0,
    },
    safieRelationsGateDevice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    if (typeof localStorage.displayOptionGate !== 'undefined') {
      this.selectedDisplayOption = JSON.parse(localStorage.displayOptionGate);
    }
  },
  watch: {
    selectedDisplayOption(value) {
      localStorage.setItem('displayOptionGate', JSON.stringify(value));
      if (value.length > 8) {
        for (const item of this.displayOptionItems) {
          if (value.find((x: any) => x === item.value) === undefined) {
            item.isDisabled = true;
          }
        }
      } else {
        for (const item of this.displayOptionItems) {
          if (item.value !== 'controllerName') {
            item.isDisabled = false;
          }
        }
      }
    },
  },
  data: (): DataType => ({
    isShow: false,
    actionItems: [
      { title: 'チェックしたゲート', emitName: 'click-enable' },
      { title: 'チェックしたゲート', emitName: 'click-disable' },
    ],
    expanded: [],
    selectedItem: [],
    selectedDisplayOption: [
      'controllerName',
      'gateName',
      'isValid',
    ],
    displayOptionItems: [
      { label: '制御盤', value: 'controllerName', isDisabled: true },
      { label: 'ゲートID', value: 'gateId'},
      { label: 'ゲート名', value: 'gateName'},
      { label: '製品ID', value: 'gateModelId'},
      { label: '製品名', value: 'gateModelName'},
      { label: '型番', value: 'gateModelCode'},
      { label: 'アイコンタイプ', value: 'iconType'},
      { label: 'メーカー番号', value: 'makerCode'},
      { label: '変更有無', value: 'customizable'},
      { label: 'ターゲット動作設定', value: 'target'},
      { label: 'ターゲット動作設定Time', value: 'controlTime'},
      { label: 'ターゲット動作設定TimeUnit', value: 'timeUnit'},
      { label: 'ゲート動作モード', value: 'isVisitor'},
      { label: 'NFCの有効', value: 'isNFCValid'},
      { label: 'テンキーの有効・無効', value: 'isTenKeyValid'},
      { label: 'ゲートパスワードの有効・無効', value: 'isGatePasswordValid'},
      { label: 'ゲートパスワードの桁数', value: 'gatePasswordLength'},
      { label: 'ゲートパスワードのseed値', value: 'gatePasswordSeed'},
      { label: '2段階認証の有効・無効', value: 'isTwoStepAuthValid'},
      { label: 'BLE出力設定', value: 'bleIntensity'},
      { label: 'ブザー鳴動設定', value: 'buzzerPattern'},
      { label: '位置情報取得', value: 'needLocation'},
      { label: '備考1', value: 'option1'},
      { label: '備考2', value: 'option2'},
      { label: 'ゲート個体番号1', value: 'gateSNMain'},
      { label: 'ゲート個体番号2', value: 'gateSNBLE'},
      { label: 'ファームウェアバージョン', value: 'gateFWMain'},
      { label: 'ファームウェアバージョン（BLE）', value: 'gateFWBLE'},
      { label: '登録日', value: 'registered'},
      { label: '更新日', value: 'updated'},
      { label: 'ゲートへ設定情報を適用した日時', value: 'reported'},
      { label: '個人コード照合データー：最新リビジョン', value: 'revisionDesired'},
      { label: '個人コード照合データー：ゲートへ設定済みのリビジョン', value: 'revisionReported'},
      { label: '電池電圧', value: 'voltage'},
      { label: '電池電圧更新日', value: 'scanDate'},
      { label: '回線番号', value: 'controllerGateNo'},
      // { label: 'ステータス', value: 'isValid'},
    ],
    selectedUserIds: [],
    showOption: false,
    isVaildOnly: localStorage.isVaildDataGate === 'true' ? true : false,
  }),
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (index[0] === 'controller.name') {
          if (!isDescending[0]) {
              return (a.controller.name < b.controller.name) ? -1 : 1;
          } else {
              return (b.controller.name < a.controller.name) ? -1 : 1;
          }
        } else if (index[0] === 'gate.gateId') {
            if (!isDescending[0]) {
              return (a.gate.gateId < b.gate.gateId) ? -1 : 1;
            } else {
              return (b.gate.gateId < a.gate.gateId) ? -1 : 1;
            }
        } else if (index[0] === 'name') {
            if (!isDescending[0]) {
                return (a.gate.name < b.gate.name) ? -1 : 1;
            } else {
                return (b.gate.name < a.gate.name) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gateModelId') {
            if (!isDescending[0]) {
                return (a.gate.gateModelId < b.gate.gateModelId) ? -1 : 1;
            } else {
                return (b.gate.gateModelId < a.gate.gateModelId) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gateModelName') {
            if (!isDescending[0]) {
                return (a.gate.gateModelName < b.gate.gateModelName) ? -1 : 1;
            } else {
                return (b.gate.gateModelName < a.gate.gateModelName) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gateModelCode') {
            if (!isDescending[0]) {
                return (a.gate.gateModelCode < b.gate.gateModelCode) ? -1 : 1;
            } else {
                return (b.gate.gateModelCode < a.gate.gateModelCode) ? -1 : 1;
            }
        } else if (index[0] === 'gate.iconType') {
            if (!isDescending[0]) {
                return (a.gate.iconType < b.gate.iconType) ? -1 : 1;
            } else {
                return (b.gate.iconType < a.gate.iconType) ? -1 : 1;
            }
        } else if (index[0] === 'gate.makerCode') {
            if (!isDescending[0]) {
                return (a.gate.makerCode < b.gate.makerCode) ? -1 : 1;
            } else {
                return (b.gate.makerCode < a.gate.makerCode) ? -1 : 1;
            }
        } else if (index[0] === 'gate.customizable') {
            if (!isDescending[0]) {
                return (a.gate.customizable < b.gate.customizable) ? -1 : 1;
            } else {
                return (b.gate.customizable < a.gate.customizable) ? -1 : 1;
            }
        } else if (index[0] === 'gate.target') {
            if (!isDescending[0]) {
                return (a.gate.target < b.gate.target) ? -1 : 1;
            } else {
                return (b.gate.target < a.gate.target) ? -1 : 1;
            }
        } else if (index[0] === 'gate.controlTime') {
            if (!isDescending[0]) {
                return (a.gate.controlTime < b.gate.controlTime) ? -1 : 1;
            } else {
                return (b.gate.controlTime < a.gate.controlTime) ? -1 : 1;
            }
        } else if (index[0] === 'gate.timeUnit') {
            if (!isDescending[0]) {
                return (a.gate.timeUnit < b.gate.timeUnit) ? -1 : 1;
            } else {
                return (b.gate.timeUnit < a.gate.timeUnit) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gatePasswordLength') {
            if (!isDescending[0]) {
                return (a.gate.gatePasswordLength < b.gate.gatePasswordLength) ? -1 : 1;
            } else {
                return (b.gate.gatePasswordLength < a.gate.gatePasswordLength) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gatePasswordSeed') {
            if (!isDescending[0]) {
                return (a.gate.gatePasswordSeed < b.gate.gatePasswordSeed) ? -1 : 1;
            } else {
                return (b.gate.gatePasswordSeed < a.gate.gatePasswordSeed) ? -1 : 1;
            }
        } else if (index[0] === 'gate.bleIntensity') {
            if (!isDescending[0]) {
                return (a.gate.bleIntensity < b.gate.bleIntensity) ? -1 : 1;
            } else {
                return (b.gate.bleIntensity < a.gate.bleIntensity) ? -1 : 1;
            }
        } else if (index[0] === 'gate.buzzerPattern') {
            if (!isDescending[0]) {
                return (a.gate.buzzerPattern < b.gate.buzzerPattern) ? -1 : 1;
            } else {
                return (b.gate.buzzerPattern < a.gate.buzzerPattern) ? -1 : 1;
            }
        } else if (index[0] === 'gate.option1') {
            if (!isDescending[0]) {
                return (a.gate.option1 < b.gate.option1) ? -1 : 1;
            } else {
                return (b.gate.option1 < a.gate.option1) ? -1 : 1;
            }
        } else if (index[0] === 'gate.option2') {
            if (!isDescending[0]) {
                return (a.gate.option2 < b.gate.option2) ? -1 : 1;
            } else {
                return (b.gate.option2 < a.gate.option2) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gateSNMain') {
            if (!isDescending[0]) {
                return (a.gate.gateSNMain < b.gate.gateSNMain) ? -1 : 1;
            } else {
                return (b.gate.gateSNMain < a.gate.gateSNMain) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gateSNBLE') {
            if (!isDescending[0]) {
                return (a.gate.gateSNBLE < b.gate.gateSNBLE) ? -1 : 1;
            } else {
                return (b.gate.gateSNBLE < a.gate.gateSNBLE) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gateFWMain') {
            if (!isDescending[0]) {
                return (a.gate.gateFWMain < b.gate.gateFWMain) ? -1 : 1;
            } else {
                return (b.gate.gateFWMain < a.gate.gateFWMain) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gateFWBLE') {
            if (!isDescending[0]) {
                return (a.gate.gateFWBLE < b.gate.gateFWBLE) ? -1 : 1;
            } else {
                return (b.gate.gateFWBLE < a.gate.gateFWBLE) ? -1 : 1;
            }
        } else if (index[0] === 'gate.reported') {
            if (!isDescending[0]) {
                return (a.gate.reported < b.gate.reported) ? -1 : 1;
            } else {
                return (b.gate.reported < a.gate.reported) ? -1 : 1;
            }
        } else if (index[0] === 'gate.revisionDesired') {
            if (!isDescending[0]) {
                return (a.gate.revisionDesired < b.gate.revisionDesired) ? -1 : 1;
            } else {
                return (b.gate.revisionDesired < a.gate.revisionDesired) ? -1 : 1;
            }
        } else if (index[0] === 'gate.revisionReported') {
            if (!isDescending[0]) {
                return (a.gate.revisionReported < b.gate.revisionReported) ? -1 : 1;
            } else {
                return (b.gate.revisionReported < a.gate.revisionReported) ? -1 : 1;
            }
        } else if (index[0] === 'gate.voltage') {
            if (!isDescending[0]) {
                return (a.gate.voltage < b.gate.voltage) ? -1 : 1;
            } else {
                return (b.gate.voltage < a.gate.voltage) ? -1 : 1;
            }
        } else if (index[0] === 'isVisitor') {
            if (!isDescending[0]) {
                return (a.gate.isVisitor < b.gate.isVisitor) ? -1 : 1;
            } else {
                return (b.gate.isVisitor < a.gate.isVisitor) ? -1 : 1;
            }
        } else if (index[0] === 'isNFCValid') {
            if (!isDescending[0]) {
                return (a.gate.isNFCValid < b.gate.isNFCValid) ? -1 : 1;
            } else {
                return (b.gate.isNFCValid < a.gate.isNFCValid) ? -1 : 1;
            }
        } else if (index[0] === 'isTenKeyValid') {
            if (!isDescending[0]) {
                return (a.gate.isTenKeyValid < b.gate.isTenKeyValid) ? -1 : 1;
            } else {
                return (b.gate.isTenKeyValid < a.gate.isTenKeyValid) ? -1 : 1;
            }
        } else if (index[0] === 'isGatePasswordValid') {
            if (!isDescending[0]) {
                return (a.gate.isGatePasswordValid < b.gate.isGatePasswordValid) ? -1 : 1;
            } else {
                return (b.gate.isGatePasswordValid < a.gate.isGatePasswordValid) ? -1 : 1;
            }
        } else if (index[0] === 'isTwoStepAuthValid') {
            if (!isDescending[0]) {
                return (a.gate.isTwoStepAuthValid < b.gate.isTwoStepAuthValid) ? -1 : 1;
            } else {
                return (b.gate.isTwoStepAuthValid < a.gate.isTwoStepAuthValid) ? -1 : 1;
            }
        } else if (index[0] === 'needLocation') {
            if (!isDescending[0]) {
                return (a.gate.needLocation < b.gate.needLocation) ? -1 : 1;
            } else {
                return (b.gate.needLocation < a.gate.needLocation) ? -1 : 1;
            }
        } else if (index[0] === 'scanDate') {
            if (!isDescending[0]) {
                return (a.gate.scanDate < b.gate.scanDate) ? -1 : 1;
            } else {
                return (b.gate.scanDate < a.gate.scanDate) ? -1 : 1;
            }
        } else {
          if (!isDescending[0]) {
              console.log(a[index]);
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    CheckColumn(data: any) {
      localStorage.setItem('displayOptionGate', JSON.stringify(data));
    },
    clickEditController(ID: any) {
      this.$router.push({ path: Config.CONTROL_PANEL_EDIT,
      query: {controllerId: ID, goBack: 'gates'}});
    },
    clickJoinUser(ID: any) {
      this.$router.push({ path: Config.GATE_ADD_USER, query: {gateId: ID}});
    },
    converDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD');
    },
    converDateTime(t: any) {
      if (t > 0) {
        return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm:ss');
      } else {
        return 'ー';
      }
    },
    goDetailControl(ID: any) {
      this.$router.push({ path: Config.CONTROL_PANEL_DETAIL, query: {controllerId: ID}});
    },
    goDetailGate(ID: any) {
      this.$router.push({ path: Config.GATE_DETAIL, query: {gateId: ID}});
    },
    safieDeviceLinkTo(deviceId: string): void {
      window.open(`${Config.SAFIE_STREAM_URL}/${deviceId}`, '_blank');
    },
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
            {
              text: 'ゲート名',
              align: 'start',
              sortable: true,
              width: '20%',
              value: 'name',
            },
          ];
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.expanded = [];
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.expanded = [];
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
});
