








import {Vue } from 'vue-property-decorator';
import CardImportTemplate from '@/components/templates/CardImportTemplate/CardImportTemplate.vue';
import * as Config from '@/config';
export interface DataType {
  loading: boolean;
  successMessageLogs: string;
  errorMessageLogs: string;
  showModalImport: boolean;
  progress: number;
}
export default Vue.extend({
  name: 'CardImportPage',
  components: {
    CardImportTemplate,
  },
  data: (): DataType => ({
    loading: true,
    successMessageLogs: '',
    errorMessageLogs: '',
    progress: 0,
    showModalImport: false,
  }),
  mounted() {
    //
  },
  methods: {
    resetData() {
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
    },
  },
});
