

































































































































































































































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import GateOfGroupTable from '@/components/organisms/GateOfGroupTable/GateOfGroupTable.vue';
import UserOfGroupTable from '@/components/organisms/UserOfGroupTable/UserOfGroupTable.vue';
import moment from 'moment';

interface Type {
  groupId: string;
  groupName: string;
  userId: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
  userCount: number;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  groupSelected: Type[];
  isVaildOnly: boolean;
  showOption: boolean;
  expanded: any;
}

export default Vue.extend({
  name: 'GroupListTable',
  components: {
    ShowPerPage,
    SuccessButton,
    Pagination,
    DisplayOption,
    SelectPerPage,
    TextLink,
    GateOfGroupTable,
    UserOfGroupTable,
  },
  props: {
    groupTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    dataGroups: {
      type: Array as PropType<Type[]>,
      default: () => [],
    },
    isRemoveGroupSelected: {
      default: 0,
    },
    groupExpanedGate: {
      default: [],
    },
    isReRenderGate: {
      default: 0,
    },
    groupExpanedUser: {
      default: [],
    },
    isReRenderUser: {
      default: 0,
    },
    isRemoveGateSelected: {
      default: 0,
    },
    isRemoveUserSelected: {
      default: 0,
    },
  },
  mounted() {
    if (typeof localStorage.displayOptionGroup === 'undefined') {
      localStorage.setItem('displayOptionGroup', JSON.stringify(this.selectedDisplayOption));
    } else {
      this.selectedDisplayOption = JSON.parse(localStorage.displayOptionGroup);
    }
  },
  watch: {
    groupSelected(val: Type[]) {
      this.$emit('select-groups', val.map((item) => item.groupId));
    },
    isRemoveGroupSelected(value) {
        this.groupSelected = [];
    },
    selectedDisplayOption(value) {
      localStorage.setItem('displayOptionGroup', JSON.stringify(value));
    },
  },
  data: (): DataType => ({
    expanded: [],
    groupSelected: [],
    selectedDisplayOption: [
      'groupId',
      'name',
    ],
    displayOptionItems: [
      { label: '通行権限グループID', value: 'groupId', isDisabled: true },
      { label: '通行権限グループ名', value: 'name', isDisabled: false },
      { label: '通行可能時間名', value: 'passableTimeSettingName', isDisabled: false },
      { label: '登録日', value: 'registered', isDisabled: false },
      { label: 'ステータス', value: 'isValid', isDisabled: false },
    ],
    isVaildOnly: localStorage.isVaildDataGroup === 'true' ? true : false,
    showOption: false,
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '通行権限グループID',
            align: 'start',
            sortable: true,
            value: 'groupId',
          },
        ];
        if (this.selectedDisplayOption.includes('name')) {
          headers.push({
            text: '通行権限グループ名',
            align: 'start',
            sortable: true,
            value: 'name',
          });
        }
        if (this.selectedDisplayOption.includes('passableTimeSettingName')) {
          headers.push({
          text: '通行可能時間名',
          align: 'start',
          sortable: true,
          value: 'passableTimeSetting.name',
        });
        }
        if (this.selectedDisplayOption.includes('registered')) {
          headers.push({
            text: '登録日',
            align: 'start',
            sortable: true,
            value: 'registered',
          });
        }
        if (this.selectedDisplayOption.includes('isValid')) {
          headers.push({
            text: 'ステータス',
            align: 'start',
            sortable: true,
            value: 'isValid',
          });
        }
        headers.push({
          text: 'ユーザー数/ゲート数',
          align: 'center',
          width: '200px',
          sortable: false,
          value: 'data-table-expand',
        });
        headers.push({
          text: '',
          align: 'end',
          sortable: false,
          width: '150px',
          value: 'icons',
        });
        return headers;
      },
    },
    headerTimes: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '曜日',
            align: 'start',
            sortable: false,
            value: 'dayTypeName',
          },
          {
            text: '時間',
            align: 'start',
            sortable: false,
            value: 'expand-time',
          },
        ];
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.expanded = [];
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.expanded = [];
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (index[0] === 'passableTimeSetting.name') {
          const aValue = (a.passableTimeSetting !== undefined ? a.passableTimeSetting.name : '');
          const bValue = (b.passableTimeSetting !== undefined ? b.passableTimeSetting.name : '');
          if (!isDescending[0]) {
              return (aValue < bValue) ? -1 : 1;
          } else {
              return (bValue < aValue) ? -1 : 1;
          }
        } else {
          if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    CheckColumn(data: any) {
      localStorage.setItem('displayOptionGroup', JSON.stringify(data));
    },
    convertDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm');
    },
  },
});
