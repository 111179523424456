import { render, staticRenderFns } from "./ErrorButton.vue?vue&type=template&id=72a83523&scoped=true&"
import script from "./ErrorButton.vue?vue&type=script&lang=ts&"
export * from "./ErrorButton.vue?vue&type=script&lang=ts&"
import style0 from "./ErrorButton.vue?vue&type=style&index=0&id=72a83523&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72a83523",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\003742\\Documents\\Alligate_join\\03_code\\Update\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('72a83523')) {
      api.createRecord('72a83523', component.options)
    } else {
      api.reload('72a83523', component.options)
    }
    module.hot.accept("./ErrorButton.vue?vue&type=template&id=72a83523&scoped=true&", function () {
      api.rerender('72a83523', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/ErrorButton/ErrorButton.vue"
export default component.exports