











































































































































import Vue, { PropType } from 'vue';
import GroupListModalTable from '@/components/organisms/GroupListModalTable/GroupListModalTable.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Searchbox, { SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import GroupAddPage from '@/components/pages/group/GroupAddPage.vue';
import ModalTitle from '@/components/molecules/ModalTitle/ModalTitle.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import ProgressModal from '@/components/molecules/ProgressModal/ProgressModal.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import ModalLoading from '@/components/molecules/ModalTitle/ModalLoading.vue';
import * as Config from '@/config';

export interface DataType {
  searchTargetItems: SearchTargetItem[];
  selectedGroupIds: string[];
  actionItems: ActionItem[];
  showConfirm: boolean;
  showProgress: boolean;
  typeAction: number;
  isAuthority: string;
  isLoad: boolean;
}
interface Type {
  groupId: string;
  groupName: string;
  userId: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
  userCount: number;
}
interface ArrayTime {
  passableTimeSettingId: string;
  name: string;
  dayTypeName: string;
  startTime: string;
  endTime: string;
}
export default Vue.extend({
  name: 'GroupListModalTemplate',
  components: {
    Searchbox,
    GroupListModalTable,
    Breadcrumbs,
    ActionButton,
    GroupAddPage,
    ModalTitle,
    Modal,
    ProgressModal,
    SuccessButton,
    ModalLoading,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    dataGroupsModal: {
      type: Object,
      default: ({}),
    },
    errorMessageProcess: {
      default: [],
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
      successItemProcess: {
      type: Number,
      default: 0,
    },
    isGroupEditModalShow: {
      type: Boolean,
      default: false,
    },
    authority: {
      type: Object,
      default: ({}),
    },
    groupExpanedGate: {
      default: [],
    },
    isReRenderGate: {
      default: 0,
    },
    groupExpanedUser: {
      default: [],
    },
    isReRenderUser: {
      default: 0,
    },
    isRemoveGateSelected: {
      default: 0,
    },
    isRemoveUserSelected: {
      default: 0,
    },
  },
  data: (): DataType => ({
    isAuthority: '',
    typeAction: 0,
    showConfirm: false,
    showProgress: false,
    searchTargetItems: [
      { label: '通行権限グループ名', value: 'name', isDisabled: true },
    ],
    selectedGroupIds: [],
    actionItems: [
      { title: '有効化する', emitName: 'click-enable' },
      { title: '無効化する', emitName: 'click-disable' },
    ],
    isLoad: false,
  }),
  watch: {
    isLoad(val: any) {
      this.$emit('isLoad', val);
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: '全ての通行権限グループ一覧', disabled: true },
        ];
      },
    },
    isModalShow: {
      get(): boolean {
          return this.isGroupEditModalShow;
      },
      set(val: boolean): void {
        return;
      },
    },
  },
  methods: {
    showDialogConfirm(type: any) {
      this.showConfirm = true;
      this.typeAction = type;
    },
    closeDialogConfirm() {
      this.showConfirm = false;
    },
    clickGroupsAction() {
      this.showProgress = true;
      this.showConfirm = false;
      this.$emit('click-groups-action', {data: this.selectedGroupIds, typeAction: this.typeAction});
    },
    closeModalProgress() {
      this.showProgress = false;
      this.showConfirm = false;
      this.$emit('closeModalProgress', this.typeAction);
    },
    deleteBridgeProperty(event: any) {

      this.$emit('ChangeProperty', event);
    },
    isSuccess(event: any) {
      this.isLoad = event;
    },
    clickEditGroup(id: any) {
      this.isLoad = false;
      localStorage.setItem('groupId', id);
      this.$emit('clickEditGroup');
    },
    textTitle() {
      let text = '';
      switch (this.typeAction) {
        case 0:
          text = 'グループを有効化'; break;
        case 1:
          text = 'グループを無効化'; break;
        case 2:
          text = '関連付けを解除して無効化'; break;
        case 3:
          text = '関連付け解除'; break;
        case 4:
          text = '関連付け解除'; break;
        default:
          break;
      }
      return text;
    },
    textAction() {
      let text = '';
      switch (this.typeAction) {
        case 0:
          text = 'チェックしたグループを有効化します。'; break;
        case 1:
          text = 'チェックしたグループを無効化します。'; break;
        case 2:
          text = 'グループの関連付けを解除して無効化しますか？'; break;
        case 3:
          text = 'このゲートとの関連付けを解除しますか？'; break;
        case 4:
          text = 'このユーザーとの関連付けを解除しますか？'; break;
        default:
          break;
      }
      return text;
    },
    successLabel() {
      let text = '';
      switch (this.typeAction) {
        case 0:
          text = '件を有効化しました。'; break;
        case 1:
          text = '件を無効化しました。'; break;
        case 2:
          text = '件を削除しました。'; break;
        case 3:
          text = '件を解除しました。'; break;
        case 4:
          text = '件を解除しました。'; break;
        default:
          break;
      }
      return text;
    },
    isDialogConfirm(data: any) {
      this.showDialogConfirm(data.type);
      this.$emit('show-dialog-confirm', data);
    },
  },
});
