
















































import Vue, { PropType } from 'vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import GroupListTable from '@/components/organisms/GroupListTable/GroupListTable.vue';
import GateListPage from '@/components/pages/gate/GateListPage.vue';
import UsersAllowedTable from '@/components/organisms/UsersAllowedTable/UsersAllowedTable.vue';
import AffiliationAllowedTable from '@/components/organisms/UsersAllowedTable/AffiliationAllowedTable.vue';
import TimePeriodAllowed from '@/components/organisms/TimePeriodAllowed/TimePeriodAllowed.vue';
import TemporaryAccessAuthority from '@/components/organisms/TemporaryAccessAuthority/TemporaryAccessAuthority.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import GroupListModalPage from '@/components/pages/group-modal/GroupListModalPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import GateListTable from '@/components/organisms/GateListTable/GateListTable.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import * as Config from '@/config';
import store from '@/store';
export interface DataType {
  searchTargetItems: SearchTargetItem[];
  selectedGroupIds: string[];
  actionItems: any;
  tab: any;
  isShow: boolean;
  isShowGate: boolean;
  groupItem: any;
  isLoading: any;
  isLoadData: boolean;
  authority: any;
}
interface Type {
  groupId: string;
  groupName: string;
  userId: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
  userCount: number;
}
interface ArrayTime {
  passableTimeSettingId: string;
  name: string;
  dayTypeName: string;
  startTime: string;
  endTime: string;
}
export default Vue.extend({
  name: 'GroupListTemplate',
  components: {
    Searchbox,
    SuccessButton,
    GroupListTable,
    Breadcrumbs,
    ActionButton,
    GateListPage,
    UsersAllowedTable,
    AffiliationAllowedTable,
    TimePeriodAllowed,
    TemporaryAccessAuthority,
    TextLink,
    GroupListModalPage,
    GateListTable,
    Modal,
  },
  props: {
    //
  },
  data: (): DataType => ({
    isShow: false,
    isShowGate: false,
    tab: 0,
    searchTargetItems: [
      { label: '通行権限グループ名', value: 'name', isDisabled: true },
    ],
    selectedGroupIds: [],
    actionItems: [
      { title: '通行権限グループ一覧', tabname: 'tab1' },
      { title: ' 全てのゲート一覧', tabname: 'tab2' },
    ],
    groupItem: '',
    isLoading: false,
    isLoadData: false,
    authority: store.state.auth,
  }),
  watch: {
    //
  },
  mounted() {
    if (this.authority.isAdmin) {
      this.actionItems = [
        { title: '通行権限グループ一覧', tabname: 'tab1' },
        { title: ' 全てのゲート一覧', tabname: 'tab2' },
      ];
    } else {
      this.actionItems = [
        { title: '通行権限グループ一覧', tabname: 'tab1' },
      ];
    }
    const dataQuery = this.$route.query;
    if (dataQuery.tabActive !== undefined && dataQuery.tabActive !== '' && dataQuery.tabActive !== null) {
      this.tab = 1;
    }
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ゲート/通行権限', disabled: true },
          { text: this.tab === 0 ? '通行権限グループ一覧' : '全てのゲート一覧', disabled: true },
        ];
      },
    },
  },
  methods: {
    //
    clickGroupDetail(groupId: any) {
      this.$router.push({ path: Config.GROUP_DETAIL, query: {groupId: groupId.toString()}});
    },
    changeGroupId(item: any) {
      this.groupItem = '';
      this.isShow = false;
      setTimeout(() => {
        this.groupItem = item;
        this.$emit('change-groupId', {tab: this.tab, groupId: this.groupItem});
      }, 100);
    },
    loadGroupId(item: any) {
      this.groupItem = '';
      setTimeout(() => {
        this.groupItem = item;
        this.$emit('load-groupId', {tab: this.tab, groupId: this.groupItem});
      }, 100);
    },
    goTimeSettings() {
      this.isShow = false;
      this.$router.push({ path: Config.SETTING});
    },
    isShowGroup() {
      localStorage.setItem('isVaildDataGroupModal', 'false');
      this.isShow = true;
      this.isLoadData =  false;
    },
    loadingFromChild(val: any) {
      this.isLoading = val;
    },
    closePageModal(val: any) {
      this.isShow = false;
      if (val === true) {
        this.loadGroupId(this.groupItem);
      }
    },
    getIsLoad(val: any) {
      this.isLoadData =  val;
    },
  },
});
