










import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ModalMessage',
  components: {
    //
  },
  props: {
    sucessMessage: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
});
