var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c(
        "v-container",
        { staticClass: "pb-0" },
        [
          _vm.successMessageLogs !== "" &&
          _vm.GateSubmittedFail.length === 0 &&
          _vm.UserSubmittedFail.length === 0 &&
          _vm.administratorsAddFail.length === 0 &&
          _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-success" },
                [_vm._v("\n      " + _vm._s(_vm.successMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.successMessageLogs !== "" &&
          _vm.GateSubmittedFail.length === 0 &&
          _vm.UserSubmittedFail.length === 0 &&
          _vm.administratorsAddFail.length > 0 &&
          _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [
                  _vm.isEdit
                    ? _c("span", [_vm._v("通行権限グループが編集されました。")])
                    : _c("span", [
                        _vm._v("通行権限グループが追加されました。")
                      ]),
                  _vm._v("通行権限グループ管理者紐づけに失敗しています。"),
                  _c("br"),
                  _vm._v(
                    "\n      通行権限グループ管理者: " +
                      _vm._s(_vm.administratorsAddFail.toString()) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.successMessageLogs !== "" &&
          _vm.GateSubmittedFail.length > 0 &&
          _vm.UserSubmittedFail.length > 0 &&
          _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [
                  _vm.isEdit
                    ? _c("span", [_vm._v("通行権限グループが編集されました。")])
                    : _c("span", [
                        _vm._v("通行権限グループが追加されました。")
                      ]),
                  _vm._v("ゲートとユーザーの紐づけに失敗しています。"),
                  _c("br"),
                  _vm._v(
                    "\n      通行権限グループ編集より、ゲートとユーザーの確認をしてください。"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n      別の通行可能時間帯が設定されている通行権限グループに紐づいている場合、追加することができません。"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n      登録失敗ゲート名: " +
                      _vm._s(_vm.GateSubmittedFail.toString()) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n      登録失敗ユーザー名: " +
                      _vm._s(_vm.UserSubmittedFail.toString()) +
                      "\n      "
                  ),
                  _vm.administratorsAddFail.length > 0
                    ? _c("span", [
                        _c("br"),
                        _vm._v(
                          " 登録失敗ユーザー名: " +
                            _vm._s(_vm.administratorsAddFail.toString())
                        )
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.successMessageLogs !== "" &&
          _vm.GateSubmittedFail.length > 0 &&
          _vm.UserSubmittedFail.length === 0 &&
          _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [
                  _vm.isEdit
                    ? _c("span", [_vm._v("通行権限グループが編集されました。")])
                    : _c("span", [
                        _vm._v("通行権限グループが追加されました。")
                      ]),
                  _vm._v("ゲートの紐づけに失敗しています。"),
                  _c("br"),
                  _vm._v(
                    "\n      通行権限グループ編集より、ゲートの確認をしてください。"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n      別の通行可能時間帯が設定されている通行権限グループに紐づいている場合、追加することができません。"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n      登録失敗ゲート名: " +
                      _vm._s(_vm.GateSubmittedFail.toString()) +
                      "\n      "
                  ),
                  _vm.administratorsAddFail.length > 0
                    ? _c("span", [
                        _c("br"),
                        _vm._v(
                          " 登録失敗ユーザー名: " +
                            _vm._s(_vm.administratorsAddFail.toString())
                        )
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.successMessageLogs !== "" &&
          _vm.GateSubmittedFail.length === 0 &&
          _vm.UserSubmittedFail.length > 0 &&
          _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [
                  _vm.isEdit
                    ? _c("span", [_vm._v("通行権限グループが編集されました。")])
                    : _c("span", [
                        _vm._v("通行権限グループが追加されました。")
                      ]),
                  _vm._v("ユーザーの紐づけに失敗しています。"),
                  _c("br"),
                  _vm._v(
                    "\n      通行権限グループ編集より、ユーザーの確認をしてください。"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n      別の通行可能時間帯が設定されている通行権限グループに紐づいている場合、追加することができません。"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n      登録失敗ユーザー名: " +
                      _vm._s(_vm.UserSubmittedFail.toString()) +
                      "\n      "
                  ),
                  _vm.administratorsAddFail.length > 0
                    ? _c("span", [
                        _c("br"),
                        _vm._v(
                          " 登録失敗ユーザー名: " +
                            _vm._s(_vm.administratorsAddFail.toString())
                        )
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [_vm._v("\n      " + _vm._s(_vm.errorMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("group-add-form", {
            attrs: {
              gateItems: _vm.gateItems,
              userItems: _vm.userItems,
              groupDetail: _vm.groupDetail,
              loading: _vm.loading,
              isEdit: _vm.isEdit,
              dataAllUses: _vm.dataAllUses,
              dataAllGates: _vm.dataAllGates,
              dataAllTimes: _vm.dataAllTimes,
              expand: _vm.expand,
              isGetGroup: _vm.isGetGroup,
              successMessageLogs: _vm.successMessageLogs,
              errorMessageLogs: _vm.errorMessageLogs,
              UserSubmittedFail: _vm.UserSubmittedFail,
              GateSubmittedFail: _vm.GateSubmittedFail,
              administratorsAddFail: _vm.administratorsAddFail,
              isDisabledGroupOptions: _vm.isDisabledGroupOptions,
              authority: _vm.authority
            },
            on: {
              clickSaveGroup: function($event) {
                return _vm.$emit("clickSaveGroup", $event)
              },
              clickUpdateGroup: function($event) {
                return _vm.$emit("clickUpdateGroup", $event)
              },
              "change-expanded": function($event) {
                return _vm.$emit("change-expanded", $event)
              },
              "go-time-settings": function($event) {
                return _vm.$emit("go-time-settings", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }