var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card", [
    _c(
      "div",
      { staticClass: "allig-gate-passage-table" },
      [
        _c(
          "v-container",
          [
            _vm.successMessageLogs !== "" && _vm.isGetDataList === false
              ? _c(
                  "v-col",
                  { staticClass: "mb-4 font-weight-bold mt-1 bg-success" },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.successMessageLogs) + "\n      "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.errorMessageLogs !== "" && _vm.isGetDataList === false
              ? _c(
                  "v-col",
                  { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.errorMessageLogs) + "\n      "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-row",
              { attrs: { justify: "end" } },
              [
                _c(
                  "v-col",
                  { staticClass: "btn-add" },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { icon: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-help-circle")])],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "通行可能時間設定は、ALLIGATE Lock Proのゲートのみ設定が可能です"
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-primary",
                        attrs: { outlined: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("clickAddPassable")
                          }
                        }
                      },
                      [_vm._v("\n        追加\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              {
                attrs: {
                  "align-content": "center",
                  "no-gutters": "",
                  justify: "end"
                }
              },
              [
                _c("show-per-page", {
                  staticClass: "ml-4 mb-2 mr-2",
                  attrs: {
                    "per-page": _vm.perPagePassable,
                    page: _vm.pagePassable,
                    "items-length": _vm.passableTotalCount
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("v-data-table", {
              staticClass: "border-table scroll-table",
              attrs: {
                "must-sort": "",
                headers: _vm.headers,
                loading: _vm.isGetDataList,
                items: _vm.filteredItems,
                "items-per-page": _vm.perPagePassable,
                "custom-sort": _vm.customSort,
                "no-data-text": "データーはありません",
                "loading-text": "データを読み込中です",
                "hide-default-footer": "",
                dense: "",
                "show-expand": "",
                "single-expand": "",
                "item-key": "passableTimeSettingId"
              },
              on: {
                "update:options": function($event) {
                  return _vm.$emit("change-sort-passable", $event)
                },
                "item-expanded": function($event) {
                  return _vm.$emit("change-expanded-time", {
                    event: $event,
                    expanded: "time"
                  })
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item.name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "click-name item-user-name",
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "clickDetailPassable",
                                  item.passableTimeSettingId
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n        " + _vm._s(item.name) + "\n        "
                            )
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.isDefault",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("span", [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.convertStatus(item.timeSettings)) +
                              "\n      "
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.data-table-expand",
                    fn: function(ref) {
                      var expand = ref.expand
                      var item = ref.item
                      var isExpanded = ref.isExpanded
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "", disabled: isExpanded },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "no-bg",
                                              attrs: {
                                                color: "white",
                                                elevation: "0",
                                                disabled: _vm.isGetDataList
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.selectedGroupIds = []
                                                  expand(!isExpanded)
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "item-count" },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-account-supervisor")
                                              ]),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(item.groupCount) +
                                                  "\n              "
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", { staticClass: "text-caption" }, [
                              _vm._v("通行権限グループ一覧を開く")
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "transition",
                          { attrs: { name: "expand-icon" } },
                          [
                            isExpanded
                              ? _c("v-icon", [_vm._v("mdi-chevron-down")])
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "item.icons",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "clickEditPassable",
                                                    item.passableTimeSettingId
                                                  )
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-square-edit-outline")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_vm._v(" "), _c("span", [_vm._v(" 時間帯編集 ")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "clickConfirmDeletePassable",
                                                    item.passableTimeSettingId
                                                  )
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-trash-can-outline")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_vm._v(" "), _c("span", [_vm._v(" 時間帯削除 ")])]
                        )
                      ]
                    }
                  },
                  {
                    key: "expanded-item",
                    fn: function(ref) {
                      var headers = ref.headers
                      var item = ref.item
                      return [
                        _c(
                          "td",
                          {
                            staticClass: "group-of-time",
                            attrs: { colspan: headers.length }
                          },
                          [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mt-2 mb-5 ml-5 mr-5",
                                    attrs: { justify: "space-between" }
                                  },
                                  [
                                    _vm.ExpandedGroup[
                                      item.passableTimeSettingId
                                    ] &&
                                    _vm.ExpandedGroup[
                                      item.passableTimeSettingId
                                    ].maxRecords !== undefined
                                      ? _c(
                                          "div",
                                          { staticClass: "allig-title mt-1" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  "通行権限グループ一覧 (全" +
                                                    _vm.ExpandedGroup[
                                                      item.passableTimeSettingId
                                                    ].maxRecords +
                                                    "件)"
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "allig-title mt-1" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  "通行権限グループ一覧 (全0件)"
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-row",
                                          { staticClass: "mt-0" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "btn-primary",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.clickJoinGroup(
                                                      item.passableTimeSettingId
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                関連付け追加\n                "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("group-of-time", {
                                  attrs: {
                                    loading: _vm.loadingGroup,
                                    passableTimeSettingId:
                                      item.passableTimeSettingId,
                                    groups: _vm.ExpandedGroup[
                                      item.passableTimeSettingId
                                    ]
                                      ? _vm.ExpandedGroup[
                                          item.passableTimeSettingId
                                        ].groups
                                      : [],
                                    pageGroup: _vm.pageGroup,
                                    groupTotalCount:
                                      _vm.ExpandedGroup[
                                        item.passableTimeSettingId
                                      ] &&
                                      _vm.ExpandedGroup[
                                        item.passableTimeSettingId
                                      ].maxRecords
                                        ? _vm.ExpandedGroup[
                                            item.passableTimeSettingId
                                          ].maxRecords
                                        : 0
                                  },
                                  on: {
                                    "select-groups": function(groupIds) {
                                      _vm.selectedGroupIds = groupIds
                                    },
                                    "change-page-group": function($event) {
                                      return _vm.$emit(
                                        "change-page-group",
                                        $event
                                      )
                                    },
                                    "confirm-action": function($event) {
                                      return _vm.$emit("confirm-action", $event)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.selectedItem,
                callback: function($$v) {
                  _vm.selectedItem = $$v
                },
                expression: "selectedItem"
              }
            }),
            _vm._v(" "),
            _c(
              "v-row",
              {
                staticClass: "mt-3",
                attrs: { "no-gutters": "", justify: "space-between" }
              },
              [_c("v-spacer")],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-dialog",
          {
            attrs: { width: "600", persistent: "" },
            model: {
              value: _vm.showConfirmDelete,
              callback: function($$v) {
                _vm.showConfirmDelete = $$v
              },
              expression: "showConfirmDelete"
            }
          },
          [
            _c(
              "v-card",
              [
                _c("ModalTitle", {
                  attrs: { title: "通行可能時間設定" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("clickCloseConfirmDeletePassable")
                    }
                  }
                }),
                _vm._v(" "),
                _c("v-divider"),
                _vm._v(" "),
                _c("v-row", {
                  staticStyle: { height: "5px" },
                  attrs: { "no-gutters": "" }
                }),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  { staticClass: "pa-4" },
                  [
                    _vm.successMessageLogsModal === "" &&
                    _vm.errorMessageLogsModal === ""
                      ? _c("p", [
                          _vm._v(
                            "この通行可能時間設定との関連付けを削除しますか？"
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _vm.successMessageLogsModal !== ""
                          ? _c(
                              "v-col",
                              {
                                staticClass:
                                  "mb-4 font-weight-bold mt-1 bg-success no-bg"
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.successMessageLogsModal) +
                                    "\n        "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.errorMessageLogsModal !== ""
                          ? _c(
                              "v-col",
                              {
                                staticClass:
                                  "mb-4 font-weight-bold mt-1 bg-error no-bg"
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.errorMessageLogsModal) +
                                    "\n        "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.successMessageLogsModal === "" &&
                _vm.errorMessageLogsModal === ""
                  ? _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-2", attrs: { justify: "end" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-secondary",
                                attrs: { outlined: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "clickCloseConfirmDeletePassable"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              キャンセル\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.successMessageLogsModal === "" &&
                            _vm.errorMessageLogsModal === ""
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "btn-secondary",
                                    attrs: { outlined: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("clickDeletePassable")
                                      }
                                    }
                                  },
                                  [_vm._v("OK")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-2", attrs: { justify: "end" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-secondary",
                                attrs: { outlined: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "clickCloseConfirmDeletePassable"
                                    )
                                  }
                                }
                              },
                              [_vm._v("\n              OK\n            ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }