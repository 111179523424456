

































import {Vue } from 'vue-property-decorator';
import GateDetailTemplate from '@/components/templates/GateDetailTemplate/GateDetailTemplate.vue';
import * as Config from '@/config';
const arrayDay = ['月曜', '火曜', '水曜', '木曜', '金曜', '土曜', '日曜'];
import store from '@/store';
export interface TypeDessert {
  name: string;
  isVaild: boolean;
}
export interface DataType {
  loadingChild: boolean;
  loading: boolean;
  successMessageLogs: string;
  errorMessageLogs: string;
  gateDetail: any;
  groupList: any;
  dataUnlockingTime: any;
  groupId: any;
  dayTypes: any;
  groupItemsOrigin: any;
  groupsAddFail: any;
  isFinishDetailGate: boolean;
  goBack: string;
  successMessageModal: string;
  errorMessageModal: string;
  showModal: boolean;
  showConfirm: boolean;
  isGateEditModalShow: boolean;
  isRemoteUnlockValid: boolean;
  authority: any;
}
export default Vue.extend({
  name: 'GateDetailPage',
  components: {
    GateDetailTemplate,
  },
  props: {
    isAdminOfRemoteUnlock: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    loadingChild: false,
    loading: false,
    showConfirm: false,
    successMessageLogs: '',
    errorMessageLogs: '',
    gateDetail: {
      gateId: '',
      gateName: '',
      option1: '',
      option2: '',
      groups: [],
    },
    groupList: [],
    groupsAddFail: [],
    dataUnlockingTime: [],
    groupItemsOrigin: [],
    dayTypes: [],
    isFinishDetailGate: false,
    groupId: '',
    goBack: 'gates',
    showModal: false,
    successMessageModal: '',
    errorMessageModal: '',
    isGateEditModalShow: false,
    isRemoteUnlockValid: false,
    authority: store.state.auth,
  }),
  mounted() {
    // 一旦べた書き
    const gateId = localStorage.getItem('gateId');
    if (gateId != null) {
      this.loadingChild = true;
      this.groupList = [];
      this.getGateData(gateId)
        .then(() => this.getGroupByGate(1, gateId))
        .then(() => this.getUnlockingTime(gateId))
        .then(() => {
          this.loadingChild = false;
        });
      }

    // 権限確認
    const authority = store.state.auth;
    // 0 組織管理権限 1 所属管理権限あり 2 通行権限グループ通行権限あり 3 権限なし
    if (authority.isAdminOfRemoteUnlock) {
      const remoteUnlock = localStorage.getItem('remoteUnlock');
      if (remoteUnlock === 'true') {
        // 表示の変数
        this.isRemoteUnlockValid = true;
      } else {
        this.isRemoteUnlockValid = false;
      }
    } else {
      this.isRemoteUnlockValid = true;
    }
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  methods: {
    remoteUnlock() {
      this.loading = true;
      this.successMessageModal  =  '';
      this.errorMessageModal = '';
      const dataQuery = this.$route.query;
      // const gateId =  dataQuery.gateId.toString();
      const gateId = this.gateDetail.gateId;
      this.$http.post(Config.GATES_LIST_API + '/' + encodeURIComponent(gateId) + '/remote-unlock')
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.successMessageModal = 'ゲートへリモート解錠要求を行いました。';
            this.loading = false;
          }
          this.loading = false;
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          switch (err.status) {
            case 400:
              this.errorMessageModal = 'リモート解錠要求に失敗しました。再度やり直してください。';
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageModal = Config.ERROR_403;
              } else {
                this.errorMessageModal = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageModal = 'リモート解錠要求に失敗しました。以下の原因の可能性があります。<br> ・ログインユーザーが組織管理権限を持っていない場合 <br> ・ログインユーザーがこのゲートに紐づく通行権限グループに登録されていない場合 <br>・ログインユーザーが個人コードを持っていない場合';
              break;
            case 500:
              this.errorMessageModal = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageModal = Config.ERROR_503;
              break;
            default:
              this.errorMessageModal = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
        }); // catch
    },
    clickConfirm(data: any) {
      this.successMessageModal  =  '';
      this.errorMessageModal  = '';
      this.showConfirm = true;
    },
    closeModalConfirm(data: any) {
      this.showConfirm = false;
    },
    clickEditGate(data: any) {
      localStorage.setItem('gateId', data.gateId);
      this.isGateEditModalShow = true;
    },
    ChangeProperty(value: any) {
      this.isGateEditModalShow = false;
      if (value) {
        // 編集画面から戻るボタンで画面遷移してきたとき画面表示時と同じ処理を入れてるけどもっといいやり方あれば関数化してもいいかも？
        const gateId = localStorage.getItem('gateId');
        if (gateId != null) {
          this.loadingChild = true;
          this.groupList = [];
          this.getGateData(gateId)
            .then(() => this.getGroupByGate(1, gateId))
            .then(() => this.getUnlockingTime(gateId))
            .then(() => {
            this.loadingChild = false;
          });
        }
      }
    },
    getUnlockingTime(gateId: string) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.UNLOCKING_TIME_SETTING + '?gateId=' + encodeURIComponent(gateId) + '&sortKey=asc:dayTypeId')
          .then((response: any) => {
            if (response.body.maxRecords > 0 && response.body.unlockingTimeSettings.length > 0) {
              // this.dataUnlockingTime = response.body.unlockingTimeSettings;
              const data = response.body.unlockingTimeSettings;
              data.forEach((item: any, i: any) => {
                const check = this.dataUnlockingTime.filter((o1: { dayTypeId: any; }) => o1.dayTypeId
                === item.dayTypeId);
                if (check.length === 0) {
                  const listTime = [];

                  if (item.startTime && item.startTime !== '88:88') {
                    listTime.push(
                      {
                        startTime: item.startTime,
                        endTime: item.endTime,
                      },
                    );
                  }
                  if (item.startTime2 && item.startTime2 !== '88:88') {
                    listTime.push(
                      {
                        startTime: item.startTime2,
                        endTime: item.endTime2,
                      },
                    );
                  }
                  if (item.startTime3 && item.startTime3 !== '88:88') {
                    listTime.push(
                      {
                        startTime: item.startTime3,
                        endTime: item.endTime3,
                      },
                    );
                  }
                  if (item.startTime4 && item.startTime4 !== '88:88') {
                    listTime.push(
                      {
                        startTime: item.startTime4,
                        endTime: item.endTime4,
                      },
                    );
                  }
                  if (item.startTime5 && item.startTime5 !== '88:88') {
                    listTime.push(
                      {
                        startTime: item.startTime5,
                        endTime: item.endTime5,
                      },
                    );
                  }

                  this.dataUnlockingTime.push(
                    {
                      gateId: item.gateId,
                      dayTypeId: item.dayTypeId.toString(),
                      dayTypeName: item.dayTypeName,
                      allDay: item.allDay,
                      startTime: item.startTime5,
                      endTime: item.endTime5,
                      listTime,
                    },
                  );
                }
              });
            } else {
              this.dataUnlockingTime = [];
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            this.dataUnlockingTime = [];
            resolve(false);
          }); // catch
      });
    },
    clickBackGate() {
      // this.$router.push({ path: '/' + this.goBack});
      this.$emit('deleteBridgeProperty');
    },
    getGateData(gateId: string) {
      return new Promise((resolve, reject) => {
        this.errorMessageLogs = '';
        this.$http.get(Config.GATES_LIST_API + '?gateId=' + encodeURIComponent(gateId))
          .then((response: any) => {
            if (response.body.maxRecords > 0 && response.body.gates.length > 0) {
              this.gateDetail = response.body.gates[0];
              this.gateDetail.groups = [];
              resolve(null);
            }
          })
          .then( null, (err: any) => {
          console.log('err: ', err);
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          resolve(null);
        }); // catch
      });
    },
    getGroupByGate(page: any, gateId: string) {
      return new Promise((resolve, reject) => {
        this.groupList = [];
        let check;
        check = this.getGroupByGateItem(1, gateId);
        resolve(check);
      });
    },
    async getGroupByGateItem(page: any, gateId: string) {
      let result;
      result = await this.$http.get(Config.RELATION_GATE_GROUP_API + '?gateId='
      + encodeURIComponent(gateId) + '&isExceptOptionalGate=true&embed=group&limit=1000&page=' + page)
        .then(async (response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.relationsGateGroup.forEach((element: any) => {
              this.groupList.push({text: element.group.name, value: element.groupId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getGroupByGate(page + 1, gateId);
            } else {
              return true;
            }
          } else {
            return false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          return false;
        }); // catch
    },
    validateBeforeSubmit(data: any) {
      this.errorMessageLogs = '';
    },
    resetData() {
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    clickGroupDetail($event: any) {
      this.$emit('clickGroupDetail', $event);
    },
  },
});
