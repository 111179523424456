



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import { PassableTime } from '@/types/alligate';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import UserGateTable from '@/components/organisms/UserGateTable/UserGateTable.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import GroupOfGateTable from '@/components/organisms/GroupOfGateTable/GroupOfGateTable.vue';
import ControlPanelDetail from '@/components/pages/controlPanel/ControlPanelDetailPage.vue';
import GateDetailPage from '@/components/pages/gate/GateDetailPage.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import ControlPanelAddPage from '@/components/pages/controlPanel/ControlPanelAddPage.vue';
import GateAllListTable from '@/components/organisms/UsersAllowedTable/GateListTable.vue';
import GateLockAddPage from '../../pages/gate/GateLockAddPage.vue';
import DatePicker from '@/components/molecules/DatePicker/DatePicker.vue';
import GateImport from '@/components/organisms/GateImport/GateImport.vue';
import GateLockDetailPage from '@/components/pages/gate/GateLockDetailPage.vue';
import ModalTitle from '@/components/molecules/ModalTitle/ModalTitle.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import * as Config from '@/config';
import moment from 'moment';

import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';
export interface DataType {
  selectedItem: any;
  selectedDisplayOptionModal: string[];
  displayOptionItems: DisplayOptionItem[];
  selectedUserIds: string[];
  showOption: boolean;
  isVaildOnly: boolean;
  isShow: boolean;
  expanded: any;
  dataGatesLoad: any;
  isShowControlPanelDetail: boolean;
  isShowDetailGate: boolean;
  actionItems: ActionItem[];
  showProgress: boolean;
  showGate: boolean;
  type: string;
  isGateLockModalShow: boolean;
  isAddControlShow: boolean;
  isGateLockAdd: boolean;
  isConfirm: boolean;
  isHandleCommand: boolean;
  isHandleCommandProcess: boolean;
  gateid: string;
  currentGateName: string;
  dateGate: any;
  isGatePassword: boolean;
  timeGate: number;
  listTime: any;
  isShowDetailGateLock: boolean;
  isConfirmAction: boolean;
  isValid: boolean;
  selectedIds: any;
  gateNameHandle: string;
  isControlEdit: boolean;
  isReload: boolean;
  isCloseControll: boolean;
}

export default Vue.extend({
  name: 'GateListTableModal',
  components: {
    ShowPerPage,
    SuccessButton,
    Pagination,
    SelectPerPage,
    DisplayOption,
    UserGateTable,
    TextLink,
    GroupOfGateTable,
    Searchbox,
    ControlPanelDetail,
    GateDetailPage,
    ActionButton,
    ControlPanelAddPage,
    GateAllListTable,
    GateLockAddPage,
    DatePicker,
    GateImport,
    GateLockDetailPage,
    ModalTitle,
    Modal,
  },
  props: {
    inPage: {
      type: String,
      default: '',
    },
    gateTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 100,
    },
    searchTargetItems: {
      default: () => [],
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    isAdminOfRemoteUnlock: {
      type: Boolean,
      default: false,
    },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    dataGates: {
      type: Array,
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageModal: {
      type: String,
      default: '',
    },
    successMessageModal: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },

    gateExpanedGroup: {
      type: Map,
      default: new Map(),
    },
    gateId: {
      default: '',
    },
    gateName: {
      default: '',
    },
    isReRenderGroup: {
      default: 0,
    },
    isRemoveGroupSelected: {
      default: 0,
    },

    gateExpanedUser: {
      type: Map,
      default: new Map(),
    },
    isReRenderUser: {
      default: 0,
    },
    isRemoveUserSelected: {
      default: 0,
    },
    safieRelationsGateDevice: {
      type: Object,
      default: () => ({}),
    },
    dataRelationGate: {
      type: Object,
      default: () => ({}),
    },
    expandData: {
      type: Object,
      default: () => ({}),
    },
    authority: {
      type: Object,
      default: ({}),
    },
    btnUnlock: {
      type: Array,
      default: () => [],
    },
    gatePassword: {
      type: String,
      default: '',
    },
    errorMessageLogsPhone: {
      type: String,
      default: '',
    },
    successMessageLogsPhone: {
      type: String,
      default: '',
    },
    lastLog: {
      type: Array,
      default: () => [],
    },
    isFinishHandleCommand: {
      type: Boolean,
      default: false,
    },
    // CSV
    showProgressExportCSV: {
      type: Boolean,
      default: false,
    },

    // Excel
    progressCsvXlsx: {
      type: Number,
      default: 0,
    },
    showModalImport: {
      type: Boolean,
      default: false,
    },
    showProgressImport: {
      type: Boolean,
      default: false,
    },
    successItemImport: {
      type: Number,
      default: 0,
    },
    totalDataRowImport: {
      type: Number,
      default: 0,
    },
    errorMessageImport: {
      default: [],
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    sheetUserName: {
      type: String,
      default: '',
    },
    isShowViewCSV: {
      type: Boolean,
      default: false,
    },
    errorMessageCsvXlsx: {
      type: String,
      default: '',
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.$route.path === '/groups') {
        if (typeof localStorage.displayOptionGateModal !== 'undefined') {
          this.selectedDisplayOptionModal = JSON.parse(localStorage.displayOptionGateModal);
      }
    } else {
      if (typeof localStorage.displayOptionGate !== 'undefined') {
        this.selectedDisplayOptionModal = JSON.parse(localStorage.displayOptionGate);
      }
    }
  },
  watch: {
    isShowDetailGateLock(val: any) {
      this.$emit('isShowDetailGateLock', val);
    },
    dataGates(data: any) {
      if (data.length === 0) {
        this.dataGatesLoad = [];
      }
    },
    isGetDataList(val: any) {
      if (val ===  false && this.dataGates.length > 0) {
        this.selectedItem = [];
        this.dataGatesLoad = this.dataGates;
        this.expanded = this.dataGates;
      }
    },
    selectedDisplayOptionModal(value) {
      if (this.$route.path === '/groups') {
        localStorage.setItem('displayOptionGateModal', JSON.stringify(value));
      } else {
        localStorage.setItem('displayOptionGate', JSON.stringify(value));
      }
      if (value.length > 8) {
        for (const item of this.displayOptionItems) {
          if (value.find((x: any) => x === item.value) === undefined) {
            item.isDisabled = true;
          }
        }
      } else {
        for (const item of this.displayOptionItems) {
          if (item.value !== 'controllerName' && item.value !== 'gateName') {
            item.isDisabled = false;
          }
        }
      }
    },
  },
  data: (): DataType => ({
    isControlEdit: false,
    isReload: false,
    type: 'del',
    showProgress: false,
    showGate: false,
    isShow: false,
    actionItems: [
      { title: '有効化する', emitName: 'click-enable' },
      { title: '無効化する', emitName: 'click-disable' },
    ],
    expanded: [],
    selectedItem: [],
    selectedDisplayOptionModal: [
      'controllerName',
      'gateName',
    ],
    displayOptionItems: [
      { label: '制御盤', value: 'controllerName', isDisabled: true },
      { label: 'ゲートID', value: 'gateId'},
      { label: 'ゲート名', value: 'gateName', isDisabled: true },
      { label: '製品ID', value: 'gateModelId'},
      { label: '製品名', value: 'gateModelName'},
      { label: '型番', value: 'gateModelCode'},
      { label: 'アイコンタイプ', value: 'iconType'},
      { label: 'メーカー番号', value: 'makerCode'},
      { label: '変更有無', value: 'customizable'},
      { label: 'ターゲット動作設定', value: 'target'},
      { label: 'ターゲット動作設定Time', value: 'controlTime'},
      { label: 'ターゲット動作設定TimeUnit', value: 'timeUnit'},
      { label: 'ゲート動作モード', value: 'isVisitor'},
      { label: 'NFCの有効', value: 'isNFCValid'},
      { label: 'テンキーの有効・無効', value: 'isTenKeyValid'},
      { label: 'ゲートパスワードの有効・無効', value: 'isGatePasswordValid'},
      { label: 'ゲートパスワードの桁数', value: 'gatePasswordLength'},
      { label: 'ゲートパスワードのseed値', value: 'gatePasswordSeed'},
      { label: '2段階認証の有効・無効', value: 'isTwoStepAuthValid'},
      { label: 'BLE出力設定', value: 'bleIntensity'},
      { label: 'ブザー鳴動設定', value: 'buzzerPattern'},
      { label: '位置情報取得', value: 'needLocation'},
      { label: '備考1', value: 'option1'},
      { label: '備考2', value: 'option2'},
      { label: 'ゲート個体番号1', value: 'gateSNMain'},
      { label: 'ゲート個体番号2', value: 'gateSNBLE'},
      { label: 'ファームウェアバージョン', value: 'gateFWMain'},
      { label: 'ファームウェアバージョン（BLE）', value: 'gateFWBLE'},
      { label: '登録日', value: 'registered'},
      { label: '更新日', value: 'updated'},
      { label: 'ゲートへ設定情報を適用した日時', value: 'reported'},
      { label: '個人コード照合データー：最新リビジョン', value: 'revisionDesired'},
      { label: '個人コード照合データー：ゲートへ設定済みのリビジョン', value: 'revisionReported'},
      { label: '電池電圧', value: 'voltage'},
      { label: '電池電圧更新日', value: 'scanDate'},
      { label: '回線番号', value: 'controllerGateNo'},
      { label: 'ステータス', value: 'isValid'},
    ],
    selectedUserIds: [],
    showOption: false,
    isVaildOnly: localStorage.isVaildDataGate === 'true' ? true : false,
    isShowControlPanelDetail: false,
    isShowDetailGate: false,
    isGateLockModalShow: false,
    isAddControlShow: false,
    isGateLockAdd: false,
    isConfirm: false,
    isHandleCommand: false,
    isHandleCommandProcess: false,
    gateid: '',
    currentGateName: '',
    isGatePassword: false,
    dataGatesLoad: [],
    timeGate: 1,
    dateGate: moment(new Date()).format('YYYY-MM-DD'),
    listTime: [
      { text: '00:00〜23:59', value: 1 },
      { text: '00:00〜11:59', value: 2 },
      { text: '12:00〜23:59', value: 3 },
      { text: '09:00〜16:59', value: 4 },
    ],
    isShowDetailGateLock: false,
    isConfirmAction: false,
    isValid: true,
    selectedIds: [],
    gateNameHandle: '',
    isCloseControll: false,
  }),
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      this.selectedItem = [];
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      console.log(index[0]);
      items.sort((a: any, b: any) => {
        if (index[0] === 'controller.name') {
          if (!isDescending[0]) {
              return (a.controllerName < b.controllerName) ? -1 : 1;
          } else {
              return (b.controllerName < a.controllerName) ? -1 : 1;
          }
        } else if (index[0] === 'gate.gateId') {
            if (!isDescending[0]) {
              return (a.gate.gateId < b.gate.gateId) ? -1 : 1;
            } else {
              return (b.gate.gateId < a.gate.gateId) ? -1 : 1;
            }
        } else if (index[0] === 'gate.name') {
            if (!isDescending[0]) {
                return (a.gate.name < b.gate.name) ? -1 : 1;
            } else {
                return (b.gate.name < a.gate.name) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gateModelId') {
            if (!isDescending[0]) {
                return (a.gate.gateModelId < b.gate.gateModelId) ? -1 : 1;
            } else {
                return (b.gate.gateModelId < a.gate.gateModelId) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gateModelName') {
            if (!isDescending[0]) {
                return (a.gate.gateModelName < b.gate.gateModelName) ? -1 : 1;
            } else {
                return (b.gate.gateModelName < a.gate.gateModelName) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gateModelCode') {
            if (!isDescending[0]) {
                return (a.gate.gateModelCode < b.gate.gateModelCode) ? -1 : 1;
            } else {
                return (b.gate.gateModelCode < a.gate.gateModelCode) ? -1 : 1;
            }
        } else if (index[0] === 'gate.iconType') {
            if (!isDescending[0]) {
                return (a.gate.iconType < b.gate.iconType) ? -1 : 1;
            } else {
                return (b.gate.iconType < a.gate.iconType) ? -1 : 1;
            }
        } else if (index[0] === 'gate.makerCode') {
            if (!isDescending[0]) {
                return (a.gate.makerCode < b.gate.makerCode) ? -1 : 1;
            } else {
                return (b.gate.makerCode < a.gate.makerCode) ? -1 : 1;
            }
        } else if (index[0] === 'gate.customizable') {
            if (!isDescending[0]) {
                return (a.gate.customizable < b.gate.customizable) ? -1 : 1;
            } else {
                return (b.gate.customizable < a.gate.customizable) ? -1 : 1;
            }
        } else if (index[0] === 'gate.target') {
            if (!isDescending[0]) {
                return (a.gate.target < b.gate.target) ? -1 : 1;
            } else {
                return (b.gate.target < a.gate.target) ? -1 : 1;
            }
        } else if (index[0] === 'gate.controlTime') {
            if (!isDescending[0]) {
                return (a.gate.controlTime < b.gate.controlTime) ? -1 : 1;
            } else {
                return (b.gate.controlTime < a.gate.controlTime) ? -1 : 1;
            }
        } else if (index[0] === 'gate.timeUnit') {
            if (!isDescending[0]) {
                return (a.gate.timeUnit < b.gate.timeUnit) ? -1 : 1;
            } else {
                return (b.gate.timeUnit < a.gate.timeUnit) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gatePasswordLength') {
            if (!isDescending[0]) {
                return (a.gate.gatePasswordLength < b.gate.gatePasswordLength) ? -1 : 1;
            } else {
                return (b.gate.gatePasswordLength < a.gate.gatePasswordLength) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gatePasswordSeed') {
            if (!isDescending[0]) {
                return (a.gate.gatePasswordSeed < b.gate.gatePasswordSeed) ? -1 : 1;
            } else {
                return (b.gate.gatePasswordSeed < a.gate.gatePasswordSeed) ? -1 : 1;
            }
        } else if (index[0] === 'gate.bleIntensity') {
            if (!isDescending[0]) {
                return (a.gate.bleIntensity < b.gate.bleIntensity) ? -1 : 1;
            } else {
                return (b.gate.bleIntensity < a.gate.bleIntensity) ? -1 : 1;
            }
        } else if (index[0] === 'gate.buzzerPattern') {
            if (!isDescending[0]) {
                return (a.gate.buzzerPattern < b.gate.buzzerPattern) ? -1 : 1;
            } else {
                return (b.gate.buzzerPattern < a.gate.buzzerPattern) ? -1 : 1;
            }
        } else if (index[0] === 'gate.option1') {
            if (!isDescending[0]) {
                return (a.gate.option1 < b.gate.option1) ? -1 : 1;
            } else {
                return (b.gate.option1 < a.gate.option1) ? -1 : 1;
            }
        } else if (index[0] === 'gate.option2') {
            if (!isDescending[0]) {
                return (a.gate.option2 < b.gate.option2) ? -1 : 1;
            } else {
                return (b.gate.option2 < a.gate.option2) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gateSNMain') {
            if (!isDescending[0]) {
                return (a.gate.gateSNMain < b.gate.gateSNMain) ? -1 : 1;
            } else {
                return (b.gate.gateSNMain < a.gate.gateSNMain) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gateSNBLE') {
            if (!isDescending[0]) {
                return (a.gate.gateSNBLE < b.gate.gateSNBLE) ? -1 : 1;
            } else {
                return (b.gate.gateSNBLE < a.gate.gateSNBLE) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gateFWMain') {
            if (!isDescending[0]) {
                return (a.gate.gateFWMain < b.gate.gateFWMain) ? -1 : 1;
            } else {
                return (b.gate.gateFWMain < a.gate.gateFWMain) ? -1 : 1;
            }
        } else if (index[0] === 'gate.gateFWBLE') {
            if (!isDescending[0]) {
                return (a.gate.gateFWBLE < b.gate.gateFWBLE) ? -1 : 1;
            } else {
                return (b.gate.gateFWBLE < a.gate.gateFWBLE) ? -1 : 1;
            }
        } else if (index[0] === 'gate.reported') {
            if (!isDescending[0]) {
                return (a.gate.reported < b.gate.reported) ? -1 : 1;
            } else {
                return (b.gate.reported < a.gate.reported) ? -1 : 1;
            }
        } else if (index[0] === 'gate.revisionDesired') {
            if (!isDescending[0]) {
                return (a.gate.revisionDesired < b.gate.revisionDesired) ? -1 : 1;
            } else {
                return (b.gate.revisionDesired < a.gate.revisionDesired) ? -1 : 1;
            }
        } else if (index[0] === 'gate.revisionReported') {
            if (!isDescending[0]) {
                return (a.gate.revisionReported < b.gate.revisionReported) ? -1 : 1;
            } else {
                return (b.gate.revisionReported < a.gate.revisionReported) ? -1 : 1;
            }
        } else if (index[0] === 'gate.voltage') {
            if (!isDescending[0]) {
                return (a.gate.voltage < b.gate.voltage) ? -1 : 1;
            } else {
                return (b.gate.voltage < a.gate.voltage) ? -1 : 1;
            }
        } else if (index[0] === 'gate.isVisitor') {
            if (!isDescending[0]) {
                return (a.gate.isVisitor < b.gate.isVisitor) ? -1 : 1;
            } else {
                return (b.gate.isVisitor < a.gate.isVisitor) ? -1 : 1;
            }
        } else if (index[0] === 'gate.isNFCValid') {
            if (!isDescending[0]) {
                return (a.gate.isNFCValid < b.gate.isNFCValid) ? -1 : 1;
            } else {
                return (b.gate.isNFCValid < a.gate.isNFCValid) ? -1 : 1;
            }
        } else if (index[0] === 'gate.isTenKeyValid') {
            if (!isDescending[0]) {
                return (a.gate.isTenKeyValid < b.gate.isTenKeyValid) ? -1 : 1;
            } else {
                return (b.gate.isTenKeyValid < a.gate.isTenKeyValid) ? -1 : 1;
            }
        } else if (index[0] === 'gate.isGatePasswordValid') {
            if (!isDescending[0]) {
                return (a.gate.isGatePasswordValid < b.gate.isGatePasswordValid) ? -1 : 1;
            } else {
                return (b.gate.isGatePasswordValid < a.gate.isGatePasswordValid) ? -1 : 1;
            }
        } else if (index[0] === 'gate.isTwoStepAuthValid') {
            if (!isDescending[0]) {
                return (a.gate.isTwoStepAuthValid < b.gate.isTwoStepAuthValid) ? -1 : 1;
            } else {
                return (b.gate.isTwoStepAuthValid < a.gate.isTwoStepAuthValid) ? -1 : 1;
            }
        } else if (index[0] === 'gate.needLocation') {
            if (!isDescending[0]) {
                return (a.gate.needLocation < b.gate.needLocation) ? -1 : 1;
            } else {
                return (b.gate.needLocation < a.gate.needLocation) ? -1 : 1;
            }
        } else if (index[0] === 'gate.scanDate') {
            if (!isDescending[0]) {
                return (a.gate.scanDate < b.gate.scanDate) ? -1 : 1;
            } else {
                return (b.gate.scanDate < a.gate.scanDate) ? -1 : 1;
            }
        } else if (index[0] === 'gate.registered') {
            if (!isDescending[0]) {
                return (a.gate.registered < b.gate.registered) ? -1 : 1;
            } else {
                return (b.gate.registered < a.gate.registered) ? -1 : 1;
            }
        } else if (index[0] === 'gate.updated') {
            if (!isDescending[0]) {
                return (a.gate.updated < b.gate.updated) ? -1 : 1;
            } else {
                return (b.gate.updated < a.gate.updated) ? -1 : 1;
            }
        } else if (index[0] === 'gate.isValid') {
            if (!isDescending[0]) {
                return (a.gate.isValid < b.gate.isValid) ? -1 : 1;
            } else {
                return (b.gate.isValid < a.gate.isValid) ? -1 : 1;
            }
        } else {
          if (!isDescending[0]) {
              console.log(a[index]);
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    CheckColumn(data: any) {
      if (this.$route.path === '/groups') {
        localStorage.setItem('displayOptionGateModal', JSON.stringify(data));
      } else {
        localStorage.setItem('displayOptionGate', JSON.stringify(data));
      }
    },
    clickEditController(ID: any) {
      this.$router.push({ path: Config.CONTROL_PANEL_EDIT,
      query: {controllerId: ID, goBack: 'gates'}});
    },
    clickJoinUser(ID: any) {
      this.$router.push({ path: Config.GATE_ADD_USER, query: {gateId: ID}});
    },
    converDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD');
    },
    converDateTime(t: any) {
      if (t > 0) {
        return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm:ss');
      } else {
        return '-';
      }
    },
    goDetailControl(ID: any) {
      window.localStorage.setItem('controllerId', ID);
      this.isShowControlPanelDetail = true;
    },
    goDetailGate(ID: any, gateNameHandle: any) {
      this.gateNameHandle = '';
      this.gateNameHandle = gateNameHandle;
      window.localStorage.setItem('gateId', ID);
      this.isShowDetailGate = true;
      this.isReload = false;
    },
    goDetailGateLock(ID: any, gateNameHandle: any) {
      this.gateid = ID;
      this.gateNameHandle = '';
      this.gateNameHandle = gateNameHandle;
      this.isShowDetailGateLock = true;
      this.isReload = false;
    },
    goControlEdit(ID: any) {
      this.isReload = false;
      window.localStorage.setItem('controllerId', ID);
      window.localStorage.setItem('isEditFlag', 'true');
      this.isAddControlShow = true;
      this.isControlEdit = true;
      this.isCloseControll = false;
    },
    goControlAdd(ID: any) {
      this.isReload = false;
      window.localStorage.setItem('isEditFlag', 'false');
      this.isAddControlShow = true;
      this.isControlEdit = false;
      this.isCloseControll = false;
    },
    safieDeviceLinkTo(deviceId: string): void {
      window.open(`${Config.SAFIE_STREAM_URL}/${deviceId}`, '_blank');
    },
    closeModal() {
      this.isShowControlPanelDetail = false;
      this.isShowDetailGate = false;
      this.isAddControlShow = false;
      this.isGateLockModalShow = false;
      this.isGateLockAdd = false;
    },
    deleteBridgeProperty() {
      this.closeModal();
      this.$emit('close-modal-control', this.isReload);
    },
    deleteGateLockAddPage(data: any) {
      this.closeModal();
      if (this.isShowDetailGateLock === false) {
        this.$emit('close-modal-control', data);
      }
    },
    clickGroupDetail($event: any) {
      this.closeModal();
      this.$emit('clickGroupDetail', $event);
    },
    closeModalGate() {
      this.showProgress = false;
      // this.$emit('close-modal-control');
      this.$emit('load-gates', 1);
    },
    removeGate(data: any) {
      this.type = 'del';
      this.showProgress = true;
      this.isConfirm = false;
      this.$emit('remove-gate', data);
    },
    confirmAction() {
      const data = this.type === 'disable' ? false : true;
      this.showProgress = true;
      this.isConfirmAction = false;
      this.$emit('confirm-action', {isValid : data, selectedItem: this.selectedItem});
    },
    addGate(data: any) {
      this.type = 'add';
      this.showProgress = true;
      this.$emit('add-gate', data);
    },
    loadGate() {
      this.showGate = true;
      this.selectedIds = [];
      this.$emit('load-all-gate', 1);
    },
    openHandleCommand(val: any, text: any) {
      this.isHandleCommand = true;
      this.gateid = val;
      this.currentGateName = text;
      this.$emit('openHandleCommand', this.gateid);
    },
    closeHandleCommand() {
      this.isHandleCommand = false;
      // this.gateid = '';
      // this.currentGateName = '';
    },
    handleCommand() {
      this.$emit('handleCommand', this.gateid);
    },
    getGatePassword(val: any) {
      this.dateGate = moment(new Date()).format('YYYY-MM-DD'),
      this.isGatePassword = true;
      this.gateid = val;
    },
    getTime(val: any) {
      console.log(val);
      this.dateGate = val;
    },
    closePasswordGenerate() {
      this.isGatePassword = false;
      this.gateid = '';
      this.$emit('closePasswordGenerate');
    },
    converDateFull(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD H:mm:ss');
    },
    converStatus(s: any) {
      let response = '';
      if (s === 0 || s === 99) {
        response = '実行中';
      } else if (s === 1) {
        response = '解錠成功';
      } else if (s === 2) {
        response = '解錠失敗';
      }
      return response;
    },
    getLastLog() {
      this.$emit('getLog', this.gateid);
    },
    convertStatus(val: any) {
      if (val === true || val === 1) {
        return '有効';
      } else {
        return '無効';
      }
    },
    clickEditGateLock(gateId: any) {
      this.isControlEdit = true;
      this.isGateLockModalShow = true;
      this.isReload = false;
      this.$emit('clickEditGateLock', gateId);
    },
    converTextBLE(value: any) {
      let textBLE = '';
      switch (value) {
        case 0:
          textBLE = '非常に強い';
          break;
        case 1:
          textBLE = 'とても強い';
          break;
        case 2:
          textBLE = '強い';
          break;
        case 3:
          textBLE = 'やや強い';
          break;
        case 4:
          textBLE = '普通';
          break;
        case 5:
          textBLE = 'やや弱い';
          break;
        case 6:
          textBLE = '弱い';
          break;
        case 7:
          textBLE = 'とても弱い';
          break;
        default:
          textBLE = '普通';
          break;
      }
      return textBLE;
    },
    closeModalControl(data: any) {
      if (this.isShowDetailGateLock) {
        const gateid = this.gateid;
        this.gateid = '';
        setTimeout(() => {
        this.gateid = gateid;
      }, 100);
      } else {
        console.log('isShowDetailGateLock', this.isShowDetailGateLock);
        this.isAddControlShow = false;
        if (this.isCloseControll) {
          this.$emit('close-modal-page-from-add');
          this.$emit('loading-from-child', false);
        } else {
          this.$emit('close-modal-control', data);
        }
      }
    },
    controllerSuccess(val: any) {
      this.isReload = val;
    },
    updateGate(data: any) {
      console.log('data: ', data);
      this.gateNameHandle = data.gateName;
    },
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
            {
              text: '制御盤名',
              align: 'start',
              sortable: true,
              value: 'controller.name',
              class: 'controllerName',
            },
          ];
        if (this.selectedDisplayOptionModal.includes('gateId')) {
          headers.push({
            text: 'ゲートID',
            align: 'start',
            sortable: false,
            value: 'gate.gateId',
          });
        }
        if (this.selectedDisplayOptionModal.includes('gateName')) {
          headers.push({
            text: 'ゲート名',
            align: 'start',
            sortable: true,
            value: 'gate.name',
          });
        }
        if (this.selectedDisplayOptionModal.includes('gateModelId')) {
          headers.push({
            text: '製品ID',
            align: 'start',
            sortable: false,
            value: 'gate.gateModelId',
          });
        }
        if (this.selectedDisplayOptionModal.includes('gateModelName')) {
          headers.push({
            text: '製品名',
            align: 'start',
            sortable: false,
            value: 'gate.gateModelName',
          });
        }
        if (this.selectedDisplayOptionModal.includes('gateModelCode')) {
          headers.push({
            text: '型番',
            align: 'start',
            sortable: false,
            value: 'gate.gateModelCode',
          });
        }
        if (this.selectedDisplayOptionModal.includes('iconType')) {
          headers.push({
            text: 'アイコンタイプ',
            align: 'start',
            sortable: false,
            value: 'gate.iconType',
          });
        }
        if (this.selectedDisplayOptionModal.includes('makerCode')) {
          headers.push({
            text: 'メーカー番号',
            align: 'start',
            sortable: false,
            value: 'gate.makerCode',
          });
        }
        if (this.selectedDisplayOptionModal.includes('customizable')) {
          headers.push({
            text: '変更有無',
            align: 'start',
            sortable: false,
            value: 'gate.customizable',
          });
        }
        if (this.selectedDisplayOptionModal.includes('target')) {
          headers.push({
            text: 'ターゲット動作設定',
            align: 'start',
            sortable: false,
            value: 'gate.target',
          });
        }
        if (this.selectedDisplayOptionModal.includes('controlTime')) {
          headers.push({
            text: 'ターゲット動作設定Time',
            align: 'start',
            sortable: false,
            value: 'gate.controlTime',
          });
        }
        if (this.selectedDisplayOptionModal.includes('timeUnit')) {
          headers.push({
            text: 'ターゲット動作設定TimeUnit',
            align: 'start',
            sortable: false,
            value: 'gate.timeUnit',
          });
        }
        if (this.selectedDisplayOptionModal.includes('isVisitor')) {
          headers.push({
            text: 'ゲート動作モード',
            align: 'start',
            sortable: false,
            value: 'gate.isVisitor',
          });
        }
        if (this.selectedDisplayOptionModal.includes('isNFCValid')) {
          headers.push({
            text: 'NFCの有効',
            align: 'start',
            sortable: false,
            value: 'gate.isNFCValid',
          });
        }
        if (this.selectedDisplayOptionModal.includes('isTenKeyValid')) {
          headers.push({
            text: 'テンキーの有効・無効',
            align: 'start',
            sortable: false,
            value: 'gate.isTenKeyValid',
          });
        }
        if (this.selectedDisplayOptionModal.includes('isGatePasswordValid')) {
          headers.push({
            text: 'ゲートパスワードの有効・無効',
            align: 'start',
            sortable: false,
            value: 'gate.isGatePasswordValid',
          });
        }
        if (this.selectedDisplayOptionModal.includes('gatePasswordLength')) {
          headers.push({
            text: 'ゲートパスワードの桁数',
            align: 'start',
            sortable: false,
            value: 'gate.gatePasswordLength',
          });
        }
        if (this.selectedDisplayOptionModal.includes('gatePasswordSeed')) {
          headers.push({
            text: 'ゲートパスワードのseed値',
            align: 'start',
            sortable: false,
            value: 'gate.gatePasswordSeed',
          });
        }
        if (this.selectedDisplayOptionModal.includes('isTwoStepAuthValid')) {
          headers.push({
            text: '2段階認証の有効・無効',
            align: 'start',
            sortable: false,
            value: 'gate.isTwoStepAuthValid',
          });
        }
        if (this.selectedDisplayOptionModal.includes('bleIntensity')) {
          headers.push({
            text: 'BLE出力設定',
            align: 'start',
            sortable: false,
            value: 'gate.bleIntensity',
          });
        }
        if (this.selectedDisplayOptionModal.includes('buzzerPattern')) {
          headers.push({
            text: 'ブザー鳴動設定',
            align: 'start',
            sortable: false,
            value: 'gate.buzzerPattern',
          });
        }
        if (this.selectedDisplayOptionModal.includes('needLocation')) {
          headers.push({
            text: '位置情報取得',
            align: 'start',
            sortable: false,
            value: 'gate.needLocation',
          });
        }
        if (this.selectedDisplayOptionModal.includes('option1')) {
          headers.push({
            text: '備考1',
            align: 'start',
            sortable: false,
            value: 'gate.option1',
          });
        }
        if (this.selectedDisplayOptionModal.includes('option2')) {
          headers.push({
            text: '備考2',
            align: 'start',
            sortable: false,
            value: 'gate.option2',
          });
        }
        if (this.selectedDisplayOptionModal.includes('gateSNMain')) {
          headers.push({
            text: 'ゲート個体番号1',
            align: 'start',
            sortable: false,
            value: 'gate.gateSNMain',
          });
        }
        if (this.selectedDisplayOptionModal.includes('gateSNBLE')) {
          headers.push({
            text: 'ゲート個体番号2',
            align: 'start',
            sortable: false,
            value: 'gate.gateSNBLE',
          });
        }
        if (this.selectedDisplayOptionModal.includes('gateFWMain')) {
          headers.push({
            text: 'ファームウェアバージョン',
            align: 'start',
            sortable: false,
            value: 'gate.gateFWMain',
          });
        }
        if (this.selectedDisplayOptionModal.includes('gateFWBLE')) {
          headers.push({
            text: 'ファームウェアバージョン（BLE）',
            align: 'start',
            sortable: false,
            value: 'gate.gateFWBLE',
          });
        }
        if (this.selectedDisplayOptionModal.includes('registered')) {
          headers.push({
            text: '登録日',
            align: 'start',
            sortable: false,
            value: 'gate.registered',
          });
        }
        if (this.selectedDisplayOptionModal.includes('updated')) {
          headers.push({
            text: '更新日',
            align: 'start',
            sortable: false,
            value: 'gate.updated',
          });
        }
        if (this.selectedDisplayOptionModal.includes('reported')) {
          headers.push({
            text: 'ゲートへ設定情報を適用した日時',
            align: 'start',
            sortable: false,
            value: 'gate.reported',
          });
        }
        if (this.selectedDisplayOptionModal.includes('revisionDesired')) {
          headers.push({
            text: '個人コード照合データー：最新リビジョン',
            align: 'start',
            sortable: false,
            value: 'gate.revisionDesired',
          });
        }
        if (this.selectedDisplayOptionModal.includes('revisionReported')) {
          headers.push({
            text: '個人コード照合データー：ゲートへ設定済みのリビジョン',
            align: 'start',
            sortable: false,
            value: 'gate.revisionReported',
          });
        }
        if (this.selectedDisplayOptionModal.includes('voltage')) {
          headers.push({
            text: '電池電圧',
            align: 'start',
            sortable: false,
            value: 'gate.voltage',
          });
        }
        if (this.selectedDisplayOptionModal.includes('scanDate')) {
          headers.push({
            text: '電池電圧更新日',
            align: 'start',
            sortable: false,
            value: 'gate.scanDate',
          });
        }
        if (this.selectedDisplayOptionModal.includes('controllerGateNo')) {
          headers.push({
            text: '回線番号',
            align: 'start',
            sortable: false,
            value: 'gate.controllerGateNo',
          });
        }
        if (this.selectedDisplayOptionModal.includes('status')) {
          headers.push({
            text: 'ステータス',
            align: 'start',
            sortable: false,
            value: 'status',
          });
        }
        if (this.selectedDisplayOptionModal.includes('isValid')) {
          headers.push({
            text: 'ステータス',
            align: 'start',
            sortable: false,
            value: 'gate.isValid',
          });
        }
        headers.push({
          text: '',
          align: 'end',
          sortable: false,
          value: 'action-expand',
          width: '200px',
        });
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.expanded = [];
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.expanded = [];
        this.$emit('change-per-page', val);
        return;
      },
    },
    showValid: {
      get(): boolean {
        return this.isValid;
      },
      set(val: boolean): void {
        this.$emit('get-gate-isValid', val);
        return;
      },
    },
    isShowModal: {
      get(): boolean {
        return this.showModal;
      },
      set(val: boolean): void {
        if (val === false) {
          this.$emit('closeModalUnClock');
        }
        return;
      },
    },
  },
});
