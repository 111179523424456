

















































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import Searchbox from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import { DataTableHeader } from 'vuetify';
import { CardDetail } from '@/types/alligate';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import moment from 'moment';
import ModalLoading from '@/components/molecules/ModalTitle/ModalLoading.vue';
export interface TypeItem {
  title: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  idSelected: any;
  showOption: boolean;
  isVaildOnly: boolean;
  isShow: boolean;
  isShowUserDetail: boolean;
  getData: any;
  // checkedColumnNames: any;
}

export default Vue.extend({
  name: 'UsersListTable',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
    TextLink,
    ModalLoading,
  },
  watch: {
    idSelected(val: CardDetail[]) {
      this.$emit('select-pcodes', val.map((item) => item.pCodeId));
    },
    isFinishProcessEnable(value) {
      if (value) {
        this.idSelected = [];
      }
    },
    selectedDisplayOption(value) {
      localStorage.setItem('displayOptionPCode', JSON.stringify(value));
    },
    loadingChild(val) {
      if (val === false ) {
        this.getData = this.dataList;
        this.getData.map((o: { disable: boolean; }) => o.disable = false);
        this.getData.forEach((element: any, key: any) => {
          const check = this.relationsDepartmentGroup.some((item: any) => item.group.groupId === element.groupId);
          if (check) {
            this.getData[key].disable = true;
          }
        });
        this.idSelected = [];
        if (this.dataAdded.length > 0 && this.isPage === 'affiliation-add-new') {
          this.idSelected = this.dataAdded;
        }
      }
    },
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    isPage: {
      type: String,
      default: '',
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 100,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingGroup: {
      type: Boolean,
      default: false,
    },
    dataList: {
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    isFinishProcessEnable: {
      type: Boolean,
      default: false,
    },
    relationsDepartmentGroup: {
      type: Array,
      default: () => [],
    },
    dataAdded: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    if (typeof localStorage.displayOptionPCode === 'undefined') {
      localStorage.setItem('displayOptionPCode', JSON.stringify(this.selectedDisplayOption));
    } else {
      this.selectedDisplayOption = JSON.parse(localStorage.displayOptionPCode);
    }
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '通行権限グループID',
            align: 'start',
            sortable: true,
            value: 'groupId',
          },
        ];
        headers.push({
            text: '通行権限グループ名',
            align: 'start',
            sortable: true,
            value: 'name',
          });
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
    loadingChild: {
      get(): boolean {
        return !this.loading && !this.loadingGroup ? false : true;
      },
      set(val: boolean): void {
        return;
      },
    },
  },
  data: (): DataType => ({
    isShow: false,
    isShowUserDetail: false,
    selectedDisplayOption: [
      'groupId',
      'name',
    ],
    displayOptionItems: [
      { label: '通行権限グループID', value: 'groupId', isDisabled: true },
      { label: '通行権限グループ名', value: 'name', isDisabled: false },
    ],
    idSelected: [],
    showOption: false,
    isVaildOnly: localStorage.isVaildDataCard === 'true' ? true : false,
    getData: [],
  }),
  methods: {
    CheckColumn(data: any) {
      localStorage.setItem('displayOptionPCode', JSON.stringify(data));
    },
    convertDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm');
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    checkItem(data: any) {
      return data.filter((item: any) => {
          return (!item.disable);
        });
    },
  },
});
