import Vue from 'vue';
import * as Config from '@/config';
import { DayType } from '@/types/alligate';

/**
 * 全日付タイプを取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<DayType[]>} 取得した全日付タイプ
 */
export async function getAllDayTypes(vue: Vue): Promise<DayType[]> {
  try {
    const dayTypes = await getAllDayTypesIter(vue, 1);
    return dayTypes;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw new Error('日付タイプの取得に失敗しました。日付タイプを取得する権限がありません。');
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 全日付タイプを取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<DayType[]>} 取得した全日付タイプ
 */
async function getAllDayTypesIter(vue: Vue, page: number): Promise<DayType[]> {
  const query = `?limit=1000&page=${page}&sortKey=${Config.DESC}:${Config.REGISTERED}`;
  try {
    const response = await vue.$axios.get(Config.DAY_TYPE_API + query);
    if (response.data.endRecord === response.data.maxRecords) {
      return response.data.dayTypes;
    } else {
      const nextDayTypes = await getAllDayTypesIter(vue, page + 1);
      return response.data.dayTypes.concat(nextDayTypes);
    }
  } catch (err) {
    throw err;
  }
}
