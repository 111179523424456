var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-list-template" },
    [
      _c("breadcrumbs", {
        staticClass: "mb-0",
        attrs: { items: _vm.breadcrumbs }
      }),
      _vm._v(" "),
      _c("v-container", { staticClass: "pa-0" }, [
        _c(
          "div",
          { staticClass: "container-group" },
          [
            _c(
              "div",
              { staticClass: "round-action pa-0" },
              [
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { transition: false },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    _vm._l(_vm.actionItems, function(item) {
                      return _c(
                        "v-tab",
                        { key: item.tabname, staticClass: "log-tab" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ]
              ],
              2
            ),
            _vm._v(" "),
            _c("v-container", [
              _c(
                "div",
                { staticClass: "round-tab group-tab " },
                [
                  _c(
                    "v-overlay",
                    {
                      staticClass: "overlay-group-tab",
                      attrs: {
                        absolute: "",
                        value: _vm.isLoading,
                        color: "white"
                      }
                    },
                    [
                      _c("v-progress-circular", {
                        staticClass: "user-loading-progress",
                        attrs: { indeterminate: "", color: "green" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    _vm._l(_vm.actionItems, function(item) {
                      return _c(
                        "v-tab-item",
                        {
                          key: item.tabname,
                          attrs: {
                            transition: false,
                            "reverse-transition": false
                          }
                        },
                        [
                          item.tabname === "tab1"
                            ? _c(
                                "v-card",
                                [
                                  _c("GroupListModalPage", {
                                    on: {
                                      loadingCircle: function($event) {
                                        return _vm.$emit(
                                          "loadingCircle",
                                          $event
                                        )
                                      },
                                      textCircle: function($event) {
                                        return _vm.$emit("textCircle", $event)
                                      },
                                      isLoad: function($event) {
                                        return _vm.getIsLoad($event)
                                      },
                                      "go-time-settings": _vm.goTimeSettings,
                                      "click-group-detail": function($event) {
                                        return _vm.clickGroupDetail($event)
                                      },
                                      loading: function($event) {
                                        return _vm.$emit(
                                          "loading-from-child",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.tabname === "tab2"
                            ? _c(
                                "v-card",
                                [
                                  _c("gate-list-page", {
                                    attrs: {
                                      inPage: "modal-group",
                                      groupId: "no-id"
                                    },
                                    on: {
                                      loading: function($event) {
                                        return _vm.$emit(
                                          "loading-from-child",
                                          $event
                                        )
                                      },
                                      clickGroupDetail: function($event) {
                                        return _vm.clickGroupDetail($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }