import Vue from 'vue';
import * as Config from '@/config';
import { FaceImage } from '@/types/alligate-zenescan';

export interface PostFaceImageBody {
  imageFileId: string;
  pCodeId: string;
}

/**
 * 顔写真一覧を取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<FaceImage[]>} 取得した全情報
 */
export async function getAllFaceImages(vue: Vue): Promise<FaceImage[]> {
  try {
    const faceImages = await getAllFaceImagesIter(vue, 1);
    return faceImages;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('顔画像情報一覧の取得に失敗しました。顔画像を取得する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 全顔画像情報設定を取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<FaceImage[]>} 取得した全通知設定
 */
async function getAllFaceImagesIter(vue: Vue, page: number): Promise<FaceImage[]> {
  const query = `?limit=1000&page=${page}&sortKey=${Config.DESC}:${Config.REGISTERED}`;
  try {
    const response = await vue.$axios.get(Config.FACE_IMAGE_API + query);
    if (response.data.endRecord === response.data.maxRecords) {
      return response.data.faceImages;
    } else {
      const nextFaceImages = await getAllFaceImagesIter(vue, page + 1);
      return response.data.faceImages.concat(nextFaceImages);
    }
  } catch (err) {
    throw err;
  }
}

/**
 * 指定したIDの削除を行う。
 * @param {Vue} vue Vueインスタンス
 * @param {String} ID 削除対象のFaceImageId
 * @returns {Promise<any>} DELETE /face-image/{ID} のレスポンス
 */
export async function deleteFaceImage(vue: Vue, ID: string): Promise<any> {
  try {
    const response = await vue.$axios.delete(Config.FACE_IMAGE_API + `/${encodeURIComponent(ID)}`);
    return response.data;
  } catch (err) {
    switch ((err as any).response.status) {
      case 400:
        throw(new Error('顔画像の削除に失敗しました。'));
      case 401:
        throw(new Error('顔画像の削除に失敗しました。顔画像を削除する権限がありません。'));
      case 403:
        throw(new Error('顔画像の削除に失敗しました。顔画像を削除する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return {};
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_DELETE);
    }
  }
}

/**
 * 指定したIDの設定変更を行う。
 * @param {Vue} vue Vueインスタンス
 * @param {String} ID 変更対象のFaceImageId
 * @param {String} pCodeId 紐つける個人コード
 * @returns {Promise<any>} PUT /face-image/{ID} のレスポンス
 */
export async function putFaceImage(vue: Vue, ID: string, pCodeId: string): Promise<any> {
  try {
    const response = await vue.$axios.put(Config.FACE_IMAGE_API + `/${encodeURIComponent(ID)}`, {pCodeId});
    return response.data;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('顔画像情報の変更に失敗しました。顔画像情報を変更する権限がありません。'));
      case 404:
        throw(new Error('顔画像情報の変更に失敗しました。顔画像情報は既に削除されています。'));
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_PUT);
    }
  }
}

/**
 * 顔画像情報の追加を行う。
 * @param {Vue} vue Vueインスタンス
 * @param {PostFaceImageBody} body 登録する顔画像情報
 * @returns {Promise<any>} POST /face-image のレスポンス
 */
export async function postFaceImage(vue: Vue, body: PostFaceImageBody): Promise<any> {
  try {
    const response = await vue.$axios.post(Config.FACE_IMAGE_API, body);
    return response.data;
  } catch (err) {
    switch ((err as any).response.status) {
      case 400:
        throw(new Error('顔画像の追加に失敗しました。'));
      case 403:
        throw(new Error('顔画像の追加に失敗しました。顔画像を追加する権限がありません。'));
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_POST);
    }
  }
}

/**
 * 取得件数とページ指定で顔画像情報を取得する
 * @param {Vue} vue Vueインスタンス
 * @param {number} limit 取得件数
 * @param {number} page ページ番号
 * @param {string} pCodeId 個人コード
 * @return {Promise<[FaceImage[], number]>} 取得した全顔画像情報
 */
export async function getFaceImagesLimitPage(vue: Vue, limit: number, page: number, pCodeId: string | undefined | null)
  : Promise<[FaceImage[], number]> {
  let query = `?limit=${limit}&page=${page}&sortKey=${Config.DESC}:${Config.REGISTERED}`;
  if (pCodeId && pCodeId.length > 0) {
    query = query + `&pCodeId=${pCodeId}`;
  }
  try {
    const response = await vue.$axios.get(Config.FACE_IMAGE_API + query);
    return [response.data.faceImages, response.data.maxRecords];
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('顔画像情報一覧の取得に失敗しました。顔画像を取得する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [[], 0];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}
