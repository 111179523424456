

































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import Searchbox from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import { DataTableHeader } from 'vuetify';
import { CardDetail } from '@/types/alligate';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import moment from 'moment';

export interface TypeItem {
  title: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  idSelected: CardDetail[];
  showOption: boolean;
  isVaildOnly: boolean;
  isShow: boolean;
  isShowUserDetail: boolean;
  // checkedColumnNames: any;
}

export default Vue.extend({
  name: 'UsersListTable',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
    TextLink,
  },
  watch: {
    idSelected(val: CardDetail[]) {
      this.$emit('select-pcodes', val.map((item) => item.pCodeId));
    },
    isFinishProcessEnable(value) {
      if (value) {
        this.idSelected = [];
      }
    },
    selectedDisplayOption(value) {
      localStorage.setItem('displayOptionPCode', JSON.stringify(value));
    },
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    isPage: {
      type: String,
      default: '',
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 100,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataList: {
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    isFinishProcessEnable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (typeof localStorage.displayOptionPCode === 'undefined') {
      localStorage.setItem('displayOptionPCode', JSON.stringify(this.selectedDisplayOption));
    } else {
      this.selectedDisplayOption = JSON.parse(localStorage.displayOptionPCode);
    }
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'ユーザー名',
            align: 'start',
            sortable: true,
            width: '20%',
            value: 'name',
          },
        ];
        if (this.selectedDisplayOption.includes('userId')) {
          headers.push({
            text: 'ユーザーID',
            align: 'start',
            sortable: true,
            value: 'userId',
          });
        }
        if (this.selectedDisplayOption.includes('type')) {
          headers.push({
            text: 'カード種別',
            align: 'start',
            sortable: true,
            value: 'type',
          });
        }
        if (this.selectedDisplayOption.includes('registered')) {
          headers.push({
            text: '登録日',
            align: 'start',
            sortable: true,
            value: 'registered',
          });
        }
        if (this.selectedDisplayOption.includes('updated')) {
          headers.push({
            text: '更新日',
            align: 'start',
            sortable: true,
            value: 'updated',
          });
        }
        if (this.selectedDisplayOption.includes('isValid')) {
          headers.push({
            text: 'ステータス',
            align: 'start',
            sortable: true,
            value: 'isValid',
          });
        }
        headers.push({
          text: '',
          align: 'end',
          sortable: false,
          width: '150px',
          value: 'icons',
        });
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  data: (): DataType => ({
    isShow: false,
    isShowUserDetail: false,
    selectedDisplayOption: [
      'userId',
      'userName',
    ],
    displayOptionItems: [
      { label: 'カード番号', value: 'pCodeId', isDisabled: true },
      { label: '名称', value: 'name' },
      { label: 'ユーザーID', value: 'userId' },
      { label: 'ユーザー名', value: 'userName' },
      { label: 'カード種別', value: 'type' },
      { label: '登録日', value: 'registered' },
      { label: '更新日', value: 'updated' },
      { label: 'ステータス', value: 'isValid'},
    ],
    idSelected: [],
    showOption: false,
    isVaildOnly: localStorage.isVaildDataCard === 'true' ? true : false,
  }),
  methods: {
    CheckColumn(data: any) {
      localStorage.setItem('displayOptionPCode', JSON.stringify(data));
    },
    convertDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm');
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
  },
});
