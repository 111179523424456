


















































































































import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import * as Config from '@/config';
import { user } from '@/store/getters';
interface TypeSelect {
  text: string;
  value: string;
}
export interface DataType {
  rulesValidate: any;
}
export default Vue.extend({
  name: 'KeyAddForm',
  components: {
    InputForm,
  },
  props: {
    existGateId: {
      default: [],
      },
      listTimes: {
        default: [],
      },
      passagesData: {
        default: {
            gateId: '0',
            terms: '0',
            isOnce: false,
            result: '',
        },
      },
      loading: {
      default: false,
      },
      errorMessageLogs: {
      default: '',
      },
      successMessageLogs: {
      default: '',
      },
  },
  watch: {
      //
  },
  data: (): DataType => ({
    rulesValidate: {
      // validateUserId: (userId: string) => {
      //   if (userId !== null && userId !== undefined && userId !== '') {
      //     const pattern = /^[a-zA-Z0-9.!@#$%&'*+/=?^_`{|}~-]{4,96}$/;
      //     return pattern.test(userId) || Config.USERID_VALIDATE;
      //   } else {
      //     return true;
      //   }
      // },
    },
  }),
  methods: {
    geneKey() {
      this.$emit('generate-key', {
        gateId: this.passagesData.gateId,
        terms: this.passagesData.terms,
        isOnce: this.passagesData.isOnce,
      });
    },
    coppyClipboard() {
      navigator.clipboard.writeText(this.passagesData.result);
    },
  },
});
