var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "timeline-table" }, [
    _c("table", [
      _c(
        "tbody",
        [
          _c(
            "tr",
            [
              _c("th", { staticClass: "th-item-title" }),
              _vm._v(" "),
              _vm._l(_vm.tableHeader, function(item, index) {
                return _c(
                  "td",
                  { key: index, staticClass: "text-caption header" },
                  [_vm._v("\n          " + _vm._s(item) + "\n        ")]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _vm.tableLineItems.length === 0
            ? _c("tr", [
                _c("th"),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass:
                      "border text-body-2 text-center font-weight-bold",
                    style: { "background-color": _vm.NONE },
                    attrs: { colspan: "24" }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.noDataMessage) + "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("td")
              ])
            : _vm._l(_vm.tableLineItems, function(item) {
                return _c(
                  "tr",
                  { key: item.id },
                  [
                    _c(
                      "th",
                      {
                        staticClass:
                          "th-item-title pl-4 text-body-2 text-left border"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "item-title col-12 pa-0 text-truncate"
                          },
                          [
                            _vm.authority.isAdmin ||
                            _vm.authority.isAdminToViewUser
                              ? _c("text-link", {
                                  staticClass: "item-user-id",
                                  attrs: { text: item.title },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "click-user-name",
                                        item.id
                                      )
                                    }
                                  }
                                })
                              : _c("span", [
                                  _vm._v(_vm._s(item.title) + "\n        ")
                                ])
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(item.colors, function(color, index2) {
                      return _c(
                        "v-tooltip",
                        {
                          key: index2,
                          attrs: { right: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "td",
                                      _vm._b(
                                        {
                                          staticClass: "border",
                                          class: {
                                            hoverable: color !== _vm.NONE
                                          },
                                          style: { "background-color": color }
                                        },
                                        "td",
                                        attrs,
                                        false
                                      ),
                                      [
                                        color !== _vm.NONE
                                          ? _c(
                                              "div",
                                              _vm._g(
                                                {
                                                  staticClass: "hoverable-item"
                                                },
                                                on
                                              )
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", { staticClass: "text-pre" }, [
                            _vm._v(_vm._s(item.tooltipText[index2]))
                          ])
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("td")
                  ],
                  2
                )
              })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }