var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-tabs", class: _vm.classValue },
    [
      _c(
        "v-tabs",
        {
          ref: "alligTabs",
          attrs: { color: "green", vertical: _vm.vertical },
          model: {
            value: _vm.innerValue,
            callback: function($$v) {
              _vm.innerValue = $$v
            },
            expression: "innerValue"
          }
        },
        _vm._l(_vm.items, function(item) {
          return _c(
            "v-tab",
            {
              key: item.value,
              attrs: { disabled: _vm.disabled },
              on: {
                click: function($event) {
                  return _vm.$emit("click-tab", item.value)
                }
              }
            },
            [
              _vm._t(
                "tab." + item.value,
                [_vm._v("\n        " + _vm._s(item.text) + "\n      ")],
                { item: item }
              )
            ],
            2
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "v-tabs-items",
        {
          staticClass: "overflow-visible",
          model: {
            value: _vm.innerValue,
            callback: function($$v) {
              _vm.innerValue = $$v
            },
            expression: "innerValue"
          }
        },
        _vm._l(_vm.items, function(item) {
          return _c(
            "v-tab-item",
            {
              key: item.value,
              attrs: { transition: false, "reverse-transition": false }
            },
            [_c("v-card", { attrs: { flat: "" } }, [_vm._t(item.value)], 2)],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }