
































import Vue, { PropType } from 'vue';

export interface TabItem {
  text: string;
  value: string;
}

export default Vue.extend({
  name: 'Tabs',
  props: {
    value: {
      type: String,
      default: '',
    },
    classValue: {
      type: String,
      default: '',
    },
    items: {
      type: Array as PropType<TabItem[]>,
      default: () => [],
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get(): number {
        const index = this.items.findIndex((item) => item.value === this.value);
        return index;
      },
      set(val: number): void {
        this.$emit('input', this.items[val] ? this.items[val].value : '');
        return;
      },
    },
  },
  methods: {
    callSlider() {
      (this.$refs.alligTabs as any).callSlider();
    },
  },
});
