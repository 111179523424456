var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-gate-table" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-data-table",
            {
              staticClass: "border-table border-table",
              attrs: {
                headers: _vm.headersGroup,
                items: _vm.groupOfGate,
                "items-per-page": 25,
                "no-data-text": "該当するゲートがありません",
                "loading-text": "データを読み込中です",
                "hide-default-footer": "",
                dense: "",
                "show-select": true,
                "item-key": "relationId",
                loading: _vm.isGroupOfGate
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item.isValid",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.group !== undefined && item.group.isValid === true
                          ? _c("span", [_vm._v("有効")])
                          : _c("span", [_vm._v("無効")])
                      ]
                    }
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.selectedItem,
                callback: function($$v) {
                  _vm.selectedItem = $$v
                },
                expression: "selectedItem"
              }
            },
            [_vm._v(">\n    ")]
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "mt-3",
              attrs: { "no-gutters": "", justify: "space-between" }
            },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("pagination", {
                staticClass: "mb-1",
                attrs: {
                  "page-length": Math.ceil(_vm.total / 25),
                  disabled: _vm.isGroupOfGate
                },
                model: {
                  value: _vm.innerPageGroup,
                  callback: function($$v) {
                    _vm.innerPageGroup = $$v
                  },
                  expression: "innerPageGroup"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Modal", {
        attrs: {
          title: "関連付け解除",
          text: "このグループとの関連付けを解除しますか？",
          smallScreen: "600",
          className: "common-modal"
        },
        on: {
          close: function($event) {
            return _vm.$emit("closeModalConfirm")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c("v-spacer"),
                _vm._v(" "),
                _c("cancel-button", {
                  staticClass: "mr-2",
                  attrs: {
                    text: _vm.selectedItem.length > 0 ? "キャンセル" : "実行"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("closeModalConfirm")
                    }
                  }
                }),
                _vm._v(" "),
                _vm.selectedItem.length > 0
                  ? _c("success-button", {
                      attrs: { text: "OK" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("clickRemoveGroup", _vm.selectedItem)
                        }
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.isShow,
          callback: function($$v) {
            _vm.isShow = $$v
          },
          expression: "isShow"
        }
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showProgress,
            callback: function($$v) {
              _vm.showProgress = $$v
            },
            expression: "showProgress"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v("関連付け解除")
              ]),
              _vm._v(" "),
              _vm.totalDataRowProcess > 0
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-progress-linear",
                        {
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.progress,
                            callback: function($$v) {
                              _vm.progress = $$v
                            },
                            expression: "progress"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(Math.round(_vm.progress)) + "%")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.successItemProcess > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.totalDataRowProcess) +
                                "件中" +
                                _vm._s(_vm.successItemProcess) +
                                "件を解除しました。"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageProcess.length > 0
                        ? _c("div", [
                            _c("span", { staticClass: "error-box-title" }, [
                              _vm._v("処理状況")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "error-box" },
                              [
                                _vm._l(_vm.errorMessageProcess, function(item) {
                                  return [
                                    _c("p", {
                                      key: item,
                                      domProps: { innerHTML: _vm._s(item) }
                                    })
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _vm.isFinishProcess
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c("success-button", {
                            attrs: { text: "OK" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("closeModalProgress")
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }