






































































































import Vue from 'vue';
import InRoomTemplate from '@/components/templates/InRoomTemplate/InRoomTemplate.vue';
import { InRoomSummaryByUserExcel } from '@/utils/excel/in-room-summary-by-user';
import { InRoomSummaryByRoomExcel } from '@/utils/excel/in-room-summary-by-room';
import { Gate, Room, RoomRoomLog, User, UserRoomLog } from '@/types/alligate';
import { SearchValue } from '@/components/molecules/Search/Search.vue';
import AlligateUtils from '@/utils/alligate';
import { getAllUsersIsValid } from '@/utils/alligate/user';
import store from '@/store';
import * as Config from '@/config';
import { TAB_VALUE_IN_ROOM_TIMELINE, TAB_VALUE_IN_ROOM_USER, TAB_VALUE_IN_ROOM_USER_TIMELINE} from '@/components/organisms/InRoomDetail/InRoomDetail.vue';
export interface DataType {
  value: Room;
  currentTab: string;
  successMessages: string[];
  errorMessages: string[];

  roomsLoading: boolean;
  allRooms: Room[];

  roomLoading: boolean;
  room: Room;
  roomInfo: Room;
  roomSuccessMessages: string[];
  roomErrorMessages: string[];

  inRoomUserLoading: boolean;
  inRoomUsers: User[];
  inRoomUserTotalCount: number;
  inRoomUserPage: number;
  inRoomUserPerPage: number;
  inRoomUserSearchValue: SearchValue;
  inRoomUserSortBy: string;
  inRoomUserSortOrder: string;

  inAllRoom: Room[];
  inAllRoomLoading: boolean;
  inAllRoomSearchValue: SearchValue;
  roomTotalCount: number;
  currentPage: number;
  roomEdit: Room;

  roomRoomLogLoading: boolean;
  roomRoomLogs: RoomRoomLog[];
  roomRoomLogTotalCount: number;
  roomRoomLogPage: number;
  roomRoomLogPerPage: number;
  roomRoomLogDate: string;
  roomRoomLogSearchValue: SearchValue;
  roomRoomLogProgressMessage: string;
  roomRoomLogProgressErrorMessage: string;

  userRoomLogLoading: boolean;
  user: User;
  userRoomLogs: UserRoomLog[];
  userRoomLogDate: string;
  userRoomLogProgressMessage: string;
  userRoomLogProgressErrorMessage: string;
  userRoomLogSuccessMessages: string[];
  userRoomLogErrorMessages: string[];

  roomFormLoading: boolean;
  roomFormSuccessMessages: string[];
  roomFormErrorMessages: string[];
  allControllerGates: Gate[];
  isLookRoomModalShow: boolean;
  isAllRoomModalShow: boolean;
  tabItems: any;
  userFullList: any;
  authority: any;
  roomInfoLoading: boolean;
  isPage: string;
}

export default Vue.extend({
  name: 'InRoomManagementRoomPage',
  components: {
    InRoomTemplate,
  },
  data: (): DataType => ({
    value: {
      roomId: '',
      name: '',
    },
    currentTab: TAB_VALUE_IN_ROOM_USER,

    successMessages: [],
    errorMessages: [],
    roomsLoading: false,
    allRooms: [],

    roomFormLoading: false,
    roomFormSuccessMessages: [],
    roomFormErrorMessages: [],
    allControllerGates: [],

    roomLoading: false,
    room: {
      roomId: '',
      name: '',
    },
    roomSuccessMessages: [],
    roomErrorMessages: [],

    inRoomUserLoading: false,
    inRoomUsers: [],
    inRoomUserTotalCount: 0,
    inRoomUserPage: 1,
    inRoomUserPerPage: 100,
    inRoomUserSearchValue: {
      text: '',
      targets: ['userId', 'name'],
    },
    inRoomUserSortBy: 'name',
    inRoomUserSortOrder: Config.ASC,

    roomRoomLogLoading: false,
    roomRoomLogs: [],
    roomRoomLogTotalCount: 0,
    roomRoomLogPage: 1,
    roomRoomLogPerPage: 100,
    roomRoomLogDate: new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10),
    roomRoomLogSearchValue: {
      text: '',
      targets: ['userName'],
    },
    roomRoomLogProgressMessage: '',
    roomRoomLogProgressErrorMessage: '',

    inAllRoomLoading: false,
    inAllRoom: [],
    roomInfo: {
      roomId: '',
      name: '',
      relationsRoomGate: [],
    },
    inAllRoomSearchValue: {
      text: '',
      targets: ['name'],
    },
    roomTotalCount: 0,
    currentPage: 1,
    roomEdit: {
      roomId: '',
      name: '',
    },
    authority: store.state.auth,
    roomInfoLoading: false,

    userRoomLogLoading: false,
    user: {
      userId: '',
      name: '',
      furigana: '',
      email: '',
      phoneNumber: '',
      isAdmin: false,
      isPhoneAdmin: false,
      shareKeyBeValid: false,
      isMfaSms: false,
      passCode: '',
      isValid: false,
      option1: '',
      option2: '',
      isPasswordLoginValid: false,
      groupCount: 0,
      gateCount: 0,
      timelimitedGateCount: 0,
    },
    userRoomLogs: [],
    userRoomLogDate: new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10),
    userRoomLogProgressMessage: '',
    userRoomLogProgressErrorMessage: '',
    userRoomLogSuccessMessages: [],
    userRoomLogErrorMessages: [],
    isLookRoomModalShow: false,
    isAllRoomModalShow: false,
    tabItems: [
      { text: '在室中ユーザー', value: TAB_VALUE_IN_ROOM_USER },
      { text: '在室履歴', value: TAB_VALUE_IN_ROOM_TIMELINE },
    ],
    userFullList: [],
    isPage: 'room',
  }),
  mounted() {
    this.$emit('activeMenu', 'in-room-management/room');
    if (this.$route.path === '/in-room-management/user') {
      this.tabItems = [
        { text: '在室履歴', value: TAB_VALUE_IN_ROOM_USER_TIMELINE },
      ];
      this.roomsLoading = true;
      this.initializePageUser();
      Promise.all([
        getAllUsersIsValid(this).then((users: any) => this.userFullList = users),
      //
      ]).then(() => {
        this.clickInRoomUser(this.userFullList[0].userId);
        this.currentTab = TAB_VALUE_IN_ROOM_USER_TIMELINE;
        this.roomsLoading = false;
      });
    } else {
      this.roomsLoading = true;
      this.inRoomUserLoading = true;
      this.initializePage();
      this.tabItems = [
        { text: '在室ユーザー一覧', value: TAB_VALUE_IN_ROOM_USER },
        { text: '在室履歴', value: TAB_VALUE_IN_ROOM_TIMELINE },
      ];
    }
  },
  watch: {
    roomsLoading(val: any) {
      // this.$emit('loading', val);
    },
  },
  methods: {
    /**
     * マウント時、および、部屋作成時のページの初期化処理
     */
    async initializePage(): Promise<void> {
      await this.loadAllRooms();
      if (this.allRooms.length > 0) {
        if (this.$route.query.roomId !== null && this.$route.query.roomId !== undefined) {
          const roomId = this.$route.query.roomId;
          this.clickRoom(roomId.toString());
        } else {
          this.setRoom(this.allRooms[0]);
        }
      } else {
        this.roomsLoading = false;
        this.inRoomUserLoading = false;
        this.roomRoomLogLoading = false;
        this.inRoomUsers = [];
        this.roomRoomLogs = [];
      }
    },
    /**
     * 全部屋を読み込む
     */
    async loadAllRooms(): Promise<void> {
      this.roomsLoading = true;
      this.initializeRoomsData();
      try {
        this.allRooms = await AlligateUtils.room.getAllRooms(this);
      } catch (err) {
        this.errorMessages = [(err as Error).message];
      }
      this.roomsLoading = false;
    },
    /**
     * 全部屋関連のデータを初期化
     */
    initializeRoomsData() {
      // 部屋作成完了メッセージなどが消えてしまうため、ここでアラートメッセージの初期化はしない
      this.allRooms = [];
    },
    /**
     * 個々の部屋ページの初期化
     */
    async setRoom(room: Room): Promise<void> {
      this.room = room;
      this.initializeRoomData();
      // 在室中ユーザ数を更新するために、どのタブが選ばれたとしても読み込む
      this.loadInRoomUsers();
      if (this.currentTab === TAB_VALUE_IN_ROOM_TIMELINE) {
        this.loadRoomRoomLogs();
      }
    },
    /**
     * 部屋表示のデータを初期化
     */
    initializeRoomData() {
      this.roomSuccessMessages = [];
      this.roomErrorMessages = [];

      this.inRoomUsers = [];
      this.inRoomUserTotalCount = 0;
      this.inRoomUserPage = 1;
      this.inRoomUserPerPage = 100;
      this.inRoomUserSearchValue.text = '';

      this.roomRoomLogs = [];
      this.roomRoomLogTotalCount = 0;
      this.roomRoomLogPage = 1;
      this.roomRoomLogPerPage = 100;
      this.roomRoomLogSearchValue.text = '';

      this.user = {
        userId: '',
        name: '',
        furigana: '',
        email: '',
        phoneNumber: '',
        isAdmin: false,
        isPhoneAdmin: false,
        shareKeyBeValid: false,
        isMfaSms: false,
        passCode: '',
        isValid: false,
        option1: '',
        option2: '',
        isPasswordLoginValid: false,
        groupCount: 0,
        gateCount: 0,
        timelimitedGateCount: 0,
      };
      this.userRoomLogs = [];
      this.userRoomLogProgressMessage = '';
      this.userRoomLogProgressErrorMessage = '';
    },
    /**
     * 表示する在室中ユーザを読み込む
     */
    async loadInRoomUsers() {
      this.inRoomUserLoading = true;
      this.initializeInRoomUserData();
      try {
        const query: { [name: string]: string } = {
          page: String(this.inRoomUserPage),
          limit: String(this.inRoomUserPerPage),
          sortKey: `${this.inRoomUserSortOrder}:${this.inRoomUserSortBy}`,
        };
        if (this.inRoomUserSearchValue.text !== '') {
          query.keyword = this.inRoomUserSearchValue.text;
          query.scorp = this.inRoomUserSearchValue.targets.join(',');
        }
        const response = await AlligateUtils.room.getInRoomUsers(this, this.room.roomId, query);
        this.inRoomUserTotalCount = response.maxRecords;
        this.inRoomUsers = response.users;
        // 在室中ユーザの数も更新する
        this.room.roomUserCount = response.maxRecords;
        const index = this.allRooms.findIndex((r) => r.roomId === this.room.roomId);
        this.$set(this.allRooms[index], 'roomUserCount', response.maxRecords);
      } catch (err) {
        this.roomErrorMessages = [(err as Error).message];
      }
      this.inRoomUserLoading = false;
    },
    /**
     * 在室中ユーザ関連のデータを初期化
     */
    initializeInRoomUserData() {
      this.roomSuccessMessages = [];
      this.roomErrorMessages = [];
      this.inRoomUsers = [];
      this.inRoomUserTotalCount = 0;
    },
    /**
     * 表示する在室履歴を読み込む
     */
    async loadRoomRoomLogs() {
      this.roomRoomLogLoading = true;
      this.initializeRoomRoomLogData();
      try {
        const query: { [name: string]: string } = {
          page: String(this.roomRoomLogPage),
          limit: String(this.roomRoomLogPerPage),
          date: this.roomRoomLogDate,
          sortKey: `${Config.ASC}:userName`,
        };
        if (this.roomRoomLogSearchValue.text !== '') {
          query.keyword = this.roomRoomLogSearchValue.text;
          query.scorp = this.roomRoomLogSearchValue.targets.join(',');
        }
        const response = await AlligateUtils.log.getRoomRoomLogs(this, this.room.roomId, query);
        this.roomRoomLogTotalCount = response.maxRecords;
        this.roomRoomLogs = response.users;
      } catch (err) {
        this.roomErrorMessages = [(err as Error).message];
      }
      this.roomRoomLogLoading = false;
    },
    /**
     * 在室履歴関連のデータを初期化
     */
    initializeRoomRoomLogData() {
      this.roomSuccessMessages = [];
      this.roomErrorMessages = [];
      this.roomRoomLogs = [];
      this.roomRoomLogTotalCount = 0;
    },
    /**
     * 全制御盤ゲートを取得する
     */
    async loadAllControllerGates(): Promise<void> {
      this.roomFormLoading = true;
      this.initializeControllerGatesData();
      try {
        this.allControllerGates = await AlligateUtils.controller.getAllControllerGates(this);
      } catch (err) {
        this.roomFormErrorMessages = [(err as Error).message];
      }
      this.roomFormLoading = false;
    },
    /**
     * 全制御盤ゲート関連のデータを初期化
     */
    initializeControllerGatesData() {
      this.roomFormSuccessMessages = [];
      this.roomFormErrorMessages = [];
      this.allControllerGates = [];
    },
    /**
     * 左メニューから部屋を選択した際の処理
     */
    async clickRoom(roomId: string) {
      const room = this.allRooms.find((r) => r.roomId === roomId);
      if (room && this.room.roomId !== '') {
        this.setRoom(room);
      }
    },
    /**
     * 部屋の作成をクリック
     */
    async startCreateRoom() {
      this.value = {
        roomId: '',
        name: '',
      };
      this.loadAllControllerGates();
    },
    /**
     * 部屋の編集をクリック
     */
    async startEditRoom(roomEdit: Room) {
      this.value = roomEdit;
      this.roomEdit = roomEdit;
      this.loadAllControllerGates();
    },
    /**
     * タブをクリックした場合
     */
    async clickTab(tabName: string) {
      if (tabName === TAB_VALUE_IN_ROOM_USER) {
        this.loadInRoomUsers();
      } else if (tabName === TAB_VALUE_IN_ROOM_TIMELINE) {
        this.loadRoomRoomLogs();
      }
    },
    /**
     * ユーザをクリックした場合
     */
    async clickInRoomUser(userId: string) {
      console.log('userId', userId);
      // 部屋のタイムラインを見ているならばその日付、在室中ユーザを見ているならば今日の日付でユーザ情報を表示する
      this.userRoomLogDate = this.currentTab === TAB_VALUE_IN_ROOM_TIMELINE
        ? this.roomRoomLogDate
        : new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10);
      // 異なるユーザをクリックした場合はユーザ情報を初期化する
      if (this.user.userId !== userId) {
        this.user = {
          userId: '',
          name: '',
          furigana: '',
          email: '',
          phoneNumber: '',
          isAdmin: false,
          isPhoneAdmin: false,
          shareKeyBeValid: false,
          isMfaSms: false,
          passCode: '',
          isValid: false,
          option1: '',
          option2: '',
          isPasswordLoginValid: false,
          groupCount: 0,
          gateCount: 0,
          timelimitedGateCount: 0,
        };
      }
      await this.loadUserRoomLogs(userId);
    },
    /**
     * ユーザ在室履歴を読み込む
     */
    async loadUserRoomLogs(userId: string) {
      this.userRoomLogLoading = true;
      this.initializeUserRoomLogData();
      try {
        // ユーザ情報を読み込む
        if (this.user.userId !== userId) {
          this.user = await AlligateUtils.user.getUser(this, userId);
        }
        // 在室履歴を読み込む
        const query: { [name: string]: string } = {
          page: '1',
          limit: '1000',
          date: this.userRoomLogDate,
          sortKey: `${Config.ASC}:roomName`,
        };
        const response = await AlligateUtils.log.getUserRoomLogs(this, userId, query);
        this.userRoomLogs = response.rooms;
      } catch (err) {
        this.userRoomLogErrorMessages = [(err as Error).message];
      }
      this.userRoomLogLoading = false;
    },
    /**
     * ユーザ在室履歴関連データの初期化
     */
    initializeUserRoomLogData() {
      this.userRoomLogSuccessMessages = [];
      this.userRoomLogErrorMessages = [];
      this.userRoomLogs = [];
    },
    /**
     * ソートが変更された場合
     */
    async changeInRoomUserSort(option: { sortBy: string[], sortDesc: boolean[] }) {
      const oldSortBy = this.inRoomUserSortBy;
      const oldSortOrder = this.inRoomUserSortOrder;
      this.inRoomUserSortBy = option.sortBy.length === 0
        ? 'name'
        : option.sortBy[0];
      this.inRoomUserSortOrder = option.sortDesc.length === 0
        ? Config.ASC
        : option.sortDesc[0] ? Config.DESC : Config.ASC;
      if (oldSortBy !== this.inRoomUserSortBy
        || oldSortOrder !== this.inRoomUserSortOrder) {
        this.loadInRoomUsers();
      }
    },
    /**
     * ページが変更された場合
     */
    async changeInRoomUserPage(page: number) {
      if (this.inRoomUserPage !== page) {
        this.inRoomUserPage = page;
        this.loadInRoomUsers();
      }
    },
    /**
     * 検索が実行された場合
     */
    async searchInRoomUser(searchVal: SearchValue) {
      this.inRoomUserSearchValue = searchVal;
      this.loadInRoomUsers();
    },
    /**
     * リロード
     */
    async reloadInRoomUser() {
      this.loadInRoomUsers();
    },
    /**
     * 日付が変更された場合
     */
    async inputRoomRoomLogDate(date: string) {
      if (this.roomRoomLogDate !== date) {
        this.roomRoomLogDate = date;
        this.loadRoomRoomLogs();
      }
    },
    /**
     * ページが変更された場合
     */
    async changeRoomRoomLogPage(page: number) {
      if (this.roomRoomLogPage !== page ) {
        this.roomRoomLogPage = page;
        this.loadRoomRoomLogs();
      }
    },
    /**
     * 検索が実行された場合
     */
    async searchRoomRoomLog(searchVal: SearchValue) {
      this.roomRoomLogSearchValue = searchVal;
      this.loadRoomRoomLogs();
    },
    /**
     * リロードする
     */
    async reloadRoomRoomLog() {
      this.loadRoomRoomLogs();
    },
    /**
     * 集計結果をエクスポートする
     */
    async exportRoomRoomLogExcel() {
      this.roomRoomLogProgressErrorMessage = '';
      this.roomRoomLogProgressMessage = '出力準備中です。';
      const excel = new InRoomSummaryByRoomExcel();
      try {
        this.roomRoomLogProgressMessage = 'テンプレートをクラウドから取得しています。';
        await excel.downloadExcelFromCloud(this);
        this.roomRoomLogProgressMessage = 'クラウドからログ情報を取得しています。';
        const allRoomRoomLogs = await AlligateUtils.log
          .getAllRoomRoomLogs(this, this.room.roomId, this.roomRoomLogDate);
        this.roomRoomLogProgressMessage = '出力するExcelデータを生成しています。';
        await excel.inputData(this.roomRoomLogDate, this.room, allRoomRoomLogs);
        this.roomRoomLogProgressMessage = '出力しています。';
        await excel.downloadExcel();
        this.roomRoomLogProgressMessage = '';
      } catch (err) {
        this.roomRoomLogProgressErrorMessage = (err as Error).message;
        this.roomRoomLogProgressMessage = '';
      }
    },
    /**
     * 日付が変更された場合
     */
    async inputUserRoomLogDate(date: string) {
      if (this.userRoomLogDate !== date) {
        this.userRoomLogDate = date;
        this.loadUserRoomLogs(this.user.userId);
      }
    },
    /**
     * リロードする
     */
    async reloadUserRoomLog() {
      this.loadUserRoomLogs(this.user.userId);
    },
    /**
     * 集計結果をエクスポートする
     */
    async exportUserRoomLogExcel() {
      this.userRoomLogProgressErrorMessage = '';
      this.userRoomLogProgressMessage = '出力準備中です。';
      const excel = new InRoomSummaryByUserExcel();
      try {
        this.userRoomLogProgressMessage = 'テンプレートをクラウドから取得しています。';
        await excel.downloadExcelFromCloud(this);
        this.userRoomLogProgressMessage = '出力するExcelデータを生成しています。';
        await excel.inputData(this.userRoomLogDate, this.user, this.userRoomLogs);
        this.userRoomLogProgressMessage = '出力しています。';
        await excel.downloadExcel();
        this.userRoomLogProgressMessage = '';
      } catch (err) {
        this.userRoomLogProgressErrorMessage = (err as Error).message;
        this.userRoomLogProgressMessage = '';
      }
    },
    /**
     * 部屋を削除する
     */
    async deleteRoom(roomId: string) {
      this.isPage = '';
      this.roomsLoading = true;
      this.successMessages = [];
      this.errorMessages = [];
      try {
        await AlligateUtils.room.deleteRoom(this, roomId);
        this.successMessages = ['部屋を削除しました。'];
        this.roomFormLoading = false;
        this.inRoomUserLoading = true;
        this.roomRoomLogLoading = true;
        this.isPage = 'room';
        this.room = {
          roomId: '',
          name: '',
        };
        this.initializePage();
        if (this.isAllRoomModalShow) {
          this.loadInAllRoom();
        }
      } catch (err) {
        this.isPage = 'room';
        this.errorMessages = [(err as Error).message];
        this.inRoomUserLoading = false;
        this.roomsLoading = false;
        this.roomRoomLogLoading = false;
      }
    },
    /**
     * 部屋を作成する
     */
    async createRoom(val: Room) {
      this.roomsLoading = true;
      this.roomFormLoading = true;
      this.successMessages = [];
      this.errorMessages = [];
      const data: {[name: string]: any} = {
        name: val.name,
      };
      if (val.relationsRoomGate) {
        data.relationsRoomGate = val.relationsRoomGate.map((r) => ({
          gateId: r.gateId,
          entryExitType: 0,
        }));
      }
      try {
        await AlligateUtils.room.postRoom(this, data);
        this.successMessages = ['新しい部屋を作成しました。'];
        this.roomsLoading = false;
        this.roomFormLoading = true;
        setTimeout(() => {
          this.initializePage();
          this.inAllRoomSearchValue.text = '';
          if (this.isAllRoomModalShow) {
            this.loadInAllRoom();
          }
        }, 300);
      } catch (err) {
        this.errorMessages = [(err as Error).message];
        this.roomsLoading = false;
        this.roomFormLoading = true;
      }
    },
    /**
     * 部屋を更新する
     */
    async updateRoom(val: Room) {
      this.roomsLoading = true;
      this.roomFormLoading = true;
      this.successMessages = [];
      this.errorMessages = [];
      const data: {[name: string]: any} = {
        name: val.name,
      };
      if (val.relationsRoomGate) {
        data.relationsRoomGate = val.relationsRoomGate.map((r) => ({
          gateId: r.gateId,
          entryExitType: 0,
        }));
      }
      try {
        await AlligateUtils.room.putRoom(this, val.roomId, data);
        this.successMessages = ['部屋情報を更新しました。'];
        const index = this.allRooms.findIndex((r) => r.roomId === val.roomId);
        this.$set(this.allRooms, index, val);
        this.setRoom(val);
        this.inAllRoomSearchValue.text = '';
        if (this.isAllRoomModalShow) {
          this.loadInAllRoom();
        }
        if (this.isLookRoomModalShow) {
          this.loadRoomById();
        }
      } catch (err) {
        this.errorMessages = [(err as Error).message];
      }
      this.roomsLoading = false;
      this.roomFormLoading = false;
    },
    clicklookroom(room: Room) {
      this.roomInfo = room;
      this.isLookRoomModalShow = true;
      this.allControllerGates = [];
      this.successMessages = [];
      this.errorMessages = [];
    },
    closeLookRoom() {
      this.isLookRoomModalShow = false;
      this.successMessages = [];
      this.errorMessages = [];
    },
    ChangeProperty() {
      this.isLookRoomModalShow = false;
      this.isAllRoomModalShow = false;
    },
    clicAllRoom() {
      this.isAllRoomModalShow = true;
      this.inAllRoom = [];
      this.successMessages = [];
      this.errorMessages = [];
      this.loadInAllRoom();
    },
    closeAllRoom() {
      this.isAllRoomModalShow = false;
      this.errorMessages = [];
      this.successMessages = [];
    },
    async searchInAllRoom(searchVal: SearchValue) {
      this.inAllRoomSearchValue = searchVal;
      this.loadInAllRoom();
    },
    async loadInAllRoom(): Promise<void> {
      this.inAllRoomLoading = true;
      this.inAllRoom = [];

      try {
        const roomResponse = await AlligateUtils.room.getRoomsByKeyword(
          this,
          this.currentPage,
          this.inAllRoomSearchValue.text,
        );
        this.roomTotalCount = roomResponse.maxRecords;
        this.inAllRoom = roomResponse.rooms;
      } catch (err) {
        this.errorMessages = [(err as Error).message];
      }
      this.inAllRoomLoading = false;
    },
    changePage(value: any): any {
      this.currentPage = value;
      this.loadInAllRoom();
    },
    async loadRoomById(): Promise<void> {
      this.roomInfoLoading = true;
      this.allControllerGates = [];

      try {
        const room = await AlligateUtils.room.getRoomById(
          this,
          this.roomInfo.roomId,
        );
        await this.loadAllControllerGates().then(() => {
          this.roomInfoLoading = false;
        });
        this.roomInfo = {
          roomId: room.roomId,
          name: room.name,
          relationsRoomGate: room.relationsRoomGate,
        };
      } catch (err) {
        this.roomInfoLoading = false;
        this.errorMessages = [(err as Error).message];
      }
    },
    async initializePageUser(): Promise<void> {
      this.roomsLoading = true;
      this.initializeRoomsData();
      try {
        this.allRooms = await AlligateUtils.room.getAllRooms(this);
      } catch (err) {
        this.errorMessages = [(err as Error).message];
      }
      if (this.allRooms.length > 0) {
        this.room = this.allRooms[0];
      }
    },
  },
});
