import * as config from '@/config';

const PAT_USER_ID = new RegExp(/^[a-zA-Z0-9@.!#$%&'*+/=?\^_`{|}~\-]{4,96}$/);

export default {
  // バリデーションルール (エラー.7の場合はメッセージを、エラーなしの場合はtrueを返す)
  relationUserFederatedLoginServiceUserIdRules: [
    (val: string | undefined | null): string | boolean =>
      (val === undefined || val === null)
        ? 'ユーザーIDの入力は必須です。' : true,
    (val: string | undefined | null): string | boolean =>
      (val !== undefined && val !== null && PAT_USER_ID.test(val))
        ? 'ユーザーIDに誤った値が入力されています。' : true,
  ],
  relationUserFederatedLoginServiceLoginEmailRules: [
    (val: string | undefined | null): string | boolean =>
      (val === undefined || val === null)
        ? 'Eメールアドレスの入力は必須です。' : true,
  ],
  passableTimeSettingDayTypeId: [
    (val: string | undefined | null): string | boolean =>
      (val === undefined || val === null || val === '')
        ? '日付名は必ず指定してください.' : true,
  ],
  passableTimeSettingNameRules: [
    (val: string | undefined | null): string | boolean =>
      (val === undefined || val === null || val === '')
        ? '時間帯設定名は必須項目です.' : true,
  ],
  passableTimeSettingTimeSettingRules: [
    (allDay: number | undefined | null, startTime: string | undefined | null, endTime: string | undefined | null)
      : string | boolean =>
      (allDay === undefined || allDay === null)
        ? 'allDayは必須項目です。' : true,
    (allDay: number | undefined | null, startTime: string | undefined | null, endTime: string | undefined | null)
      : string | boolean =>
      (allDay !== config.ALLDAY_PASSABLE && allDay !== config.ALLDAY_IMPASSABLE && allDay !== config.ALLDAY_NO_SETTING)
        ? 'allDayに不正な値が入力されています。' : true,
    (allDay: number | undefined | null, startTime: string | undefined | null, endTime: string | undefined | null)
      : string | boolean =>
      (allDay !== config.ALLDAY_PASSABLE && allDay !== config.ALLDAY_IMPASSABLE
        && (startTime === undefined || startTime === null || endTime === undefined || endTime === null))
        ? '終日可能または終日不可能を設定しない場合、必ず時間を入力してください。' : true,
    (allDay: number | undefined | null, startTime: string | undefined | null, endTime: string | undefined | null)
      : string | boolean =>
      (allDay === config.ALLDAY_NO_SETTING
        && startTime !== undefined && startTime !== null && endTime !== undefined && endTime !== null
        && startTime > endTime)
        ? '開始時刻が終了時刻を上回っています。' : true,
  ],
  relationOwnerFederatedLoginServiceMetadataUrlRules: [
    (val: string | undefined | null): string | boolean =>
      (val === undefined || val === null || val === '')
        ? 'SAMLログインを有効にする場合、メタデータURLを必ず指定してください。' : true,
  ],
  roomNameRules: [
    (val: string | undefined | null): string | boolean =>
      (val === undefined || val === null || val === '')
        ? '部屋名を入力してください。' : true,
  ],
  roomRelationsRoomGateRules: [
    (val: string[] | undefined | null): string | boolean =>
      (val === undefined || val === null || val.length === 0)
        ? '1つ以上のゲートを設定してください。' : true,
  ],
};
