













































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import Searchbox from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import { DataTableHeader } from 'vuetify';
import { CardDetail } from '@/types/alligate';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import moment from 'moment';

export interface TypeItem {
  title: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  idSelected: any;
  showOption: boolean;
  isVaildOnly: boolean;
  isShow: boolean;
  isShowUserDetail: boolean;
  getData: any;
  checkAll: boolean;
  // checkedColumnNames: any;
}

export default Vue.extend({
  name: 'UsersListTable',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
    TextLink,
  },
  watch: {
    isFinishProcessEnable(value) {
      if (value) {
        this.idSelected = [];
      }
    },
    selectedDisplayOption(value) {
      localStorage.setItem('displayOptionPCode', JSON.stringify(value));
    },
    loading(val) {
      if (val === false ) {
        this.getData = this.dataList;
        this.getData.map((o: { disable: boolean; }) => o.disable = false);
        this.getData.forEach((element: any, key: any) => {
          const check = this.relationsGateGroup.some((item: any) => item.gateId === element.gateId);
          if (check) {
            this.getData[key].disable = true;
          } else {
            this.checkAll = true;
          }
        });
        this.idSelected = this.selectedIds;
      }
    },
    idSelected(val: any) {
      this.$emit('select-Ids', val.map((item: any) => item));
    },
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    isPage: {
      type: String,
      default: '',
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataList: {
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    isFinishProcessEnable: {
      type: Boolean,
      default: false,
    },
    relationsGateGroup: {
      type: Array,
      default: () => [],
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    if (typeof localStorage.displayOptionPCode === 'undefined') {
      localStorage.setItem('displayOptionPCode', JSON.stringify(this.selectedDisplayOption));
    } else {
      this.selectedDisplayOption = JSON.parse(localStorage.displayOptionPCode);
    }
    if (this.isPage === 'group-add-gate') {
      this.selectedDisplayOption = [
        'gateId',
        'name',
        'groupCount',
      ] ;
    }
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'ゲートID',
            align: 'start',
            sortable: true,
            value: 'gateId',
          },
        ];
        headers.push({
            text: 'ゲート名',
            align: 'start',
            sortable: true,
            value: 'gateName',
          });
        if (this.selectedDisplayOption.includes('groupCount') && this.isPage === 'group-add-gate') {
          headers.push({
            text: '通行権限グループ',
            align: 'start',
            sortable: true,
            value: 'groupCount',
          });
        }
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  data: (): DataType => ({
    isShow: false,
    isShowUserDetail: false,
    selectedDisplayOption: [
      'gateId',
      'name',
    ],
    displayOptionItems: [
      { label: 'ゲート名', value: 'groupId', isDisabled: true },
      { label: 'ゲート名', value: 'name', isDisabled: false },
    ],
    idSelected: [],
    showOption: false,
    isVaildOnly: localStorage.isVaildDataCard === 'true' ? true : false,
    getData: [],
    checkAll: false,
  }),
  methods: {
    CheckColumn(data: any) {
      localStorage.setItem('displayOptionPCode', JSON.stringify(data));
    },
    convertDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm');
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    checkItem(data: any) {
      return data.filter((item: any) => {
          return (!item.disable);
        });
    },
  },
});
