











































import Vue, { PropType } from 'vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import InputForm, { SelectItem } from '@/components/molecules/InputForm/InputForm.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import ErrorButton from '@/components/molecules/ErrorButton/ErrorButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import IconButton from '@/components/molecules/IconButton/IconButton.vue';
import { FaceImageFile } from '@/types/alligate-zenescan';

interface DataType {
  isClickExpandArea: boolean;
  isDisplayDeleteConfirm: boolean;
}

interface SelectValues {
  text: string;
  value: string;
}

export default Vue.extend({
  name: 'FaceImageInputVerification',
  components: {
    Modal,
    Loading,
    InputForm,
    SuccessButton,
    ErrorButton,
    CancelButton,
    IconButton,
  },
  data: (): DataType => {
    return {
      isClickExpandArea: false,
      isDisplayDeleteConfirm: false,
    };
  },
  props: {
    isVisible: {
      type: Boolean,
      default: () => false,
    },
    isLoadBlockingVerification: {
      type: Boolean,
      default: () => true,
    },
    loadBlockingTextVerification: {
      type: String,
      default: () => '',
    },
    verificationTitle: {
      type: String,
      default: () => '',
    },
    verificationMessage: {
      type: String,
      default: () => '',
    },
    verificationMessage2: {
      type: String,
      default: () => '',
    },
    verificationMessage3: {
      type: String,
      default: () => '',
    },
    value: {
      type: Array as PropType<FaceImageFile[]>,
      default: () => [],
    },
  },
  methods: {
    close(): void {
      this.$emit('reset-msg');
      this.$emit('close');
      return;
    },
    clickVerificationSave(): void {
      this.$emit('click-verification-save', this.value);
    },
  },
});
