

















































































































































































import Vue, { PropType } from 'vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import GateList from '@/components/organisms/GateList/GateList.vue';
import * as Config from '@/config';
import { Group, Gate, RelationGateGroup } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';

export interface DataType {
  selectedItem: [];
  loadGroups: any;
  showConfirm: boolean;
  groupPassage: any;
  groupItem: any;
}

export default Vue.extend({
  name: 'GroupGateTable',
  components: {
    Pagination,
    ShowPerPage,
    TextLink,
    GateList,
  },
  props: {
    isClear: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    groupTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 0,
    },
    pageGroup: {
      type: Number,
      default: 0,
    },
    userId: {
      type: String,
      default: '',
    },
    ExpandedGroup: {
      type: Array,
      default: () => [],
    },
    isPage: {
      type: String,
      default: '',
    },
    messageSetting: {
      default: () => ({
        error: '',
        success: '',
      }),
    },
    authority: {
        type: Object,
        default: ({}),
      },
  },
  data: (): DataType => ({
    groupPassage: [],
    selectedItem: [],
    loadGroups: [],
    showConfirm: false,
    groupItem: '',
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'ゲート名',
            align: 'start',
            sortable: false,
            width: '33.3%',
            value: 'gateName',
          },
          {
            text: '適用されている通行権限グループ',
            align: 'start',
            sortable: false,
            width: '33.3%',
            value: 'groupName',
          },
          {
            text: '通行可能時間帯',
            align: 'start',
            sortable: false,
            width: '33.3%',
            value: 'passableTimeSettingName',
          },
        ];
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.pageGroup;
      },
      set(val: number): void {
        this.$emit('change-page-group', {page: val, userId: this.userId, change: 'group' });
        return;
      },
    },
  },
  watch: {
    selectedItem(val: []) {
      console.log('val', val);
      if (this.isPage === 'add-user-group-gate') {
        this.$emit('select-Ids', val.map((item: any) => item.groupId));
      } else {
        this.$emit('select-Ids', val.map((item: any) => item.groupPassageId));
      }
    },
    isClear(val: any) {
      if (val === false) {
        this.selectedItem = [];
      }
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    clickDetail(ID: any) {
      console.log('this.$route.path', this.$route.path);
      if (this.$route.path === '/groups') {
        this.$router.push({ path: Config.GROUP, query: {groupId: ID}});
        this.$emit('close-popup', ID);
      } else {
        this.$router.push({ path: Config.GROUP, query: {groupId: ID}});
      }
    },
    addGroupPass(item: any) {
      this.showConfirm = true;
      this.groupPassage.push({
        text: item.group.name,
        value: item.group.groupId,
      });
    },
    checkPassableTimeSetting(item: any) {
      if (item.passableTimeSetting !== undefined && item.passableTimeSetting.timeSettings !== undefined
      && item.passableTimeSetting.timeSettings !== null && item.passableTimeSetting.timeSettings.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
});
