var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-list-template" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "btn-round", attrs: { justify: "end" } },
            [
              _c(
                "v-col",
                { staticClass: "btn-add pt-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-secondary float-left",
                      attrs: { outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.clickBackGate(_vm.goBack)
                        }
                      }
                    },
                    [_vm._v("\n        戻る\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-primary",
                      attrs: {
                        outlined: "",
                        disabled: _vm.selectedGateIds.length === 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("clickConfirm", _vm.selectedGateIds)
                        }
                      }
                    },
                    [_vm._v("\n          関連付け追加\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("gate-group-list-table", {
            attrs: {
              gateTotalCount: _vm.gateTotalCount,
              dataGates: _vm.dataGates,
              page: _vm.page,
              perPage: _vm.perPage,
              loading: _vm.loading,
              successMessageLogs: _vm.successMessageLogs,
              errorMessageLogs: _vm.errorMessageLogs,
              gateOfGroup: _vm.gateOfGroup
            },
            on: {
              "change-per-page": function($event) {
                return _vm.$emit("changePerPage", $event)
              },
              "change-page": function($event) {
                return _vm.$emit("changePage", $event)
              },
              "select-gates": function(gateIds) {
                _vm.selectedGateIds = gateIds
              }
            }
          }),
          _vm._v(" "),
          _c("Modal", {
            attrs: {
              title: "ゲートの関連付けを追加",
              text:
                _vm.selectedGateIds.length === 0
                  ? "少なくてもゲートを一つ選んでください。"
                  : "チェックしたゲートを追加しますか？",
              smallScreen: "600",
              className: "common-modal"
            },
            scopedSlots: _vm._u([
              {
                key: "modal-footer",
                fn: function() {
                  return [
                    _c("v-spacer"),
                    _vm._v(" "),
                    _vm.selectedGateIds.length > 0
                      ? _c("cancel-button", {
                          attrs: { text: "キャンセル" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("closeDialog")
                            }
                          }
                        })
                      : _c("cancel-button", {
                          attrs: { text: "OK" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("closeDialog")
                            }
                          }
                        }),
                    _vm._v(" "),
                    _vm.selectedGateIds.length > 0
                      ? _c("success-button", {
                          attrs: { text: "OK" },
                          on: {
                            click: function($event) {
                              return _vm.$emit(
                                "clickAddGate",
                                _vm.selectedGateIds
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.showValid,
              callback: function($$v) {
                _vm.showValid = $$v
              },
              expression: "showValid"
            }
          }),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { width: "600", persistent: "" },
              model: {
                value: _vm.showProgress,
                callback: function($$v) {
                  _vm.showProgress = $$v
                },
                expression: "showProgress"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "background-color-green" },
                    [_vm._v("ゲートの関連付けを追加")]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-row", {
                    staticStyle: { height: "5px" },
                    attrs: { "no-gutters": "" }
                  }),
                  _vm._v(" "),
                  _vm.totalDataRowProcess > 0
                    ? _c(
                        "v-card-text",
                        { staticClass: "pa-4" },
                        [
                          _c(
                            "v-progress-linear",
                            {
                              attrs: {
                                height: "25",
                                color: "green darken-1",
                                striped: ""
                              },
                              model: {
                                value: _vm.progress,
                                callback: function($$v) {
                                  _vm.progress = $$v
                                },
                                expression: "progress"
                              }
                            },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(Math.round(_vm.progress)) + "%")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm.successItemProcess > 0
                            ? _c("span", { staticClass: "success-label" }, [
                                _vm._v(
                                  _vm._s(_vm.totalDataRowProcess) +
                                    "件中" +
                                    _vm._s(_vm.successItemProcess) +
                                    "件を追加しました。"
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errorMessageProcess.length > 0
                            ? _c("div", [
                                _c("span", { staticClass: "error-box-title" }, [
                                  _vm._v("追加失敗")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "error-box" },
                                  [
                                    _vm._l(_vm.errorMessageProcess, function(
                                      item
                                    ) {
                                      return [
                                        _c("p", {
                                          key: item,
                                          domProps: { innerHTML: _vm._s(item) }
                                        })
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFinishProcess
                    ? _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-row",
                            { staticClass: "ma-2", attrs: { justify: "end" } },
                            [
                              _c("success-button", {
                                attrs: { text: "OK" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("closeModalProgress")
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }