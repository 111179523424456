var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-title",
    { class: _vm.className },
    [
      _vm._t("default", [
        _c(
          "v-row",
          { attrs: { "no-gutters": "", justify: "space-between" } },
          [
            _vm._v(_vm._s(_vm.title) + "\n        "),
            _c(
              "v-icon",
              {
                attrs: { color: "white" },
                on: {
                  click: function($event) {
                    return _vm.$emit("click")
                  }
                }
              },
              [_vm._v("mdi-close")]
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }