var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-reflection-status-table" },
    [
      _vm.errorMessageLogs !== ""
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _vm.errorMessageLogs !== ""
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "mt-5 mb-4 font-weight-bold mt-1 bg-error"
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.errorMessageLogs) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "", justify: "end", "align-content": "end" } },
        [
          _c("v-col", [
            _vm.controllerName !== ""
              ? _c("p", { staticClass: "controllerName" }, [
                  _vm._v("制御盤: " + _vm._s(_vm.controllerName))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticStyle: { "text-align": "end" }, attrs: { justify: "end" } },
            [
              _c("show-per-page", {
                staticClass: "ml-4 mt-5 mr-2",
                attrs: {
                  "per-page": _vm.perPage,
                  page: _vm.page,
                  "items-length": _vm.statusTotalCount
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "border-table",
        attrs: {
          "must-sort": "",
          "custom-sort": _vm.customSort,
          headers: _vm.headers,
          headerChild: _vm.headerChild,
          items: _vm.dataCommands,
          "items-per-page": _vm.perPage,
          "item-key": "commandId",
          "no-data-text": "データーはありません",
          "hide-default-footer": ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.status",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.status === 0
                    ? _c("span", [_vm._v("未送信")])
                    : item.status === 1
                    ? _c("span", [_vm._v("送信済み")])
                    : item.status === 2
                    ? _c("span", [_vm._v("成功")])
                    : item.status === 3
                    ? _c("span", [_vm._v("失敗")])
                    : item.status === 4
                    ? _c("span", [_vm._v("キャンセル")])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.registered",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n    " +
                      _vm._s(_vm.converDateShort(item.registered)) +
                      "\n  "
                  )
                ]
              }
            },
            {
              key: "item.sendAt",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n    " + _vm._s(_vm.converDateShort(item.sendAt)) + "\n  "
                  )
                ]
              }
            },
            {
              key: "expanded-item",
              fn: function(ref) {
                var headers = ref.headers
                var item = ref.item
                return [
                  _c(
                    "td",
                    { attrs: { colspan: headers.length } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-container",
                            [
                              _c("p", { staticClass: "title-expand" }, [
                                _vm._v("追加予定の個人コード一覧")
                              ]),
                              _vm._v(" "),
                              _c("v-data-table", {
                                staticClass: "border-table",
                                attrs: {
                                  "must-sort": "",
                                  headers: _vm.headerChild,
                                  items: item.items,
                                  "no-data-text": "データーはありません",
                                  "hide-default-footer": "",
                                  dense: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectedItem,
          callback: function($$v) {
            _vm.selectedItem = $$v
          },
          expression: "selectedItem"
        }
      }),
      _vm._v(" "),
      _c("v-row", {
        staticStyle: { height: "20px" },
        attrs: { "no-gutters": "" }
      }),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-3",
          attrs: { "no-gutters": "", justify: "space-between" }
        },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "mr-5 mb-2" },
            [
              _c("select-per-page", {
                attrs: {
                  page: _vm.page,
                  "per-page-items": [25, 50, 100, 1000],
                  "items-length": _vm.statusTotalCount,
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.innerPerPage,
                  callback: function($$v) {
                    _vm.innerPerPage = $$v
                  },
                  expression: "innerPerPage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            staticClass: "mb-1",
            attrs: {
              "page-length": Math.ceil(_vm.statusTotalCount / _vm.perPage),
              disabled: _vm.loading
            },
            model: {
              value: _vm.innerPage,
              callback: function($$v) {
                _vm.innerPage = $$v
              },
              expression: "innerPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }