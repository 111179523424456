var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "in-room-modal-page" },
    [
      _c("in-room-modal-template", {
        attrs: {
          "all-gates": _vm.allGates,
          "room-info": _vm.roomInfo,
          "success-messages": _vm.successMessages,
          "error-messages": _vm.errorMessages,
          "room-info-loading": _vm.roomInfoLoading,
          authority: _vm.authority
        },
        on: {
          "click-edit-room": function($event) {
            return _vm.$emit("click-edit-room", $event)
          },
          "close-look-room": function($event) {
            return _vm.$emit("close-look-room")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }