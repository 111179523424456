





















































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import Searchbox from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import AffiliationListTable from '@/components/organisms/UsersAllowedTable/AffiliationListTable.vue';
import { DataTableHeader } from 'vuetify';
import { CardDetail } from '@/types/alligate';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import AffiliationListModalPage from '@/components/pages/aff-modal/AffiliationListModalPage.vue';
import ModalTitle from '@/components/molecules/ModalTitle/ModalTitle.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import moment from 'moment';

export interface TypeItem {
  title: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  cardSelected: any;
  showOption: boolean;
  isVaildOnly: boolean;
  isShowAff: boolean;
  showProgress: boolean;
  type: string;
  isConfirm: boolean;
  isShow: boolean;
  isReloadData: boolean;
  department: any;
  // checkedColumnNames: any;
}

export default Vue.extend({
  name: 'AffiliationAllowedTable',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
    TextLink,
    AffiliationListTable,
    AffiliationListModalPage,
    ModalTitle,
    Modal,
  },
  watch: {
    cardSelected(val: any) {
      this.$emit('select-aff', val.map((item: any) => item.relationId));
    },
    isFinishProcessEnable(value) {
      if (value) {
        this.cardSelected = [];
      }
    },
    selectedDisplayOption(value) {
      localStorage.setItem('displayOptionPCode', JSON.stringify(value));
    },
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    cardTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 100,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingAff: {
      type: Boolean,
      default: false,
    },
    dataList: {
      default: () => [],
    },
    departments: {
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    isFinishProcessEnable: {
      type: Boolean,
      default: false,
    },
    dataRelationDepartmentGroup: {
      type: Object,
      default: () => ({}),
    },
    authority: {
      type: Object,
      default: ({}),
    },
    optionDepartments: {
      type: Object,
      default: ({}),
    },
    isAdminOfGroupPassages: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (typeof localStorage.displayOptionPCode === 'undefined') {
      localStorage.setItem('displayOptionPCode', JSON.stringify(this.selectedDisplayOption));
    } else {
      this.selectedDisplayOption = JSON.parse(localStorage.displayOptionPCode);
    }
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '所属名',
            align: 'start',
            sortable: true,
            value: 'name',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  data: (): DataType => ({
    type: 'add',
    selectedDisplayOption: [
      'name',
    ],
    displayOptionItems: [
    ],
    cardSelected: [],
    showOption: false,
    isVaildOnly: localStorage.isVaildDataCard === 'true' ? true : false,
    isShowAff: false,
    showProgress: false,
    isConfirm: false,
    isShow: false,
    isReloadData: false,
    department: {},
  }),
  methods: {
    addAff(data: any) {
      this.type = 'add';
      this.showProgress = true;
      this.$emit('add-aff-to-group', data);
    },
    removeAff(data: any) {
      this.type = 'del';
      this.showProgress = true;
      this.isConfirm = false;
      this.$emit('remove-aff-to-group', data);
    },
    loadShowAff() {
      this.type = 'add';
      this.isShowAff = true;
      this.$emit('load-list-aff', 1);
    },
    CheckColumn(data: any) {
      localStorage.setItem('displayOptionPCode', JSON.stringify(data));
    },
    convertDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm');
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    loadDetail(val: any) {
      this.department = val;
      this.isShow = true;
      this.isReloadData = false;
    },
    closeModal(val: any) {
      if (val) {
        this.$emit('close-modal');
      }
    },
    closeModalAdd(val: any) {
      if (val) {
        this.$emit('close-modal-add');
      }
    },
    closeModalAff(val: any) {
      this.isShow = false;
      if (val) {
        this.$emit('load-aff-again');
      }
    },
    getReload(val: any) {
      this.isReloadData = val;
    },
  },
});
