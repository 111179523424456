





































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import { DataTableHeader } from 'vuetify';
export interface TypeItem {
  title: string;
}
export interface DataType {
  isShowModal: boolean;
  files: any;
}
export default Vue.extend({
  name: 'RegisterLoginUserTemplate',
  components: {
    CancelButton,
    SuccessButton,
    InputForm,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    showModalImport: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    progressWidth() {
      return 'width:' + this.progress + '%';
    },
  },
  data: (): DataType => ({
    isShowModal: false,
    files: [],
  }),
  watch: {
    message() {
      if (this.isShowModal) {
        this.isShowModal = false;
      }
    },
  },
  methods: {
    showModal(relationId: string) {
      this.isShowModal = true;
    },
    previewFiles(event: any) {
      console.log(event.target.files[0]);
      this.files = event.target.files[0];
    },
  },
});
