var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("group-list-template", {
    on: {
      "loading-from-child": function($event) {
        return _vm.$emit("loading", $event)
      },
      loadingCircle: function($event) {
        return _vm.$emit("loadingCircle", $event)
      },
      textCircle: function($event) {
        return _vm.$emit("textCircle", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }