
















































































































































import { Vue } from 'vue-property-decorator';
import SettingTemplate from '@/components/templates/SettingTemplate/SettingTemplate.vue';
import {LoginService, NoticeSettingType, NoticeSetting, SettingInfo, PCode, Gate, User, ConfigLog } from '@/types/alligate';
import * as Config from '@/config';
import { getNoticeSettingType } from '@/utils/alligate/notice-setting-type';
import { getAllNoticeSettings, deleteNoticeSetting, postNoticeSetting, putNoticeSetting } from '@/utils/alligate/notice-setting';
import { getAllUsers } from '@/utils/alligate/user';
import { getAllPcodes } from '@/utils/alligate/pcode';
import { getAllGates } from '@/utils/alligate/gate';
import { getAllConfigLogs } from '@/utils/alligate/config-log';
import moment from 'moment';
const currentTime = new Date();
const currentYear = new Date().getFullYear().toString();

export interface DataType {
  // PassableTime
  passableTotalCount: number;
  dataPassables: any;
  pagePassable: number;
  dataPassableDetail: any;
  isEdit: number;
  perPagePassable: number;
  currPagePassable: number;
  scorpPassable: any;
  keywordPassable: string;
  groupListAdd: any;
  groupListEdit: any;
  groupListByPassableTime: any;
  dayTypes: any;
  arrayTime: any;
  errorMessageLogsModal: string;
  successMessageLogsModal: string;
  // NFC - Phase 2
  ownerId: string;
  blockCount: boolean;
  ownerInfor: any;
  message_success: string;
  countReset: number;
  /* External Login Settings [外部ログイン設定]*/
  oneLoginLoginService: LoginService;
  appleIdLoginService: LoginService;
  googleLoginService: LoginService;
  SettingInfo: SettingInfo[];
  saveInfor: any;
  federatedLoginServices: [];
  OwnerFederatedLoginService: [];
  isSaveGoogle: boolean;
  isSaveOnelogin: boolean;
  imshowProgressExport: boolean;
  isSaveAppleId: boolean;
  showProgressImport: boolean;
  savingProgress: number;
  isSamlFederatedLoginService: string;
  isExternalLogin: string;
  cognitoIdProviderName: string;
  federatedLoginServiceId: string;
  isFederatedLoginService: string;
  pages: number;
  currPage: number;
  sucessStatus: boolean;
  userList: any;
  realtionsUserList: any;
  isDisabled: boolean;
  pageSizeList: number;
  relationOwnerFederatedLoginServiceId: string;
  maxRecord: number;
  perPage: number;
  keyword: string;
  exportData: any;
  dataLoad: number;
  progress: number;
  importData: any;
  totalDataRow: number;
  percent: number;
  exeCount: number;
  errorName: string;
  successItem: number;
  errorMessageExcel: any;
  success: boolean;
  showModalImport: boolean;
  errorMessage: string;
  errorMessageParams: any;
  // Global
  loading: boolean;
  isGetDataList: boolean;
  logLabelErr: string;
  errorMessageLogs: string;
  successMessageLogs: string;
  passableTimeSettingId: string;
  showConfirmDelete: boolean;
  showProgressExportCSV: boolean;
  isFinishProcess: boolean;
  isLoadingAdd: boolean;
  ExpandedGroup: any;
  pageGroup: number;
  maxGroup: number;
  loadingGroup: any;
  months: any;
  isCountGroup: number;
  selectYear: string;
  dayTypesCalendar: any;
  timeAdd: any;
  timeOrig: any;
  countDate: number;
  isSaveDate: number;
  calendarsLength: number;
  checkLoadPassDetail: number;
  countDateType: number;
  totalDateType: number;
  isFinishDate: boolean;
  saveFinish: boolean;
  loadingDate: boolean;
  arrayController: any;
  listDates: any;
  timeSettings: any;
  timeDefault: string;
  noticeSettingTypes: NoticeSettingType[];
  noticeSettings: NoticeSetting[];
  userFullList: User[];
  pcodeFullList: PCode[];
  gateFullList: Gate[];
  configLogFullList: ConfigLog[];
  isOneLoad: boolean;
  isLoadingNoticeSetting: boolean;
  isLoadBlockingNoticeSetting: boolean;
  isLoadBlockingNoticeSettingType: boolean;
  loadBlockingTextNoticeSetting: string;
  settingInfor: {
    type: number;
    isOwnerCodeValid: boolean;
    pCodeConfigType: number;
    ownerCodePosition1: number;
    ownerCodePosition2: number;
    ownerCodePosition3: number;
    ownerCodeLength1: number;
    ownerCodeLength2: number;
    ownerCodeLength3: number;
    readPosition1: number;
    readPosition2: number;
    readPosition3: number;
    readLength1: number;
    readLength2: number;
    readLength3: number;
    systemCode: string;
    serviceCode: string;
    blockCount: number;
    keyType: number;
    keyValue: string;
    sectorNumber: number;
    ownerCode: string;
    phonesBeValid: boolean;
    keyTokenRefreshable: boolean;
    keyTokenLifetime: number;
    keyTokenRefreshRate: number;
  };
  nfcSettingSelect: any;
  listNfcSettingSelect: any;
  fistTime: boolean;
  editStatus: boolean;
  isFelica: boolean;
  isMifare: boolean;
  editStatusException: boolean;
  listConfifLog: any;
  isEditLogLabel: boolean;
  dataEditLogLabel: any;
}
export default Vue.extend({
  components: {
    SettingTemplate,
  },
  data: (): DataType => ({
    /* Passable Time Settings [通行可能時間設定]*/
    passableTotalCount: 0,
    dataPassables: [],
    pagePassable: 0,
    dataPassableDetail: [],
    isEdit: 0,
    perPagePassable: Config.PAGE_DEFAULT,
    currPagePassable: 1,
    scorpPassable: ['passableTimeSettingId'],
    keywordPassable: '',
    groupListAdd: [],
    groupListEdit: [],
    arrayController: [],
    dayTypes: [],
    groupListByPassableTime: [],
    arrayTime: [],
    // NFC - Phase 2
    ownerId: '',
    blockCount: false,
    ownerInfor: [],
    timeSettings: [],
    message_success: '',
    countReset: 0,
    countDateType: 0,
    totalDateType: 0,
    /* External Login Settings [外部ログイン設定]*/
    googleLoginService: {
      relationId: '',
      federatedLoginServiceId: '',
      oldMetadataUrl: '',
      metadataUrl: '',
      isValid: false,
      name: 'Google',
      oldIsValid: false,
      isSaml: false,
      isResponse: false,
    },
    oneLoginLoginService: {
      relationId: '',
      federatedLoginServiceId: '',
      metadataUrl: '',
      oldMetadataUrl: '',
      isValid: false,
      name: 'SAML',
      oldIsValid: false,
      isSaml: false,
      isResponse: false,
    },
    appleIdLoginService: {
      relationId: '',
      federatedLoginServiceId: '',
      oldMetadataUrl: '',
      metadataUrl: '',
      isValid: false,
      name: 'Apple',
      oldIsValid: false,
      isSaml: false,
      isResponse: false,
    },
    SettingInfo: [],
    saveInfor: [],
    loading: false,
    logLabelErr: '',
    errorMessageLogs: '',
    federatedLoginServices: [],
    OwnerFederatedLoginService: [],
    isSaveGoogle: true,
    isSaveOnelogin: true,
    isSaveAppleId: true,
    savingProgress: 0,
    successMessageLogs: '',
    isSamlFederatedLoginService: 'false',
    isExternalLogin: '',
    cognitoIdProviderName: '',
    federatedLoginServiceId: '',
    isFederatedLoginService: 'false',
    pages: 0,
    currPage: 1,
    sucessStatus: false,
    imshowProgressExport: false,
    showProgressImport: false,
    isDisabled: true,
    userList: [],
    realtionsUserList: [],
    pageSizeList: 25,
    relationOwnerFederatedLoginServiceId: '',
    maxRecord: 0,
    perPage: Config.PAGE_DEFAULT,
    keyword: '',
    exportData: [],
    dataLoad: 0,
    progress: 0,
    importData: [],
    totalDataRow: 0,
    percent: 0,
    exeCount: 0,
    errorName: '',
    successItem: 0,
    errorMessageExcel: [],
    showModalImport: false,
    success: false,
    showConfirmDelete: false,
    isGetDataList: false,
    passableTimeSettingId: '',
    errorMessage: '',
    errorMessageParams: {
      email: '',
      userId: '',
    },
    errorMessageLogsModal: '',
    successMessageLogsModal: '',
    isFinishProcess: false,
    showProgressExportCSV: false,
    ExpandedGroup: [],
    dayTypesCalendar: [],
    pageGroup: 1,
    maxGroup: 0,
    loadingGroup: true,
    isCountGroup: 0,
    selectYear: currentYear,
    months: [],
    timeAdd: [],
    timeOrig: [],
    isLoadingAdd: false,
    countDate: 0,
    calendarsLength: 0,
    isSaveDate: 0,
    isFinishDate: false,
    saveFinish: false,
    loadingDate: false,
    listDates: [],
    timeDefault: '',
    checkLoadPassDetail: 0,
    noticeSettingTypes: [],
    noticeSettings: [],
    userFullList: [],
    pcodeFullList: [],
    gateFullList: [],
    configLogFullList: [],
    /** noticeSettingなどでユーザ一覧取得するのに時間がかかりすぎるので再ロードしないように */
    isOneLoad: false,
    /** 画面操作を防ぐ必要のないローディング用 */
    isLoadingNoticeSetting: false,
    /** 画面操作を防ぐロードブロッキング用 */
    isLoadBlockingNoticeSetting: false,
    isLoadBlockingNoticeSettingType: false,
    /** 画面操作を防ぐロードブロッキング中に表示するテキスト */
    loadBlockingTextNoticeSetting: '',
    settingInfor: {
      type: 0,
      isOwnerCodeValid: true,
      pCodeConfigType: 0,
      ownerCodePosition1: 0,
      ownerCodePosition2: 0,
      ownerCodePosition3: 0,
      ownerCodeLength1: 0,
      ownerCodeLength2: 0,
      ownerCodeLength3: 0,
      readPosition1: 0,
      readPosition2: 0,
      readPosition3: 0,
      readLength1: 0,
      readLength2: 0,
      readLength3: 0,
      systemCode: '',
      serviceCode: '',
      blockCount: 0,
      keyType: 0,
      keyValue: '',
      sectorNumber: 0,
      ownerCode: '',
      phonesBeValid: false,
      keyTokenRefreshable: false,
      keyTokenLifetime: 60,
      keyTokenRefreshRate: 30,
    },
    nfcSettingSelect: {},
    listNfcSettingSelect: [],
    fistTime: true,
    editStatus: true,
    isFelica: true,
    isMifare: true,
    editStatusException: true,
    listConfifLog: [],
    isEditLogLabel: false,
    dataEditLogLabel: {},
  }),
  created() {
    // this.getOwnerInfor(); // PHASE 2
    /* Passable Time Settings [通行可能時間設定]*/
    /* External Login Settings [外部ログイン設定]*/
    const p: any = this.$route.query.p;
    if (p !== '' && p !== undefined && p !== null) {
      localStorage.setItem('isExternalLogin', p);
    } else {
      localStorage.setItem('isExternalLogin', 'setting');
    }
    if (typeof localStorage.passableSortKey === 'undefined') {
      localStorage.setItem('passableSortKey', 'registered');
    }
    if (typeof localStorage.passableSortName === 'undefined') {
      localStorage.setItem('passableSortName', 'desc');
    }
    if (typeof localStorage.pageSizePassable === 'undefined') {
      localStorage.setItem('pageSizePassable', Config.PAGE_DEFAULT.toString());
      this.perPagePassable = Config.PAGE_DEFAULT;
    } else {
      this.perPagePassable = parseInt(localStorage.pageSizePassable);
    }
    this.isGetDataList = true;
    this.getListPassableTimeSettings()
    .then(() => this.getGroupCount());
    this.$emit('activeMenu', 'setting');
  },
  watch: {
    checkLoadPassDetail(value) {
      if (value === 1) {
        this.getDayTypesDetail(1);
      }
    },
    countDate(value) {
      if (value > 0 &&  value >= this.calendarsLength) {
        this.isFinishDate = true;
        this.isSaveDate++;
        this.loading = false;
      }
    },
    countDateType(value) {
      if (value > 0 &&  value >= this.totalDateType) {
        this.loadingDate = false;
      }
    },
    savingProgress(value) {
      if (value >= 3) {
        this.loading = false;
      }
    },
    loading(value) {
      this.$emit('loading', value);
    },
    exeCount(newVal, oldVal) {
      const vm = this;
      if (newVal > 0 && newVal < vm.totalDataRow) {
        vm.addExe(vm.importData[newVal][0], vm.importData[newVal][1],
        vm.importData[newVal][2], (newVal + 2), vm.percent);
      } else if (newVal > 0 && newVal === vm.totalDataRow) {
        this.exeCount = 0;
        this.success = true;
        this.importData = [];
      }
    },
    isCountGroup(val) {
      if (val > 0 && val >= this.dataPassables.length) {
        this.isGetDataList = false;
      }
    },
  },
  mounted() {
    this.resetMessage();
    const vm = this;
    // 外部ログインサービス利用設定
    vm.isExternalLogin = localStorage.isExternalLogin;
    vm.cognitoIdProviderName = localStorage.cognitoIdProviderName;
    vm.federatedLoginServiceId = localStorage.federatedLoginServiceId;
    if (vm.isExternalLogin === 'setting') {
      this.getPlan()
        .then(() => {
          vm.isFederatedLoginService = localStorage.isFederatedLoginService;
          vm.isSamlFederatedLoginService = localStorage.isSamlFederatedLoginService;
          this.loading = false;
        });
    } else if (vm.isExternalLogin === 'registration') {
      this.loading = true;
      this.getUsers(1);
      this.getPlan()
        .then(() => this.getOwnerFederatedLoginService())
        .then(() => this.getFederatedLoginRelation())
        .then(() => {
          vm.isFederatedLoginService = localStorage.isFederatedLoginService;
          vm.isSamlFederatedLoginService = localStorage.isSamlFederatedLoginService;
          this.loading = false;
        });
    } else {
      this.getPlan()
        .then(() => this.getOwnerFederatedLoginService())
        .then(() => {
          vm.isFederatedLoginService = localStorage.isFederatedLoginService;
          vm.isSamlFederatedLoginService = localStorage.isSamlFederatedLoginService;
          const ID = vm.getFederatedLoginServiceRelationId(vm.federatedLoginServiceId);
          vm.relationOwnerFederatedLoginServiceId = ID;
          this.loading = false;
        });
    }

    if (typeof localStorage.pageSizeLogConfig === 'undefined') {
      localStorage.setItem('pageSizeLogConfig', '100');
      this.perPage = 100;
    } else {
      this.perPage = parseInt(localStorage.pageSizeLogConfig);
    }
    if (typeof localStorage.configLogSortKey === 'undefined') {
      localStorage.setItem('configLogSortKey', 'accessLogId');
    }
    if (typeof localStorage.configLogSortName === 'undefined') {
      localStorage.setItem('configLogSortName', 'desc');
    }
    if (typeof localStorage.activeTab === 'undefined') {
      localStorage.setItem('activeTab', 'setting1');
    }

    this.getTypeInfo();
    this.getOwnerInfor();
  },
  methods: {
    getMonths() {
      const arrMonths = [];
      const d = new Date();
      for (let i = 0; i < 12; i++) {
        const getMonth = moment(d, 'YYYY-MM').add(i, 'months').format('YYYY-MM');
        const array = getMonth.split('-');
        const date = {
          month: array[1],
          year: array[0],
          value: array[0] + '-' + (array[1]).toString().padStart(2, '0'),
        };
        arrMonths.push(date);
      }
      this.months = [
      {
        month: arrMonths[0].month,
        compare: arrMonths[0].value,
        value: [arrMonths[0].value],
        calendarId: '',
        controllerId: '',
        dayTypeId: [],
      },
      {
        month: arrMonths[1].month,
        compare: arrMonths[1].value,
        value: [arrMonths[1].value],
        calendarId: '',
        controllerId: '',
        dayTypeId: [],
      },
      {
        month: arrMonths[2].month,
        compare: arrMonths[2].value,
        value: [arrMonths[2].value],
        calendarId: '',
        controllerId: '',
        dayTypeId: [],
      },
      {
        month: arrMonths[3].month,
        compare: arrMonths[3].value,
        value: [arrMonths[3].value],
        calendarId: '',
        controllerId: '',
        dayTypeId: [],
      },
      {
        month: arrMonths[4].month,
        compare: arrMonths[4].value,
        value: [arrMonths[4].value],
        calendarId: '',
        controllerId: '',
        dayTypeId: [],
      },
      {
        month: arrMonths[5].month,
        compare: arrMonths[5].value,
        value: [arrMonths[5].value],
        calendarId: '',
        controllerId: '',
        dayTypeId: [],
      },
      {
        month: arrMonths[6].month,
        compare: arrMonths[6].value,
        value: [arrMonths[6].value, arrMonths[6].value],
        calendarId: '',
        controllerId: '',
        dayTypeId: [],
      },
      {
        month: arrMonths[7].month,
        compare: arrMonths[7].value,
        value: [arrMonths[7].value],
        calendarId: '',
        controllerId: '',
        dayTypeId: [],
      },
      {
        month: arrMonths[8].month,
        compare: arrMonths[8].value,
        value: [arrMonths[8].value],
        calendarId: '',
        controllerId: '',
        dayTypeId: [],
      },
      {
        month: arrMonths[9].month,
        compare: arrMonths[9].value,
        value: [arrMonths[9].value],
        calendarId: '',
        controllerId: '',
        dayTypeId: [],
      },
      {
        month: arrMonths[10].month,
        compare: arrMonths[10].value,
        value: [arrMonths[10].value],
        calendarId: '',
        controllerId: '',
        dayTypeId: [],
      },
      {
        month: arrMonths[11].month,
        compare: arrMonths[11].value,
        value: [arrMonths[11].value],
        calendarId: '',
        controllerId: '',
        dayTypeId: [],
      },
    ];
    },
    formatTimestamp(t: string) {
      return t !== '' ? moment(t).unix() : '';
    },
    reset() {
      this.errorMessageLogs = '';
      this.successMessageLogs = '';
      this.errorMessageExcel = [];
      this.exportData = [];
      this.dataLoad = 0;
      this.loading = false;
      this.exeCount = 0;
      this.success = true;
      this.importData = [];
    },
    getDayTypeCalendar() {
      return new Promise((resolve, reject) => {
        const arrayDay = ['月曜', '火曜', '水曜', '木曜', '金曜', '土曜', '日曜'];
        this.$http.get(Config.DATE_TYPE + '?limit=1000&page=1&sortKey=asc:dayTypeId')
          .then((response: any) => {
            if (response.body.maxRecords > 0) {
              response.body.dayTypes.forEach((element: any) => {
                if (!arrayDay.includes(element.name)) {
                  this.dayTypesCalendar.push({text: element.name, value: element.dayTypeId});
                }
              });
            } else {
              this.dayTypes = null;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    initializeNoticeSetting(): void {
      if (this.isOneLoad === false) {
        this.isOneLoad = true;
        this.isLoadingNoticeSetting = true;
        this.isLoadBlockingNoticeSettingType = true;
        this.isLoadBlockingNoticeSetting = true;
        this.loadBlockingTextNoticeSetting = '通知設定の各種データ取得中';
        Promise.all([
          this.resetMsg(),
          getNoticeSettingType(this).then((noticeSettingTypes) => this.noticeSettingTypes = noticeSettingTypes)
            .then(() => this.isLoadBlockingNoticeSettingType = false),
          getAllNoticeSettings(this).then((noticeSettings) => this.noticeSettings = noticeSettings),
          getAllUsers(this).then((users) => this.userFullList = users),
          getAllPcodes(this).then((pcodes) => this.pcodeFullList = pcodes),
          getAllGates(this).then((gates) => this.gateFullList = gates),
          getAllConfigLogs(this).then((configLogs) => this.configLogFullList = configLogs),
        ]).then(() => {
          this.loadBlockingTextNoticeSetting = '';
          this.isLoadingNoticeSetting = false;
          this.isLoadBlockingNoticeSetting = false;
          this.isOneLoad = true;
        }).catch((err) => {
          this.loadBlockingTextNoticeSetting = '';
          this.isLoadingNoticeSetting = false;
          this.isLoadBlockingNoticeSetting = false;
          this.isOneLoad = false;
          this.errorMessageLogsModal = err.message;
        });
      }
      return ;
    },
    /** 通知設定済みのリストを更新 */
    async reloadNoticeSettingList() {
      this.isLoadingNoticeSetting = true;
      return Promise.all([
        getAllNoticeSettings(this).then((noticeSettings) => this.noticeSettings = noticeSettings),
      ])
      .then(() => {
        this.isLoadingNoticeSetting = false;
      })
      .catch((err) => {
        this.isLoadingNoticeSetting = false;
        this.errorMessageLogsModal = err.message;
      });
    },
    addNewPostNoticeSetting(noticeSetting: NoticeSetting): void {
      this.isLoadBlockingNoticeSetting = true;
      this.loadBlockingTextNoticeSetting = '通知設定の新規登録中';
      this.isLoadingNoticeSetting = true;
      this.resetMsg();
      postNoticeSetting(this, noticeSetting)
      .then((result: any) => {
        if (result.message === 'Success') {
          this.successMessageLogsModal = `${noticeSetting.name} ${Config.ADD_NOTICE_SETTING_API_SUCCESS}`;
        }
        this.loadBlockingTextNoticeSetting = '';
        this.isLoadBlockingNoticeSetting = false;
      })
      .then(() => this.reloadNoticeSettingList())
      .catch((err) => {
        this.errorMessageLogsModal = err.message;
        this.isLoadingNoticeSetting = false;
        this.isLoadBlockingNoticeSetting = false;
      });
    },
    editPutNoticeSetting(noticeSetting: NoticeSetting): void {
      this.isLoadBlockingNoticeSetting = true;
      this.isLoadingNoticeSetting = true;
      this.loadBlockingTextNoticeSetting = '通知設定の変更中';
      this.resetMsg();
      putNoticeSetting(this, noticeSetting.noticeSettingId, noticeSetting)
      .then((result: any) => {
        if (result.message === 'Success') {
          this.successMessageLogsModal = `${noticeSetting.name} ${Config.EDIT_NOTICE_SETTING_API_SUCCESS}`;
        }
        this.loadBlockingTextNoticeSetting = '';
        this.isLoadBlockingNoticeSetting = false;
      })
      .then(() => this.reloadNoticeSettingList())
      .catch((err) => {
        this.errorMessageLogsModal = err.message;
        this.isLoadingNoticeSetting = false;
        this.isLoadBlockingNoticeSetting = false;
      });
    },
    clickDeleteNoticeSetting(ID: any) {
      this.isLoadBlockingNoticeSetting = true;
      this.isLoadingNoticeSetting = true;
      this.loadBlockingTextNoticeSetting = '通知設定の削除中';
      this.resetMsg();
      deleteNoticeSetting(this, ID)
      .then((result) => {
        if (result.message === 'Success') {
          this.successMessageLogsModal = `${Config.DELETE_NOTICE_SETTING_API_SUCCESS}`;
        }
        this.loadBlockingTextNoticeSetting = '';
        this.isLoadBlockingNoticeSetting = false;
      })
      .then(() => this.reloadNoticeSettingList())
      .catch((err) => {
        this.errorMessageLogsModal = err.message;
        this.isLoadingNoticeSetting = false;
        this.isLoadBlockingNoticeSetting = false;
      });
    },
    getDayTypeDetail(ID: any, element: any, item: any) {
      this.$http.get(Config.DATE_TYPE + '?dayTypeId=' + encodeURIComponent(ID))
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            const date = element.date.split('-');
            const compare = date[0] + '-' + date[1];
            item.value.push(element.date);
            item.calendarId = element.calendarId;
            item.controllerId = element.controllerId;
            item.dayTypeId.push({date: element.date, dayTypeId: element.dayTypeId,
            dayTypeName: response.body.dayTypes[0].name});
            // item.dayTypeId = element.dayTypeId;
            this.timeOrig.push({
              inputDate: element.date,
              calendarId: element.calendarId,
              controllerId: element.controllerId,
              dayTypeId: element.dayTypeId,
            });
          }
          this.countDate++;
        })
        .then( null, (err: any) => {
          this.countDate++;
        }); // catch
    },
    async getCalendar(page: any) {
      const d = new Date();
      const currentMonth = moment(d, 'YYYY-MM').add(0, 'months').format('YYYY-MM');
      const nextMonth = moment(d, 'YYYY-MM').add(11, 'months').format('YYYY-MM');
      const arrayCurrentMonth = currentMonth.split('-');
      const arrayNextMonth = nextMonth.split('-');
      const notBefore = arrayCurrentMonth[0] + '-' + arrayCurrentMonth[1] + '-01';
      const notAfter = arrayNextMonth[0] + '-' + arrayNextMonth[1] + '-' +
      new Date(parseInt(arrayNextMonth[0]), parseInt(arrayNextMonth[1]), 0).getDate();
      await this.$http.get(Config.CALENDAR_LIST_API + '?limit=1000&page=' + page +
      '&notBefore=' + notBefore + '&notAfter=' + notAfter)
        .then(async (response: any) => {
          this.calendarsLength = response.body.maxRecords;
          if (response.body.maxRecords > 0) {
            const calendars = response.body.calendars;
            calendars.forEach((element: any, key: any) => {
              if (this.listDates[element.date] !== undefined) {
                const idx = this.listDates.filter((item: any) =>
                item.controllerId === element.controllerId && item.calendarId === element.calendarId);
                if (idx.length === 0) {
                  this.listDates[element.date].push({controllerId: element.controllerId,
                  calendarId: element.calendarId});
                }
              } else {
                this.$set(this.listDates, element.date, []);
                this.listDates[element.date].push({controllerId: element.controllerId, calendarId: element.calendarId});
                // this.listDates[element.date].push(element.controllerId);
              }
              const date = element.date.split('-');
              const compare = date[0] + '-' + date[1];
              const index  = this.months.map((e: { compare: any; }) => e.compare).indexOf(compare);
              if (index >= 0 && !this.months[index].value.includes(element.date)) {
                const i = this.dayTypesCalendar.map((e: { value: any; }) => e.value).indexOf(element.dayTypeId);
                const item = this.months[index];
                if (i >= 0) {
                  item.value.push(element.date);
                  item.calendarId = element.calendarId;
                  item.controllerId = element.controllerId;
                  item.dayTypeId.push({date: element.date, dayTypeId: element.dayTypeId,
                  dayTypeName: this.dayTypesCalendar[i].text, dayTypeIdDefault: element.dayTypeId,
                  dayTypeNameDefault: this.dayTypesCalendar[i].text});
                  this.timeOrig.push({
                    inputDate: element.date,
                    calendarId: element.calendarId,
                    controllerId: element.controllerId,
                    dayTypeId: element.dayTypeId,
                  });
                }
              }
              this.countDate++;
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getCalendar(page + 1);
            }
          } else {
            this.countDate++;
          }
        })
        .then(null, (error: any) => {
          switch (error.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (error.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.isFinishDate = true;
          this.loading = false;
          this.countDate++;
          return false;
        });
    },
    async isGetCalendar() {
      this.isFinishDate = false;
      this.loading = true;
      this.dayTypesCalendar = [
        {text: '設定なし', value: 'Del'},
      ];
      this.timeOrig = [];
      this.timeAdd = [];
      this.countDate = 0;
      this.calendarsLength = 0;
      // this.months = [];
      this.getMonths();
      await this.getDayTypeCalendar();
      this.listDates = [];
      this.getCalendar(1);

    },
    closeDate() {
      this.isFinishProcess = true;
      this.saveFinish = false;
      this.errorMessageLogsModal = '';
      this.successMessageLogsModal = '';
    },
    closeDateFinish() {
      this.errorMessageLogsModal = '';
      this.successMessageLogsModal = '';
      this.arrayController = [];
      this.saveFinish = false;
      // this.isGetCalendar();
    },
    setDateOne(itemDate: any) {
      const arr = Array.prototype.map.call(Object(this.months), (item) => {
        if (item.compare === itemDate.date.compare) {
          const index  = item.dayTypeId.map((e: { date: any; }) => e.date).indexOf(itemDate.inputDate);
          const i  = item.value.indexOf(itemDate.inputDate);
          if (i >= 0 ) {
            if (item.dayTypeId[index].dayTypeId === 'choose') {
              if (item.dayTypeId[index].dayTypeIdDefault === undefined) {
                item.dayTypeId.splice(parseInt(index), 1);
                if (i >= 0) {
                  item.value.splice(parseInt(i), 1);
                }
              } else {
                item.dayTypeId[index].dayTypeId = item.dayTypeId[index].dayTypeIdDefault;
                item.dayTypeId[index].dayTypeName = item.dayTypeId[index].dayTypeNameDefault;
                item.dayTypeId[index].action = '';
              }
              const t = this.timeAdd.map((e: { inputDate: any; }) => e.inputDate).indexOf(itemDate.inputDate);
              if (t >= 0) {
                this.timeAdd.splice(parseInt(t), 1);
              }
            } else {
              item.dayTypeId[index].dayTypeId = 'choose';
              item.dayTypeId[index].dayTypeName = 'choose';
              item.dayTypeId[index].action = 'change';
            }
          } else {
            item.value.push(itemDate.inputDate);
            item.dayTypeId.push({date: itemDate.inputDate, dayTypeId: 'choose', dayTypeName: 'choose',
            action: 'change'});
          }
        }
      });
      this.isSaveDate++;
    },
    setTypeDate(eventArgs: any) {
      const typeDate = eventArgs.typeDate;
      const arr = Array.prototype.map.call(Object(this.months), (item) => {
        item.dayTypeId.forEach((element: any, key: any) => {
          if (element.dayTypeId === 'choose') {
            element.dayTypeId = typeDate.value;
            element.dayTypeName = typeDate.text;
            const i  = this.timeAdd.map((e: { inputDate: any; }) => e.inputDate).indexOf(element.date);
            const index  = item.dayTypeId.map((e: { date: any; }) => e.date).indexOf(element.date);
            if (i >= 0) {
              this.timeAdd[i].dayTypeId = typeDate.value;
            } else {
              if (element.dayTypeId !== 'Del' ||
              (item.dayTypeId[index].dayTypeIdDefault !== undefined
              && item.dayTypeId[index].dayTypeNameDefault !== undefined)) {
                this.timeAdd.push({
                  inputDate: element.date,
                  calendarId: item.calendarId,
                  controllerId: item.controllerId,
                  dayTypeId: element.dayTypeId,
                });
              }
            }
          }
        });
      });
      this.isSaveDate++;
    },
    saveDate(eventArgs: any) {
      this.isLoadingAdd = true;
      const itemDate = eventArgs.itemDate;
      const arr = Array.prototype.map.call(Object(this.months), (item) => {
        if (item.compare === itemDate.date.compare) {
          const index  = this.timeAdd.map((e: { inputDate: any; }) => e.inputDate).indexOf(itemDate.inputDate);
          const indexO  = this.timeOrig.map((e: { inputDate: any; }) => e.inputDate).indexOf(itemDate.inputDate);
          const i  = item.value.indexOf(itemDate.inputDate);
          if (itemDate.dayTypeId === 'Del' && indexO < 0) {
            // not run
          } else if (itemDate.dayTypeId === 'Del' && indexO >= 0) {
            this.timeAdd.push({
              inputDate: itemDate.inputDate,
              calendarId: itemDate.date.calendarId,
              controllerId: itemDate.date.controllerId,
              dayTypeId: itemDate.dayTypeId,
            });
            if (i >= 0 ) {
              item.value.splice(i, 1);
            }
          } else {
            if (index >= 0 ) {
              this.timeAdd[index].dayTypeId = itemDate.dayTypeId;
              if (i < 0 ) {
                item.value.push(itemDate.inputDate);
              }
            } else {
              this.timeAdd.push({
                inputDate: itemDate.inputDate,
                calendarId: itemDate.date.calendarId,
                controllerId: itemDate.date.controllerId,
                dayTypeId: itemDate.dayTypeId,
              });
              item.value.push(itemDate.inputDate);
              item.dayTypeId.push({date: itemDate.inputDate, dayTypeId: 'New'});
            }
          }
        }
      });
      this.isLoadingAdd = false;
    },
    async saveAllDate(eventArgs: any) {
      this.loading = true;
      this.isFinishProcess = false;
      this.saveFinish = false;
      this.errorMessageLogsModal = '';
      this.successMessageLogsModal = '';
      const itemDate = this.timeAdd;
      const timeOrig = this.timeOrig;
      const postDate = itemDate.filter((o1: { inputDate: any; dayTypeId: any }) =>
        !timeOrig.some((o2: { inputDate: any; }) => o1.inputDate === o2.inputDate));
      const deleteDate = itemDate.filter((o1: { inputDate: any; dayTypeId: any }) =>
        timeOrig.some((o2: { inputDate: any; dayTypeId: any }) => o1.inputDate === o2.inputDate &&
        o1.dayTypeId.toString() !== o2.dayTypeId.toString() && o1.dayTypeId === 'Del'));
      const updateDate = itemDate.filter((o1: { inputDate: any; dayTypeId: any }) =>
        timeOrig.some((o2: { inputDate: any; dayTypeId: any }) => o1.inputDate === o2.inputDate  && o1.dayTypeId !== 'Del'));
      // get date delete and date update (update : delete + post)
      const deleteDateAll = deleteDate.concat(updateDate);
      let result;
      result = true;
      if (deleteDateAll.length > 0) {
        let controllerIds: string|any[];
        let calendarIds: string|any[];
        let controllerIdArray: any;
        controllerIds = [];
        calendarIds = [];
        controllerIdArray = [];
        // get all controllerId and calendarId of one day
        for (const item of deleteDateAll) {
          controllerIds = controllerIds.concat(this.listDates[item.inputDate]);
        }
        // remove duplicate
        const controllerIdsUniq = controllerIds.filter((v, i, a) => a.findIndex((t: any) => (t.calendarId ===
        v.calendarId && t.controllerId === v.controllerId )) === i);
        for (const item of controllerIdsUniq) {
          if (!controllerIdArray.includes(item.controllerId)) {
            controllerIdArray.push(item.controllerId);
          }
          if (calendarIds[item.controllerId] !== undefined) {
            if (!calendarIds[item.controllerId].includes(item.calendarId)) {
              calendarIds[item.controllerId].push(item.calendarId);
            }
          } else {
            this.$set(calendarIds, item.controllerId, []);
            calendarIds[item.controllerId].push(item.calendarId);
          }
        }
        for (const item of controllerIdArray) {
          const calendarIdsArray = calendarIds[item];
          result = await this.deleteDate(item, calendarIdsArray);
          if (result === false) {
            break;
          }
        }
      }
      if (updateDate.length > 0 && result === true) {
        let controllerIdArrayUpdate: any;
        controllerIdArrayUpdate = [];
        let calendarIdsUpdate: any;
        calendarIdsUpdate = [];
        for (const item of updateDate) {
          if (!controllerIdArrayUpdate.includes(item.controllerId)) {
            controllerIdArrayUpdate.push(item.controllerId);
          }
          if (calendarIdsUpdate[item.controllerId] !== undefined) {
            const index = calendarIdsUpdate[item.controllerId].filter((i: any) => i.date === item.date);
            if (index.length === 0) {
              calendarIdsUpdate[item.controllerId].push({
                date: item.inputDate,
                dayTypeId: item.dayTypeId,
              });
            }
          } else {
            this.$set(calendarIdsUpdate, item.controllerId, []);
            calendarIdsUpdate[item.controllerId].push({
              date: item.inputDate,
              dayTypeId: item.dayTypeId,
            });
          }
        }
        for (const item of controllerIdArrayUpdate) {
          const data = {
            controllerId: item,
            calendars : calendarIdsUpdate[item],
          };
          result = await this.addDate(data);
          if (result === false) {
            break;
          }
        }
        // const data = {
        //   dayTypeId : item.dayTypeId,
        // };
        // result = await this.updateDate(data, item.calendarId);
        // if (result === false) {
        //   break;
        // }
      }
      const calendarsData = [];
      if (postDate.length > 0 && result === true) {
        for (const item of postDate) {
          if (item.dayTypeId !== 'Del') {
            calendarsData.push({
              date : item.inputDate,
              dayTypeId : item.dayTypeId,
            });
          }
        }
        if (calendarsData.length > 0) {
          result = await this.addDateWithController(calendarsData);
        }
      }
      if (calendarsData.length === 0 && deleteDate.length === 0 && updateDate.length === 0) {
        this.errorMessageLogsModal = Config.ERROR_404_CALENDAR;
      }
      await this.isGetCalendar();
      this.saveFinish = true;
    },
    deleteDate(controllerIdData: any, calendarIdsData: any) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.CALENDAR_LIST_API, {body:
        {controllerId: controllerIdData, calendarIds: calendarIdsData}})
          .then((response: any) => {
            if (response.body.message === 'Success') {
              this.successMessageLogsModal = Config.CALENDAR_SUCCESS;
              resolve(true);
            } else {
              this.errorMessageLogsModal = Config.ERROR_DEFAULT;
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            switch (err.status) {
              case 400:
                this.errorMessageLogsModal = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogsModal = Config.ERROR_403;
                } else {
                  this.errorMessageLogsModal = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogsModal = Config.ERROR_404_CALENDAR;
                break;
              case 500:
                this.errorMessageLogsModal = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogsModal = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogsModal = Config.ERROR_DEFAULT;
                break;
            }
            resolve(false);
          });
      });
    },
    updateDate(data: any, ID: any) {
      return new Promise((resolve, reject) => {
        this.$http.put(Config.CALENDAR_LIST_API + '/' + encodeURIComponent(ID), data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              this.successMessageLogsModal = Config.CALENDAR_SUCCESS;
              resolve(true);
            } else {
              this.errorMessageLogsModal = Config.ERROR_DEFAULT;
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            switch (err.status) {
              case 400:
                this.errorMessageLogsModal = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogsModal = Config.ERROR_403;
                } else {
                  this.errorMessageLogsModal = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogsModal = Config.ERROR_404_CALENDAR;
                break;
              case 500:
                this.errorMessageLogsModal = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogsModal = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogsModal = Config.ERROR_DEFAULT;
                break;
            }
            resolve(false);
          });
      });
    },
    addDate(data: any) {
      return new Promise((resolve, reject) => {
        this.errorMessageLogsModal = '';
        this.successMessageLogsModal = '';
        this.$http.post(Config.CALENDAR_LIST_API, data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              this.successMessageLogsModal = Config.CALENDAR_SUCCESS;
              resolve(true);
            } else {
              this.errorMessageLogsModal = Config.ERROR_DEFAULT;
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            switch (err.status) {
              case 400:
                this.errorMessageLogsModal = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogsModal = Config.ERROR_403;
                } else {
                  this.errorMessageLogsModal = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogsModal = Config.ERROR_404_CALENDAR;
                break;
              case 409:
                this.errorMessageLogsModal = Config.ERROR_409_CALENDAR;
                break;
              case 500:
                this.errorMessageLogsModal = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogsModal = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogsModal = Config.ERROR_DEFAULT;
                break;
            }
            resolve(false);
          });
      });
    },
    searchLogin(eventArgs: any) {
      this.loading = true;
      if (eventArgs.text !== null) {
        this.keyword = eventArgs.text;
      } else {
        this.keyword = '';
      }
      this.getFederatedLoginRelation().then(() => {
        this.loading = false;
      });
    },
    selectUserLogin(eventArgs: any) {
      this.loading = true;
      if (eventArgs !== null) {
        this.keyword = eventArgs;
      } else {
        this.keyword = '';
      }
      this.getFederatedLoginRelation().then(() => {
        this.loading = false;
      });
    },
    async addDateAll(dataList: any, calendarsData: any, page: any) {
      let result;
      for (const element of dataList) {
        const data = {
          controllerId: element.controllerId,
          calendars : calendarsData,
        };
        result = await this.addDate(data);
        if (result === false) {
          break;
        }
      }
      return result;

    },
    addDateWithController(calendarsData: any) {
      return new Promise((resolve, reject) => {
        this.arrayController = [];
        const check = this.getController(1, calendarsData);
        resolve(check);
      });
    },
    async getController(page: number, calendarsData: any) {
      let result;
      this.successMessageLogsModal = '';
      this.errorMessageLogsModal = '';
      result = await this.$http.get(Config.RELATION_CONTROLLER_GATE + '?limit=1000&page=' + page + '&embed=controller')
        .then(async (response: any) => {
          if (response.body.maxRecords > 0) {
            let check;
            const relationsControllerGate = response.body.relationsControllerGate;
            let arrayData: any[];
            arrayData = [];
            relationsControllerGate.forEach((element: any, key: any) => {
              if (!this.arrayController.includes(element.controllerId)) {
                this.arrayController.push(element.controllerId);
                arrayData.push(element);
              }
            });
            check = await this.addDateAll(arrayData, calendarsData, page);
            if (response.body.maxRecords > response.body.endRecord  && check === true) {
              this.getController(page + 1, calendarsData);
            } else {
              return check;
            }
          } else {
            this.successMessageLogsModal = Config.ERROR_DEFAULT;
            return false;
          }
        })
        .then(null, (err) => {
          switch (err.status) {
              case 400:
                this.errorMessageLogsModal = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogsModal = Config.ERROR_403;
                } else {
                  this.errorMessageLogsModal = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogsModal = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogsModal = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogsModal = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogsModal = Config.ERROR_DEFAULT;
                break;
          }
          return false;
        });
      return result;
    },
    /* Passable Time Settings [通行可能時間設定]*/
    changePerPagePassable(value: any): any {
      this.perPagePassable = value;
      this.isCountGroup = 0;
      localStorage.setItem('pageSizePassable', value);
      this.isGetDataList = true;
      this.getListPassableTimeSettings()
      .then(() => this.getGroupCount());
    },
    changePagePassable(value: any): any {
      this.currPagePassable = value;
      this.isGetDataList = true;
      this.isCountGroup = 0;
      this.getListPassableTimeSettings()
      .then(() => this.getGroupCount());
    },
    clickAddPassable(ID: any) {
      this.groupListAdd = [];
      this.dayTypes = [];
      const type = 0;
      this.resetMessage();
      this.isEdit = 3;
      this.groupListByPassableTime = [];
      this.getGroups(1, type);
      this.getDayTypes(1);
    },
    clickEditPassable(ID: any) {
      this.groupListAdd = [];
      this.groupListByPassableTime = [];
      this.dayTypes = [];
      const type = 1;
      this.resetMessage();
      this.loading = true;
      this.isEdit = 2;
      this.loadingDate = true;
      this.countDateType = 0;
      this.timeSettings = [];
      if (ID !== undefined) {
        this.getDetailPassableTimeSetting(ID).then(() => {
          this.loading = false;
          this.getGroups(1, type);
          this.getGroupByPassableTime(1, ID);
          this.getDayTypes(1);
          if (this.dataPassableDetail.length > 0 &&
          this.dataPassableDetail[0].timeSettings !== undefined && this.dataPassableDetail[0].timeSettings !== null &&
          this.dataPassableDetail[0].timeSettings !== '') {
            this.dataPassableDetail[0].timeSettings.forEach((element: any) => {
              this.timeSettings.push(
                {
                  dayTypeId: element.dayTypeId,
                  dayTypeName: element.dayTypeName,
                  doSet: null,
                  endTime: element.endTime,
                  hourEnd: '00',
                  hourStart: '00',
                  minuteEnd: '00',
                  minuteStart: '00',
                  name: element.dayTypeName,
                  notSet: null,
                  startTime: element.startTime,
                  text: element.dayTypeName,
                  value: element.dayTypeId,
                  allDay: element.allDay,
                },
              );
            });
          }
        });
      }
    },
    clickDetailPassable(ID: any) {
      this.resetMessage();
      this.loading = true;
      this.isEdit = 1;
      this.groupListByPassableTime = [];
      this.dayTypes = [];
      this.checkLoadPassDetail = 0;
      if (ID !== undefined) {
        this.getDetailPassableTimeSetting(ID).then(() => {
          this.getGroupByPassableTimeDetail(1, ID);
        });
      }
    },
    clickConfirmDeletePassable(ID: any) {
      this.showConfirmDelete = true;
      this.passableTimeSettingId = ID;
    },
    resetMsg() {
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      this.errorMessageLogsModal = '';
      this.successMessageLogsModal = '';
    },
    clickCloseConfirmDeletePassable() {
      this.showConfirmDelete = false;
      this.passableTimeSettingId = '';
      this.errorMessageLogsModal = '';
      this.successMessageLogsModal = '';
    },
    clickDeletePassable() {
      this.resetMessage();
      this.loading = true;
      this.delPassable(this.passableTimeSettingId);
    },
    clickBackPassable() {
      this.resetMessage();
      this.resetData();
      this.isGetDataList = true;
      this.isCountGroup = 0;
      this.getListPassableTimeSettings()
      .then(() => this.getGroupCount());
      this.isEdit = 0;
    },
    clickSavePassable(eventArgs: any) {
      this.loading = true;
      const passableTimeSettingId =  eventArgs.dataPassableDetail[0].passableTimeSettingId;
      const timeZones =  eventArgs.timeZones;
      const SpecialTime =  eventArgs.arrayAddSpecialTime;
      this.arrayTime = {
        name : eventArgs.passableName,
        timeSettings: [],
      };
      for (const item of timeZones) {
        if (item.notSet !== true && item.doSet !== true) {
          this.arrayTime.timeSettings.push({
            dayTypeId: (item.dayTypeId),
            allDay: 0,
            startTime: item.startTime,
            endTime: item.endTime,
          });
        } else if (item.notSet === true) {
          this.arrayTime.timeSettings.push({
            dayTypeId: (item.dayTypeId),
            allDay: 2,
            startTime: '00:00',
            endTime: '00:00',
          });
        } else if (item.doSet === true) {
          this.arrayTime.timeSettings.push({
            dayTypeId: (item.dayTypeId),
            allDay: 1,
            startTime: '00:00',
            endTime: '00:00',
          });
        }
      }
      if (SpecialTime.length > 0) {
        for (const itemS of SpecialTime) {
          itemS.endTime = itemS.allDay === 0 ? itemS.endTime : '00:00';
          itemS.startTime = itemS.allDay === 0 ? itemS.startTime : '00:00';
          this.arrayTime.timeSettings.push({
            dayTypeId: (itemS.dayTypeId),
            allDay: itemS.allDay,
            startTime: itemS.startTime,
            endTime: itemS.endTime,
          });
        }
      }
      if (passableTimeSettingId !== undefined) {
        this.updatePassable(passableTimeSettingId, this.arrayTime)
        .then(() => this.removePassableGroup(eventArgs))
        .then(() => this.updatePassableGroup(eventArgs, passableTimeSettingId))
        .then(() => {
          const ID = passableTimeSettingId;
          this.groupListByPassableTime = [];
          this.dayTypes = [];
          if (ID !== undefined) {
            this.getDetailPassableTimeSetting(ID).then(() => {
              this.loading = false;
              this.getGroupByPassableTime(1, ID);
              this.getDayTypes(1);
            });
          }
        });
      }
    },
    clickRegisterPassable(eventArgs: any) {
      this.loading = true;
      const timeZones =  eventArgs.timeZones;
      const SpecialTime =  eventArgs.arrayAddSpecialTime;
      this.arrayTime = {
        name : eventArgs.passableName,
        timeSettings: [],
      };
      for (const item of timeZones) {
        if (item.notSet !== true && item.doSet !== true) {
          this.arrayTime.timeSettings.push({
            dayTypeId: (item.value),
            allDay: 0,
            startTime: item.startTime,
            endTime: item.endTime,
          });
        } else if (item.notSet === true) {
          this.arrayTime.timeSettings.push({
            dayTypeId: (item.value),
            allDay: 2,
            startTime: '00:00',
            endTime: '00:00',
          });
        } else if (item.doSet === true) {
          this.arrayTime.timeSettings.push({
            dayTypeId: (item.value),
            allDay: 1,
            startTime: '00:00',
            endTime: '00:00',
          });
        }
      }
      if (SpecialTime.length > 0) {
        for (const itemS of SpecialTime) {
          itemS.endTime = itemS.allDay === 0 ? itemS.endTime : '00:00';
          itemS.startTime = itemS.allDay === 0 ? itemS.startTime : '00:00';
          this.arrayTime.timeSettings.push({
            dayTypeId: (itemS.dayTypeId),
            allDay: itemS.allDay,
            startTime: itemS.startTime,
            endTime: itemS.endTime,
          });
        }
      }
      this.postPassable(this.arrayTime)
        .then(() => this.updatePassableGroup(eventArgs, this.passableTimeSettingId))
        .then(() => {
          this.groupListByPassableTime = [];
          this.groupListAdd = [];
          this.dayTypes = [];
          this.getGroups(1, 0);
          this.getDayTypes(1);
          this.loading = false;
        });
    },
    changeExpanded(payload: any) {
      const data = payload.event;
      if (data.value === true) {
        this.ExpandedGroup = {};
        this.pageGroup = 1;
        this.maxGroup = 0;
        this.loadingGroup = true;
        this.$set(this.ExpandedGroup, data.item.passableTimeSettingId, []);
        this.getExpandedGroup(data.item.passableTimeSettingId);
      }
    },
    changePageGroup(data: any) {
      this.ExpandedGroup = {};
      this.pageGroup = data.page;
      this.maxGroup = 0;
      this.loadingGroup = true;
      this.$set(this.ExpandedGroup, data.passableTimeSettingId, []);
      this.getExpandedGroup(data.passableTimeSettingId);
    },
    getExpandedGroup(ID: any) {
      const apiLink = Config.GROUP_LIST_API + '?passableTimeSettingId=' + encodeURIComponent(ID) +
      '&limit=25&page=' + this.pageGroup + '&isValid=true';
      this.$http.get(apiLink)
        .then((response: any) => {
          this.maxGroup = response.body.maxRecords;
          if (response.body.maxRecords > 0) {
            const res = response.body;
            this.ExpandedGroup[ID] = {
              maxRecords: res.maxRecords,
              groups: res.groups,
            };
          } else {
            this.ExpandedGroup = {};
          }
          this.loadingGroup = false;
        })
        .then( null, (err: any) => {
          this.loadingGroup = false;
        });
    },
    getListPassableTimeSettings(): any {
      return new Promise((resolve, reject) => {
        const apiLink = Config.PASSABLE_TIME_SETTING + '?limit=' + localStorage.pageSizePassable +
        '&page=' + this.currPagePassable;
        this.dataPassables = [];
        this.$http.get(apiLink)
          .then((response: any) => {
            if (!this.isEmpty(response.body.passableTimeSettings)) {
              this.dataPassables = response.body.passableTimeSettings;
              this.passableTotalCount = response.body.maxRecords;
              this.dataPassables.forEach((element: any, key: any) => {
                if (element.isDefault === true) {
                  this.timeDefault = element.passableTimeSettingId;
                  this.passableTotalCount--;
                }
              });
              this.pagePassable = this.currPagePassable;
              // this.loading = false;
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .then(null, (err) => {
            // this.loading = false;
            switch (err.status) {
                case 400:
                  this.errorMessageLogs = Config.ERROR_400;
                  break;
                case 403:
                  if (err.message === 'Unauthorized') {
                    this.errorMessageLogs = Config.ERROR_403;
                  } else {
                    this.errorMessageLogs = Config.ERROR_403_DENIED;
                  }
                  break;
                case 404:
                  this.errorMessageLogs = Config.ERROR_404;
                  break;
                case 500:
                  this.errorMessageLogs = Config.ERROR_500;
                  break;
                case 503:
                  this.errorMessageLogs = Config.ERROR_503;
                  break;
                default:
                  this.errorMessageLogs = Config.ERROR_DEFAULT;
                  break;
              }
            resolve(false);
          });
      });
    },
    getGroupCount() {
      if (this.dataPassables.length > 0) {
        for (const [i, item] of this.dataPassables.entries()) {
          this.getGroupCountItem(item, i);
        }
      } else {
        this.isGetDataList = false;
      }
    },
    getGroupCountItem(data: any, key: any) {
      return new Promise((resolve, reject) => {
        const apiLink = Config.GROUP_LIST_API + '?passableTimeSettingId=' +
        encodeURIComponent(data.passableTimeSettingId) + '&isValid=true';
        this.$http.get(apiLink)
          .then((response: any) => {
            this.dataPassables[key].groupCount = 0;
            if (response.body.maxRecords > 0) {
              this.dataPassables[key].groupCount = response.body.maxRecords;
            }
            this.isCountGroup++;
            resolve(true);
          })
          .then( null, (err: any) => {
            if (this.dataPassables.length > 0) {
              this.dataPassables[key].groupCount = 0;
            }
            this.isCountGroup++;
            resolve(false);
          });
      });
    },
    getDetailPassableTimeSetting(passableTimeSettingId: string): any {
      return new Promise((resolve, reject) => {
      this.pagePassable = Math.ceil(this.passableTotalCount / localStorage.pageSizePassable);
      const apiLink = Config.PASSABLE_TIME_SETTING + '?limit=' + localStorage.pageSizePassable +
      '&page=' + this.currPagePassable + '&scorp=' + this.scorpPassable + '&keyword=' + passableTimeSettingId;
      this.dataPassableDetail = [];
      this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.passableTimeSettings)) {
            this.dataPassableDetail = response.body.passableTimeSettings;
          }
          // this.loading = false;
          resolve(true);
        })
        .then(null, (err) => {
          // this.loading = false;
          switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
          }
          resolve(false);
        });
      });
    },
    delPassable(ID: any) {
      this.$http.delete(Config.PASSABLE_TIME_SETTING + '/' + encodeURIComponent(ID))
        .then((response: any) => {
          this.successMessageLogsModal = 'アイテムが削除されました';
          this.errorMessageLogsModal = '';
          this.getListPassableTimeSettings();
          this.loading = false;
        })
        .then( null, (err: any) => {
          this.successMessageLogsModal = '';
          this.loading = false;
          switch (err.status) {
            case 400:
              this.errorMessageLogsModal = '形式が誤っている。';
              break;
            case 403:
              this.errorMessageLogsModal = 'IDトークンが無効、もしくは権限がない。削除対象のpassableTimeSettingがデフォルト設定であり、削除権限がない。';
              break;
            case 404:
              this.errorMessageLogsModal = '該当するデーターがありません。';
              break;
            case 409:
              this.errorMessageLogsModal = '対象の通行可能時間帯設定が設定されているゲートが存在するため、削除できません';
              break;
            case 500:
              this.errorMessageLogsModal = '意図しないエラー。';
              break;
            case 503:
              this.errorMessageLogsModal = 'サービスが利用不可。システムメンテナンス等の場合に利用します。';
              break;
            default:
              this.errorMessageLogsModal = Config.ERROR_DEFAULT;
              break;
          }
        }); // catch
    },
    postPassable(data: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.PASSABLE_TIME_SETTING, data)
          .then((response: any) => {
            this.successMessageLogs = Config.ADD_PASSABLE_TIME_API_SUCCESS;
            this.errorMessageLogs = '';
            this.passableTimeSettingId = response.body.passableTimeSettingId;
            resolve(true);
          })
          .then( null, (err: any) => {
            this.successMessageLogs = '';
            switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 420:
                this.errorMessageLogs = Config.ERROR_420;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            this.loading = false;
            resolve(false);
          }); // catch
      });
    },
    updatePassable(ID: string, data: any) {
      return new Promise((resolve, reject) => {
        this.$http.put(Config.PASSABLE_TIME_SETTING + '/' + encodeURIComponent(ID), data)
          .then((response: any) => {
            this.successMessageLogs = Config.EDIT_PASSABLE_TIME_API_SUCCESS;
            this.errorMessageLogs = '';
            resolve(null);
          })
          .then( null, (err: any) => {
            this.successMessageLogs = '';
            switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                this.errorMessageLogs = Config.ERROR_403;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            resolve(null);
          }); // catch
      });
    },
    async removePassableGroup(eventArgs: any) {
      // get item removed
      const groupListByPassableTime = this.groupListByPassableTime;
      const delData = groupListByPassableTime.filter((o1: { value: any; }) =>
      !eventArgs.inputGroup.some((o2: { value: any; }) => o1.value === o2.value));
      if (delData !== null && delData.length > 0) {
        const dataGroup = {
          passableTimeSettingId: this.timeDefault,
        };
        for (const item of delData) {
          await this.updatePassableGroupItem(item.value, dataGroup);
        }
      }
    },
    async updatePassableGroup(eventArgs: any, passableTimeSettingId: any) {
      if (eventArgs.inputGroup !== undefined && eventArgs.inputGroup !== null
        && eventArgs.inputGroup !== '' && eventArgs.inputGroup.length > 0) {
        const ID = passableTimeSettingId;
        const dataGroup = {
          passableTimeSettingId: ID,
        };
        for (const item of eventArgs.inputGroup) {
          await this.updatePassableGroupItem(item.value, dataGroup);
        }
      }
    },
    updatePassableGroupItem(groupId: string, data: any) {
      return new Promise((resolve, reject) => {
        this.$http.put(Config.GROUP_LIST_API + '/' + encodeURIComponent(groupId), data)
          .then((response: any) => {
            this.errorMessageLogs = '';
            if (response.body.message === 'Success') {
              // have data
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(true);
          }); // catch
      });
    },
    getGroups(page: any, type: any) {
      this.$http.get(Config.GROUP_LIST_API + '?limit=1000&page=' + page + '&sortKey=desc:registered&isValid=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.groups.forEach((element: any) => {
              if (type === 0) {
                this.groupListAdd.push({text: element.name !== undefined ? element.name :
              '', value: element.groupId});
              } else {
                this.groupListEdit.push({text: element.name !== undefined ? element.name :
              '', value: element.groupId});
              }
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getGroups(page + 1, type);
            }
          } else {
            this.groupListAdd = [];
            this.groupListEdit = [];
          }
        })
        .then( null, (err: any) => {
          // get error
        }); // catch
    },
    getGroupByPassableTimeDetail(page: number, ID: string) {
      this.$http.get(Config.GROUP_LIST_API + '?passableTimeSettingId='
      + encodeURIComponent(ID) + '&limit=1000&page=' + page + '&sortKey=desc:registered&embed=passableTimeSetting&isValid=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.groups.forEach((element: any) => {
              this.groupListByPassableTime.push({text: element.name, value: element.groupId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getGroupByPassableTime(page + 1, ID);
            }
            if (response.body.maxRecords <= response.body.endRecord) {
              this.checkLoadPassDetail = 1;
            }
          } else {
            this.groupListByPassableTime = [];
            this.checkLoadPassDetail = 1;
          }
        })
        .then( null, (err: any) => {
          this.checkLoadPassDetail = 1;
        }); // catch
    },
    getGroupByPassableTime(page: number, ID: string) {
      this.$http.get(Config.GROUP_LIST_API + '?passableTimeSettingId='
      + encodeURIComponent(ID) + '&limit=1000&page=' + page + '&sortKey=desc:registered&embed=passableTimeSetting&isValid=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.groups.forEach((element: any) => {
              this.groupListByPassableTime.push({text: element.name, value: element.groupId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getGroupByPassableTime(page + 1, ID);
            }
          } else {
            this.groupListByPassableTime = [];
          }
        })
        .then( null, (err: any) => {
          // get error
        }); // catch
    },
    getDayTypes(page: any) {
      this.dayTypes = [];
      // this.dayTypes = [{text: '日曜', value: '0', notSet: false}, {text: '月曜', value: '1'}];
      this.$http.get(Config.DATE_TYPE + '?limit=1000&page=' + page + '&sortKey=asc:dayTypeId')
        .then((response: any) => {
          this.totalDateType = Math.ceil((response.body.maxRecords) / 1000);
          if (response.body.maxRecords > 0) {
            this.countDateType++;
            response.body.dayTypes.forEach((element: any) => {
              this.dayTypes.push({text: element.name, value: element.dayTypeId, notSet: false, doSet: false, endTime: '00:00', startTime: '00:00'});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getDayTypes(page + 1);
            }
          } else {
            this.dayTypes = [];
          }
        })
        .then( null, (err: any) => {
          this.countDateType++;
        }); // catch
    },
    getDayTypesDetail(page: any) {
      this.dayTypes = [];
      // this.dayTypes = [{text: '日曜', value: '0', notSet: false}, {text: '月曜', value: '1'}];
      this.$http.get(Config.DATE_TYPE + '?limit=1000&page=' + page + '&sortKey=asc:dayTypeId')
        .then((response: any) => {
          this.totalDateType = Math.ceil((response.body.maxRecords) / 1000);
          if (response.body.maxRecords > 0) {
            this.countDateType++;
            response.body.dayTypes.forEach((element: any) => {
              this.dayTypes.push({text: element.name, value: element.dayTypeId, notSet: false, doSet: false, endTime: '00:00', startTime: '00:00'});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getDayTypes(page + 1);
            } else {
              this.loading = false;
            }
          } else {
            this.dayTypes = [];
            this.loading = false;
          }
        })
        .then( null, (err: any) => {
          this.countDateType++;
          this.loading = false;
        }); // catch
    },
    //
    resetData() {
      this.dataPassables = [];
      this.passableTotalCount = 0;
      this.pagePassable = 0;
    },
    getFederatedLoginService() {
      this.loading = true;
      this.$http.get(Config.FEDERATED_LOGIN_SERVICE_API)
        .then((res: any) => {
          this.federatedLoginServices = res.body.federatedLoginServices;
          this.getFederatedLoginServiceId('Onelogin');
          this.getFederatedLoginServiceId('Google');
          this.getFederatedLoginServiceId('Apple');
          this.getOwnerFederatedLoginService();
          this.getPlan();
          this.loading = false;
        })
        .then( null, (err: any) => {
          this.loading = false;
        });
        },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    changePerPageLogin(value: any): any {
      this.pageSizeList = value;
      this.loading = true;
      this.currPage = 1;
      this.getFederatedLoginRelation().then(() => {
        this.loading = false;
      });
    },
    changePageLogin(value: any): any {
      this.currPage = value;
      this.loading = true;
      this.getFederatedLoginRelation().then(() => {
        this.loading = false;
      });
    },
    goPageExport() {
      this.showModalImport = true;
    },
    closeModalProcess(data: any) {
      this.showProgressExportCSV = false;
      this.showProgressImport = false;
      this.showModalImport = false;
      this.reset();
    },
    clickExport(data: any) {
      this.showModalImport = false;
      this.reset();
      this.pages = 1;
      this.progress = 0;
      this.federatedLoginServiceId = data.federatedLoginServiceId;
      this.showProgressExportCSV = true;
      setTimeout(() => {
        this.getData();
      }, 200);
    },
    async getData() {
      const vm = this;
      let page = 1;
      let isContinueGetData: boolean = true;
      while (isContinueGetData) {
        await this.getDataForExport(page);
        this.progress += (100 / this.pages);
        page++;
        if (page <= this.pages) {
          isContinueGetData = true;
        } else {
          isContinueGetData = false;
        }
      }
      this.downloadCSV();
      this.isFinishProcess = true;
    },

    getDataForExport(page: number) {
      const vm = this;
      return new Promise((resolve) => {
        const ID = this.getFederatedLoginServiceRelationId(this.federatedLoginServiceId);
        const lable = this.cognitoIdProviderName + 'ID';
        if (ID) {
          this.$http.get(Config.USER_FEDERATED_LOGIN_SERVICE_API
          + '?limit=1000&page=' + page + '&relationOwnerFederatedLoginServiceId=' + ID)
          .then((response: any) => {
            for (const item of response.body.relationsUserFederatedLoginService) {
              this.exportData.push({
                [lable]: item.federatedLoginEmail,
                'ALLIGATEユーザーID': item.userId,
                '変更（1：登録、2：削除、3：変更無し）': 3,
              });
            }
            if (response.body.maxRecords > 0) {
                vm.pages = Math.ceil((response.body.maxRecords) / 1000);
            }
            resolve(true);
          })
          .then(null, (err: any) => {
            resolve(false);
          });
        } else {
          resolve(false);
        }
      });
    },
    downloadCSV() {
      const saveAs = require('file-saver');
      let filename;
      if (this.exportData.length === 0) {
        const lable = this.cognitoIdProviderName + 'ID';
        this.exportData.push({
            [lable]: '',
            'ALLIGATEユーザーID': '',
            '変更（1：登録、2：削除、3：変更無し）': '',
        });
      }
      const csv = this.convertArrayOfObjectsToCSV({
        data: this.exportData,
      });
      filename = 'user-federated-login-data.csv';
      if (this.cognitoIdProviderName !== '') {
        filename = this.cognitoIdProviderName.toLowerCase() + '-user-federated-login-data.csv';
      }
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([ bom, csv ], {type: 'text/csv;charset=utf-8;'});
      // ダウンロード実行
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari) {
        saveAs(blob, filename);
      } else {
        if (window.navigator.msSaveOrOpenBlob !== undefined) {
          // IEの場合
          navigator.msSaveBlob(blob, filename);
        } else {
          // IE以外(Chrome, Firefox)
          // const downloadLink = $('<a></a>');
          const downloadLink =  document.createElement('a');
          downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
          downloadLink.setAttribute('download', filename);
          downloadLink.setAttribute('target', '_blank');
          // $('body').append(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }
      }
    },
    clickImport(files: any) {
      this.showProgressImport = true;
      this.showModalImport = false;
      this.showProgressExportCSV = false;
      this.importExe(files);
    },
    closeModalImport() {
      this.showModalImport = false;
      this.exportData = [];
      this.importData = [];
      this.exeCount = 0;
      this.successItem = 0;
      this.success = false;
      this.loading = false;
      this.errorName = '';
      this.progress = 0;
      this.pages = 0;
      this.dataLoad = 0;
      this.errorMessageExcel = [];
      this.errorMessageLogs = '';
      this.successMessageLogs = '';
    },
    closeModalProcessImport() {
      this.showModalImport = false;
      this.showProgressImport = false;
      this.exportData = [];
      this.importData = [];
      this.exeCount = 0;
      this.successItem = 0;
      this.success = false;
      this.loading = false;
      this.errorName = '';
      this.progress = 0;
      this.pages = 0;
      this.dataLoad = 0;
      this.errorMessageExcel = [];
      this.errorMessageLogs = '';
      this.successMessageLogs = '';
      this.getFederatedLoginRelation();
    },
    importExe(data: any): void {
      const vm = this;
      const files = data.files;
      if (files !== undefined && files !== null && files.length !== 0) {
        this.progress = 0;
        const reader = new FileReader();
        reader.onload = (): void => {
          const read =  reader.result as string;
          const lineArr = read.split('\n');
          const itemArr = [];
          for (let i = 0; i < lineArr.length; i++) {
            itemArr[i] = lineArr[i].split(',');
          }
          const checkLable = vm.cognitoIdProviderName + 'ID';
          if (itemArr.length > 1) {
            if (itemArr[0][0] === undefined || itemArr[0][0] !== checkLable) {
              vm.errorMessage = '選択されたファイルフォーマットが外部ログインサービスの種類と異なります。 選択ファイルを確認して下さい。';
              this.success = true;
            } else {
              vm.importData = [];
              for (let k = 1; k < itemArr.length; k++) {
                if (itemArr[k].length === 3 && itemArr[k][0].length > 0) {
                  vm.importData.push(itemArr[k]);
                }
              }
              if (vm.importData.length > 0) {
                vm.totalDataRow = vm.importData.length;
                vm.errorMessage = '';
                vm.percent = (100 / (vm.totalDataRow));
                vm.addExe(vm.importData[0][0], vm.importData[0][1], vm.importData[0][2], 2, vm.percent);
              } else {
                vm.errorMessage = '不正なファイルです。正しいファイルを選択しなおしてください。';
                this.success = true;
              }
            }
          } else {
            vm.errorMessage = '不正なファイルです。正しいファイルを選択しなおしてください。';
            this.success = true;
          }
        };
        reader.readAsText(files, 'Shift_JIS');
      } else {
        vm.errorMessage = 'CSVのファイルを選択してください。';
        this.success = true;
      }
    },
    addExe(email: any, userId: any, change: any, z: number, percent: number) {
      const vm = this;
      change = vm.replaceSpecilSymboy(change);
      const Infor = {
        userId: vm.replaceSpecilSymboy(userId),
        federatedLoginEmail: vm.replaceSpecilSymboy(email),
        relationOwnerFederatedLoginServiceId: this.relationOwnerFederatedLoginServiceId,
      };
      if (change === '1') {
        this.$http.post(Config.USER_FEDERATED_LOGIN_SERVICE_API, Infor)
          .then((response: any) => {
            setTimeout(() => {
              vm.exeCount++;
              vm.progress += percent;
              vm.successItem++;
            }, 200);
          })
          .then( null, (error: any) => {
            let rowstatus = '';
            let errorName = '';
            switch (error.status) {
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  errorName = '';
                } else {
                  errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + errorName + '</span>';
                break;
              case 403:
                errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + errorName + '</span>';
                break;
              case 409:
                errorName = '入力されたユーザーIDは既に使用されています。他のユーザーIDを入力してください。';
                rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + errorName + '</span>';
                break;
              case 500:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + errorName + '</span>';
                break;
              case 503:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + errorName + '</span>';
                break;
              default:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + errorName + '</span>';
                break;
            }
            this.$set(this.errorMessageExcel, z, rowstatus);
            setTimeout(() => {
              vm.exeCount++;
              vm.progress += percent;
            }, 200);
          });
      } else if (change === '2') {
        this.$http.get(Config.USER_FEDERATED_LOGIN_SERVICE_API +
        '?relationOwnerFederatedLoginServiceId=' + this.relationOwnerFederatedLoginServiceId
        + '&userId=' + vm.replaceSpecilSymboy(userId))
          .then((response: any) => {
            let relationId = '';
            response.body.relationsUserFederatedLoginService.forEach((item: { relationId: string; }) => {
              relationId = item.relationId;
            });
            if (relationId !== '') {
              this.$http.delete(Config.USER_FEDERATED_LOGIN_SERVICE_API + '/' + relationId)
                .then((responseDel: any) => {
                  setTimeout(() => {
                    vm.exeCount++;
                    vm.progress += percent;
                    vm.successItem++;
                  }, 200);
                })
                .then( null, (error: any) => {
                  let rowstatus = '';
                  let errorName = '';
                  switch (error.status) {
                    case 401:
                      if (error.body.message === 'The incoming token has expired') {
                        errorName = '';
                      } else {
                        errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                      }
                      break;
                    case 400:
                      errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                      rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + errorName + '</span>';
                      break;
                    case 403:
                      errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                      rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + errorName + '</span>';
                      break;
                    case 409:
                      errorName = '入力されたユーザーIDは既に使用されています。他のユーザーIDを入力してください。';
                      rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + errorName + '</span>';
                      break;
                    case 500:
                      errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                      rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + errorName + '</span>';
                      break;
                    case 503:
                      errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                      rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + errorName + '</span>';
                      break;
                    default:
                      errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                      rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + errorName + '</span>';
                      break;
                  }
                  this.$set(this.errorMessageExcel, z, rowstatus);
                  setTimeout(() => {
                    vm.exeCount++;
                    vm.progress += percent;
                  }, 200);
                });
            }
          })
          .then( null, (error: any) => {
            let rowstatus = '';
            switch (error.status) {
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  vm.errorName = '';
                } else {
                  vm.errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 400:
                vm.errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + vm.errorName + '</span>';
                break;
              case 403:
                vm.errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + vm.errorName + '</span>';
                break;
              case 409:
                vm.errorName = '入力されたユーザーIDは既に使用されています。他のユーザーIDを入力してください。';
                rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + vm.errorName + '</span>';
                break;
              case 500:
                vm.errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + vm.errorName + '</span>';
                break;
              case 503:
                vm.errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + vm.errorName + '</span>';
                break;
              default:
                vm.errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = z + ' 行目>>' + userId + '(' + email + ') >><span>' + vm.errorName + '</span>';
                break;
            }
            this.$set(this.errorMessageExcel, z, rowstatus);
            setTimeout(() => {
              vm.exeCount++;
              vm.progress += percent;
            }, 200);
          });
      } else {
        setTimeout(() => {
          vm.exeCount++;
          vm.progress += percent;
          vm.successItem++;
        }, 200);
      }
    },
    replaceSpecilSymboy(txtString: any) {
      return txtString.replace(/\r/g, '').replace(/\n/g, '\r\n');
    },
    // getDataForExport(page: any, federatedLoginServiceId: any) {
    //   const ID = this.getFederatedLoginServiceRelationId(federatedLoginServiceId);
    //   const lable = this.cognitoIdProviderName + 'ID';
    //   if (ID) {
    //     this.$http.get(Config.USER_FEDERATED_LOGIN_SERVICE_API
    //   + '?limit=1000&page=' + page + '&relationOwnerFederatedLoginServiceId=' + ID)
    //       .then((response: any) => {
    //         for (const item of response.body.relationsUserFederatedLoginService) {
    //           this.exportData.push({
    //             [lable]: item.federatedLoginEmail,
    //             'ALLIGATEユーザーID': item.userId,
    //             '変更（1：登録、2：削除、3：変更無し）': 3,
    //           });
    //         }
    //         this.pages = Math.ceil((response.body.maxRecords) / 1000);
    //         this.dataLoad++;
    //       })
    //       .then( null, (err: any) => {
    //         this.exportData = [];
    //         this.dataLoad++;
    //       }); // catch
    //   } else {
    //     this.exportData = [];
    //     this.dataLoad++;
    //   }
    // },
    // downloadCSV() {
    //   const saveAs = require('file-saver');
    //   let filename;
    //   if (this.exportData.length === 0) {
    //       const lable = this.cognitoIdProviderName + 'ID';
    //       this.exportData.push({
    //           [lable]: '',
    //           'ALLIGATEユーザーID': '',
    //           '変更（1：登録、2：削除、3：変更無し）': '',
    //       });
    //   }
    //   const csv = this.convertArrayOfObjectsToCSV({
    //     data: this.exportData,
    //   });
    //   filename = 'user-federated-login-data.csv';
    //   if (this.cognitoIdProviderName !== '') {
    //     filename = this.cognitoIdProviderName.toLowerCase() + '-user-federated-login-data.csv';
    //   }
    //   const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    //   const blob = new Blob([ bom, csv ], {type: 'text/csv;charset=utf-8;'});
    //   // ダウンロード実行
    //   const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    //   if (isSafari) {
    //     saveAs(blob, filename);
    //   } else {
    //     console.log('window.navigator.msSaveOrOpenBlob', window.navigator.msSaveOrOpenBlob);
    //     if (window.navigator.msSaveOrOpenBlob !== undefined) {
    //       // IEの場合
    //       navigator.msSaveBlob(blob, filename);
    //     } else {
    //       // IE以外(Chrome, Firefox)
    //       // const downloadLink = $('<a></a>');
    //       const downloadLink =  document.createElement('a');
    //       downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
    //       downloadLink.setAttribute('download', filename);
    //       downloadLink.setAttribute('target', '_blank');
    //       // $('body').append(downloadLink);
    //       downloadLink.click();
    //       downloadLink.remove();
    //     }
    //   }
    // },
    convertArrayOfObjectsToCSV(args: any) {
      let result: any;
      let ctr: number;
      let keys: any[];
      let columnDelimiter: string|undefined;
      let lineDelimiter: any;
      let data: any;
      data = args.data || null;
      if (data == null || !data.length) {
        return null;
      }
      columnDelimiter = args.columnDelimiter || ',';
      lineDelimiter = args.lineDelimiter || '\n';
      keys = Object.keys(data[0]);
      result = '';
      result += keys.join(columnDelimiter);
      result += lineDelimiter;
      data.forEach((item: { [x: string]: any; }) => {
        ctr = 0;
        keys.forEach((key: any) => {
          if (ctr > 0) {
            result += columnDelimiter;
          }
          result += item[key];
          ctr++;
        });
        result += lineDelimiter;
      });
      return result;
    },
    getFederatedLoginServiceId(providerName: string) {
      switch (providerName) {
        case 'Google':
          const googleId: any = this.federatedLoginServices.filter((element: any) => element.name === 'Google');
          if (googleId.length > 0) {
            this.googleLoginService.federatedLoginServiceId = googleId[0].federatedLoginServiceId;
            this.googleLoginService.name = googleId[0].name;
          }
          break;
        case 'Onelogin':
          const oneloginId: any = this.federatedLoginServices.filter((element: any) => element.name === 'Onelogin');
          if (oneloginId.length > 0) {
            this.oneLoginLoginService.federatedLoginServiceId = oneloginId[0].federatedLoginServiceId;
            // this.oneLoginLoginService.name = oneloginId[0].name;
            this.oneLoginLoginService.name = 'SAML';
          }
          break;
        case 'Apple':
          const appleId: any = this.federatedLoginServices.filter((element: any) => element.name === 'Apple');
          if (appleId.length > 0) {
            this.appleIdLoginService.federatedLoginServiceId = appleId[0].federatedLoginServiceId;
            this.appleIdLoginService.name = appleId[0].name;
          }
          break;
        default:
          break;
      }
    },
    getOwnerFederatedLoginService() {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.OWNER_FEDERATED_LOGIN_SERVICE_API + '?embed=federatedLoginService')
          .then((response: any) => {
            this.OwnerFederatedLoginService = response.body.relationsOwnerFederatedLoginService;
            this.isLoginServiceEnable();
            resolve(null);
          })
          .then( null, (err: any) => {
            resolve(null);
          });
      });
    },
    isLoginServiceEnable() {
      const gg: any = this.OwnerFederatedLoginService
        .filter((element: any) => element.federatedLoginServiceId === this.googleLoginService.federatedLoginServiceId);
      const ol: any = this.OwnerFederatedLoginService
        .filter((element: any) =>
          element.federatedLoginServiceId === this.oneLoginLoginService.federatedLoginServiceId,
        );
      const ap: any = this.OwnerFederatedLoginService
        .filter((element: any) => element.federatedLoginServiceId === this.appleIdLoginService.federatedLoginServiceId);
      if (gg.length > 0) {
        this.googleLoginService.isValid = gg[0].isValid;
        this.googleLoginService.oldIsValid = gg[0].isValid;
        this.googleLoginService.relationId = gg[0].relationId;
        this.googleLoginService.isSaml = gg[0].federatedLoginService.isSaml;
        this.googleLoginService.isResponse = true;
      }
      if (ol.length > 0) {
        this.oneLoginLoginService.isValid = ol[0].isValid;
        this.oneLoginLoginService.oldIsValid = ol[0].isValid;
        this.oneLoginLoginService.relationId = ol[0].relationId;
        this.oneLoginLoginService.metadataUrl = ol[0].metadataUrl;
        this.oneLoginLoginService.oldMetadataUrl = ol[0].metadataUrl;
        this.oneLoginLoginService.isSaml = ol[0].federatedLoginService.isSaml;
        this.oneLoginLoginService.isResponse = true;
      }
      if (ap.length > 0) {
        this.appleIdLoginService.isValid = ap[0].isValid;
        this.appleIdLoginService.oldIsValid = ap[0].isValid;
        this.appleIdLoginService.relationId = ap[0].relationId;
        this.appleIdLoginService.isSaml = ap[0].federatedLoginService.isSaml;
        this.appleIdLoginService.isResponse = true;
      }
    },
    saveConfig() {
      this.loading = true;
      this.isSaveGoogle = true;
      this.isSaveOnelogin = true;
      this.isSaveAppleId = true;
      this.saveOnelogin();
      this.saveGoogle();
      this.saveAppleId();
    },
    saveOnelogin() {
      if (this.oneLoginLoginService.oldIsValid !== this.oneLoginLoginService.isValid ||
      this.oneLoginLoginService.oldMetadataUrl !== this.oneLoginLoginService.metadataUrl) {
        this.isSaveOnelogin = false;
        if (this.oneLoginLoginService.relationId === '') {
          this.submitRelationConfig(Config.ONELOGIN, this.oneLoginLoginService.federatedLoginServiceId,
          this.oneLoginLoginService.isValid, this.oneLoginLoginService.metadataUrl);
        } else {
          this.updateRelationConfig(Config.ONELOGIN, this.oneLoginLoginService.relationId,
          this.oneLoginLoginService.isValid, this.oneLoginLoginService.metadataUrl);
        }
      } else {
        this.savingProgress++;
      }
    },
    saveGoogle() {
      if (this.googleLoginService.oldIsValid !== this.googleLoginService.isValid) {
        this.isSaveGoogle = false;
        if (this.googleLoginService.relationId === '') {
          this.submitRelationConfig(Config.GOOGLE, this.googleLoginService.federatedLoginServiceId,
          this.googleLoginService.isValid, this.googleLoginService.metadataUrl);
        } else {
          this.updateRelationConfig(Config.GOOGLE, this.googleLoginService.relationId,
          this.googleLoginService.isValid, this.googleLoginService.metadataUrl);
        }
      } else {
        this.savingProgress++;
      }
    },
    saveAppleId() {
      if (this.appleIdLoginService.oldIsValid !== this.appleIdLoginService.isValid) {
        this.isSaveAppleId = false;
        if (this.appleIdLoginService.relationId === '') {
          this.submitRelationConfig(Config.APPLEID, this.appleIdLoginService.federatedLoginServiceId,
           this.appleIdLoginService.isValid, this.appleIdLoginService.metadataUrl);
        } else {
          this.updateRelationConfig(Config.APPLEID, this.appleIdLoginService.relationId,
           this.appleIdLoginService.isValid, this.appleIdLoginService.metadataUrl);
        }
      } else {
        this.savingProgress++;
      }
    },
    submitRelationConfig(type: string, federatedLoginServiceId: string, isValid: boolean, metadataUrl: string) {
      let data = {};
      if (isValid === true) {
        data = {
          federatedLoginServiceId,
          isValid,
          metadataFile: '',
          metadataUrl,
        };
      } else {
        data = {
          federatedLoginServiceId,
          isValid,
          metadataFile: '',
        };
      }
      this.$http.post(Config.OWNER_FEDERATED_LOGIN_SERVICE_API, data)
        .then((response) => {
          this.savingProgress++;
          this.checkSaveStatus(type);
        })
        .then( null, (err: any) => {
          this.savingProgress++;
        }); // catch
    },
    updateRelationConfig(type: string, relationId: string, isValid: boolean, metadataUrl: string) {
      let data = {};
      if (isValid === true) {
        data = {
          metadataUrl,
          metadataFile: '',
          isValid,
        };
      } else {
        data = {
          metadataFile: '',
          isValid,
        };
      }
      this.$http.put(Config.OWNER_FEDERATED_LOGIN_SERVICE_API + '/' + relationId, data)
        .then((response) => {
          this.savingProgress++;
          this.checkSaveStatus(type);
        })
        .then( null, (err: any) => {
          this.savingProgress++;
        }); // catch
    },
    checkSaveStatus(type: string) {
      switch (type) {
        case Config.GOOGLE :
          this.isSaveGoogle = true;
          break;
        case Config.ONELOGIN :
          this.isSaveOnelogin = true;
          break;
        case Config.APPLEID :
          this.isSaveAppleId = true;
          break;
      }
    },
        // 外部ログインサービス利用設定
    checkIsChangeConfig() {
      if (this.googleLoginService.oldIsValid !== this.googleLoginService.isValid) {
        return true;
      }
      if (this.oneLoginLoginService.oldIsValid !== this.oneLoginLoginService.isValid ||
      this.oneLoginLoginService.oldMetadataUrl !== this.oneLoginLoginService.metadataUrl) {
        return true;
      }
      if (this.appleIdLoginService.oldIsValid !== this.appleIdLoginService.isValid) {
        return true;
      }
      return false;
    },
    getPlan() {
      return new Promise((resolve, reject) => {
        // this.pages = 0;
        // this.loading = true;
        localStorage.setItem('isMfa', 'false');
        localStorage.setItem('isFederatedLoginService', 'false');
        localStorage.setItem('isSamlFederatedLoginService', 'false');
        this.$http.get(Config.PLAN_API)
          .then((response: any) => {
            if (response.body.maxRecords > 0) {
              localStorage.setItem('isMfa', response.body.plans[0].isMfa);
              localStorage.setItem('isFederatedLoginService', response.body.plans[0].isFederatedLoginService);
              localStorage.setItem('isSamlFederatedLoginService', response.body.plans[0].isSamlFederatedLoginService);
            }
            resolve(null);
          })
          .then( null, (err: any) => {
            resolve(null);
          }); // catch
      });
    },
    clickResetNfc() {
      this.$http.get(Config.OWNER_API + '?ownerId=' + encodeURIComponent(this.ownerId))
        .then((response: any) => {
          if (!this.isEmpty(response.body.owners[0].pCodeConfig)) {
            this.blockCount = response.body.owners[0].pCodeConfig.blockCount;
            this.ownerInfor = response.body.owners;
            const readPosition = response.body.owners[0].pCodeConfig.readPosition;
            for (const item of readPosition) {
              this.SettingInfo.push({
                position: item.position,
                length: item.length,
              });
            }
          }
        })
        .then( null, (err: any) => {
          // get error
        }); // catch
      this.countReset++;
    },
    goRelation(loginService: LoginService) {
      this.pages = 0;
      this.currPage = 1;
      this.maxRecord = 0;
      this.$router.push({ query: Object.assign({}, this.$route.query, { p: 'registration' }) });
      localStorage.setItem('isExternalLogin', 'registration');
      localStorage.setItem('cognitoIdProviderName', loginService.name);
      localStorage.setItem('federatedLoginServiceId', loginService.federatedLoginServiceId);
      this.cognitoIdProviderName = localStorage.cognitoIdProviderName;
      this.isExternalLogin = localStorage.isExternalLogin;
      this.federatedLoginServiceId = localStorage.federatedLoginServiceId;
      this.userList = [];
      this.realtionsUserList = [];
      this.loading = true;
      return this.getPlan()
        .then(() => this.getUsers(1))
        .then(() => this.getOwnerFederatedLoginService())
        .then(() => this.getFederatedLoginRelation())
        .then(() => {
          this.isFederatedLoginService = localStorage.isFederatedLoginService;
          this.loading = false;
        });
    },
    resetMessage() {
      this.sucessStatus = false;
      this.errorMessageLogs = '';
      this.successMessageLogs = '';
      this.savingProgress = 0;
    },
    resetInput() {
      this.errorMessageLogs = '';
      this.successMessageLogs = '';
      this.errorMessageParams = {
        email: '',
        userId: '',
      };
    },
    goPageSetting() {
      this.resetMessage();
      this.resetInput();
      localStorage.setItem('cognitoIdProviderName', '');
      localStorage.setItem('isExternalLogin', 'setting');
      this.isExternalLogin = localStorage.isExternalLogin;
      this.loading = true;
      const query = Object.assign({}, this.$route.query);
      delete query.p;
      this.$router.replace({ query });
      return this.getPlan()
        .then(() => this.getOwnerFederatedLoginService())
        .then(() => {
          this.isFederatedLoginService = localStorage.isFederatedLoginService;
          this.loading = false;
        });
    },
    getUsers(page: any) {
      this.$http.get(Config.USERS_LIST_API + '?limit=100&page=' + page + '&sortKey=asc:userId&isValid=true&isExceptOptionalUser=true&exclude=thumbnail')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.users.forEach((element: any) => {
              this.userList.push({text: element.userId, value: element.userId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getUsers(page + 1);
            } else {
              this.isDisabled = false;
            }
          } else {
            this.userList = null;
          }
        })
        .then( null, (err: any) => {
          // get error
        }); // catch
    },
    getFederatedLoginRelation() {
      return new Promise((resolve, reject) => {
        const ID = this.getFederatedLoginServiceRelationId(this.federatedLoginServiceId);
        this.realtionsUserList = [];
        if (ID) {
          let apiLink = Config.USER_FEDERATED_LOGIN_SERVICE_API + '?limit=' + this.pageSizeList +
          '&page=' + this.currPage + '&relationOwnerFederatedLoginServiceId=' + ID;
          if (this.keyword !== '' && this.keyword !== undefined) {
            apiLink = Config.USER_FEDERATED_LOGIN_SERVICE_API + '?limit=' + this.pageSizeList +
          '&page=' + this.currPage + '&relationOwnerFederatedLoginServiceId=' + ID + '&keyword=' + this.keyword;
          }
          this.relationOwnerFederatedLoginServiceId = ID;
          this.$http.get(apiLink)
            .then((response: any) => {
              this.maxRecord = response.body.maxRecords;
              if (response.body.maxRecords > 0) {
                response.body.relationsUserFederatedLoginService.forEach((element: any) => {
                  this.realtionsUserList.push(element);
                });
                this.pages = Math.ceil((response.body.maxRecords) / this.pageSizeList);
              } else {
                this.realtionsUserList = [];
              }
              resolve(null);
            })
            .then( null, (err: any) => {
              this.realtionsUserList = [];
              resolve(null);
            }); // catch
        } else {
          resolve(null);
        }
      });
    },
    getFederatedLoginServiceRelationId(federatedLoginServiceId: string) {
      const relation: any = this.OwnerFederatedLoginService.filter((element: any) =>
        element.federatedLoginServiceId === federatedLoginServiceId,
      );
      if (relation.length > 0) {
        return relation[0].relationId;
      } else {
        return false;
      }
    },
    addRelation(user: any) {
      const canSubmit = this.validateBeforeSubmit(user);
      const Infor = {
        userId: user.userId,
        federatedLoginEmail: user.email,
        relationOwnerFederatedLoginServiceId: this.relationOwnerFederatedLoginServiceId,
      };
      this.sucessStatus = false;
      if (canSubmit) {
        this.loading = true;
        this.resetInput();
        this.$http.post(Config.USER_FEDERATED_LOGIN_SERVICE_API, Infor)
          .then((response: any) => {
            this.realtionsUserList = [];
            this.pages = 0;
            this.currPage = 1;
            this.getFederatedLoginRelation();
            this.successMessageLogs = 'ログインユーザーが追加されました。';
            this.errorMessageLogs = '';
            this.sucessStatus = true;
            this.loading = false;
          })
          .then( null, (error: any) => {
            switch (error.status) {
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  this.errorMessageLogs = '';
                } else {
                  this.errorMessageLogs = Config.ERROR_403;
                }
                break;
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                this.errorMessageLogs = Config.ERROR_403;
                break;
              case 409:
                this.errorMessageLogs = Config.ERROR_409_USER;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            this.loading = false;
            this.sucessStatus = false;
            this.successMessageLogs = '';
          }); // catch
      }
    },
    deleteRelation(relationId: string) {
      this.loading = true;
      this.$http.delete(Config.USER_FEDERATED_LOGIN_SERVICE_API + '/' + relationId)
        .then((response: any) => {
          this.realtionsUserList = [];
          this.pages = 0;
          this.currPage = 1;
          this.resetInput();
          this.successMessageLogs = 'ログインユーザーが削除されました。';
          this.errorMessageLogs = '';
          this.loading = false;
          this.sucessStatus = true;
          this.getFederatedLoginRelation();
        })
        .then( null, (error: any) => {
            switch (error.status) {
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  this.errorMessageLogs = '';
                } else {
                  this.errorMessageLogs = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 400:
                this.errorMessageLogs = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                break;
              case 403:
                this.errorMessageLogs = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                break;
              case 500:
                this.errorMessageLogs = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
              case 503:
                this.errorMessageLogs = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
              default:
                this.errorMessageLogs = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                break;
            }
            this.loading = false;
            this.sucessStatus = false;
            this.successMessageLogs = '';
          }); // catch;
    },
    validateBeforeSubmit(data: any) {
      this.errorMessageParams.email = '';
      this.errorMessageParams.userId = '';
      const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/.test(data.email);
      if (!validEmail) {
        this.errorMessageParams.email = '正しいメールアドレスを入力してください。';
        return false;
      } else {
        this.errorMessageParams.email = '';
      }
      let userId = '';
      if (data.userId !== null && data.userId !== ''
      && data.userId !== undefined) {
        userId = data.userId;
      }
      const validUserID = /^[a-zA-Z0-9@.!#$%&'*+/=?^_`{|}~-]{4,96}$/.test(userId);
      if (!validUserID) {
        this.errorMessageParams.userId = 'ログインに使用するIDは必須です。';
        return false;
      } else {
        this.errorMessageParams.userId = '';
      }
      return true;
    },
    initializeNFCSetting() {
      this.getTypeInfo();
      this.getOwnerInfor();
    },
    checkEditStatus() {
      if (!this.fistTime) {
        this.settingInfor.ownerCodePosition1 = 0;
        this.settingInfor.ownerCodePosition2 = 0;
        this.settingInfor.ownerCodePosition3 = 0;
        this.settingInfor.ownerCodeLength1 = 0;
        this.settingInfor.ownerCodeLength2 = 0;
        this.settingInfor.ownerCodeLength3 = 0;
        this.settingInfor.readPosition1 = 0;
        this.settingInfor.readPosition2 = 0;
        this.settingInfor.readPosition3 = 0;
        this.settingInfor.readLength1 = 0;
        this.settingInfor.readLength2 = 0;
        this.settingInfor.readLength3 = 0;
        this.settingInfor.ownerCode = '';
        this.settingInfor.systemCode = '';
        this.settingInfor.serviceCode = '';
        this.settingInfor.blockCount = 0;
        this.settingInfor.keyType = 1;
        this.settingInfor.keyValue = '';
        this.settingInfor.sectorNumber = 0;
        this.settingInfor.isOwnerCodeValid = false;
      }
      switch (this.settingInfor.pCodeConfigType) {
        case 0: // 製造番号読み出し
          this.fistTime = false;
          this.editStatus = true;
          this.isFelica = true;
          this.isMifare = true;
          this.editStatusException = true;
          break;
        case 1:
          this.fistTime = false;
          this.editStatus = true;
          this.isFelica = true;
          this.isMifare = true;
          this.editStatusException = false;
          break;
        case 2:
          this.fistTime = false;
          this.editStatus = true;
          this.isFelica = true;
          this.isMifare = true;
          this.editStatusException = false;
          break;
        case 3:
          this.fistTime = false;
          this.editStatus = true;
          this.isFelica = true;
          this.isMifare = true;
          this.editStatusException = true;
          break;
        case 4:
          this.fistTime = false;
          this.editStatus = false;
          this.isFelica = true;
          this.isMifare = false;
          this.editStatusException = false;
          break;
        case 5:
          this.fistTime = false;
          this.editStatus = false;
          this.isFelica = false;
          this.isMifare = true;
          this.editStatusException = false;
          break;
        case 6: // 製造番号読み出し（Felica）
          this.fistTime = false;
          this.editStatus = true;
          this.isFelica = true;
          this.isMifare = true;
          this.editStatusException = true;
          break;
        case 7: // 製造番号読み出し（MYFARE）
          this.fistTime = false;
          this.editStatus = true;
          this.isFelica = true;
          this.isMifare = true;
          this.editStatusException = true;
          break;
      }
    },
    getTypeInfo() {
      this.loading = true;
      this.$http
        .get(Config.CONFIG_PCODE_TYPE_API)
        .then((response: any) => {
          if (
            response.body.pCodeConfigTypes !== undefined &&
            response.body.pCodeConfigTypes.length > 0
          ) {
            // task 22404: require list (0, 6, 7, 1, 2, 3, 4, 5)
            let index = 1;
            response.body.pCodeConfigTypes.forEach((item: any) => {
              if (item.type === 6 || item.type === 7) {
                // if type = 6, 7 then push into list by index
                this.listNfcSettingSelect.splice(index, 0, item); // 0: not remove any item
                index++;
              } else {
                this.listNfcSettingSelect.push(item);
              }
            });
          } else {
            this.errorMessageLogs = Config.ERROR_DEFAULT;
          }
          this.loading = false;
        })
        .then(null, (err: any) => {
          this.loading = false;
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        }); // catch
    },
    getOwnerInfor() {
      this.loading = true;
      this.$http.get(Config.OWNER_API + '?ownerId=' + encodeURIComponent(localStorage.ownerId))
        .then((response: any) => {
          this.fistTime = true;
          this.settingInfor.pCodeConfigType = response.body.owners[0].pCodeConfigType;
          this.settingInfor.phonesBeValid = response.body.owners[0].phonesBeValid;
          this.settingInfor.keyTokenRefreshable = response.body.owners[0].keyTokenRefreshable;
          this.settingInfor.keyTokenLifetime = response.body.owners[0].keyTokenLifetime;
          this.settingInfor.keyTokenRefreshRate = response.body.owners[0].keyTokenRefreshRate;
          this.settingInfor.type = response.body.owners[0].pCodeConfig.type;
          this.settingInfor.isOwnerCodeValid = response.body.owners[0].pCodeConfig.isOwnerCodeValid;
          this.settingInfor.ownerCode = response.body.owners[0].pCodeConfig.ownerCode;
          if (!this.isEmpty(response.body.owners[0].pCodeConfig)) {
            const ownerCodePosition = response.body.owners[0].pCodeConfig.ownerCodePosition;
            for (let i = 0; i < ownerCodePosition.length; i++) {
              switch (i) {
                case 0:
                  this.settingInfor.ownerCodePosition1 =
                    response.body.owners[0].pCodeConfig.ownerCodePosition[i].position;
                  this.settingInfor.ownerCodeLength1 =
                    response.body.owners[0].pCodeConfig.ownerCodePosition[i].length;
                  break;
                case 1:
                  this.settingInfor.ownerCodePosition2 =
                    response.body.owners[0].pCodeConfig.ownerCodePosition[i].position;
                  this.settingInfor.ownerCodeLength2 =
                    response.body.owners[0].pCodeConfig.ownerCodePosition[i].length;
                  break;
                case 2:
                  this.settingInfor.ownerCodePosition3 =
                    response.body.owners[0].pCodeConfig.ownerCodePosition[i].position;
                  this.settingInfor.ownerCodeLength3 =
                    response.body.owners[0].pCodeConfig.ownerCodePosition[i].length;
                  break;
              }
            }
            const readPosition = response.body.owners[0].pCodeConfig.readPosition;
            for (let j = 0; j < readPosition.length; j++) {
              switch (j) {
                case 0:
                  this.settingInfor.readPosition1 =
                    response.body.owners[0].pCodeConfig.readPosition[j].position;
                  this.settingInfor.readLength1 =
                    response.body.owners[0].pCodeConfig.readPosition[j].length;
                  break;
                case 1:
                  this.settingInfor.readPosition2 =
                    response.body.owners[0].pCodeConfig.readPosition[j].position;
                  this.settingInfor.readLength2 =
                    response.body.owners[0].pCodeConfig.readPosition[j].length;
                  break;
                case 2:
                  this.settingInfor.readPosition3 =
                    response.body.owners[0].pCodeConfig.readPosition[j].position;
                  this.settingInfor.readLength3 =
                    response.body.owners[0].pCodeConfig.readPosition[j].length;
                  break;
              }
            }
            if (this.settingInfor.type === 0) {
              if (!this.isEmpty(response.body.owners[0].pCodeConfig.credential)) {
                this.settingInfor.systemCode =
                  response.body.owners[0].pCodeConfig.credential.felica.systemCode;
                this.settingInfor.serviceCode =
                  response.body.owners[0].pCodeConfig.credential.felica.serviceCode;
              }
              this.settingInfor.blockCount =
                response.body.owners[0].pCodeConfig.blockCount;
            } else {
              this.settingInfor.keyType =
                response.body.owners[0].pCodeConfig.credential.mifare.keyType;
              this.settingInfor.keyValue =
                response.body.owners[0].pCodeConfig.credential.mifare.keyValue;
              this.settingInfor.sectorNumber =
                response.body.owners[0].pCodeConfig.sectorNumber;
            }
          }
          this.checkEditStatus();
          this.loading = false;
        })
        .then(null, (err: any) => {
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
        }); // catch
    },
    saveNfcSetting() {
      this.loading = true;
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      const Infor: any = {
        pCodeConfigType: this.settingInfor.pCodeConfigType,
      };
      switch (this.settingInfor.pCodeConfigType) {
        case 0: // 製造番号読み出し
          break;
        case 1:
          if (this.settingInfor.isOwnerCodeValid) {
            Infor.pCodeConfig = {
              ownerCode: this.settingInfor.ownerCode,
              isOwnerCodeValid: this.settingInfor.isOwnerCodeValid,
              ownerCodePosition: [
                {
                  position: parseInt(this.settingInfor.ownerCodePosition1.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength1.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition2.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength2.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition3.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength3.toString()),
                },
              ],
            };
          } else {
            Infor.pCodeConfig = {
              isOwnerCodeValid: this.settingInfor.isOwnerCodeValid,
              ownerCodePosition: [
                {
                  position: parseInt(this.settingInfor.ownerCodePosition1.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength1.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition2.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength2.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition3.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength3.toString()),
                },
              ],
            };
          }
          break;
        case 2:
          if (this.settingInfor.isOwnerCodeValid) {
            Infor.pCodeConfig = {
              ownerCode: this.settingInfor.ownerCode,
              isOwnerCodeValid: this.settingInfor.isOwnerCodeValid,
              ownerCodePosition: [
                {
                  position: parseInt(this.settingInfor.ownerCodePosition1.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength1.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition2.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength2.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition3.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength3.toString()),
                },
              ],
            };
          } else {
            Infor.pCodeConfig = {
              isOwnerCodeValid: this.settingInfor.isOwnerCodeValid,
              ownerCodePosition: [
                {
                  position: parseInt(this.settingInfor.ownerCodePosition1.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength1.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition2.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength2.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition3.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength3.toString()),
                },
              ],
            };
          }
          break;
        case 3:
          break;
        case 4:
          if (this.settingInfor.isOwnerCodeValid) {
            Infor.pCodeConfig = {
              ownerCode: this.settingInfor.ownerCode,
              blockCount: parseInt(this.settingInfor.blockCount.toString()),
              isOwnerCodeValid: this.settingInfor.isOwnerCodeValid,
              type: 0,
              credential: {
                felica: {
                  systemCode: this.settingInfor.systemCode,
                  serviceCode: this.settingInfor.serviceCode,
                },
              },
              ownerCodePosition: [
                {
                  position: parseInt(this.settingInfor.ownerCodePosition1.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength1.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition2.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength2.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition3.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength3.toString()),
                },
              ],
              readPosition: [
                {
                  position: parseInt(this.settingInfor.readPosition1.toString()),
                  length: parseInt(this.settingInfor.readLength1.toString()),
                },
                {
                  position: parseInt(this.settingInfor.readPosition2.toString()),
                  length: parseInt(this.settingInfor.readLength2.toString()),
                },
                {
                  position: parseInt(this.settingInfor.readPosition3.toString()),
                  length: parseInt(this.settingInfor.readLength3.toString()),
                },
              ],
            };
          } else {
            Infor.pCodeConfig = {
              blockCount: parseInt(this.settingInfor.blockCount.toString()),
              isOwnerCodeValid: this.settingInfor.isOwnerCodeValid,
              type: 0,
              credential: {
                felica: {
                  systemCode: this.settingInfor.systemCode,
                  serviceCode: this.settingInfor.serviceCode,
                },
              },
              ownerCodePosition: [
                {
                  position: parseInt(this.settingInfor.ownerCodePosition1.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength1.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition2.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength2.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition3.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength3.toString()),
                },
              ],
              readPosition: [
                {
                  position: parseInt(this.settingInfor.readPosition1.toString()),
                  length: parseInt(this.settingInfor.readLength1.toString()),
                },
                {
                  position: parseInt(this.settingInfor.readPosition2.toString()),
                  length: parseInt(this.settingInfor.readLength2.toString()),
                },
                {
                  position: parseInt(this.settingInfor.readPosition3.toString()),
                  length: parseInt(this.settingInfor.readLength3.toString()),
                },
              ],
            };
          }
          break;
        case 5:
          if (this.settingInfor.isOwnerCodeValid) {
            Infor.pCodeConfig = {
              ownerCode: this.settingInfor.ownerCode,
              isOwnerCodeValid: this.settingInfor.isOwnerCodeValid,
              sectorNumber: parseInt(this.settingInfor.sectorNumber.toString()),
              type: 1,
              credential: {
                mifare: {
                  keyType: parseInt(this.settingInfor.keyType.toString()),
                  keyValue: this.settingInfor.keyValue,
                },
              },
              ownerCodePosition: [
                {
                  position: parseInt(this.settingInfor.ownerCodePosition1.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength1.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition2.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength2.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition3.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength3.toString()),
                },
              ],
              readPosition: [
                {
                  position: parseInt(this.settingInfor.readPosition1.toString()),
                  length: parseInt(this.settingInfor.readLength1.toString()),
                },
                {
                  position: parseInt(this.settingInfor.readPosition2.toString()),
                  length: parseInt(this.settingInfor.readLength2.toString()),
                },
                {
                  position: parseInt(this.settingInfor.readPosition3.toString()),
                  length: parseInt(this.settingInfor.readLength3.toString()),
                },
              ],
            };
          } else {
            Infor.pCodeConfig = {
              isOwnerCodeValid: this.settingInfor.isOwnerCodeValid,
              sectorNumber: parseInt(this.settingInfor.sectorNumber.toString()),
              type: 1,
              credential: {
                mifare: {
                  keyType: parseInt(this.settingInfor.keyType.toString()),
                  keyValue: this.settingInfor.keyValue,
                },
              },
              ownerCodePosition: [
                {
                  position: parseInt(this.settingInfor.ownerCodePosition1.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength1.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition2.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength2.toString()),
                },
                {
                  position: parseInt(this.settingInfor.ownerCodePosition3.toString()),
                  length: parseInt(this.settingInfor.ownerCodeLength3.toString()),
                },
              ],
              readPosition: [
                {
                  position: parseInt(this.settingInfor.readPosition1.toString()),
                  length: parseInt(this.settingInfor.readLength1.toString()),
                },
                {
                  position: parseInt(this.settingInfor.readPosition2.toString()),
                  length: parseInt(this.settingInfor.readLength2.toString()),
                },
                {
                  position: parseInt(this.settingInfor.readPosition3.toString()),
                  length: parseInt(this.settingInfor.readLength3.toString()),
                },
              ],
            };
          }
          break;
        case 6: // 製造番号読み出し（Felica）
          break;
        case 7: // 製造番号読み出し（MYFARE）
          break;
      }
      this.$http
        .put(
          Config.OWNER_API + '/' + encodeURIComponent(localStorage.ownerId),
          Infor,
        )
        .then((response) => {
          this.loading = false;
          this.successMessageLogs = Config.EDIT_SETTING_API_SUCCESS;
          this.errorMessageLogs = '';
        })
        .then( null, (err: any) => {
          this.loading = false;
          this.successMessageLogs = '';
          // this.errorMessageLogs = Config.ADD_API_ERROR;
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              this.errorMessageLogs = Config.ERROR_403;
              break;
            case 409:
              this.errorMessageLogs = Config.ERROR_409;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        });
    },
    initializeLogLabelSetting() {
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      this.loading = true;
      this.dataEditLogLabel = {};
      this.listConfifLog = [];
      this.pages = 0;
      this.isEditLogLabel = false;
      this.$http.get(Config.CONFIG_LOG_API + '?limit=' + localStorage.pageSizeLogConfig + '&page=' +
                     this.currPage + '&sortKey=' + localStorage.configLogSortName + ':' +
                     localStorage.configLogSortKey)
        .then((response: any) => {
          if (response.body.logConfigs !== undefined && response.body.logConfigs.length > 0) {
            this.pages = Math.ceil((response.body.maxRecords) / localStorage.pageSizeLogConfig);
            this.listConfifLog = response.body.logConfigs;
          } else {
            this.listConfifLog = [];
          }
          this.loading = false;
        })
        .then( null, (err: any) => {
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.listConfifLog = [];
          this.loading = false;
        }); // catch
    },
    reloadDataLogLabel() {
      this.$http.get(Config.CONFIG_LOG_API)
        .then((response: any) => {
          if (response.body.logConfigs !== undefined && response.body.logConfigs.length > 0) {
            response.body.logConfigs.forEach((item: any) => {
              if (item.accessLogId === this.dataEditLogLabel.accessLogId) {
                this.dataEditLogLabel.logLabel = item.logLabel;
                this.dataEditLogLabel.isDefault = item.isDefault;
              }
            });
          }
          this.loading = false;
        })
        .then( null, (err: any) => {
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
        }); // catch
    },
    clickEditLogLabel(dataEditLogLabel: any) {
      this.loading = true;
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      this.isEditLogLabel = true;
      this.dataEditLogLabel = dataEditLogLabel;
      this.reloadDataLogLabel();
    },
    clickSaveLogLabel(data: any) {
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      if (data.logLabel === null || data.logLabel === '') {
        this.logLabelErr = 'ログ名称は必須です。';
        return;
      } else {
        this.logLabelErr = '';
      }
      const logInfo = {
        accessLogId: data.accessLogId,
        logLabel: data.logLabel,
      };
      this.loading = true;
      this.$http.post(Config.CONFIG_LOG_API, logInfo)
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.successMessageLogs = Config.EDIT_CONFIG_LOG_API_SUCCESS;
          } else {
            this.errorMessageLogs = Config.ERROR_DEFAULT;
          }
          this.reloadDataLogLabel();
        })
        .then(null, (err: any) => {
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
        }); // catch
    },
    clickDeleteLogLabel(data: any) {
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      this.loading = true;
      this.$http.delete(Config.CONFIG_LOG_API + '/' + encodeURIComponent(data.accessLogId))
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.successMessageLogs = Config.EDIT_CONFIG_LOG_API_SUCCESS;
          } else {
            this.errorMessageLogs = Config.ERROR_DEFAULT;
          }
          this.reloadDataLogLabel();
        })
        .then(null, (err: any) => {
          switch (err.status) {
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
        }); // catch
    },
    clickBackLogLabel() {
      this.isEditLogLabel = false;
      this.initializeLogLabelSetting();
    },
    changePerPage(value: any): any {
      this.perPage = value;
      localStorage.setItem('pageSizeUserList', value);
      this.initializeLogLabelSetting();
    },
    changePage(payload: any) {
      const type = payload.change;
      if ( type === 'log') {
        this.currPage = payload.page;
        this.initializeLogLabelSetting();
      }
    },
    saveKeyToken() {
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      if (this.settingInfor.keyTokenRefreshRate >= this.settingInfor.keyTokenLifetime) {
        this.errorMessageLogs = '更新間隔は、有効期間より長く設定することはできません。';
      } else {
        this.loading = true;
        const infor = {
          keyTokenRefreshable: this.settingInfor.keyTokenRefreshable,
          keyTokenLifetime: this.settingInfor.keyTokenLifetime,
          keyTokenRefreshRate: this.settingInfor.keyTokenRefreshRate,
        };
        this.$http.put(Config.OWNER_API + '/' + localStorage.ownerId, infor)
          .then((response: any) => {
            this.successMessageLogs = Config.EDIT_SETTING_API_SUCCESS;
            this.loading = false;
          })
          .then(null, (err: any) => {
            switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            this.loading = false;
          });
      }
    },
    savePhoneStatus() {
      this.loading = true;
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      const infor = {
        phonesBeValid: this.settingInfor.phonesBeValid,
      };
      this.$http.put(Config.OWNER_API + '/' + localStorage.ownerId, infor)
        .then((response: any) => {
          this.successMessageLogs = Config.EDIT_SETTING_API_SUCCESS;
          this.loading = false;
        })
        .then(null, (err: any) => {
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
        });
    },
  },
});
