













































































































































































import { Room, RoomRoomLog, User, UserRoomLog } from '@/types/alligate';
import Vue, { PropType } from 'vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import Alert from '@/components/molecules/Alert/Alert.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import ErrorButton from '@/components/molecules/ErrorButton/ErrorButton.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import Tabs, { TabItem } from '@/components/molecules/Tabs/Tabs.vue';
import InRoomUserTable from '@/components/organisms/InRoomUserTable/InRoomUserTable.vue';
import InRoomTimelineTable from '@/components/organisms/InRoomTimelineTable/InRoomTimelineTable.vue';
import InRoomUserTimelineTable from '@/components/organisms/InRoomUserTimelineTable/InRoomUserTimelineTable.vue';
import { SearchValue } from '@/components/molecules/Search/Search.vue';

export interface DataType {
  showSuccessMessages: boolean[];
  showUserRoomLogSuccessMessages: boolean[];
  // tabItems: TabItem[];

  deleteRoomModal: boolean;
  userRoomLogModal: boolean;
  isShow: boolean;
}

export const TAB_VALUE_IN_ROOM_USER = 'in-room-user';
export const TAB_VALUE_IN_ROOM_TIMELINE = 'in-room-timeline';
export const TAB_VALUE_IN_ROOM_USER_TIMELINE = 'in-room-user-timeline';

export default Vue.extend({
  name: 'InRoomDetail',
  components: {
    Loading,
    Alert,
    CancelButton,
    ErrorButton,
    Modal,
    SuccessButton,
    Tabs,
    InRoomUserTable,
    InRoomTimelineTable,
    InRoomUserTimelineTable,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: String,
      default: TAB_VALUE_IN_ROOM_USER,
    },
    successMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },

    room: {
      type: Object as PropType<Room>,
      default: () => ({
        roomId: '',
        name: '',
      }),
    },

    inRoomUserLoading: {
      type: Boolean,
      default: false,
    },
    inRoomUsers: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
    inRoomUserTotalCount: {
      type: Number,
      default: 0,
    },
    inRoomUserPage: {
      type: Number,
      default: 1,
    },
    inRoomUserPerPage: {
      type: Number,
      default: 25,
    },
    inRoomUserSearchValue: {
      type: Object as PropType<SearchValue>,
      default: () => ({
        text: '',
        targets: ['userId', 'name'],
      }),
    },

    roomRoomLogLoading: {
      type: Boolean,
      default: false,
    },
    roomRoomLogs: {
      type: Array as PropType<RoomRoomLog[]>,
      default: () => [],
    },
    roomRoomLogTotalCount: {
      type: Number,
      default: 0,
    },
    roomRoomLogPage: {
      type: Number,
      default: 1,
    },
    roomRoomLogPerPage: {
      type: Number,
      default: 25,
    },
    roomRoomLogDate: {
      type: String,
      default: new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10),
    },
    roomRoomLogSearchValue: {
      type: Object as PropType<SearchValue>,
      default: () => ({
        text: '',
        targets: ['userName'],
      }),
    },
    roomRoomLogProgressMessage: {
      type: String,
      default: '',
    },
    roomRoomLogProgressErrorMessage: {
      type: String,
      default: '',
    },

    userRoomLogLoading: {
      type: Boolean,
    },
    user: {
      type: Object as PropType<User>,
      default: () => ({
        userId: '',
        name: '',
        furigana: '',
        email: '',
        phoneNumber: '',
        isAdmin: false,
        isPhoneAdmin: false,
        shareKeyBeValid: false,
        isMfaSms: false,
        passCode: '',
        isValid: false,
        option1: '',
        option2: '',
        isPasswordLoginValid: false,
        groupCount: 0,
        gateCount: 0,
        timelimitedGateCount: 0,
      }),
    },
    userRoomLogs: {
      type: Array as PropType<UserRoomLog[]>,
      default: () => [],
    },
    userRoomLogDate: {
      type: String,
      default: new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10),
    },
    userRoomLogProgressMessage: {
      type: String,
      default: '',
    },
    userRoomLogProgressErrorMessage: {
      type: String,
      default: '',
    },
    userRoomLogSuccessMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    userRoomLogErrorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    tabItemsUI: {
      type: Array,
      default: [],
    },
    userFullList: {
      type: Array,
      default: [],
    },
  },
  data: (): DataType => ({
    showSuccessMessages: [],
    showUserRoomLogSuccessMessages: [],
    // tabItems: [
    //   { text: '在室中ユーザ', value: TAB_VALUE_IN_ROOM_USER },
    //   { text: '在室履歴', value: TAB_VALUE_IN_ROOM_TIMELINE },
    // ],

    deleteRoomModal: false,
    userRoomLogModal: false,
    isShow: false,
  }),
  mounted() {
    if (this.$route.path === '/in-room-management/room') {
      this.isShow = true;
    }
  },
  computed: {
    innerTab: {
      get(): string {
        return this.tab;
      },
      set(val: string) {
        this.$emit('input-tab', val);
      },
    },
    innerInRoomUserSearchValue: {
      get(): SearchValue {
        return this.inRoomUserSearchValue;
      },
      set(val: SearchValue): void {
        this.$emit('input-in-room-user-search-value', val);
        return;
      },
    },
    innerRoomRoomLogDate: {
      get(): string {
        return this.roomRoomLogDate;
      },
      set(val: string): void {
        this.$emit('input-room-room-log-date', val);
        return;
      },
    },
    innerRoomRoomLogSearchValue: {
      get(): SearchValue {
        return this.roomRoomLogSearchValue;
      },
      set(val: SearchValue): void {
        this.$emit('input-room-room-log-search-value', val);
        return;
      },
    },
    innerUserRoomLogDate: {
      get(): string {
        return this.userRoomLogDate;
      },
      set(val: string): void {
        this.$emit('input-user-room-log-date', val);
        return;
      },
    },
  },
  watch: {
    'successMessages'(messages: string[]) {
      const shows: boolean[] = [];
      messages.forEach((message) => {
        shows.push(message !== '');
      });
      this.showSuccessMessages = shows;
    },
    'userRoomLogSuccessMessages'(messages: string[]) {
      const shows: boolean[] = [];
      messages.forEach((message) => {
        shows.push(message !== '');
      });
      this.showUserRoomLogSuccessMessages = shows;
    },
    'room.roomUserCount': {
      handler() {
        (this.$refs['allig-in-room-detail-tabs'] as any).callSlider();
      },
    },
  },
  methods: {
    clickInRoomUser(userId: string) {
      // this.userRoomLogModal = true;
      this.$emit('click-in-room-user', userId);
    },
  },
});
