
























































































import Vue, { PropType } from 'vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
import { Gate } from '@/types/alligate';
export interface DataType {
  selectedItem: any;
}
export default Vue.extend({
  name: 'GateList',
  components: {
    Pagination,
    ShowPerPage,
    TextLink,
  },
  props: {
    gateTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: String,
      default: '',
    },
    gates: {
      type: Array as PropType<Gate[]>,
      default: () => [],
    },
  },
  data: (): DataType => ({
    selectedItem: [],
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'ゲート名',
            align: 'start',
            sortable: true,
            value: 'gateName',
          },
        ];
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page-gate', {page: val, groupId: this.groupId, change: 'gate'});
        return;
      },
    },
  },
  watch: {
    gates() {
      this.selectedItem = [];
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    goGateDetailPage(value: any) {
      this.$router.push({ path: Config.GROUP_DETAIL, query: {gateId: value}});
    },
    clickJoinGroup(value: any) {
      this.$router.push({ path: Config.USER_ADD_GROUP_GATE, query: {groupId: value, goBack: 'user'}});
    },
  },
});
