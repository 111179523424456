

































import {Vue } from 'vue-property-decorator';
import ModalTemplate from '@/components/templates/ModalTemplate/ModalTemplate.vue';
import * as Config from '@/config';
import store from '@/store';
import InRoomTemplate from '@/components/templates/InRoomTemplate/InRoomTemplate.vue';
export interface DataType {
  loading: boolean;
  dataModal: any;
  dataProgress: any;
  // New User Registration 新規ユーザー登録:  通行可能なゲート
  allGroups: any;
  isGetGroup: boolean;
  authority: any;
  isGetUserGate: boolean;
}
export default Vue.extend({
  name: 'GateListModalPage',
  components: {
    ModalTemplate,
  },
  props: {
    isPage: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
    departmentId: {
      type: String,
      default: '',
    },
    isAction: {
      type: String,
      default: '',
    },
    groupPassages: {
      default: () => [],
    },
    dataAdded: {
      default: () => [],
    },
  },
  data: (): DataType => ({
    loading: false,
    dataModal: {
        userAll: {
          data: [],
          total: 0,
          loading: false,
          page: 1,
          perPage: 25,
          keyword: '',
          scorp: ['userId', 'name'],
        },
        groupAll: {
          data: [],
          total: 0,
          loading: false,
          page: 1,
          perPage: 25,
        },
        groupPass: {
          data: [],
          total: 0,
          loading: false,
          page: 1,
          perPage: 25,
        },
        // New User Registration 新規ユーザー登録:  通行可能なゲート
        gateOfGroup: {
          groupIds: [],
          first: [],
          tmp: [],
          data: [],
          page: 1,
          perPage: 25,
          total : 0,
          loading: false,
          messageSetting: {
            error: '',
            success: '',
          },
        },
      },
    dataProgress: {
      total: 0,
      progressLoad: 0,
      successItem: 0,
      errorMessage: [],
      isFinish: false,
    },
    allGroups: [],
    isGetGroup: false,
    authority: store.state.auth,
    isGetUserGate: false,
  }),
  created() {
    // get data group
    if (this.isPage === 'group') {
      this.loadListGroups({perPage: 25, page: 1});
    } else if (this.isPage === 'add-user-group-gate') {
      // New User Registration 新規ユーザー登録:  通行可能なゲート
      // this.getGroupListAll(1);
    } else if (this.isPage === 'detail-user-group-gate' && this.userId !== '') {
      // User Detail ユーザー詳細:  通行可能なゲート
      this.dataModal.gateOfGroup.loading = true;
      this.dataModal.gateOfGroup.data = [];
      this.dataModal.gateOfGroup.tmp = [];
      this.getUserGateId(this.userId, 1);
    } else if (this.isPage === 'group-load-all-user' || this.isPage === 'aff-load-all-user' || this.isPage === 'affiliation-add-new') {
      // Group Tab 通行可能ユーザ:  他のユーザを追加する
      this.loadListUsers(1, 25);
    }
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
    isGetGroup(value) {
      if (value === true) {
        this.getGateOfGroup();
      }
    },
    isGetUserGate(val: any) {
      if (val === false && this.dataModal.gateOfGroup.loading === false) {
        this.dataModal.gateOfGroup.tmp = [];
        this.dataModal.gateOfGroup.data = [];
        this.dataModal.gateOfGroup.first.forEach((elements: any) => {
          if (elements.gates.length > 0) {
            elements.gates.forEach((gate: any) => {
              const item = {
                groupPassageId: elements.groupPassageId,
                relationId: gate.relationId,
                gateName: gate.name,
                group: elements.group,
              };
              item.group.passableTimeSetting = elements.passableTimeSetting;
              item.group.name = elements.group.groupName;
              this.dataModal.gateOfGroup.data.push(item);
            });
          } else {
            const item = {
                groupPassageId: elements.groupPassageId,
                relationId: elements.groupPassageId + '-' + Math.random().toString(5).substring(2),
                gateName: '',
                group: elements.group,
              };
            item.group.name = elements.group.groupName;
            item.group.passableTimeSetting = elements.passableTimeSetting;
            this.dataModal.gateOfGroup.data.push(item);
          }
        });
        this.dataModal.gateOfGroup.total = this.dataModal.gateOfGroup.data.length;
        this.dataModal.gateOfGroup.tmp = this.dataModal.gateOfGroup.data;
      }
    },
  },
  mounted() {
    //
  },
  methods: {
    // Common
    closeModal() {
      if (this.isPage === 'detail-user-group-gate') {
        // User Detail ユーザー詳細:  通行可能なゲート
        this.isGetUserGate = true;
        this.dataModal.gateOfGroup.loading = true;
        this.dataModal.gateOfGroup.first = [];
        this.dataModal.gateOfGroup.data = [];
        this.dataModal.gateOfGroup.tmp = [];
        this.dataModal.gateOfGroup.total = 0;
        this.dataModal.gateOfGroup.page = 0;
        this.getUserGateId(this.userId, 1);
      } else if (this.isPage === 'aff-load-all-user') {
        //
        this.$emit('close-modal-aff');
        this.loadListUsers(this.dataModal.userAll.page, this.dataModal.userAll.perPage);
      } else {
        this.$emit('close-modal');
      }
    },
    changePageGroup(data: any) {
      if (this.isPage === 'group') {
        this.changePageGroupAll(data);
      } else if (this.isPage === 'add-user-group-gate') {
        this.userAddChangePageGroup(data);
      } else if (this.isPage === 'detail-user-group-gate') {
        this.dataModal.gateOfGroup.loading = true;
        this.dataModal.gateOfGroup.page = data.page;
        const dataOri  = Array.of(...this.dataModal.gateOfGroup.tmp);
        this.dataModal.gateOfGroup.data =
        dataOri.slice((data.page - 1) * 25, data.page * 25);
        setTimeout(() => {
          this.dataModal.gateOfGroup.loading = false;
        }, 100);
      }
    },
    // Group
    changePageGroupAll(val: any) {
      this.dataModal.groupAll.page = val;
      const data = {
        page: this.dataModal.groupAll.page,
        perPage: this.dataModal.groupAll.perPage,
      };
      this.loadListGroups(data);
    },
    changePerPageGroupAll(val: any) {
      this.dataModal.groupAll.perPage = val;
      const data = {
        page: this.dataModal.groupAll.page,
        perPage: this.dataModal.groupAll.perPage,
      };
      this.loadListGroups(data);
    },
    loadListGroups(item: any) {
      this.dataModal.groupAll.loading = true;
      this.$http.get(Config.GROUP_LIST_API + '?limit=' + item.perPage + '&page=' + item.page + '&isValid=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            this.dataModal.groupAll.data = response.body.groups;
            this.dataModal.groupAll.total = response.body.maxRecords;
            this.dataModal.groupAll.loading = false;
            this.dataModal.groupAll.data.map((v: any) => Object.assign(v, {group: {
                groupId: v.groupId,
                name: v.name,
              }}));
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.dataModal.groupAll.loading = false;
        }); // catch
    },
    async addGroupToUser(data: any) {
      console.log('data', data);
      this.dataProgress = {
        total: 0,
        progressLoad: 0,
        successItem: 0,
        errorMessage: [],
        isFinish: false,
      };
      let rowinErrDialog = 0;
      let successItemProcess = 0;
      let progress = 0;
      let isFinishProcess = false;
      const errorMessageProcess: any = [];
      const totalDataRowProcess = data.length;
      const percent = (100 / totalDataRowProcess);
      this.dataProgress.total = totalDataRowProcess;
      for (const item of data) {
        let result;
        const groupId = item.groupId !== undefined ? item.groupId : item.group.groupId;
        result  = await this.addGroupUser(groupId, this.userId);
        if (result === true) {
          successItemProcess++;
        } else {
          const rowstatus = item.name + '>><span>' + result + '</span>';
          this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
        }
        progress += percent;
        this.dataProgress.progress = progress;
        this.dataProgress.successItem = successItemProcess;
        this.dataProgress.progressLoad = progress;
        this.dataProgress.errorMessage = errorMessageProcess;
      }
      isFinishProcess = true;
      this.dataProgress.isFinish = isFinishProcess;
      if (successItemProcess > 0) {
        this.$emit('getReload', true);
      }
    },
    addGroupUser(groupID: string, userID: string) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.GROUP_PASSAGE, {
          groupId: groupID,
          userId: userID,
        })
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 409:
                errorName = Config.ERROR_409_GROUP_PASSAGE;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            resolve(errorName);
          }); // catch
      });
    },
    // New User Registration 新規ユーザー登録:  通行可能なゲート
    getGroupListAll(page: any) {
      this.$http.get(Config.GROUP_LIST_API + '?limit=1000' + '&page=' + page +
        '&keyword=' + '' + '&isValid=true&embed=passableTimeSetting')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.groups.forEach((element: any) => {
              this.allGroups.push(element);
            });
            if (response.body.maxRecords > response.body.endRecord) {
              page++;
              this.getGroupListAll(page);
            } else {
              this.isGetGroup = true;
            }
          } else {
            this.isGetGroup = true;
          }
        })
        .then(null, (error: any) => {
          this.allGroups = [];
          this.isGetGroup = true;
        });
    },
    async getGateOfGroup() {
      this.dataModal.gateOfGroup.loading = true;
      const data = this.allGroups;
      for (let i = 0; i < data.length; i += 10) {
        const chunk = data.slice(i, i + 10);
        const arrayPromise: any = [];
        for (const [index, item] of  chunk.entries()) {
          const result = this.getGateItem(1, item);
          arrayPromise.push(result);
        }
        await Promise.all(arrayPromise);
      }
      this.dataModal.gateOfGroup.loading = false;
      this.dataModal.gateOfGroup.data = this.dataModal.gateOfGroup.tmp;
      this.dataModal.gateOfGroup.total = this.dataModal.gateOfGroup.data.length;
    },
    getGateItem(page: any , item: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.RELATION_GATE_GROUP + '?groupId=' +
        encodeURIComponent(item.groupId) + '&limit=1000&page=' + page + '&embed=gate&isExceptOptionalGate=true')
          .then((response: any) => {
            if (response.body.maxRecords > 0) {
              response.body.relationsGateGroup.forEach((element: any) => {
                const data =  {
                  gateName: element.gate.name,
                  Id: element.gate.gateId + '-' + item.groupId,
                  group: item,
                };
                this.dataModal.gateOfGroup.tmp.push(data);
              });
              if (response.body.maxRecords > response.body.endRecord) {
                this.getGateItem(page + 1, item);
              }
            } else {
              this.dataModal.gateOfGroup.tmp = [];
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            resolve(false);
            }); // catch
      });
    },
    userAddChangePageGroup(payload: any) {
      this.dataModal.gateOfGroup.page = payload.page;
      this.dataModal.gateOfGroup.loading = true;
      this.dataModal.gateOfGroup.data =
      this.dataModal.gateOfGroup.tmp.slice((payload.page - 1) * 25, payload.page * 25);
      this.dataModal.gateOfGroup.loading = false;
    },
    // User Detail ユーザー詳細:  通行可能なゲート
    getUserGateId(userId: any, pageGate: any) {
      this.dataModal.gateOfGroup.page = pageGate;
      this.$http.get(Config.USERS_LIST_API + '/' + encodeURIComponent(userId) +
      '/gate?limit=1000&isValid=true&page=' + pageGate + '&isExceptOptionalGate=true')
        .then(async (response: any) => {
            if (response.body.maxRecords > 0) {
            this.dataModal.gateOfGroup.total = response.body.maxRecords;
            console.log('response.body.gates', response.body.gates);
            response.body.gates.forEach((element: any) => {
              element.groups.forEach((el: any, index: any) => {
                const i = this.dataModal.gateOfGroup.data.findIndex((x: any) =>  x.groupId === el.groupId);
                console.log('i', i);
                if (i < 0) {
                  //
                  const groupPassageId = element.groupPassageIds !== undefined && element.groupPassageIds.length > 0
                  && element.groupPassageIds[index] !== undefined ? true : false;
                  const item  = {
                    gateModelCode: element.gateModelCode,
                    gateName: element.gateName,
                    gateId: element.gateId,
                    groupPassageId: groupPassageId ? element.groupPassageIds[index] : element.gateId + '-' + el.groupId,
                    isPassageId: groupPassageId,
                    group: el,
                    isClick: this.authority.isAdmin ? true : false,
                    sub: el.groupId + '-' + element.gateId,
                  };
                  this.dataModal.gateOfGroup.tmp.push(item);
                  this.dataModal.gateOfGroup.data.push(item);
                  this.dataModal.gateOfGroup.tmp.sort((a: any, b: any) => (a.group.name.toLowerCase() <
                  b.group.name.toLowerCase() ? -1 : 1));
                  this.dataModal.gateOfGroup.data.sort((a: any, b: any) => (a.group.name.toLowerCase() <
                  b.group.name.toLowerCase() ? -1 : 1));
                }
              });
            });
            if (response.body.maxRecords > response.body.endRecord) {
              pageGate++;
              this.getUserGateId(userId, pageGate);
            } else {
              if (this.authority.isAdmin === false) {
                const data = this.dataModal.gateOfGroup.tmp;
                const groupIds: any = [];
                for (let j = 0; j < data.length; j++) {
                  const el = data[j];
                  const index = j;
                  const groupId = data[j].group.groupId;
                  if (groupIds.includes(groupId) === false) {
                    const result = await this.getGroupAuth(groupId);
                    console.log('result', result);
                    console.log('groupId', groupId);
                    if (result) {
                      this.dataModal.gateOfGroup.tmp.map((v: any) =>
                      Object.assign(v, {isClick : v.group.groupId === groupId ? true : v.isClick }));
                      this.dataModal.gateOfGroup.data.map((v: any) =>
                      Object.assign(v, {isClick : v.group.groupId === groupId ? true : v.isClick }));
                      console.log('tmp', this.dataModal.gateOfGroup.tmp);
                      console.log('data', this.dataModal.gateOfGroup.data);
                    }
                    groupIds.push(groupId);
                  }
                }
              }
              this.dataModal.gateOfGroup.loading = false;
              this.dataModal.gateOfGroup.total = this.dataModal.gateOfGroup.tmp.length;
            }
          } else {
            this.dataModal.gateOfGroup.first = [];
            this.dataModal.gateOfGroup.loading = false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.dataModal.gateOfGroup.loading = false;
          this.$emit('load-total', {total: this.dataModal.gateOfGroup.total, ID: userId});
        }); // catch
    },
    // async compareRelationDepartmentGroupId(item: any) {
    //   const RelationDepartmentGroupId = item.relationDepartmentGroupIds;
    //   if (RelationDepartmentGroupId.length > 0) {
    //     for (const el of RelationDepartmentGroupId) {
    //       await this.getRelationId(el);
    //     }
    //   }
    // },
    // getRelationId(ID: any) {
    //   return new Promise((resolve, reject) => {
    // this.$http.get(Config.RELATION_GATE_GROUP_API +
    // '?relationId=' + encodeURIComponent(ID) + '&embed=gate&limit=25&page=1')
    //       .then(async (response: any) => {
    //         if (response.body.maxRecords > 0) {
    //         const data = response.body.relationsDepartmentGroup[0];
    //         this.dataModal.gateOfGroup.groupIds.push(data.groupId);
    //         resolve(true);
    //         } else {
    //           resolve(false);
    //         }
    //       })
    //       .then(null, (error) => {
    //         console.log(error);
    //         resolve(false);
    //       });
    //   });
    // },
    getUserGate(userId: any, pageGate: any) {
      this.$http.get(Config.GROUP_PASSAGE + '?userId=' + encodeURIComponent(userId) + '&limit=1000&page=' + pageGate + '&sortKey=desc:userId&embed=group')
        .then(async (response: any) => {
          if (response.body.maxRecords > 0) {
            const groupData =  response.body.groupPassages;
            for (let i = 0; i < groupData.length; i += 10) {
              const chunk = groupData.slice(i, i + 10);
              const arrayPromise1: any = [];
              const arrayPromise2: any = [];
              for (const [index, el] of chunk.entries()) {
                const item = el;
                item.gates = [];
                this.dataModal.gateOfGroup.first.push(item);
                const result1 = await this.getGroupDetail(item);
                const result2 = await this.getGateGroup(item, 1);
                arrayPromise1.push(result1);
                arrayPromise2.push(result2);
              }
              await Promise.all([arrayPromise1, arrayPromise2]);
            }
            if (response.body.maxRecords > response.body.endRecord) {
              pageGate++;
              this.getUserGate(userId, pageGate);
            } else {
              this.dataModal.gateOfGroup.loading = false;
              this.isGetUserGate = false;
            }
          } else {
            this.dataModal.gateOfGroup.first = [];
            this.dataModal.gateOfGroup.loading = false;
            this.isGetUserGate = false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.dataModal.gateOfGroup.first = [];
          this.dataModal.gateOfGroup.loading = false;
          this.isGetUserGate = false;
        }); // catch
    },
    getGateGroup(item: any, page: any) {
        return new Promise((resolve, reject) => {
          this.$http.get(Config.RELATION_GATE_GROUP_API + '?groupId=' + encodeURIComponent(item.groupId) + '&embed=gate&limit=1000&page=' + page + '&isExceptOptionalGate=true&isValid=true')
            .then(async (response: any) => {
              if (response.body.maxRecords > 0) {
              const data = response.body.relationsGateGroup;
              for (const element of data) {
                const i = this.dataModal.gateOfGroup.first.findIndex((x: any) =>
                x.groupPassageId === item.groupPassageId);
                if (i >= 0) {
                  const el = {
                    relationId: '',
                    gateId: '',
                    name: '',
                    group: item.group,
                  };
                  el.relationId = element.relationId;
                  el.gateId = element.gateId;
                  el.name = element.gate.name;
                  this.dataModal.gateOfGroup.first[i].gates.push(el);
                }
              }
              if (response.body.maxRecords > response.body.endRecord) {
                page++;
                this.getGateGroup(item, page);
              } else {
                resolve(true);
              }
            } else {
              resolve(false);
            }
            })
            .then(null, (error) => {
              console.log(error);
              resolve(false);
            });
        });
      },
      getGroupDetail(item: any) {
        return new Promise((resolve, reject) => {
          const apiLink = Config.GROUP_LIST_API + '?groupId=' + encodeURIComponent(item.groupId) + '&embed=passableTimeSetting';
          this.$http.get(apiLink)
            .then((response: any) => {
              const i = this.dataModal.gateOfGroup.first.findIndex((x: any) =>
              x.groupPassageId === item.groupPassageId);
              if (i >= 0) {
                this.dataModal.gateOfGroup.first[i].passableTimeSetting =
                response.body.groups[0].passableTimeSetting;
              }
              resolve(true);
            })
            .then( null, (err: any) => {
              resolve(false);
            }); // catch
        });
    },
    // 個別設定適用中
    addGroupPass(data: any) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.dataModal.gateOfGroup.messageSetting.error = '';
        this.dataModal.gateOfGroup.messageSetting.success = '';
        this.$http.post(Config.GROUPS_PASSAGE_API, {
          groupId: data.groupId,
          userId: this.userId,
        })
          .then((response: any) => {
            resolve(true);
            this.dataModal.gateOfGroup.messageSetting.success = Config.ADD_GROUP_API_SUCCESS;
            this.loading = false;
          })
          .then( null, (error: any) => {
            switch (error.status) {
              case 400:
                this.dataModal.gateOfGroup.messageSetting.error = Config.ERROR_400;
                break;
              case 403:
                if (error.message === 'Unauthorized') {
                  this.dataModal.gateOfGroup.messageSetting.error = Config.ERROR_403;
                } else {
                  this.dataModal.gateOfGroup.messageSetting.error = Config.ERROR_403_DENIED;
                }
                break;
              case 409:
                this.dataModal.gateOfGroup.messageSetting.error = '他通行権限グループと紐づいてます。通行可能時間設定が適用されたユーザーのため追加できません。';
                break;
              case 404:
                this.dataModal.gateOfGroup.messageSetting.error = Config.ERROR_404;
                break;
              case 500:
                this.dataModal.gateOfGroup.messageSetting.error = Config.ERROR_500;
                break;
              case 503:
                this.dataModal.gateOfGroup.messageSetting.error = Config.ERROR_503;
                break;
              default:
                this.dataModal.gateOfGroup.messageSetting.error = Config.ERROR_DEFAULT;
                break;
            }
            this.loading = false;
            resolve(false);
          }); // catch
      });
    },
    // チェックした個別設定を削除する
    async delGroupUser(data: any) {
      console.log('data', data);
      this.dataProgress = {
        total: 0,
        progressLoad: 0,
        successItem: 0,
        errorMessage: [],
        isFinish: false,
      };
      let rowinErrDialog = 0;
      let successItemProcess = 0;
      let progress = 0;
      let isFinishProcess = false;
      const errorMessageProcess: any = [];
      const totalDataRowProcess = data.length;
      const percent = (100 / totalDataRowProcess);
      this.dataProgress.total = totalDataRowProcess;
      for (const item of data) {
        let result;
        result  = await this.delGroupUserItem(item);
        if (result === true) {
          successItemProcess++;
        } else {
          const rowstatus = item + '>><span>' + result + '</span>';
          this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
        }
        progress += percent;
        this.dataProgress.progress = progress;
        this.dataProgress.successItem = successItemProcess;
        this.dataProgress.progressLoad = progress;
        this.dataProgress.errorMessage = errorMessageProcess;
      }
      isFinishProcess = true;
      this.dataProgress.isFinish = isFinishProcess;
      if (successItemProcess > 0) {
        this.$emit('getReload', true);
      }
    },
    delGroupUserItem(passageId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.GROUPS_PASSAGE_API + '/' + encodeURIComponent(passageId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            resolve(errorName);
          }); // catch
      });
    },
    // User Expand : 通行権限グループ(個別設定)を追加する
    getAllGroups(page: any) {
      this.dataModal.groupAll.loading = true;
      this.dataModal.groupAll.data = [];
      this.getAllGroupsNoPage(1);
    },
    getAllGroupsNoPage(page: any) {
      this.$http.get(Config.GROUP_LIST_API + '?limit=1000&page=' + page + '&isValid=true&embed=passableTimeSetting')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            this.dataModal.groupAll.total = response.body.maxRecords;
            response.body.groups.forEach((element: any) => {
              this.dataModal.groupAll.data.push(element);
            });
            if (response.body.maxRecords > response.body.endRecord) {
              page++;
              this.getAllGroupsNoPage(page);
            } else {
              this.dataModal.groupAll.loading = false;
              if (this.userId !== '') {
                this.dataModal.groupPass.data = [];
                this.getGroupPass(1, this.userId);
              }
            }
          } else {
            this.dataModal.groupAll.loading = false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.dataModal.groupAll.loading = false;
        }); // catch
    },
    // USER
    loadListUsers(page: any, perPage: any) {
      this.dataModal.userAll.data = [];
      this.dataModal.userAll.loading = true;
      const apiLink = Config.USERS_LIST_SIMPLE_API + '?limit=' + perPage + '&page=' + page + '&isExceptOptionalUser=true&isValid=true' + (this.dataModal.userAll.keyword !== '' ? '&keyword=' + this.dataModal.userAll.keyword + '&scorp=' +  this.dataModal.userAll.scorp : '');
      this.$http.get(apiLink)
        .then(async (response: any) => {
          if (response.body.maxRecords > 0) {
            if (this.departmentId !== '' || this.isPage === 'affiliation-add-new') {
              for (let i = 0; i < response.body.users.length; i += 10) {
                const chunk = response.body.users.slice(i, i + 10);
                const arrayPromise: any = [];
                for (const [index, item] of  chunk.entries()) {
                  item.departmentName = '';
                  this.dataModal.userAll.data.push(item);
                  const result =  this.getDepartmentUser(item.userId);
                  arrayPromise.push(result);
                }
                await Promise.all(arrayPromise);
              }
              this.dataModal.userAll.total = response.body.maxRecords;
            } else {
              this.dataModal.userAll.data = response.body.users;
              this.dataModal.userAll.data.map((v: any) =>
              Object.assign(v, {isAdmin : v.isAdmin !== undefined ? v.isAdmin : false }));
              this.dataModal.userAll.total = response.body.maxRecords;
            }

          }
          this.dataModal.userAll.loading = false;
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.dataModal.userAll.loading = false;
        }); // catch
    },
    getDepartmentUser(userId: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.RELATION_DEPARTMENT_USER_API + `?userId=${encodeURIComponent(userId)}&embed=department`)
          .then((response: any) => {
            const data  = response.body.relationsDepartmentUser;
            const i = this.dataModal.userAll.data.findIndex((x: any) =>
            x.userId === userId);
            if (i >= 0) {
              this.dataModal.userAll.data[i].departmentName = data.length > 0 ? data[0].department.name : '';
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            resolve(false);
          });
      });
    },
    changePageUser(val: any) {
      this.dataModal.userAll.page = val;
      this.loadListUsers(this.dataModal.userAll.page, this.dataModal.userAll.perPage);
    },
    changePerPageUser(val: any) {
      this.dataModal.userAll.page = 1;
      this.dataModal.userAll.perPage = val;
      this.loadListUsers(this.dataModal.userAll.page, this.dataModal.userAll.perPage);
    },
    async addUserToGroup(data: any) {
      console.log('data', data);
      this.dataProgress = {
        total: 0,
        progressLoad: 0,
        successItem: 0,
        errorMessage: [],
        isFinish: false,
      };
      let rowinErrDialog = 0;
      let successItemProcess = 0;
      let progress = 0;
      let isFinishProcess = false;
      const errorMessageProcess: any = [];
      const totalDataRowProcess = data.length;
      const percent = (100 / totalDataRowProcess);
      this.dataProgress.total = totalDataRowProcess;
      for (const item of data) {
        let result;
        result  = await this.addGroupUser(this.groupId, item.userId);
        if (result === true) {
          successItemProcess++;
        } else {
          const rowstatus = item.name + '>><span>' + result + '</span>';
          this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
        }
        progress += percent;
        this.dataProgress.progress = progress;
        this.dataProgress.successItem = successItemProcess;
        this.dataProgress.progressLoad = progress;
        this.dataProgress.errorMessage = errorMessageProcess;
      }
      isFinishProcess = true;
      this.dataProgress.isFinish = isFinishProcess;
      if (successItemProcess > 0) {
        this.$emit('getReload', true);
      }
    },
    async addUserToAff(data: any) {
      console.log('data', data);
      this.dataProgress = {
        total: 0,
        progressLoad: 0,
        successItem: 0,
        errorMessage: [],
        isFinish: false,
      };
      let rowinErrDialog = 0;
      let successItemProcess = 0;
      let progress = 0;
      let isFinishProcess = false;
      const errorMessageProcess: any = [];
      const totalDataRowProcess = data.length;
      const percent = (100 / totalDataRowProcess);
      this.dataProgress.total = totalDataRowProcess;
      for (const item of data) {
        let result;
        result  = await this.postUserDepartment(item.userId, this.departmentId);
        if (result === true) {
          successItemProcess++;
        } else {
          const rowstatus = item.name + '>><span>' + result + '</span>';
          this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
        }
        progress += percent;
        this.dataProgress.progress = progress;
        this.dataProgress.successItem = successItemProcess;
        this.dataProgress.progressLoad = progress;
        this.dataProgress.errorMessage = errorMessageProcess;
      }
      isFinishProcess = true;
      this.dataProgress.isFinish = isFinishProcess;
      if (successItemProcess > 0) {
        this.$emit('getReload', true);
      }
    },
    postUserDepartment(userId: string, departmentId: string) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.RELATION_DEPARTMENT_USER_API , {userId: (userId),
          departmentId: (departmentId)})
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            resolve(errorName);
          }); // catch
      });
    },
    getGroupPass(page: any, userId: any) {
      this.$http.get(Config.GROUPS_PASSAGE_API + '?userId=' + encodeURIComponent(userId) + '&embed=group&limit=1000&page=' + page)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.groupPassages.forEach((element: any) => {
              this.dataModal.groupPass.data.push(element.group);
              this.dataModal.groupPass.data.sort((a: any, b: any) =>
              (a.groupName.toLowerCase() < b.groupName.toLowerCase() ? -1 : 1));
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getGroupPass(page + 1, userId);
            }
          } else {
            this.dataModal.groupPass.data = [];
          }
        })
        .then( null, (err: any) => {
          console.log(err);
        });
    },
     async getDataAdd(data: any) {
      const dataArr: any = data;
      this.dataModal.groupPass.data = data;
      this.dataModal.gateOfGroup.tmp = [];
      this.dataModal.gateOfGroup.data = [];
      this.dataModal.gateOfGroup.first = data;
      this.dataModal.gateOfGroup.loading = true;
      let count = 0;
      let isContinueGetData = true;
      while (isContinueGetData) {
        if (this.dataModal.gateOfGroup.first.length > 0) {
          this.dataModal.gateOfGroup.first[count].gates = [];
          await this.getGateGroupAdd(data[count], 1, count);
        }
        count++;
        if (count < data.length) {
          isContinueGetData = true;
        } else {
          isContinueGetData = false;
        }
      }
      this.dataModal.gateOfGroup.first.forEach((elements: any) => {
        if (elements.gates !== undefined && elements.gates.length > 0) {
          elements.gates.forEach((gate: any) => {
            const item = {
              relationId: gate.relationId,
              gateName: gate.name,
              group: elements.group,
              isPassageId: true,
              groupId: elements.group.groupId,
            };
            this.dataModal.gateOfGroup.data.push(item);
          });
        } else {
          const item = {
              gateName: '',
              group: elements.group,
              groupId: elements.group.groupId,
              isPassageId: true,
            };
          this.dataModal.gateOfGroup.data.push(item);
        }
      });
      this.dataModal.gateOfGroup.total = this.dataModal.gateOfGroup.data.length;
      this.dataModal.gateOfGroup.tmp = this.dataModal.gateOfGroup.data;
      this.dataModal.gateOfGroup.tmp.sort((a: any, b: any) => (a.group.name.toLowerCase() <
      b.group.name.toLowerCase() ? -1 : 1));
      this.dataModal.gateOfGroup.data.sort((a: any, b: any) => (a.group.name.toLowerCase() <
      b.group.name.toLowerCase() ? -1 : 1));
      this.dataModal.gateOfGroup.loading = false;
    },
    getGateGroupAdd(item: any, page: any, index: any) {
        return new Promise((resolve, reject) => {
          this.$http.get(Config.RELATION_GATE_GROUP_API + '?groupId=' + encodeURIComponent(item.groupId) + '&embed=gate&limit=1000&page=' + page + '&isExceptOptionalGate=true&isValid=true')
            .then(async (response: any) => {
              if (response.body.maxRecords > 0) {
              const data = response.body.relationsGateGroup;
              for (const element of data) {
                const el = {
                  relationId: '',
                  gateId: '',
                  name: '',
                  group: item,
                };
                el.relationId = element.relationId;
                el.gateId = element.gateId;
                el.name = element.gate.name;
                this.dataModal.gateOfGroup.first[index].gates.push(el);
              }
              if (response.body.maxRecords > response.body.endRecord) {
                page++;
                this.getGateGroupAdd(item, page, index);
              } else {
                resolve(true);
              }
            } else {
              resolve(false);
            }
            })
            .then(null, (error) => {
              console.log(error);
              resolve(false);
            });
        });
      },
      searchAllUser(data: any) {
        console.log('data', data);
        let keyword = data.text;
        keyword = keyword.replace(/\s/g, ',');
        this.dataModal.userAll.keyword = encodeURIComponent(keyword);
        this.dataModal.userAll.scorp = data.targets;
        this.loadListUsers(1, 25);
      },
      getGroupAuth(groupId: any) {
        return new Promise((resolve, reject) => {
          console.log('AAAA');
          const apiLink = Config.GROUP_ADMINISTRATOR_API +
            '?userId=' + encodeURIComponent(localStorage.userProfileId ) +
            '&groupId=' + groupId + '&limit=25&embed=group';
          this.$http.get(apiLink)
            .then(async (response: any) => {
              if (response.body.maxRecords > 0) {
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .then( null, (err: any) => {
              console.log('err: ', err);
              resolve(false);
            });
        });
    },
  },
});
