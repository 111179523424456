


























import {Vue } from 'vue-property-decorator';
import TimeAddGroupTemplate from '@/components/templates/TimeAddGroupTemplate/TimeAddGroupTemplate.vue';
import { Group } from '@/types/alligate';
import * as Config from '@/config';
export interface DataType {
  dataGroups: any;
  perPage: number;
  currPage: number;
  keyword: string;
  scorp: any;
  groupTotalCount: number;

  loading: boolean;
  showConfirm: boolean;
  errorMessageLogs: string;
  successMessageLogs: string;

  isFinishGetUser: boolean;
  isFinishGetGate: boolean;
  rowinErrDialog: number;
  errorMessageProcess: any;
  successItemProcess: number;
  totalDataRowProcess: number;
  showProgress: boolean;
  isFinishProcess: boolean;
  progress: number;
  groupOfTime: any;
}
export default Vue.extend({
  name: 'TimeAddGroupPage',
  components: {
    TimeAddGroupTemplate,
  },
  data: (): DataType => ({
    perPage: Config.PAGE_DEFAULT,
    currPage: 1,
    scorp: ['groupId'],
    keyword: '',
    groupTotalCount: 0,
    dataGroups: [],
    loading: false,
    errorMessageLogs: '',
    successMessageLogs: '',
    isFinishGetUser: false,
    isFinishGetGate: false,
    rowinErrDialog: 0,
    errorMessageProcess: [],
    successItemProcess: 0,
    totalDataRowProcess: 0,
    showProgress: false,
    isFinishProcess: false,
    progress: 0,
    showConfirm: false,
    groupOfTime: [],
  }),
  created() {
    if (typeof localStorage.groupSortKey === 'undefined') {
      localStorage.setItem('groupSortKey', 'registered');
    }
    if (typeof localStorage.groupSortName === 'undefined') {
      localStorage.setItem('groupSortName', 'desc');
    }
    if (typeof localStorage.pageSizeGroup === 'undefined') {
      localStorage.setItem('pageSizeGroup', Config.PAGE_DEFAULT.toString());
      this.perPage = Config.PAGE_DEFAULT;
    } else {
      this.perPage = parseInt(localStorage.pageSizeGroup);
    }
    const dataQuery = this.$route.query;
    this.loading = true;
    this.groupOfTime = [];
    this.getListGroups().then(() => {
      this.getGroupOfTime(1, dataQuery.passableTimeSettingId);
    });
  },
  watch: {
    isFinishGetUser(value) {
      if (this.isFinishGetGate && value) {
        this.loading = false;
      }
    },
    isFinishGetGate(value) {
      if (this.isFinishGetUser && value) {
        this.loading = false;
      }
    },
  },
  methods: {
    closeModalProgress() {
      this.showProgress = false;
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.loading = true;
      const dataQuery = this.$route.query;
      this.getGroupOfTime(1, dataQuery.passableTimeSettingId);
    },
    closeDialogConfirm() {
      this.showConfirm = false;
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showProgress = false;
      this.totalDataRowProcess = 0;
    },
    clickConfirm() {
      this.showConfirm = true;
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showProgress = false;
      this.totalDataRowProcess = 0;
    },
    async clickAddGroup(data: any) {
      this.showConfirm = false;
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showProgress = true;
      this.totalDataRowProcess = data.length;
      const percent = (100 / this.totalDataRowProcess);
      const dataQuery = this.$route.query;
      if (dataQuery.passableTimeSettingId !== undefined && dataQuery.passableTimeSettingId !== ''
      && dataQuery.passableTimeSettingId !== null) {
        for (const item of data) {
          let result;
          const array = {
            passableTimeSettingId: dataQuery.passableTimeSettingId,
          };
          result  = await this.postGroup(array, item);
          if (result) {
            this.successItemProcess++;
          }
          this.progress += percent;
        }
        this.isFinishProcess = true;
      }
    },
    postGroup(data: any, groupId: any) {
      return new Promise((resolve, reject) => {
        this.$http.put(Config.GROUP_LIST_API + '/' + encodeURIComponent(groupId), data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .then( null, (error: any) => {
            let errorName = '';
            switch (error.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (error.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 409:
                errorName = '選択されたゲートは既に使用されています。他のゲートを選択してください。';
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = groupId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    changePerPage(value: any): any {
      this.perPage = value;
      localStorage.setItem('pageSizeGroup', value);
      this.loading = true;
      this.getListGroups().then(() => {
        this.loading = false;
      });
    },
    changePage(value: any): any {
      this.currPage = value;
      this.loading = true;
      this.getListGroups().then(() => {
        this.loading = false;
      });
    },
    getListGroups(): any {
      return new Promise((resolve, reject) => {
        const apiLink = Config.GROUP_LIST_API + '?limit=' + localStorage.pageSizeGroup +
        '&page=' + this.currPage + '&keyword=' + this.keyword + '&scorp=' + this.scorp + '&sortKey=' +
        localStorage.groupSortName + ':' + localStorage.groupSortKey + '&isValid=true';
        this.$http.get(apiLink)
          .then((response: any) => {
            this.resetData();
            if (!this.isEmpty(response.body.groups)) {
              this.dataGroups = response.body.groups;
              this.groupTotalCount = response.body.maxRecords;
              this.currPage = Math.ceil(response.body.startRecord / this.perPage);
            } else {
              this.groupTotalCount = 0;
              this.currPage = 1;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(true);
            switch (err.status) {
                case 400:
                  this.errorMessageLogs = Config.ERROR_400;
                  break;
                case 403:
                  if (err.message === 'Unauthorized') {
                    this.errorMessageLogs = Config.ERROR_403;
                  } else {
                    this.errorMessageLogs = Config.ERROR_403_DENIED;
                  }
                  break;
                case 404:
                  this.errorMessageLogs = Config.ERROR_404;
                  break;
                case 500:
                  this.errorMessageLogs = Config.ERROR_500;
                  break;
                case 503:
                  this.errorMessageLogs = Config.ERROR_503;
                  break;
                default:
                  this.errorMessageLogs = Config.ERROR_DEFAULT;
                  break;
              }
          }); // catch
      });
    },
    getGroupOfTime(page: any , ID: any) {
      this.$http.get(Config.GROUP_LIST_API + '?passableTimeSettingId=' +
      encodeURIComponent(ID) + '&limit=1000&page=' + page + '&isValid=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.groups.forEach((element: any) => {
              this.groupOfTime.push({text: element.name, value: element.groupId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getGroupOfTime(page + 1, ID);
            } else {
              this.loading = false;
            }
          } else {
            this.groupOfTime = [];
            this.loading = false;
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          }); // catch
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.dataGroups = [];
      this.errorMessageLogs = '';
      // this.groupTotalCount = 0;
    },
  },
});
