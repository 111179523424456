




































































import Vue from 'vue';
import AlligHeader from '@/components/organisms/Header/Header.vue';
import SideNavigation from '@/components/organisms/SideNavigation/SideNavigation.vue';

export interface DataType {
  showNav: boolean;
  loading: boolean;
  activeName: string;
  userName: string;
  valueCircleLoading: number;
  textCircleLoading: string;
}

export default Vue.extend({
  name: 'LayoutTemplate',
  components: {
    AlligHeader,
    SideNavigation,
  },
  props: {
    pathName: {
      type: String,
      default: 'setting',
    },
    loadingParent: {
      type: Boolean,
      default: false,
    },
    selectedItemNav: {
      type: Number,
      default: 0,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    authority: {
      type: Object,
      default: ({}),
    },
    showSubMenu: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    showNav: true,
    activeName: 'setting',
    loading: false,
    userName: '',
    textCircleLoading: '',
    valueCircleLoading: 0,
  }),
  created() {
    this.userName = localStorage.userProfileId;
    const path = this.$route.path.replace('/', '');
    const arr = path.split('/');
    this.menuActive((arr[0] === 'in-room-management' && arr.length > 1) ? path : arr[0]);
    if (this.$vuetify.breakpoint.smAndDown) {
      this.showNav = false;
    }
  },
  watch : {
    pathName(newVal, oldVal) {
      this.activeName = newVal;
      this.menuActive(this.activeName);
    },
  },
  mounted() {
    //
  },
  methods: {
    Loading(value: any) {
      this.loading = value;
      this.textCircleLoading = '';
    },
    loadingCircle(value: any) {
      this.valueCircleLoading = value;
    },
    textCircle(value: any) {
      this.textCircleLoading = value;
    },
    menuActive(activeName: any) {
      this.textCircleLoading = '';
      let selectedItemNav = 1;
      switch (activeName) {
        case 'user' : selectedItemNav = 1; break;
        case 'groups' :
        case 'group' :
        selectedItemNav = 2; break;
        case 'cards' :
        case 'card' :
        selectedItemNav = 3; break;
        case 'gates' :
        case 'gate' :
        selectedItemNav = 4; break;
        case 'log' : selectedItemNav = 5; break;
        case 'setting' : selectedItemNav = 7; break;
        case 'reflection-status' : selectedItemNav = 8; break;
        case 'external' : selectedItemNav = 9; break;
        case 'safie' : selectedItemNav = 9; break;
        case 'in-room-management/room' : selectedItemNav = 10; break;
        case 'in-room-management/user' : selectedItemNav = 11; break;
        case 'key-add' : selectedItemNav = 13; break;
        default: selectedItemNav = 0; break;
      }
      localStorage.setItem('selectedItemNav', selectedItemNav.toString());
    },
    isClass(val: any) {
      let text = val;
      switch (val) {
        case 'user' :
        case 'groups' :
        case 'group' :
        case 'cards' :
        case 'card' :
        case 'gates' :
        case 'gate' :
        case 'log' :
        case 'in-room-management/room' :
        case 'in-room-management/user' :
        text = val + ' common-class' ; break;
      }
      return text;
    },
  },
});
