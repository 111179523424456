








































































































import Vue, { PropType } from 'vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import GroupAddUserListTable from '@/components/organisms/GroupAddUserListTable/GroupAddUserListTable.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import * as Config from '@/config';

export interface DataType {
  selectedUserIds: string[];
}
interface Type {
  userId: string;
  userName: string;
  gateId: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
}
interface ArrayDefault {
  text: string;
  value: string;
}
interface ArrayTime {
  passableTimeSettingId: string;
  name: string;
  dayTypeName: string;
  startTime: string;
  endTime: string;
}
export default Vue.extend({
  name: 'UserListTemplate',
  components: {
    SuccessButton,
    GroupAddUserListTable,
    Breadcrumbs,
    Modal,
    CancelButton,
  },
  props: {
    userTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    showConfirm: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    errorMessageProcess: {
      default: [],
    },
    userOfGroup: {
      default: [],
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataUsers: {
      type: Array as PropType<Type[]>,
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    goBack: {
      type: String,
      default: '',
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        if (this.goBack === 'groups') {
          return [
            { text: '通行権限グループ', disabled: true },
            { text: 'ユーザー', disabled: true },
          ];
        } else {
          return [
            { text: 'ゲート', disabled: true },
            { text: 'ユーザー', disabled: true },
          ];
        }
      },
    },
    showValid: {
      get(): boolean {
        return this.showConfirm;
      },
      set(val: boolean): void {
        if (val === false) {
          this.$emit('closeModal');
        }
        return;
      },
    },
  },
  methods: {
    clickBackGate() {
      this.$router.push({ path: '/' + this.goBack});
    },
  },
  data: (): DataType => ({
    selectedUserIds: [],
  }),
});
