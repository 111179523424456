var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c(
        "v-container",
        { staticClass: "pt-0", staticStyle: { position: "relative" } },
        [
          _c("ModalLoading", { attrs: { loading: _vm.loadingChild } }),
          _vm._v(" "),
          _vm.successMessageLogs !== "" &&
          _vm.groupsAddFail.length > 0 &&
          _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.Message.ERROR_GATE_EDIT.replace(
                          "GROUP_NAME",
                          _vm.groupsAddFail.toString()
                        )
                      )
                    }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.successMessageLogs !== "" &&
          _vm.groupsAddFail.length === 0 &&
          _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-success" },
                [_vm._v("\n      " + _vm._s(_vm.successMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [_vm._v("\n      " + _vm._s(_vm.errorMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("gate-lock-detail-table", {
            attrs: {
              gateDetail: _vm.gateDetail,
              loading: _vm.loading,
              groupList: _vm.groupList,
              groupId: _vm.groupId,
              showConfirm: _vm.showConfirm,
              dataUnlockingTime: _vm.dataUnlockingTime,
              successMessageModal: _vm.successMessageModal,
              errorMessageModal: _vm.errorMessageModal,
              dayTypes: _vm.dayTypes,
              isFinishEditGate: _vm.isFinishEditGate,
              isRemoteUnlockValid: _vm.isRemoteUnlockValid,
              isAdminOfRemoteUnlock: _vm.isAdminOfRemoteUnlock,
              btnUnlock: _vm.btnUnlock
            },
            on: {
              clickUpdateGate: function($event) {
                return _vm.$emit("clickUpdateGate", $event)
              },
              clickConfirm: function($event) {
                return _vm.$emit("clickConfirm", $event)
              },
              closeModalConfirm: function($event) {
                return _vm.$emit("closeModalConfirm", $event)
              },
              clickRemoteUnlock: function($event) {
                return _vm.$emit("clickRemoteUnlock", $event)
              },
              "gate-edit": function($event) {
                return _vm.$emit("gate-edit", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }