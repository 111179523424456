

































































































import Vue, { PropType } from 'vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';

import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
import moment from 'moment';
export interface DataType {
  selectedItem: any;
}

export default Vue.extend({
  name: 'GroupOfGateLockTable',
  components: {
    Pagination,
    ShowPerPage,
    TextLink,
    Modal,
    SuccessButton,
    CancelButton,
  },
  props: {
    isReRenderGroup: {
      default: 0,
    },
    isGroupOfGate: {
      default: false,
    },
    isShowConfirm: {
      default: false,
    },
    groupOfGate: {
      type: Array,
      default: () => [],
    },
    gateId: {
      default: '',
    },
    groupId: {
      default: '',
    },
    pageGroup: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 1,
    },
    isRemoveGroupSelected: {
      default: 0,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    groupTotalCountDel: {
      type: Number,
      default: 0,
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    errorMessageProcess: {
      type: Array,
      default: () => [],
    },
  },
  data: (): DataType => ({
    selectedItem: [],
  }),
  computed: {
    innerPageGroup: {
      get(): number {
        return this.pageGroup;
      },
      set(val: number): void {
        this.$emit('change-page-group', {page: val});
        return;
      },
    },
    isShow: {
      get(): boolean {
        return this.isShowConfirm;
      },
      set(val: boolean): void {
        if (val === false) {
          this.$emit('closeModalConfirm');
        }
        return;
      },
    },
    headersGroup: {
      get(): DataTableHeader[] {
        const headersGroup: DataTableHeader[] = [
          {
            text: '通行権限グループID	',
            align: 'start',
            sortable: false,
            value: 'group.groupId',
          },
          {
            text: '通行権限グループ名	',
            align: 'start',
            sortable: false,
            value: 'group.name',
          },
          {
            text: 'ステータス',
            align: 'start',
            sortable: false,
            value: 'isValid',
          },
        ];
        return headersGroup;
      },
      set(): void {
        return;
      },
    },
  },
  watch: {
    isReRenderGroup(value) {
      this.$forceUpdate();
    },
    isRemoveGroupSelected(value) {
      this.selectedItem = [];
    },
    selectedItem(value: any) {
      this.$emit('select-group', value.map((item: { relationId: any; }) => item.relationId));
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
        return items;
      }
      items.sort((a: any, b: any) => {
        if (index[0] === 'group.name') {
          if (!isDescending[0]) {
              return (a.group.name < b.group.name) ? -1 : 1;
          } else {
              return (b.group.name < a.group.name) ? -1 : 1;
          }
        } else {
          if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    converDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm:ss');
    },
    changePage(val: any) {
      this.$emit('change-page-group', {page: val, groupId: this.groupId});
    },
    clickJoinGroup(ID: any) {
      this.$router.push({ path: Config.GATE_ADD_USER_GROUP, query: {groupId: ID, goBack: 'groups'}});
    },
    clickDetail(ID: string) {
      this.$router.push({ path: Config.USER_DETAIL, query: {groupId: ID, goBack: 'gates'}});
    },
  },
});
