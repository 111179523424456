import { render, staticRenderFns } from "./DragAndDropFileInputForm.vue?vue&type=template&id=40ce5b51&"
import script from "./DragAndDropFileInputForm.vue?vue&type=script&lang=ts&"
export * from "./DragAndDropFileInputForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VChip,VCol,VFileInput,VRow,VSubheader})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\003742\\Documents\\PJ\\ALLIGATE_JOIN\\work\\Fixedbug_log\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('40ce5b51')) {
      api.createRecord('40ce5b51', component.options)
    } else {
      api.reload('40ce5b51', component.options)
    }
    module.hot.accept("./DragAndDropFileInputForm.vue?vue&type=template&id=40ce5b51&", function () {
      api.rerender('40ce5b51', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/DragAndDropInputForm/DragAndDropFileInputForm.vue"
export default component.exports