var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _vm.sucessMessage !== ""
      ? _c(
          "p",
          { staticClass: "mb-4 font-weight-bold mt-2 bg-success bg-no" },
          [_vm._v("\n      " + _vm._s(_vm.sucessMessage) + "\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.errorMessage !== ""
      ? _c(
          "span",
          { staticClass: "mb-4 font-weight-bold mt-2 bg-error bg-no" },
          [_vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }