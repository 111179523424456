

















import Vue from 'vue';
import UserChangePasswordForm from '@/components/organisms/UserChangePasswordForm/UserChangePasswordForm.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
export default Vue.extend({
  name: 'UserChangePasswordTemplate',
  components: {
    UserChangePasswordForm,
    Breadcrumbs,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    userInfo: {
      default: {},
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ホーム', disabled: true },
          { text: 'パスワード変更', disabled: true },
        ];
      },
    },
  },
  methods: {
  },
});
