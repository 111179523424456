
















import { PropType } from 'vue';
import {Vue } from 'vue-property-decorator';
import InRoomModalTemplate from '../../templates/InRoomModalTemplate/InRoomModalTemplate.vue';
import { Gate, Room } from '@/types/alligate';

export default Vue.extend({
  name: 'InRoomModalPage',
  components: {
    InRoomModalTemplate,
  },
  props: {
    roomInfo: {
      type: Object as () => Room,
      default: () => ({
        roomId: '',
        name: '',
        relationsRoomGate: [],
      }),
    },
    authority: {
      type: Object,
      default: ({}),
    },
    allGates: {
      type: Array as PropType<Gate[]>,
      default: () => [],
    },
    roomInfoLoading: {
      type: Boolean,
      default: false,
    },
    successMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  mounted() {
    this.$emit('load-room-by-id');
  },
});
