



























































import Vue, { PropType } from 'vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import GateList from '@/components/organisms/GateList/GateList.vue';
import * as Config from '@/config';
import { GatePassage} from '@/types/alligate';
import { DataTableHeader } from 'vuetify';

export interface DataType {
  selectedItem: GatePassage[];
}

export default Vue.extend({
  name: 'UserGateTable',
  components: {
    Pagination,
    ShowPerPage,
    TextLink,
    GateList,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array as PropType<GatePassage[]>,
      default: () => [],
    },
    gateList: {
      default: () => [],
    },
    userTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageUser: {
      type: Number,
      default: 1,
    },
    userId: {
      type: String,
      default: '',
    },
    perPage: {
      type: Number,
      default: 25,
    },
    relationsGateUserLoading: {
      type: Boolean,
      default: false,
    },
    ExpandedUser: {
      default: () => [],
    },
    userDetail: {
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    selectedItem: [],
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '通行権限グループ名',
            align: 'start',
            sortable: true,
            value: 'userId',
          },
          {
            text: '所属ゲート数',
            align: 'start',
            sortable: false,
            width: '200px',
            value: 'data-table-expand',
          },
        ];
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.pageUser;
      },
      set(val: number): void {
        this.$emit('change-page-user', {page: val, userId: this.userId});
        return;
      },
    },
  },
  watch: {
    selectedItem(val: GatePassage[]) {
      this.$emit('select-users', val.map((item) => item.passageId));
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
      });
      return items;
    },
    clickDetail(value: any) {
      this.$router.push({ path: Config.USER_DETAIL, query: {userId: value}});
    },
  },
});
