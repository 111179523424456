



















import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'SelectPerPage',
  props: {
    perPage: {
      type: Number,
      default: 25,
    },
    page: {
      type: Number,
      default: 1,
    },
    type: {
      type: String,
      default: '',
    },
    itemsLength: {
      type: Number,
      required: true,
    },
  },
});
