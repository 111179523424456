var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c(
        "v-row",
        { staticClass: "mb-2 mt-2 mr-2", attrs: { justify: "end" } },
        [
          _vm.authority.isAdmin === true || _vm.isAdminOfRemoteUnlock === true
            ? _c(
                "v-btn",
                {
                  staticClass: "btn-primary",
                  attrs: {
                    outlined: "",
                    disabled:
                      _vm.btnUnlock === false ||
                      _vm.gateDetail.isTwoStepAuthValid === true ||
                      _vm.gateDetail.isVisitor === true ||
                      _vm.gateDetail.makerCode === 91
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("clickRemoteUnlock")
                    }
                  }
                },
                [
                  _c("v-icon", { staticStyle: { color: "white !important" } }, [
                    _vm._v("mdi-lock-outline")
                  ]),
                  _vm._v("リモート解錠\n    ")
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.authority.isAdmin
            ? _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isRemoteUnlockValid,
                      expression: "isRemoteUnlockValid"
                    }
                  ],
                  staticClass: "btn-primary",
                  attrs: { outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("gate-edit", _vm.gateDetail.gateId)
                    }
                  }
                },
                [_vm._v("\n      ゲート情報を編集する\n    ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("v-container", { staticClass: "pt-0" }, [
        _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    staticClass:
                      "text-subtitle-2 border-left-blue font-weight-bold ml-3"
                  },
                  [_vm._v("\n          ゲート情報\n        ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "round-content-scroll vh75" },
              [
                _c(
                  "v-form",
                  { ref: "GateAddForm", attrs: { "lazy-validation": "" } },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", lg: "6" } },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "pa-6 pr-10 mb-5",
                                attrs: {
                                  id: "col-left",
                                  "min-height": _vm.filterSectionHeight
                                }
                              },
                              [
                                _c("div", { staticClass: "info-item" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col col-4" },
                                      [_c("v-subheader", [_vm._v("ゲートID")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-body-2 col align-self-center"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.gateDetail.gateId) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "info-item" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col col-4" },
                                      [_c("v-subheader", [_vm._v("製品ID")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-body-2 col align-self-center"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.gateDetail.gateModelId) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "info-item" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col col-4" },
                                      [_c("v-subheader", [_vm._v("製品名")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-body-2 col align-self-center"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.gateDetail.gateModelName
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "info-item" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col col-4" },
                                      [_c("v-subheader", [_vm._v("型番")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-body-2 col align-self-center"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.gateDetail.gateModelCode
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.iconType
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-4" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("アイコンタイプ")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getTextArray(
                                                    _vm.listIconType,
                                                    _vm.gateDetail.iconType
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHideAdvance(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.customizable
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-4" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("ターゲット動作設定Time")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gateDetail.controlTime
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "info-item" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col col-4" },
                                      [_c("v-subheader", [_vm._v("ゲート名")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-body-2 col align-self-center"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.gateDetail.gateName) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.isVisitor
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-4" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("ゲート動作モード")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getTextArray(
                                                  _vm.listIsVisitor,
                                                  _vm.gateDetail.isVisitor
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.isNFCValid
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-4" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("NFCの有効")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm.gateDetail.isNFCValid
                                              ? _c("span", [_vm._v("有効")])
                                              : _c("span", [_vm._v("無効")])
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.isTenKeyValid
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-4" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("テンキーの有効・無効")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm.gateDetail.isTenKeyValid
                                              ? _c("span", [_vm._v("有効")])
                                              : _c("span", [_vm._v("無効")])
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.isGatePasswordValid
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-4" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v(
                                                "ゲートパスワードの有効・無効"
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm.gateDetail.isGatePasswordValid
                                              ? _c("span", [_vm._v("有効")])
                                              : _c("span", [_vm._v("無効")])
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.gatePasswordLength
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-4" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("ゲートパスワードの桁数")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getTextArray(
                                                  _vm.listPassLenght,
                                                  _vm.gateDetail
                                                    .gatePasswordLength
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.gatePasswordSeed
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-4" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("ゲートパスワードのseed値")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gateDetail
                                                    .gatePasswordSeed
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.isTwoStepAuthValid
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-4" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("2段階認証の有効・無効")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm.gateDetail.isTwoStepAuthValid
                                              ? _c("span", [_vm._v("有効")])
                                              : _c("span", [_vm._v("無効")])
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.bleIntensity
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-4" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("BLE出力設定")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getTextArray(
                                                  _vm.listBleIntensity,
                                                  _vm.gateDetail.bleIntensity
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.buzzerPattern
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-4" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("ブザー鳴動設定")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getTextArray(
                                                  _vm.listBuzzerPattern,
                                                  _vm.gateDetail.buzzerPattern
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", lg: "6" } },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "pa-6 pr-10 mb-5",
                                attrs: {
                                  id: "col-right",
                                  "min-height": _vm.filterSectionHeight
                                }
                              },
                              [
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.needLocation
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-4" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("位置情報取得")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm.gateDetail.needLocation
                                              ? _c("span", [_vm._v("有効")])
                                              : _c("span", [_vm._v("無効")])
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "info-item" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col col-6" },
                                      [_c("v-subheader", [_vm._v("備考.1")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-body-2 col align-self-center"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.gateDetail.option1) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "info-item" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col col-6" },
                                      [_c("v-subheader", [_vm._v("備考.2")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-body-2 col align-self-center"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.gateDetail.option2) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.gateSNMain
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("ゲート個体番号1")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gateDetail.gateSNMain
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.gateSNBLE
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("ゲート個体番号2")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gateDetail.gateSNBLE
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.gateFWMain
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v(
                                                "適用されているファームウェアバージョン（メインボード）"
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gateDetail.gateFWMain
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.gateFWBLE
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v(
                                                "適用されているファームウェアバージョン（BLE）"
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gateDetail.gateFWBLE
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.registered
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("登録日")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.converDate(
                                                    _vm.gateDetail.registered
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.updated
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("更新日")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.converDate(
                                                    _vm.gateDetail.updated
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.reported
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v(
                                                "ゲートへ設定情報を適用した日時"
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.converDate(
                                                    _vm.gateDetail.reported
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.revisionDesired
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v(
                                                "個人コード照合データー：最新リビジョン"
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gateDetail.revisionDesired
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.revisionReported
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v(
                                                "個人コード照合データー：ゲートへ設定済みのリビジョン"
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gateDetail
                                                    .revisionReported
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.voltage
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("電池電圧")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.gateDetail.voltage) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkShowHide(
                                  _vm.gateDetail.makerCode,
                                  _vm.inputIndex.scanDate
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("電池電圧更新日")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.converDate(
                                                    _vm.gateDetail.scanDate
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "info-item" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col col-6" },
                                      [
                                        _c("v-subheader", [
                                          _vm._v("ステータス")
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-body-2 col align-self-center"
                                      },
                                      [
                                        _vm.gateDetail.isValid
                                          ? _c("span", [_vm._v("有効")])
                                          : _c("span", [_vm._v("無効")])
                                      ]
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _vm.displayAntiSetting(
                                  _vm.gateDetail.isVisitor,
                                  _vm.gateDetail.makerCode
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v(
                                                "アンチパスバックの有効・無効"
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm.gateDetail.isAntiPassback
                                              ? _c("span", [_vm._v("有効")])
                                              : _c("span", [_vm._v("無効")])
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.displayAntiSetting(
                                  _vm.gateDetail.isVisitor,
                                  _vm.gateDetail.makerCode
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v("アンチパスバックのモード")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm.gateDetail.antiPassback.mode ===
                                            0
                                              ? _c("span", [
                                                  _vm._v("ログモード")
                                                ])
                                              : _vm.gateDetail.antiPassback
                                                  .mode === 1
                                              ? _c("span", [
                                                  _vm._v("通行禁止モード")
                                                ])
                                              : _c("span")
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.displayAntiSetting(
                                  _vm.gateDetail.isVisitor,
                                  _vm.gateDetail.makerCode
                                )
                                  ? _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("v-subheader", [
                                              _vm._v(
                                                "アンチパスバックの対向ゲート名"
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 col align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.gateDetail.antiPassback
                                                    .gateName
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }