var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c(
        "v-row",
        { staticClass: "mb-2 mt-2 mr-2", attrs: { justify: "end" } },
        [
          _vm.authority.isAdmin === true || _vm.isAdminOfRemoteUnlock === true
            ? _c(
                "v-btn",
                {
                  staticClass: "btn-primary",
                  attrs: { outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("clickConfirm")
                    }
                  }
                },
                [
                  _c("v-icon", { staticStyle: { color: "white !important" } }, [
                    _vm._v("mdi-lock-outline")
                  ]),
                  _vm._v("リモート解錠\n    ")
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.authority.isAdmin
            ? _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isRemoteUnlockValid,
                      expression: "isRemoteUnlockValid"
                    }
                  ],
                  staticClass: "btn-primary",
                  attrs: { outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("clickEditGate", _vm.gateDetail)
                    }
                  }
                },
                [_vm._v("\n      ゲート情報を編集する\n    ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("v-container", { staticClass: "pt-0" }, [
        _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    staticClass:
                      "text-subtitle-2 border-left-blue font-weight-bold ml-3"
                  },
                  [_vm._v("\n          ゲート情報\n        ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "round-content-scroll " },
              [
                _c(
                  "v-form",
                  { ref: "GateAddForm", attrs: { "lazy-validation": "" } },
                  [
                    _c("v-card", { staticClass: "pa-6 pr-10 mb-5" }, [
                      _c("div", { staticClass: "info-item" }, [
                        _c("div", { staticClass: "title-display-block" }, [
                          _vm._v("ゲート名")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-body-2 font-weight-bold" },
                          [_vm._v(" " + _vm._s(_vm.gateDetail.gateName) + " ")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "info-item mt-3" }, [
                        _c("div", { staticClass: "title-display-block" }, [
                          _vm._v("製品モデル名(型番)")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-body-2 font-weight-bold" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.gateDetail.gateModelCode) + " "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "info-item mt-3" }, [
                        _c("div", { staticClass: "title-display-block" }, [
                          _vm._v("備考1")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-body-2 font-weight-bold" },
                          [_vm._v(" " + _vm._s(_vm.gateDetail.option1) + " ")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "info-item mt-3" }, [
                        _c("div", { staticClass: "title-display-block" }, [
                          _vm._v("備考2")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-body-2 font-weight-bold" },
                          [_vm._v(" " + _vm._s(_vm.gateDetail.option2) + " ")]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "info-item mt-3" },
                        [
                          _c("div", { staticClass: "title-display-block" }, [
                            _vm._v("通行権限グループ")
                          ]),
                          _vm._v(" "),
                          _vm.groupList.length > 0
                            ? _c(
                                "v-row",
                                _vm._l(_vm.groupList, function(item) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: item.value,
                                      attrs: { md: "4", sm: "6", cols: "12" }
                                    },
                                    [
                                      _c("text-link", {
                                        staticClass: "font-weight-bold",
                                        attrs: { text: item.text },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickGroup(item.value)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "text-subtitle-2 border-left-blue font-weight-bold"
                          },
                          [_vm._v("\n            連続解錠時間\n          ")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "table-edit-time pl-0 table-edit-time-gate time-period"
                      },
                      [
                        _c("v-data-table", {
                          staticClass:
                            "border-table shadow-table scroll-table table-no-bg",
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.dataUnlockingTime,
                            "no-data-text": "データーはありません",
                            "hide-default-footer": "",
                            "items-per-page": -1
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "item",
                              fn: function(row) {
                                return [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "text-body-2 font-weight-bold"
                                      },
                                      [_vm._v(_vm._s(row.item.dayTypeName))]
                                    ),
                                    _vm._v(" "),
                                    row.item.allDay
                                      ? _c(
                                          "td",
                                          {
                                            staticClass:
                                              "text-body-2 font-weight-bold"
                                          },
                                          [_vm._v("終日")]
                                        )
                                      : _c(
                                          "td",
                                          _vm._l(row.item.listTime, function(
                                            item
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-body-2 font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.startTime) +
                                                    " ~\n                    " +
                                                    _vm._s(item.endTime)
                                                )
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                  ])
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("Modal", {
        attrs: {
          title: "リモート解錠",
          smallScreen: "600px",
          className: "common-modal"
        },
        scopedSlots: _vm._u([
          {
            key: "modal-body",
            fn: function() {
              return [
                _c(
                  "v-row",
                  [
                    _vm.successMessageModal === "" &&
                    _vm.errorMessageModal === ""
                      ? _c("v-col", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.gateDetail.gateName) +
                              "ゲートをリモート解錠します。\n          "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.successMessageModal !== ""
                      ? _c(
                          "v-col",
                          { staticClass: "font-weight-bold bg-success no-bg" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.successMessageModal) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.errorMessageModal !== ""
                      ? _c("v-col", {
                          staticClass: "font-weight-bold bg-error no-bg",
                          domProps: { innerHTML: _vm._s(_vm.errorMessageModal) }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c("v-spacer"),
                _vm._v(" "),
                _vm.errorMessageModal === "" && _vm.successMessageModal === ""
                  ? _c("success-button", {
                      attrs: { text: "OK" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("clickRemoteUnlock")
                        }
                      }
                    })
                  : _c("success-button", {
                      attrs: { text: "OK" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("closeModalConfirm")
                        }
                      }
                    })
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.isConfirm,
          callback: function($$v) {
            _vm.isConfirm = $$v
          },
          expression: "isConfirm"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }