var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "text-subtitle-2 font-weight-bold" }, [
            _vm._v("\n      制御盤殳定情報\n    ")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("input-form", {
        attrs: { value: "", title: "名前", inputType: "text" }
      }),
      _vm._v(" "),
      _c("input-form", {
        attrs: { value: "", title: "詳細", inputType: "text" }
      }),
      _vm._v(" "),
      _c("v-row", {
        staticStyle: { height: "20px" },
        attrs: { "no-gutters": "" }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "text-subtitle-2 font-weight-bold" }, [
            _vm._v("\n      ゲート情報\n    ")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "align-center custom-label", attrs: { md: "3" } },
        [_vm._v("\n    Onelogin でログイン\n  ")]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "align-center custom-label", attrs: { md: "3" } },
        [_vm._v("\n    Onelogin でログイン\n  ")]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "text-subtitle-2 font-weight-bold" }, [
            _vm._v("\n      アンチパスバック設定\n    ")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("input-form", {
        attrs: { title: "SMS 2段階認証", inputType: "switch" }
      }),
      _vm._v(" "),
      _c("input-form", {
        attrs: { title: "SMS 2段階認証", inputType: "switch" },
        model: {
          value: _vm.showAntiPass,
          callback: function($$v) {
            _vm.showAntiPass = $$v
          },
          expression: "showAntiPass"
        }
      }),
      _vm._v(" "),
      _vm.showAntiPass
        ? _c(
            "v-card",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-subheader", [
                        _vm._v("\n          クリア時間\n        ")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col")
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }