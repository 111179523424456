import * as GroupUtils from '@/utils/alligate/group';
import * as DayTypeUtils from '@/utils/alligate/day-type';
import * as ControllerUtils from '@/utils/alligate/controller';
import * as PlanUtils from '@/utils/alligate/plan';
import * as UserUtils from '@/utils/alligate/user';
import * as RoomUtils from '@/utils/alligate/room';
import * as LogUtils from '@/utils/alligate/log';
import * as NoticeSettingUtils from '@/utils/alligate/notice-setting';
import * as NoticeSettingTypeUtils from '@/utils/alligate/notice-setting-type';

export default {
  controller: ControllerUtils,
  dayType: DayTypeUtils,
  group: GroupUtils,
  plan: PlanUtils,
  user: UserUtils,
  room: RoomUtils,
  log: LogUtils,
  noticeSettingType: NoticeSettingTypeUtils,
  noticeSetting: NoticeSettingUtils,
};
