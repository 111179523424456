var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-gate-table" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "", justify: "space-between" } },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c("show-per-page", {
            staticClass: "ml-4 mb-2",
            attrs: {
              "per-page": 25,
              page: _vm.pageGroup,
              "items-length": _vm.groupTotalCount
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "border-table scroll-table",
        attrs: {
          "must-sort": "",
          "custom-sort": _vm.customSort,
          loading: _vm.loading,
          headers: _vm.headers,
          items: _vm.groups,
          "items-per-page": 25,
          "no-data-text": "   該当する通行権限グループがありません",
          "loading-text": "データを読み込中です",
          "hide-default-footer": "",
          dense: "",
          "item-key": "groupId"
        },
        on: {
          "item-expanded": function($event) {
            return _vm.$emit("change-expanded-group", {
              event: $event,
              passableTimeSettingId: _vm.passableTimeSettingId
            })
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("text-link", {
                    staticClass: "item-user-name",
                    attrs: { text: item.name },
                    on: {
                      click: function($event) {
                        return _vm.clickDetail(item.groupId)
                      }
                    }
                  })
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectedItem,
          callback: function($$v) {
            _vm.selectedItem = $$v
          },
          expression: "selectedItem"
        }
      }),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-3",
          attrs: { "no-gutters": "", justify: "space-between" }
        },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c("pagination", {
            staticClass: "mb-1",
            attrs: {
              "page-length": Math.ceil(_vm.groupTotalCount / 25),
              disabled: _vm.loading
            },
            model: {
              value: _vm.innerPage,
              callback: function($$v) {
                _vm.innerPage = $$v
              },
              expression: "innerPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }