
























import Vue from 'vue';
export default Vue.extend({
  name: 'ReloadButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
