var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadingParent
        ? _c(
            "v-overlay",
            { attrs: { value: _vm.loadingParent, color: "white" } },
            [
              _vm.textCircleLoading === ""
                ? _c("v-progress-circular", {
                    staticClass: "user-loading-progress",
                    attrs: { indeterminate: "", color: "green" }
                  })
                : _vm._e()
            ],
            1
          )
        : _c(
            "v-overlay",
            { attrs: { value: _vm.loading, color: "white" } },
            [
              _vm.textCircleLoading === ""
                ? _c("v-progress-circular", {
                    staticClass: "user-loading-progress text-loading",
                    attrs: { indeterminate: "", color: "green" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.textCircleLoading !== ""
                ? _c("div", { attrs: { id: "title-loading" } }, [
                    _vm._v(_vm._s(_vm.textCircleLoading))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.textCircleLoading !== ""
                ? _c(
                    "v-progress-circular",
                    {
                      attrs: {
                        indeterminate: "",
                        size: 100,
                        width: 15,
                        value: _vm.valueCircleLoading,
                        color: "green"
                      }
                    },
                    [
                      _vm._v(
                        "\n    " + _vm._s(_vm.valueCircleLoading) + "%\n  "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
      _vm._v(" "),
      _c("allig-header", {
        attrs: { userName: _vm.userName },
        on: {
          "click-nav": function() {
            return (_vm.showNav = !_vm.showNav)
          },
          "click-guid": function($event) {
            return _vm.$emit("click-guide")
          },
          "click-notice": function($event) {
            return _vm.$emit("click-notice")
          },
          "click-user-setting": function($event) {
            return _vm.$emit("click-user-setting")
          },
          "click-user-change-password": function($event) {
            return _vm.$emit("click-user-change-password")
          },
          "click-logout": function($event) {
            return _vm.$emit("click-logout")
          }
        }
      }),
      _vm._v(" "),
      _c("side-navigation", {
        attrs: {
          isAdmin: _vm.isAdmin,
          authority: _vm.authority,
          selectedItemNav: _vm.selectedItemNav,
          showSubMenu: _vm.showSubMenu
        },
        on: {
          menuClick: function($event) {
            return _vm.$emit("menuClick", $event)
          }
        },
        model: {
          value: _vm.showNav,
          callback: function($$v) {
            _vm.showNav = $$v
          },
          expression: "showNav"
        }
      }),
      _vm._v(" "),
      _c(
        "v-main",
        { staticClass: "allig-main", class: _vm.isClass(_vm.activeName) },
        [
          _c(
            "v-container",
            { staticClass: "allig-container", attrs: { fluid: "" } },
            [
              _c("router-view", {
                attrs: { isAdmin: _vm.isAdmin, authority: _vm.authority },
                on: {
                  loading: function($event) {
                    return _vm.Loading($event)
                  },
                  loadingCircle: function($event) {
                    return _vm.loadingCircle($event)
                  },
                  textCircle: function($event) {
                    return _vm.textCircle($event)
                  },
                  activeMenu: function($event) {
                    return _vm.$emit("activeMenu", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }