









import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ModalTitle',
  components: {
    //
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: 'background-color-green',
    },
  },
});
