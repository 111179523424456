













































































import Vue, { PropType } from 'vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import Search, { SearchTargetItem, SearchValue } from '@/components/molecules/Search/Search.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import ReloadButton from '@/components/molecules/ReloadButton/ReloadButton.vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import { User } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
import store from '@/store';

export interface DataType {
  searchTargetItems: SearchTargetItem[];
  authority: any;
}

export default Vue.extend({
  name: 'InRoomUserTable',
  components: {
    Pagination,
    Search,
    SelectPerPage,
    ShowPerPage,
    TextLink,
    ReloadButton,
    Loading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    searchValue: {
      type: Object as PropType<SearchValue>,
      default: () => ({
        text: '',
        targets: ['userId', 'name'],
      }),
    },
  },
  data: (): DataType => ({
    searchTargetItems: [
      { label: 'ユーザーID', value: 'userId' },
      { label: '名前', value: 'name' },
    ],
    authority:  store.state.auth,
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const ret: DataTableHeader[] =  [{
          text: '名前',
          value: 'name',
          sortable: true,
        },
        {
          text: 'ユーザーID',
          value: 'userId',
          align: 'start',
          sortable: true,
        },
        {
          text: '',
          value: 'icon',
          align: 'end',
          sortable: false,
        }];
        return ret;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerSearchValue: {
      get(): SearchValue {
        return this.searchValue;
      },
      set(val: SearchValue): void {
        this.$emit('input-search-value', val);
        return;
      },
    },
  },
  methods: {
    goRoomUserPage(val: any) {
      this.$router.push({ path: Config.IN_ROOM_MANAGEMENT_STATUS_USER, query: {userId: val}});
    },
  },
});
