var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-table aff-table" },
    [
      _c(
        "v-div",
        [
          _c("v-container", { staticClass: "pt-0 pb-0" }, [
            _vm.successMessageLogs !== "" &&
            _vm.loading === false &&
            _vm.expandData.errorData.groups.length === 0 &&
            _vm.expandData.errorData.users.length === 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "pa-2 mt-2 font-weight-bold bg-success align-self-center mr-2"
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.successMessageLogs) +
                        "\n          "
                    )
                  ]
                )
              : (_vm.successMessageLogs !== "" ||
                  _vm.errorMessageLogs !== "") &&
                _vm.loading === false
              ? _c(
                  "div",
                  {
                    staticClass:
                      "font-weight-bold bg-error align-self-center mr-2 pa-2 mt-2"
                  },
                  [
                    _vm.successMessageLogs !== "" && _vm.loading === false
                      ? _c("p", [_vm._v(_vm._s(_vm.successMessageLogs))])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.errorMessageLogs !== "" && _vm.loading === false
                      ? _c("p", [_vm._v(_vm._s(_vm.errorMessageLogs))])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.expandData.errorData.users.length > 0 &&
                    _vm.loading === false
                      ? _c("p", [
                          _vm._v(
                            "登録失敗所属ユーザー: " +
                              _vm._s(_vm.expandData.errorData.users.toString())
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.expandData.errorData.groups.length > 0 &&
                    _vm.loading === false
                      ? _c("p", [
                          _vm._v(
                            "設定失敗通行権限グループ: " +
                              _vm._s(_vm.expandData.errorData.groups.toString())
                          )
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "round-content-scroll vh75 pa-0 mt-3 pt-2" },
            [
              _c("div", { staticClass: "title-block-card mt-0 mb-0 ml-3" }, [
                _vm._v("所属情報")
              ]),
              _vm._v(" "),
              _c(
                "v-container",
                [
                  _c(
                    "v-card",
                    { staticClass: "affiliation-information" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "title-display-block" }, [
                              _vm._v("所属名 ")
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "col-12" },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "AffAddForm",
                                  attrs: { "lazy-validation": "" }
                                },
                                [
                                  _c("input-form", {
                                    attrs: {
                                      rules: [
                                        _vm.rulesValidate.requiredName,
                                        _vm.rulesValidate.validateName
                                      ]
                                    },
                                    model: {
                                      value: _vm.departmentInfo.name,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.departmentInfo,
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression: "departmentInfo.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.authority.isCustomUserAuthorityService
                        ? _c(
                            "div",
                            { staticClass: "mt-5" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { staticClass: "col-12" }, [
                                    _c(
                                      "div",
                                      { staticClass: "title-display-block" },
                                      [_vm._v("所属管理者 ")]
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.listAff, function(item, i) {
                                return _c(
                                  "v-row",
                                  { key: i, staticClass: "mt-2 ml-5" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { sm: "10", lg: "3" } },
                                      [
                                        _c(
                                          "v-row",
                                          { staticClass: "mt-2 mb-2" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "btn-del-aff",
                                                attrs: { cols: "1" }
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: { icon: "", al: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteAff(i)
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "gray--text",
                                                        attrs: {
                                                          "align-self": "ceb="
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-minus-circle"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "10" } },
                                              [
                                                _c("v-combobox", {
                                                  staticClass: "v-select-group",
                                                  attrs: {
                                                    items: _vm.dataAllow,
                                                    autocomplete: "off",
                                                    color: "green",
                                                    "hide-details": "auto",
                                                    "no-data-text": "",
                                                    outlined: "",
                                                    dense: "",
                                                    "menu-props": {
                                                      contentClass:
                                                        "v-select-item v-select-item-disabled"
                                                    },
                                                    disabled:
                                                      _vm.dataAllow.length == 0
                                                  },
                                                  on: {
                                                    change: _vm.changeAllow
                                                  },
                                                  model: {
                                                    value: item.userId,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "userId",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.userId"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "mt-2",
                                        attrs: { sm: "12", lg: "9" }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "item-check-box-detail"
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "6", lg: "3" } },
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    disabled: "",
                                                    color: "grey",
                                                    dense: "",
                                                    "hide-details": "",
                                                    label: "ユーザー閲覧権限"
                                                  },
                                                  model: {
                                                    value: item.isAdminToView,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "isAdminToView",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.isAdminToView"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "6", lg: "3" } },
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    color: "green",
                                                    dense: "",
                                                    "hide-details": "",
                                                    label: "ユーザー追加権限"
                                                  },
                                                  model: {
                                                    value:
                                                      item.isAdminToAddUser,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "isAdminToAddUser",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.isAdminToAddUser"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "6", lg: "3" } },
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    color: "green",
                                                    dense: "",
                                                    "hide-details": "",
                                                    label: "ユーザー編集権限"
                                                  },
                                                  model: {
                                                    value:
                                                      item.isAdminToEditUser,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "isAdminToEditUser",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.isAdminToEditUser"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "6", lg: "3" } },
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    color: "green",
                                                    dense: "",
                                                    "hide-details": "",
                                                    label: "カード設定権限"
                                                  },
                                                  model: {
                                                    value:
                                                      item.isAdminToSetPcodesToUser,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "isAdminToSetPcodesToUser",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.isAdminToSetPcodesToUser"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c("v-col", { staticClass: "col-2 pt-0" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "v-subheader theme--light ml-0 pa-0 mt-0 add-button",
                                        on: { click: _vm.addAffiliation }
                                      },
                                      [_vm._v(" + 追加する ")]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _c("div", { staticClass: "mt-5" })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title-block-card title-margin" }, [
                    _vm._v("所属ユーザー")
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c("UsersAllowedTable", {
                        attrs: {
                          isActionPage: "add",
                          authority: _vm.authority,
                          departmentId: "",
                          dataList: _vm.dataDirectUser.data,
                          isPage: "affiliation-add-new",
                          loading: _vm.dataDirectUser.loading,
                          totalCount: _vm.dataDirectUser.total,
                          perPage: _vm.dataDirectUser.perPage,
                          page: _vm.dataDirectUser.page,
                          users: _vm.expandData.userAll,
                          dataRelationUser: {}
                        },
                        on: {
                          "change-page": function($event) {
                            return _vm.changePageUserDirect($event)
                          },
                          "search-user": function($event) {
                            return _vm.searchItemDirect($event)
                          },
                          "affiliation-add-item-direct": function($event) {
                            return _vm.affiliationAddItemDirect($event)
                          },
                          "affiliation-remove-item-direct": function($event) {
                            return _vm.affiliationRemoveItemDirect($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title-block-card title-margin" }, [
                    _vm._v("設定されている通行権限グループ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c("AccessPermissionGroupTable", {
                        attrs: {
                          isActionPage: "add",
                          authority: _vm.authority,
                          dataGroupsModal: _vm.dataDirectGroup,
                          gateGroup: _vm.expandData.gateGroup,
                          isPage: "affiliation-add-new",
                          loading: _vm.dataDirectGroup.loading,
                          page: _vm.dataDirectGroup.page,
                          dataRelation: {},
                          groups: _vm.expandData.groupAll
                        },
                        on: {
                          "expand-gate-group": function($event) {
                            return _vm.$emit("expand-gate-group", $event)
                          },
                          "change-page": function($event) {
                            return _vm.changePageGroupDirect($event)
                          },
                          "change-page-gate-group": function($event) {
                            return _vm.$emit("change-page-gate-group", $event)
                          },
                          "remove-group": function($event) {
                            return _vm.removeGroupDirect($event)
                          },
                          "add-group": function($event) {
                            return _vm.addGroupDirect($event)
                          },
                          "load-list-groups": function($event) {
                            return _vm.$emit("load-list-groups", {
                              page: 1,
                              perPage: 25
                            })
                          },
                          "change-page-group-all": function($event) {
                            return _vm.$emit("change-page-group-all", $event)
                          },
                          "change-per-page-group-all": function($event) {
                            return _vm.$emit(
                              "change-per-page-group-all",
                              $event
                            )
                          },
                          "search-group": function($event) {
                            return _vm.searchGroupDirect($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _vm.successMessageLogs !== "" &&
                      _vm.loading === false &&
                      _vm.expandData.errorData.groups.length === 0 &&
                      _vm.expandData.errorData.users.length === 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "font-weight-bold bg-success bg-no align-self-center mr-2"
                            },
                            [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(_vm.successMessageLogs) +
                                  "\n                    "
                              )
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "font-weight-bold bg-error bg-no align-self-center mr-2"
                            },
                            [
                              _vm.successMessageLogs !== "" &&
                              _vm.loading === false
                                ? _c("p", [
                                    _vm._v(_vm._s(_vm.successMessageLogs))
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.errorMessageLogs !== "" &&
                              _vm.loading === false
                                ? _c("p", [
                                    _vm._v(_vm._s(_vm.errorMessageLogs))
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.expandData.errorData.users.length > 0 &&
                              _vm.loading === false
                                ? _c("p", [
                                    _vm._v(
                                      "登録失敗所属ユーザー: " +
                                        _vm._s(
                                          _vm.expandData.errorData.users.toString()
                                        )
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.expandData.errorData.groups.length > 0 &&
                              _vm.loading === false
                                ? _c("p", [
                                    _vm._v(
                                      "設定失敗通行権限グループ: " +
                                        _vm._s(
                                          _vm.expandData.errorData.groups.toString()
                                        )
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.registerAff()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                      作成する\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }