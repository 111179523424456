





























import {Vue } from 'vue-property-decorator';
import GateLockAddUserTemplate from '@/components/templates/GateLockAddUserTemplate/GateLockAddUserTemplate.vue';
import { User } from '@/types/alligate';
import * as Config from '@/config';
import moment from 'moment';
export interface DataType {
  dataUsers: any;
  perPage: number;
  currPage: number;
  keyword: string;
  scorp: any;
  userTotalCount: number;

  loading: boolean;
  errorMessageLogs: string;
  successMessageLogs: string;

  isFinishGetGate: boolean;
  rowinErrDialog: number;
  errorMessageProcess: any;
  userOfGate: any;
  successItemProcess: number;
  totalDataRowProcess: number;
  showProgress: boolean;
  isFinishProcess: boolean;
  showConfirm: boolean;
  progress: number;
}
export default Vue.extend({
  name: 'GateLockAddUserPage',
  components: {
    GateLockAddUserTemplate,
  },
  data: (): DataType => ({
    perPage: Config.PAGE_DEFAULT,
    currPage: 1,
    scorp: ['userId'],
    keyword: '',
    userTotalCount: 0,
    dataUsers: [],
    loading: false,
    errorMessageLogs: '',
    successMessageLogs: '',
    isFinishGetGate: false,
    rowinErrDialog: 0,
    errorMessageProcess: [],
    userOfGate: [],
    successItemProcess: 0,
    totalDataRowProcess: 0,
    showProgress: false,
    isFinishProcess: false,
    showConfirm: false,
    progress: 0,
  }),
  created() {
    if (typeof localStorage.pageSizeUser === 'undefined') {
      localStorage.setItem('pageSizeUser', Config.PAGE_DEFAULT.toString());
      this.perPage = Config.PAGE_DEFAULT;
    } else {
      this.perPage = parseInt(localStorage.pageSizeUser);
    }
    const dataQuery = this.$route.query;
    this.loading = true;
    this.userOfGate = [];
    this.getListUsers().then(() => {
      this.getUserOfGate(1, dataQuery.gateId);
    });
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
    isFinishGetGate(value) {
      if (this.isFinishGetGate && value) {
        this.loading = false;
      }
    },
  },
  methods: {
    closeModalProgress() {
      this.showProgress = false;
      this.showConfirm = false;
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
    },
    clickConfirm() {
      this.showConfirm = true;
    },
    goBackGateLockEdit() {
      if (this.$route.query.goBack !== undefined && this.$route.query.goBack === 'gate-lock-edit') {
        // this.$router.push({ path: Config.GATE_LOCK_EDIT, query: {gateId: this.$route.query.gateId}});
        this.$router.push({ path: Config.GROUP_DETAIL + '/?groupId=' + this.$route.query.groupId});
      } else {
        this.$router.push({ path: Config.GATE, query: {goBack: 'gate-lock'}});
      }
    },
    async clickAddUser(data: any) {
      this.errorMessageProcess = [];
      this.showConfirm = false;
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showProgress = true;
      this.totalDataRowProcess = data.length;
      const percent = this.totalDataRowProcess > 0 ? (100 / this.totalDataRowProcess) : 0;
      const dataQuery = this.$route.query;
      if (dataQuery.gateId !== undefined && dataQuery.gateId !== '' && dataQuery.gateId !== null) {
        if (this.totalDataRowProcess === 0) {
          this.successItemProcess++;
          this.progress = 100;
        } else {
          for (const item of data) {
            let result;
            const dt = {
              gateId: dataQuery.gateId,
              userId: item.userId,
              notBefore: this.formatTimestamp(item.begin),
              notAfter: this.formatTimestamp(item.end),
            };
            result  = await this.postUser(dt, item.userId);
            if (result) {
              this.successItemProcess++;
            }
            this.progress += percent;
          }
        }
        this.isFinishProcess = true;
      }
    },
    postUser(data: any, userId: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.GATES_PASSAGE_API, data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .then( null, (error: any) => {
            let errorName = '';
            switch (error.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (error.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 409:
                errorName = Config.ERROR_409_GATE_PASSAGE;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = userId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    getUserOfGate(page: any , gateId: any) {
      this.$http.get(Config.GATES_PASSAGE_API + '?gateId=' +
      encodeURIComponent(gateId) + '&limit=1000&page=' + page)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.passages.forEach((element: any) => {
              this.userOfGate.push({text: element.userId, value: element.userId,
              notAfter: element.notAfter, notBefore: element.notBefore});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getUserOfGate(page + 1, gateId);
            } else {
              this.loading = false;
            }
          } else {
            this.userOfGate = [];
            this.loading = false;
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          }); // catch
    },
    changePerPage(value: any): any {
      this.perPage = value;
      localStorage.setItem('pageSizeUser', value);
      this.loading = true;
      this.getListUsers().then(() => {
        this.loading = false;
      });
    },
    changePage(value: any): any {
      this.currPage = value;
      this.loading = true;
      this.getListUsers().then(() => {
        this.loading = false;
      });
    },
    searchUser(value: any): any {
      this.keyword = value.text;
      this.scorp = value.targets;
      this.loading = true;
      this.dataUsers = [];
      this.getListUsers().then(() => {
        this.loading = false;
      });
    },
    waitPush() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, 500);
      });
    },
    getListUsers(): any {
      return new Promise((resolve, reject) => {
        let apiLink = Config.USERS_LIST_API + '?limit=' + localStorage.pageSizeUser +
        '&page=' + this.currPage + '&isExceptOptionalUser=true&isValid=true&exclude=thumbnail';
        if (this.keyword !== '') {
          apiLink += '&keyword=' + this.keyword + '&scorp=' + this.scorp;
        }
        this.$http.get(apiLink)
          .then(async (response: any) => {
            this.resetData();
            const responseData = response.body.users;
            if (!this.isEmpty(responseData)) {
              for (let i = 0; i < responseData.length; i++) {
                responseData[i].begin = '';
                responseData[i].end = '';
                if (i % 50 === 0) {
                  await this.waitPush();
                  this.dataUsers.push(responseData[i]);
                } else {
                  this.dataUsers.push(responseData[i]);
                }
              }
              this.userTotalCount = response.body.maxRecords;
              this.currPage = Math.ceil(response.body.startRecord / this.perPage);
            } else {
              this.userTotalCount = 0;
              this.currPage = 1;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
              }
            resolve(true);
          }); // catch
      });
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.dataUsers = [];
      this.errorMessageLogs = '';
      // this.userTotalCount = 0;
    },
    formatTimestamp(t: string) {
      return t !== '' ? moment(t).unix() : '';
    },
  },
});
