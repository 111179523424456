var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("control-panel-add-template", {
    attrs: {
      loading: _vm.loading,
      dataControlPanels: _vm.dataControlPanels,
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      typeItems: _vm.typeItems,
      isEdit: _vm.isEdit,
      gateList: _vm.gateList,
      controllerModel: _vm.controllerModel,
      dataAllGates: _vm.dataAllGates,
      loadingChild: _vm.loadingChild
    },
    on: {
      clickBackControlPanel: function($event) {
        return _vm.clickBackControlPanel($event)
      },
      "close-modal-control": function($event) {
        return _vm.$emit("close-modal-control", $event)
      },
      clickSaveControlPanel: function($event) {
        return _vm.clickSaveControlPanel($event)
      },
      clickUpdateControlPanel: function($event) {
        return _vm.clickUpdateControlPanel($event)
      },
      changeModel: function($event) {
        return _vm.changeModel($event)
      },
      "loading-from-child": function($event) {
        return _vm.$emit("loading", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }