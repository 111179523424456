var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "in-room-user-table" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c("loading", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ]
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c("search", {
                    attrs: {
                      label: "ユーザーを検索",
                      searchTargetItems: _vm.searchTargetItems,
                      disabled: _vm.loading
                    },
                    on: {
                      search: function($event) {
                        return _vm.$emit("search", $event)
                      }
                    },
                    model: {
                      value: _vm.innerSearchValue,
                      callback: function($$v) {
                        _vm.innerSearchValue = $$v
                      },
                      expression: "innerSearchValue"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "ml-1", attrs: { cols: "auto" } },
                [
                  _c("reload-button", {
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.$emit("reload", $event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "table-shadow table-no-bg mt-5",
            attrs: {
              loading: _vm.loading,
              headers: _vm.headers,
              items: _vm.users,
              "items-per-page": _vm.perPage,
              "fixed-header": "",
              "no-data-text": "在室中のユーザーはいません",
              "loading-text": "データを読み込み中です",
              "hide-default-footer": "",
              dense: "",
              "item-key": "userId"
            },
            on: {
              "update:options": function($event) {
                return _vm.$emit("change-sort", $event)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.userId",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v("\n        " + _vm._s(item.userId) + "\n      ")
                    ]
                  }
                },
                {
                  key: "item.name",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm.authority.isAdmin || _vm.authority.isAdminToViewUser
                        ? _c("text-link", {
                            attrs: { text: item.name },
                            on: {
                              click: function($event) {
                                return _vm.$emit("click-user-name", item.userId)
                              }
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(item.name))])
                    ]
                  }
                },
                _vm.authority.isAdmin || _vm.authority.isAdminToViewUser
                  ? {
                      key: "item.icon",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.goRoomUserPage(
                                                      item.userId
                                                    )
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-text-box")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [_vm._v("ユーザの在室状況を見る")])
                            ]
                          )
                        ]
                      }
                    }
                  : null
              ],
              null,
              true
            )
          }),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "mt-3",
              attrs: { "no-gutters": "", justify: "space-between" }
            },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("pagination", {
                staticClass: "ml-5 mb-1",
                attrs: {
                  "page-length": Math.ceil(_vm.totalCount / _vm.perPage),
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.innerPage,
                  callback: function($$v) {
                    _vm.innerPage = $$v
                  },
                  expression: "innerPage"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }