import Vue from 'vue';
import * as XlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';

export class Excel {
  protected fileName: string;
  protected url: string;
  protected workbook: any;

  constructor() {
    this.fileName = '';
    this.url = '';
  }

  /**
   * Excelファイルをダウンロードする
   * @param {Vue} vue Vueインスタンス
   */
  public async downloadExcelFromCloud(vue: Vue) {
    try {
      const response = await vue.$axios.get(this.url, {
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      });
      this.workbook = await XlsxPopulate.fromDataAsync(response.data);
    } catch (err) {
      throw new Error('Excelテンプレートをダウンロードするのに失敗しました。');
    }
  }

  /**
   * Excelをダウンロードする
   */
  public async downloadExcel() {
    try {
      const wbout = await this.workbook.outputAsync();
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      saveAs(blob, this.fileName);
    } catch (err) {
      throw new Error('Excelエクスポートに失敗しました。');
    }
  }
}
