
























































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';

export default Vue.extend({
  name: 'Modal',
  components: {
    CancelButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '処理中です.......',
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    finishedCount: {
      type: Number,
      default: 0,
    },
    showPercentage: {
      type: Boolean,
      default: true,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    errorCount: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    errorContents: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    isShort: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        if (val === false) {
          this.$emit('close');
        }
        this.$emit('input', val);
        return;
      },
    },
    percent: {
      get(): number {
        if (this.totalCount <= this.finishedCount) {
          return 100;
        } else if (this.totalCount === 0) {
          return 100;
        } else {
          return Math.floor(this.finishedCount / this.totalCount * 100);
        }
      },
    },
    finished: {
      get(): boolean {
        return this.totalCount === this.finishedCount;
      },
    },
  },
});
