var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table passable-form" },
    [
      _c(
        "v-form",
        { ref: "formPassable" },
        [
          _vm.isEdit === 3
            ? _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "end" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "btn-add-card" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("clickBackPassable")
                                }
                              }
                            },
                            [_vm._v("\n          戻る\n          ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" && _vm.loading === false
                    ? _c("v-col", {
                        staticClass: "mb-4 font-weight-bold mt-1 bg-success",
                        domProps: { innerHTML: _vm._s(_vm.successMessageLogs) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errorMessageLogs !== "" && _vm.loading === false
                    ? _c("v-col", {
                        staticClass: "mb-4 font-weight-bold mt-1 bg-error",
                        domProps: { innerHTML: _vm._s(_vm.errorMessageLogs) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "text-subtitle-2 border-left-blue font-weight-bold"
                        },
                        [
                          _vm._v("\n          設定情報\n          "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-help-circle")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2569386827
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "通行可能時間設定は、ALLIGATE Lock Proのゲートのみ設定が可能です"
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "pa-6 pr-10 mb-5" },
                    [
                      _c("div", { staticClass: "info-item " }, [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c(
                              "div",
                              { staticClass: "col col-2 passable-name" },
                              [
                                _c("div", { staticClass: "v-subheader" }, [
                                  _vm._v("時間帯設定名")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("input-form", {
                              staticClass: "timezone-name",
                              attrs: { inputType: "text" },
                              model: {
                                value: _vm.passableName,
                                callback: function($$v) {
                                  _vm.passableName = $$v
                                },
                                expression: "passableName"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "div",
                            { staticClass: "col col-2 passable-name" },
                            [
                              _c("div", { staticClass: "v-subheader" }, [
                                _vm._v("通行権限グループ名")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.groupListAdd,
                                  color: "green",
                                  "return-object": "",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                  "deletable-chips": "",
                                  multiple: "",
                                  "small-chips": "",
                                  "no-data-text": "",
                                  disabled: _vm.groupListAdd.length === 0
                                },
                                model: {
                                  value: _vm.inputGroup,
                                  callback: function($$v) {
                                    _vm.inputGroup = $$v
                                  },
                                  expression: "inputGroup"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "text-subtitle-2 border-left-blue font-weight-bold"
                        },
                        [
                          _vm._v("\n          設定情報\n          "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-help-circle")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2569386827
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "通行可能時間設定は、ALLIGATE Lock Proのゲートのみ設定が可能です"
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "page-edit" },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-6 pr-10 mb-5" },
                        [
                          _vm.dataTimes.length > 0
                            ? _c(
                                "div",
                                { staticClass: "ml-10 mt-5 input-time-zone" },
                                _vm._l(_vm.dataTimes, function(item, index) {
                                  return _c(
                                    "v-row",
                                    { key: index },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1", pa: "0" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "v-subheader justify-end theme--light"
                                            },
                                            [_vm._v(_vm._s(item.text))]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "2", pa: "0" } },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "content-class":
                                                  "background-white",
                                                "close-on-content-click": false,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                left: "",
                                                "min-width": "auto"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "input-time",
                                                                attrs: {
                                                                  id:
                                                                    "inputS-" +
                                                                    item.value,
                                                                  color:
                                                                    "green",
                                                                  outlined: "",
                                                                  dense: "",
                                                                  "prepend-inner-icon":
                                                                    "mdi-clock-time-four-outline",
                                                                  rules: [
                                                                    _vm.rulesValidate.validateStartTime(
                                                                      item.text,
                                                                      item.startTime,
                                                                      item.notSet,
                                                                      item.doSet,
                                                                      _vm.allDay
                                                                    )
                                                                  ],
                                                                  disabled:
                                                                    _vm.allDay,
                                                                  "hide-details":
                                                                    "auto"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.resetInput()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.startTime,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "startTime",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.startTime"
                                                                }
                                                              },
                                                              "v-text-field",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "mt-7 mr-7 ml-7 mb-3 text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "allig-select"
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items: _vm.hours,
                                                          color: "green",
                                                          outlined: "",
                                                          dense: ""
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            item.startTime = _vm.formatTime(
                                                              item.hourStart,
                                                              item.minuteStart
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value: item.hourStart,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "hourStart",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.hourStart"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mt-2 mr-2 ml-2 font-weight-bold"
                                                    },
                                                    [_vm._v(":")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "allig-select"
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items: _vm.minutes,
                                                          color: "green",
                                                          outlined: "",
                                                          dense: "",
                                                          "hide-details": "",
                                                          "full-width": ""
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            item.startTime = _vm.formatTime(
                                                              item.hourStart,
                                                              item.minuteStart
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            item.minuteStart,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "minuteStart",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.minuteStart"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-center pad17",
                                          attrs: { cols: "1", pa: "0" }
                                        },
                                        [_vm._v("~")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "2", pa: "0" } },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "content-class":
                                                  "background-white",
                                                "close-on-content-click": false,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                left: "",
                                                "min-width": "auto"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "input-time",
                                                                attrs: {
                                                                  id:
                                                                    "inputE-" +
                                                                    item.value,
                                                                  color:
                                                                    "green",
                                                                  outlined: "",
                                                                  dense: "",
                                                                  "prepend-inner-icon":
                                                                    "mdi-clock-time-four-outline",
                                                                  rules: [
                                                                    _vm.rulesValidate.validateEndTime(
                                                                      item.text,
                                                                      item.endTime,
                                                                      item.notSet,
                                                                      item.doSet,
                                                                      _vm.allDay,
                                                                      item.startTime
                                                                    )
                                                                  ],
                                                                  disabled:
                                                                    _vm.allDay,
                                                                  "hide-details":
                                                                    "auto"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.resetInput()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.endTime,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "endTime",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.endTime"
                                                                }
                                                              },
                                                              "v-text-field",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "mt-7 mr-7 ml-7 mb-3 text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "allig-select"
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items: _vm.hours,
                                                          color: "green",
                                                          outlined: "",
                                                          dense: ""
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            item.endTime = _vm.formatTime(
                                                              item.hourEnd,
                                                              item.minuteEnd
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value: item.hourEnd,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "hourEnd",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.hourEnd"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mt-2 mr-2 ml-2 font-weight-bold"
                                                    },
                                                    [_vm._v(":")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "allig-select"
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items: _vm.minutes,
                                                          color: "green",
                                                          outlined: "",
                                                          dense: "",
                                                          "hide-details": "",
                                                          "full-width": ""
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            item.endTime = _vm.formatTime(
                                                              item.hourEnd,
                                                              item.minuteEnd
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value: item.minuteEnd,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "minuteEnd",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.minuteEnd"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "3", pa: "0" } },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "5" } },
                                                [
                                                  _c("v-checkbox", {
                                                    staticClass:
                                                      "allig-checkbox",
                                                    attrs: {
                                                      label: "終日可能",
                                                      id:
                                                        "doCheck-" + item.value,
                                                      disabled: _vm.allDay,
                                                      "hide-details": "auto"
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.changeDoSet(
                                                          "notCheck-" +
                                                            item.value,
                                                          item.notSet,
                                                          item.doSet
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: item.doSet,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "doSet",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.doSet"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "5" } },
                                                [
                                                  _c("v-checkbox", {
                                                    staticClass:
                                                      "allig-checkbox",
                                                    attrs: {
                                                      label: "終日不可",
                                                      id:
                                                        "notCheck-" +
                                                        item.value,
                                                      disabled: _vm.allDay,
                                                      "hide-details": "auto"
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.changeDoSet(
                                                          "doCheck-" +
                                                            item.value,
                                                          item.doSet,
                                                          item.notSet
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: item.notSet,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "notSet",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.notSet"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("v-spacer", { staticClass: "mt-10" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mb-2 mt-2 btn-more",
                              attrs: { justify: "start" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary btn-toggle-more",
                                  attrs: { outlined: "", disabled: _vm.allDay },
                                  on: {
                                    click: function($event) {
                                      return _vm.advancedSetting(_vm.isAdvanced)
                                    }
                                  }
                                },
                                [
                                  _vm._v("\n          詳細設定\n          "),
                                  _c("v-icon", [
                                    _vm._v("mdi-menu-down-outline")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isAdvanced
                            ? _c(
                                "div",
                                {
                                  staticClass: "table-edit-time special-time "
                                },
                                [
                                  _vm.errorMessageTime !== "" &&
                                  _vm.allDay === false
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "mb-4 font-weight-bold mt-1 bg-error"
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(_vm.errorMessageTime) +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-data-table",
                                    {
                                      staticClass: "border-table",
                                      attrs: {
                                        "must-sort": "",
                                        headers: _vm.headers,
                                        items: _vm.dataSpecialTimes,
                                        "no-data-text": "データーはありません",
                                        "loading-text": "データを読み込中です",
                                        "hide-default-footer": ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function(row) {
                                              return [
                                                _c("tr", [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        row.item.dayTypeName
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    row.item.allDay === 1
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "span-time"
                                                          },
                                                          [_vm._v("終日可能")]
                                                        )
                                                      : row.item.allDay === 2
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "span-time"
                                                          },
                                                          [_vm._v("終日不可")]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "span-time"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.item
                                                                  .startTime
                                                              ) +
                                                                " ~ " +
                                                                _vm._s(
                                                                  row.item
                                                                    .endTime
                                                                )
                                                            )
                                                          ]
                                                        ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-3" },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: { icon: "" },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.deleteSpecialTime(
                                                                  row.item
                                                                    .dayTypeId
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "gray--text"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-trash-can-outline"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3895399424
                                      )
                                    },
                                    [
                                      _c("template", { slot: "body.prepend" }, [
                                        _c("tr", { staticClass: "first" }, [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "2" } },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-1" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        md: "2",
                                                        xl: "2"
                                                      }
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        staticClass:
                                                          "table-select",
                                                        attrs: {
                                                          items:
                                                            _vm.itemSpecialday,
                                                          "return-object": "",
                                                          label: _vm.$vuetify
                                                            .breakpoint.mdAndUp
                                                            ? ""
                                                            : "その他時間帯",
                                                          color: "green",
                                                          "hide-details":
                                                            "auto",
                                                          outlined: "",
                                                          dense: "",
                                                          "no-data-text": "",
                                                          disabled: _vm.allDay
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.inputSpecialday,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.inputSpecialday = $$v
                                                          },
                                                          expression:
                                                            "inputSpecialday"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.errorListGate !== ""
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "error-special"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.errorListGate
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "input-time"
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0",
                                                              attrs: {
                                                                cols: "2"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-menu",
                                                                {
                                                                  attrs: {
                                                                    "content-class":
                                                                      "background-white",
                                                                    "close-on-content-click": false,
                                                                    transition:
                                                                      "scale-transition",
                                                                    "offset-y":
                                                                      "",
                                                                    left: "",
                                                                    "min-width":
                                                                      "auto"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "v-text-field",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "input-time",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "green",
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      "prepend-inner-icon":
                                                                                        "mdi-clock-time-four-outline",
                                                                                      disabled:
                                                                                        _vm.allDay,
                                                                                      "hide-details":
                                                                                        "auto",
                                                                                      autocomplete:
                                                                                        "off",
                                                                                      readonly:
                                                                                        "",
                                                                                      onfocus:
                                                                                        "this.removeAttribute('readonly');"
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.resetInput()
                                                                                      }
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm.hoursStartSpecial,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.hoursStartSpecial = $$v
                                                                                      },
                                                                                      expression:
                                                                                        "hoursStartSpecial"
                                                                                    }
                                                                                  },
                                                                                  "v-text-field",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              )
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "p",
                                                                              {
                                                                                staticClass:
                                                                                  "error-special add hours"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.errorHoursStartSpecial
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    4005117647
                                                                  )
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      staticClass:
                                                                        "mt-7 mr-7 ml-7 mb-3 text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "allig-select"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                items:
                                                                                  _vm.hours,
                                                                                color:
                                                                                  "green",
                                                                                outlined:
                                                                                  "",
                                                                                dense:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.hoursStartSpecial = _vm.formatTime(
                                                                                    _vm.hourStart,
                                                                                    _vm.minuteStart
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.hourStart,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.hourStart = $$v
                                                                                },
                                                                                expression:
                                                                                  "hourStart"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mt-2 mr-2 ml-2 font-weight-bold"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            ":"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "allig-select"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                items:
                                                                                  _vm.minutes,
                                                                                color:
                                                                                  "green",
                                                                                outlined:
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                "hide-details":
                                                                                  "",
                                                                                "full-width":
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.hoursStartSpecial = _vm.formatTime(
                                                                                    _vm.hourStart,
                                                                                    _vm.minuteStart
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.minuteStart,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.minuteStart = $$v
                                                                                },
                                                                                expression:
                                                                                  "minuteStart"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-center ml-1 mr-1 mt-2",
                                                              attrs: {
                                                                cols: "1"
                                                              }
                                                            },
                                                            [_vm._v(" ~ ")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0",
                                                              attrs: {
                                                                cols: "2"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-menu",
                                                                {
                                                                  attrs: {
                                                                    "content-class":
                                                                      "background-white",
                                                                    "close-on-content-click": false,
                                                                    transition:
                                                                      "scale-transition",
                                                                    "offset-y":
                                                                      "",
                                                                    left: "",
                                                                    "min-width":
                                                                      "auto"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "v-text-field",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "input-time",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "green",
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      "prepend-inner-icon":
                                                                                        "mdi-clock-time-four-outline",
                                                                                      disabled:
                                                                                        _vm.allDay,
                                                                                      "hide-details":
                                                                                        "auto",
                                                                                      autocomplete:
                                                                                        "off",
                                                                                      readonly:
                                                                                        "",
                                                                                      onfocus:
                                                                                        "this.removeAttribute('readonly');"
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.resetInput()
                                                                                      }
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm.hoursEndSpecial,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.hoursEndSpecial = $$v
                                                                                      },
                                                                                      expression:
                                                                                        "hoursEndSpecial"
                                                                                    }
                                                                                  },
                                                                                  "v-text-field",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              )
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "error-special add hours"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.errorHoursEndSpecial
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    950849892
                                                                  )
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      staticClass:
                                                                        "mt-7 mr-7 ml-7 mb-3 text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "allig-select"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                items:
                                                                                  _vm.hours,
                                                                                color:
                                                                                  "green",
                                                                                outlined:
                                                                                  "",
                                                                                dense:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.hoursEndSpecial = _vm.formatTime(
                                                                                    _vm.hourEnd,
                                                                                    _vm.minuteEnd
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.hourEnd,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.hourEnd = $$v
                                                                                },
                                                                                expression:
                                                                                  "hourEnd"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mt-2 mr-2 ml-2 font-weight-bold"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            ":"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "allig-select"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                items:
                                                                                  _vm.minutes,
                                                                                color:
                                                                                  "green",
                                                                                outlined:
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                "hide-details":
                                                                                  "",
                                                                                "full-width":
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.hoursEndSpecial = _vm.formatTime(
                                                                                    _vm.hourEnd,
                                                                                    _vm.minuteEnd
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.minuteEnd,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.minuteEnd = $$v
                                                                                },
                                                                                expression:
                                                                                  "minuteEnd"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0 ml-2",
                                                              attrs: {
                                                                cols: "2"
                                                              }
                                                            },
                                                            [
                                                              _c("v-checkbox", {
                                                                staticClass:
                                                                  "allig-checkbox mt-1",
                                                                attrs: {
                                                                  label:
                                                                    "終日可能",
                                                                  "hide-details":
                                                                    "auto"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeDoSetSpecial()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.doSet,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.doSet = $$v
                                                                  },
                                                                  expression:
                                                                    "doSet"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0",
                                                              attrs: {
                                                                cols: "2"
                                                              }
                                                            },
                                                            [
                                                              _c("v-checkbox", {
                                                                staticClass:
                                                                  "allig-checkbox mt-1",
                                                                attrs: {
                                                                  label:
                                                                    "終日不可",
                                                                  "hide-details":
                                                                    "auto"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeNotSetSpecial()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.notSet,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.notSet = $$v
                                                                  },
                                                                  expression:
                                                                    "notSet"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-2 btn-add-time"
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "btn-secondary",
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    disabled:
                                                                      _vm.allDay,
                                                                    "hide-details":
                                                                      "auto"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.addSpecialTime()
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                追加\n                "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-row", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "error-special add hours"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errorAddListGate
                                                      )
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ])
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-10 mt-10",
                          attrs: { justify: "end" }
                        },
                        [
                          _vm.successMessageLogs !== "" && _vm.loading === false
                            ? _c("v-col", {
                                staticClass:
                                  "mb-4 font-weight-bold mt-1 bg-success messageRow bg-no",
                                attrs: { cols: "11", align: "right" },
                                domProps: {
                                  innerHTML: _vm._s(_vm.successMessageLogs)
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errorMessageLogs !== "" && _vm.loading === false
                            ? _c("v-col", {
                                staticClass:
                                  "mb-4 font-weight-bold mt-1 bg-error messageRow bg-no",
                                attrs: { cols: "11", align: "right" },
                                domProps: {
                                  innerHTML: _vm._s(_vm.errorMessageLogs)
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "messageRow",
                              attrs: { cols: "1", align: "right" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-primary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.clickRegisterPassable()
                                    }
                                  }
                                },
                                [_vm._v("\n            登録\n            ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isEdit === 2
            ? _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "end" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "btn-add-card" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("clickBackPassable")
                                }
                              }
                            },
                            [_vm._v("\n          戻る\n          ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _vm.successMessageLogs !== "" && _vm.loading === false
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mb-4 font-weight-bold mt-1 bg-success"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.successMessageLogs) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageLogs !== "" && _vm.loading === false
                        ? _c(
                            "v-col",
                            {
                              staticClass: "mb-4 font-weight-bold mt-1 bg-error"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errorMessageLogs) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "text-subtitle-2 border-left-blue font-weight-bold"
                        },
                        [
                          _vm._v("\n          設定情報\n          "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-help-circle")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2569386827
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "通行可能時間設定は、ALLIGATE Lock Proのゲートのみ設定が可能です"
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "pa-6 pr-10 mb-5" },
                    [
                      _c("div", { staticClass: "info-item " }, [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c(
                              "div",
                              { staticClass: "col col-2 passable-name" },
                              [
                                _c("div", { staticClass: "v-subheader" }, [
                                  _vm._v("時間帯設定名")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("input-form", {
                              staticClass: "timezone-name",
                              attrs: { inputType: "text" },
                              model: {
                                value: _vm.passableName,
                                callback: function($$v) {
                                  _vm.passableName = $$v
                                },
                                expression: "passableName"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "div",
                            { staticClass: "col col-2 passable-name" },
                            [
                              _c("div", { staticClass: "v-subheader" }, [
                                _vm._v("通行権限グループ名")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.groupListEdit,
                                  color: "green",
                                  "return-object": "",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                  "deletable-chips": "",
                                  multiple: "",
                                  "small-chips": "",
                                  "no-data-text": "",
                                  disabled: _vm.groupListEdit.length === 0
                                },
                                model: {
                                  value: _vm.inputGroup,
                                  callback: function($$v) {
                                    _vm.inputGroup = $$v
                                  },
                                  expression: "inputGroup"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "text-subtitle-2 border-left-blue font-weight-bold"
                        },
                        [
                          _vm._v("\n          設定情報\n          "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-help-circle")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2569386827
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "通行可能時間設定は、ALLIGATE Lock Proのゲートのみ設定が可能です"
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "page-edit" },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-6 pr-10 mb-5" },
                        [
                          _c(
                            "div",
                            { staticClass: "ml-10 mt-5 input-time-zone" },
                            _vm._l(_vm.dataTimes, function(item) {
                              return _c(
                                "v-row",
                                { key: item.dayTypeName },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "1", pa: "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "v-subheader justify-end theme--light"
                                        },
                                        [_vm._v(_vm._s(item.dayTypeName))]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2", pa: "0" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "content-class": "background-white",
                                            "close-on-content-click": false,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            left: "",
                                            "min-width": "auto"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "input-time",
                                                            attrs: {
                                                              color: "green",
                                                              outlined: "",
                                                              dense: "",
                                                              "prepend-inner-icon":
                                                                "mdi-clock-time-four-outline",
                                                              rules: [
                                                                _vm.rulesValidate.validateStartTime(
                                                                  item.dayTypeName,
                                                                  item.startTime,
                                                                  item.notSet,
                                                                  item.doSet,
                                                                  _vm.allDay
                                                                )
                                                              ],
                                                              disabled:
                                                                _vm.allDay,
                                                              "hide-details":
                                                                "auto"
                                                            },
                                                            model: {
                                                              value:
                                                                item.startTime,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "startTime",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.startTime"
                                                            }
                                                          },
                                                          "v-text-field",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "mt-7 mr-7 ml-7 mb-3 text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "allig-select" },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.hours,
                                                      color: "green",
                                                      outlined: "",
                                                      dense: ""
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        item.startTime = _vm.formatTime(
                                                          item.hourStart,
                                                          item.minuteStart
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: item.hourStart,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "hourStart",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.hourStart"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mt-2 mr-2 ml-2 font-weight-bold"
                                                },
                                                [_vm._v(":")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "allig-select" },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.minutes,
                                                      color: "green",
                                                      outlined: "",
                                                      dense: "",
                                                      "hide-details": "",
                                                      "full-width": ""
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        item.startTime = _vm.formatTime(
                                                          item.hourStart,
                                                          item.minuteStart
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: item.minuteStart,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "minuteStart",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.minuteStart"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-center pad17",
                                      attrs: { cols: "1", pa: "0" }
                                    },
                                    [_vm._v("~")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2", pa: "0" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "content-class": "background-white",
                                            "close-on-content-click": false,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            left: "",
                                            "min-width": "auto"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "input-time",
                                                            attrs: {
                                                              color: "green",
                                                              outlined: "",
                                                              dense: "",
                                                              "prepend-inner-icon":
                                                                "mdi-clock-time-four-outline",
                                                              rules: [
                                                                _vm.rulesValidate.validateEndTime(
                                                                  item.dayTypeName,
                                                                  item.endTime,
                                                                  item.notSet,
                                                                  item.doSet,
                                                                  _vm.allDay,
                                                                  item.startTime
                                                                )
                                                              ],
                                                              disabled:
                                                                _vm.allDay,
                                                              "hide-details":
                                                                "auto"
                                                            },
                                                            model: {
                                                              value:
                                                                item.endTime,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "endTime",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.endTime"
                                                            }
                                                          },
                                                          "v-text-field",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "mt-7 mr-7 ml-7 mb-3 text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "allig-select" },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.hours,
                                                      color: "green",
                                                      outlined: "",
                                                      dense: ""
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        item.endTime = _vm.formatTime(
                                                          item.hourEnd,
                                                          item.minuteEnd
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: item.hourEnd,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "hourEnd",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.hourEnd"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mt-2 mr-2 ml-2 font-weight-bold"
                                                },
                                                [_vm._v(":")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "allig-select" },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.minutes,
                                                      color: "green",
                                                      outlined: "",
                                                      dense: "",
                                                      "hide-details": "",
                                                      "full-width": ""
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        item.endTime = _vm.formatTime(
                                                          item.hourEnd,
                                                          item.minuteEnd
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: item.minuteEnd,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "minuteEnd",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.minuteEnd"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "3", pa: "0" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "5" } },
                                            [
                                              _c("v-checkbox", {
                                                staticClass: "allig-checkbox",
                                                attrs: {
                                                  label: "終日可能",
                                                  id:
                                                    "doCheck-" + item.dayTypeId,
                                                  disabled: _vm.allDay,
                                                  "hide-details": "auto"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.changeDoSet(
                                                      "notCheck-" +
                                                        item.dayTypeId,
                                                      item.notSet,
                                                      item.doSet
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: item.doSet,
                                                  callback: function($$v) {
                                                    _vm.$set(item, "doSet", $$v)
                                                  },
                                                  expression: "item.doSet"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "5" } },
                                            [
                                              _c("v-checkbox", {
                                                staticClass: "allig-checkbox",
                                                attrs: {
                                                  label: "終日不可",
                                                  id:
                                                    "notCheck-" +
                                                    item.dayTypeId,
                                                  disabled: _vm.allDay,
                                                  "hide-details": "auto"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.changeDoSet(
                                                      "doCheck-" +
                                                        item.dayTypeId,
                                                      item.doSet,
                                                      item.notSet
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: item.notSet,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "notSet",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.notSet"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("v-spacer", { staticClass: "mt-10" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mb-2 mt-2 btn-more",
                              attrs: { justify: "start" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary btn-toggle-more",
                                  attrs: { outlined: "", disabled: _vm.allDay },
                                  on: {
                                    click: function($event) {
                                      return _vm.advancedSetting(_vm.isAdvanced)
                                    }
                                  }
                                },
                                [
                                  _vm._v("\n          詳細設定\n          "),
                                  _c("v-icon", [
                                    _vm._v("mdi-menu-down-outline")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isAdvanced
                            ? _c(
                                "div",
                                { staticClass: "table-edit-time special-time" },
                                [
                                  _vm.errorMessageTime !== "" &&
                                  _vm.allDay === false
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "mb-4 font-weight-bold mt-1 bg-error"
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(_vm.errorMessageTime) +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-data-table",
                                    {
                                      staticClass: "border-table",
                                      attrs: {
                                        "must-sort": "",
                                        headers: _vm.headers,
                                        items: _vm.dataSpecialTimes,
                                        "no-data-text": "データーはありません",
                                        "loading-text": "データを読み込中です",
                                        "hide-default-footer": ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function(row) {
                                              return [
                                                _c("tr", [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        row.item.dayTypeName
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    row.item.allDay === 1
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "span-time"
                                                          },
                                                          [_vm._v("終日可能")]
                                                        )
                                                      : row.item.allDay === 2
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "span-time"
                                                          },
                                                          [_vm._v("終日不可")]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "span-time"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.item
                                                                  .startTime
                                                              ) +
                                                                " ~ " +
                                                                _vm._s(
                                                                  row.item
                                                                    .endTime
                                                                )
                                                            )
                                                          ]
                                                        ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "last-col ml-3"
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: { icon: "" },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.deleteSpecialTime(
                                                                  row.item
                                                                    .dayTypeId
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "gray--text"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-trash-can-outline"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        87691815
                                      )
                                    },
                                    [
                                      _c("template", { slot: "body.prepend" }, [
                                        _c("tr", { staticClass: "first" }, [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "2" } },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-1" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        md: "2",
                                                        xl: "2"
                                                      }
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        staticClass:
                                                          "table-select",
                                                        attrs: {
                                                          items:
                                                            _vm.itemSpecialday,
                                                          "return-object": "",
                                                          label: _vm.$vuetify
                                                            .breakpoint.mdAndUp
                                                            ? ""
                                                            : "その他時間帯",
                                                          color: "green",
                                                          "hide-details":
                                                            "auto",
                                                          outlined: "",
                                                          dense: "",
                                                          "no-data-text": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.inputSpecialday,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.inputSpecialday = $$v
                                                          },
                                                          expression:
                                                            "inputSpecialday"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.errorListGate !== ""
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "error-special"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.errorListGate
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "input-time"
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0",
                                                              attrs: {
                                                                cols: "2"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-menu",
                                                                {
                                                                  attrs: {
                                                                    "content-class":
                                                                      "background-white",
                                                                    "close-on-content-click": false,
                                                                    transition:
                                                                      "scale-transition",
                                                                    "offset-y":
                                                                      "",
                                                                    left: "",
                                                                    "min-width":
                                                                      "auto"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "v-text-field",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "input-time",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "green",
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      "prepend-inner-icon":
                                                                                        "mdi-clock-time-four-outline"
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm.hoursStartSpecial,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.hoursStartSpecial = $$v
                                                                                      },
                                                                                      expression:
                                                                                        "hoursStartSpecial"
                                                                                    }
                                                                                  },
                                                                                  "v-text-field",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              )
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "p",
                                                                              {
                                                                                staticClass:
                                                                                  "error-special hours"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.errorHoursStartSpecial
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    2476932953
                                                                  )
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      staticClass:
                                                                        "mt-7 mr-7 ml-7 mb-3 text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "allig-select"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                items:
                                                                                  _vm.hours,
                                                                                color:
                                                                                  "green",
                                                                                outlined:
                                                                                  "",
                                                                                dense:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.hoursStartSpecial = _vm.formatTime(
                                                                                    _vm.hourStart,
                                                                                    _vm.minuteStart
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.hourStart,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.hourStart = $$v
                                                                                },
                                                                                expression:
                                                                                  "hourStart"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mt-2 mr-2 ml-2 font-weight-bold"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            ":"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "allig-select"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                items:
                                                                                  _vm.minutes,
                                                                                color:
                                                                                  "green",
                                                                                outlined:
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                "hide-details":
                                                                                  "",
                                                                                "full-width":
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.hoursStartSpecial = _vm.formatTime(
                                                                                    _vm.hourStart,
                                                                                    _vm.minuteStart
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.minuteStart,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.minuteStart = $$v
                                                                                },
                                                                                expression:
                                                                                  "minuteStart"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-center ml-1 mr-1 mt-2",
                                                              attrs: {
                                                                cols: "1"
                                                              }
                                                            },
                                                            [_vm._v(" ~ ")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0",
                                                              attrs: {
                                                                cols: "2"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-menu",
                                                                {
                                                                  attrs: {
                                                                    "content-class":
                                                                      "background-white",
                                                                    "close-on-content-click": false,
                                                                    transition:
                                                                      "scale-transition",
                                                                    "offset-y":
                                                                      "",
                                                                    left: "",
                                                                    "min-width":
                                                                      "auto"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "v-text-field",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "input-time",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "green",
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      "prepend-inner-icon":
                                                                                        "mdi-clock-time-four-outline"
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm.hoursEndSpecial,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.hoursEndSpecial = $$v
                                                                                      },
                                                                                      expression:
                                                                                        "hoursEndSpecial"
                                                                                    }
                                                                                  },
                                                                                  "v-text-field",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              )
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "error-special hours"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.errorHoursEndSpecial
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    1422140722
                                                                  )
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      staticClass:
                                                                        "mt-7 mr-7 ml-7 mb-3 text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "allig-select"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                items:
                                                                                  _vm.hours,
                                                                                color:
                                                                                  "green",
                                                                                outlined:
                                                                                  "",
                                                                                dense:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.hoursEndSpecial = _vm.formatTime(
                                                                                    _vm.hourEnd,
                                                                                    _vm.minuteEnd
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.hourEnd,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.hourEnd = $$v
                                                                                },
                                                                                expression:
                                                                                  "hourEnd"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mt-2 mr-2 ml-2 font-weight-bold"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            ":"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "allig-select"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                items:
                                                                                  _vm.minutes,
                                                                                color:
                                                                                  "green",
                                                                                outlined:
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                "hide-details":
                                                                                  "",
                                                                                "full-width":
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.hoursEndSpecial = _vm.formatTime(
                                                                                    _vm.hourEnd,
                                                                                    _vm.minuteEnd
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.minuteEnd,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.minuteEnd = $$v
                                                                                },
                                                                                expression:
                                                                                  "minuteEnd"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0 ml-2",
                                                              attrs: {
                                                                cols: "2"
                                                              }
                                                            },
                                                            [
                                                              _c("v-checkbox", {
                                                                staticClass:
                                                                  "allig-checkbox mt-1",
                                                                attrs: {
                                                                  label:
                                                                    "終日可能",
                                                                  "hide-details":
                                                                    "auto"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeDoSetSpecial()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.doSet,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.doSet = $$v
                                                                  },
                                                                  expression:
                                                                    "doSet"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0",
                                                              attrs: {
                                                                cols: "2"
                                                              }
                                                            },
                                                            [
                                                              _c("v-checkbox", {
                                                                staticClass:
                                                                  "allig-checkbox mt-1",
                                                                attrs: {
                                                                  label:
                                                                    "終日不可",
                                                                  "hide-details":
                                                                    "auto"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeNotSetSpecial()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.notSet,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.notSet = $$v
                                                                  },
                                                                  expression:
                                                                    "notSet"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-2 btn-add-time"
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "btn-secondary",
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    disabled:
                                                                      _vm.allDay
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.addSpecialTime()
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                追加\n                "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-row", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "error-special add hours"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errorAddListGate
                                                      )
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ])
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-10 mt-10",
                          attrs: { justify: "end" }
                        },
                        [
                          _vm.successMessageLogs !== "" && _vm.loading === false
                            ? _c("v-col", {
                                staticClass:
                                  "mb-4 font-weight-bold mt-1 bg-success messageRow bg-no",
                                attrs: { cols: "11", align: "right" },
                                domProps: {
                                  innerHTML: _vm._s(_vm.successMessageLogs)
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errorMessageLogs !== "" && _vm.loading === false
                            ? _c("v-col", {
                                staticClass:
                                  "mb-4 font-weight-bold mt-1 bg-error messageRow bg-no",
                                attrs: { cols: "11", align: "right" },
                                domProps: {
                                  innerHTML: _vm._s(_vm.errorMessageLogs)
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "messageRow",
                              attrs: { cols: "1", align: "right" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-primary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.clickSavePassable()
                                    }
                                  }
                                },
                                [_vm._v("\n            登録\n            ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.isEdit === 1
            ? _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "end" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "btn-add-card" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("clickBackPassable")
                                }
                              }
                            },
                            [_vm._v("\n          戻る\n          ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "text-subtitle-2 border-left-blue font-weight-bold"
                        },
                        [
                          _vm._v("\n          設定情報\n          "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { icon: "" } },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-help-circle")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "通行可能時間設定は、ALLIGATE Lock Proのゲートのみ設定が可能です"
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "pa-6 pr-10 mb-5" },
                    [
                      _c("div", { staticClass: "info-item " }, [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c(
                              "div",
                              { staticClass: "col col-2 passable-name" },
                              [
                                _c("div", { staticClass: "v-subheader" }, [
                                  _vm._v("時間帯設定名")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      " text-body-2 align-self-center",
                                    attrs: { md: "3", sm: "6", cols: "12" }
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(_vm.passableName) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "div",
                            { staticClass: "col col-2 passable-name" },
                            [
                              _c("div", { staticClass: "v-subheader" }, [
                                _vm._v("通行権限グループ名")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c(
                                "v-row",
                                _vm._l(_vm.groupListByPassableTime, function(
                                  item
                                ) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: item.value,
                                      attrs: { md: "3", sm: "6", cols: "12" }
                                    },
                                    [
                                      _c("text-link", {
                                        attrs: { text: item.text },
                                        on: {
                                          click: function($event) {
                                            return _vm.goGroup(item.value)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "text-subtitle-2 border-left-blue font-weight-bold"
                        },
                        [
                          _vm._v("\n          設定情報\n          "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { icon: "" } },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-help-circle")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "通行可能時間設定は、ALLIGATE Lock Proのゲートのみ設定が可能です"
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-card", { staticClass: "pa-6 pr-5 mb-5" }, [
                    _c("div", { staticClass: "page-detail" }, [
                      _c(
                        "div",
                        { staticClass: "item-time-zone" },
                        _vm._l(_vm.dataTimes, function(item) {
                          return _c(
                            "div",
                            { key: item.name, staticClass: "info-item" },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col col-3" }, [
                                  _c("div", { staticClass: "v-subheader" }, [
                                    _vm._v(_vm._s(item.dayTypeName))
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-body-2 col align-self-center"
                                  },
                                  [
                                    item.allDay === 1
                                      ? _c("span", [_vm._v("終日可能")])
                                      : item.allDay === 2
                                      ? _c("span", [_vm._v("終日不可")])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(item.startTime) +
                                              " ~ " +
                                              _vm._s(item.endTime)
                                          )
                                        ])
                                  ]
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "item-time-zone" },
                    [
                      _c("v-data-table", {
                        staticClass: "border-table scroll-table",
                        attrs: {
                          "must-sort": "",
                          headers: _vm.headers,
                          items: _vm.dataSpecialTimes,
                          "no-data-text": "データーはありません",
                          "loading-text": "データを読み込中です",
                          "hide-default-footer": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function(row) {
                              return [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(_vm._s(row.item.dayTypeName))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { attrs: { colspan: "2" } }, [
                                    row.item.allDay === 1
                                      ? _c("span", [_vm._v("終日可能")])
                                      : row.item.allDay === 2
                                      ? _c("span", [_vm._v("終日不可")])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(row.item.startTime) +
                                              " ~ " +
                                              _vm._s(row.item.endTime)
                                          )
                                        ])
                                  ])
                                ])
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mb-10 mt-10", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit(
                                "clickEditPassable",
                                _vm.dataPassableDetail[0].passableTimeSettingId
                              )
                            }
                          }
                        },
                        [_vm._v("\n          編集\n      ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }