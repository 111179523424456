var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c("div", { staticClass: "main-home" }, [
        _c(
          "div",
          { staticClass: "container-home" },
          [
            _c("v-row", [
              _c(
                "p",
                { staticClass: "ml-10 text-subtitle-2 font-weight-bold ml" },
                [_vm._v("メインメニュー")]
              )
            ]),
            _vm._v(" "),
            _c(
              "v-row",
              { staticClass: "page-content page-button ml-2 item-1" },
              [
                _vm.authority.isAdmin === true ||
                _vm.authority.isAdminToViewUser === true
                  ? _c("router-link", { attrs: { to: { name: "user" } } }, [
                      _c("div", { staticClass: "item-button" }, [
                        _c(
                          "div",
                          { staticClass: "menu-item-button" },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "mt-2",
                                attrs: { justify: "center" }
                              },
                              [
                                _c("v-icon", { attrs: { size: "60" } }, [
                                  _vm._v("mdi-account")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-row", { attrs: { justify: "center" } }, [
                              _c(
                                "span",
                                {
                                  staticClass: "menu-description margin-top-5"
                                },
                                [_vm._v("ユーザーの登録・編集を行います。")]
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.authority.isAdmin === true
                  ? _c("router-link", { attrs: { to: { name: "card" } } }, [
                      _c("div", { staticClass: "item-button" }, [
                        _c(
                          "div",
                          { staticClass: "menu-item-button" },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "mt-2",
                                attrs: { justify: "center" }
                              },
                              [
                                _c("v-icon", { attrs: { size: "60" } }, [
                                  _vm._v("mdi-badge-account-horizontal-outline")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-row", { attrs: { justify: "center" } }, [
                              _c(
                                "span",
                                {
                                  staticClass: "menu-description margin-top-5"
                                },
                                [_vm._v("カードの登録・編集を行います。")]
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.authority.isAdmin === true ||
                _vm.authority.isAdminToViewGroup === true
                  ? _c("router-link", { attrs: { to: { path: "groups" } } }, [
                      _c("div", { staticClass: "item-button" }, [
                        _c(
                          "div",
                          { staticClass: "menu-item-button" },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "mt-2",
                                attrs: { justify: "center" }
                              },
                              [
                                _c("v-icon", { attrs: { size: "60" } }, [
                                  _vm._v("mdi-door-closed")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-row", { attrs: { justify: "center" } }, [
                              _c(
                                "span",
                                {
                                  staticClass: "menu-description margin-top-5"
                                },
                                [_vm._v("通行権限の登録・編集を行います。")]
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("router-link", { attrs: { to: { name: "log" } } }, [
                  _c("div", { staticClass: "item-button" }, [
                    _c(
                      "div",
                      { staticClass: "menu-item-button" },
                      [
                        _c(
                          "v-row",
                          { staticClass: "mt-2", attrs: { justify: "center" } },
                          [
                            _c("v-icon", { attrs: { size: "60" } }, [
                              _vm._v("mdi-format-list-bulleted")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-row", { attrs: { justify: "center" } }, [
                          _c(
                            "span",
                            { staticClass: "menu-description margin-top-5" },
                            [_vm._v("ログ情報の閲覧・CSV出力を行います。")]
                          )
                        ])
                      ],
                      1
                    )
                  ])
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("v-row", [
              _c(
                "p",
                { staticClass: "ml-10 text-subtitle-2 font-weight-bold" },
                [_vm._v("\n          お知らせ\n        ")]
              )
            ]),
            _vm._v(" "),
            _c(
              "v-row",
              { staticClass: "page-content page-button ml-2 item-2" },
              [
                _c("div", { staticClass: "item-button" }, [
                  _c(
                    "div",
                    {
                      staticClass: "menu-item-button",
                      on: {
                        click: function($event) {
                          return _vm.openPage(
                            "https://alligate.me/2024/05/29/2599/"
                          )
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/image/2022042803_icatch.png")
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "i-date" }, [
                        _vm._v("2024.05.29")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "title-cate" }, [
                        _vm._v("お知らせ")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "sum" }, [
                        _vm._v(
                          "【重要】臨時休業のお知らせ(2024/6/7)\n              "
                        )
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-button" }, [
                  _c(
                    "div",
                    {
                      staticClass: "menu-item-button",
                      on: {
                        click: function($event) {
                          return _vm.openPage(
                            "https://alligate.me/2023/08/08/2262/"
                          )
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/image/bio-idiom_icatch.png")
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "i-date" }, [
                        _vm._v("2023.08.08")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "title-cate" }, [
                        _vm._v("サービス連携")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "sum" }, [
                        _vm._v(
                          "NECの「Bio-IDiom スマートロックサービス」と連携\n              "
                        )
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-button" }, [
                  _c(
                    "div",
                    {
                      staticClass: "menu-item-button",
                      on: {
                        click: function($event) {
                          return _vm.openPage(
                            "https://alligate.me/2023/06/07/2250/"
                          )
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/image/AQUA_icatch.png")
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "i-date" }, [
                        _vm._v("2023.06.07")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "title-cate" }, [
                        _vm._v("サービス連携")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "sum" }, [
                        _vm._v(
                          "アクア株式会社の「Cloud IoTランドリーシステム」...\n              "
                        )
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-button" }, [
                  _c(
                    "div",
                    {
                      staticClass: "menu-item-button",
                      on: {
                        click: function($event) {
                          return _vm.openPage(
                            "https://alligate.me/2023/05/30/2244/"
                          )
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/image/TSEX_icatch.png")
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "i-date" }, [
                        _vm._v("2023.05.30")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "title-cate" }, [
                        _vm._v("サービス連携")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "sum" }, [
                        _vm._v("「TeamSpirit EX」と連携開始\n              ")
                      ])
                    ]
                  )
                ])
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }