var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("gate-edit-template", {
    attrs: {
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      gateDetail: _vm.gateDetail,
      groupList: _vm.groupList,
      groupId: _vm.groupId,
      loading: _vm.loading,
      dayTypes: _vm.dayTypes,
      loadingChild: _vm.loadingChild,
      dataUnlockingTime: _vm.dataUnlockingTime,
      dataUnlockingTimeConvert: _vm.dataUnlockingTimeConvert,
      errorMessageTime: _vm.errorMessageTime,
      groupsAddFail: _vm.groupsAddFail,
      isFinishEditGate: _vm.isFinishEditGate,
      listGateModel: _vm.listGateModel
    },
    on: {
      clickBackGate: function($event) {
        return _vm.clickBackGate($event)
      },
      clickUpdateGate: function($event) {
        return _vm.clickUpdateGate($event)
      },
      "loading-from-child": function($event) {
        return _vm.$emit("loading", $event)
      },
      showDialogImport: function($event) {
        return _vm.showDialogImport($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }