var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "v-overlay",
        {
          staticClass: "overlay-group-tab",
          attrs: { absolute: "", value: _vm.loading, color: "white" }
        },
        [
          _c("v-progress-circular", {
            staticClass: "user-loading-progress",
            attrs: { indeterminate: "", color: "green" }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }