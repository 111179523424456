var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-reflection-controller-table" },
    [
      _c(
        "v-row",
        { staticClass: "justify-end" },
        [
          _vm.authority.isAdmin
            ? _c(
                "v-btn",
                {
                  staticClass: "btn-secondary mt-3 mr-5",
                  attrs: { outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("clickEditController")
                    }
                  }
                },
                [_vm._v("\n          制御盤情報を編集する\n      ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-subtitle-2 border-left-blue font-weight-bold ml-3"
                },
                [_vm._v("\n          制御盤情報\n        ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "round-content-scroll vh75" },
            [
              _c("v-card", { staticClass: "pa-6 pt-5 pb-5 pr-10 mb-4" }, [
                _c(
                  "div",
                  { staticClass: "page-detail mb-0 pl-0 pr-0" },
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "title-display-block" }, [
                            _vm._v("制御盤名 ")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "weight-bold " }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.controlPanelDetail.controller.name)
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "title-display-block" }, [
                            _vm._v("制御盤モデル ")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "weight-bold" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.controlPanelDetail.controller.model)
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "col-12" },
                          [
                            _c("div", { staticClass: "title-display-block" }, [
                              _vm._v("制御盤に設定されているゲート ")
                            ]),
                            _vm._v(" "),
                            _vm.gateList.length > 0
                              ? _c(
                                  "v-row",
                                  _vm._l(_vm.gateList, function(item) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: item.value,
                                        attrs: { md: "3", sm: "6", cols: "12" }
                                      },
                                      [
                                        _c("text-link", {
                                          staticClass: "item-user-name",
                                          attrs: { text: item.text },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "clickGateDetail",
                                                item.value
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: " mt-3 mb-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text-subtitle-2 border-left-blue font-weight-bold pb-0"
                    },
                    [_vm._v("\n          アンチパス設定\n        ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card", { staticClass: "pa-6 pt-5 pb-0 pr-10 mb-4" }, [
                _c(
                  "div",
                  { staticClass: "page-detail mb-0" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pa-0 ma-0" },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "title-display-block" },
                                    [_vm._v("アンチパス設定 ")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "weight-bold " }, [
                                    _vm.controlPanelDetail.controller
                                      .antipassbackSetting !== undefined &&
                                    _vm.controlPanelDetail.controller
                                      .antipassbackSetting === 2
                                      ? _c("span", [_vm._v("ON")])
                                      : _c("span", [_vm._v("OFF")])
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "pa-0 ma-0" },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "title-display-block" },
                                    [_vm._v("アンチパスクリア時間 ")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "weight-bold " }, [
                                    _vm.controlPanelDetail.controller
                                      .antipassbackClearTime !== undefined
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.controlPanelDetail.controller
                                                .antipassbackClearTime
                                            )
                                          )
                                        ])
                                      : _c("span")
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pl-0 ", attrs: { cols: "9" } },
                          [
                            _c("v-combobox", {
                              staticClass: "list-card",
                              attrs: {
                                items: _vm.dataAllUses,
                                multiple: "",
                                "deletable-chips": "",
                                chips: "",
                                disabled:
                                  _vm.dataAllUses.length === 0 ||
                                  _vm.controlPanelDetail.controller
                                    .antipassbackSetting === undefined ||
                                  _vm.controlPanelDetail.controller
                                    .antipassbackSetting !== 2,
                                autocomplete: "off",
                                color: "green",
                                "hide-details": "auto",
                                outlined: "",
                                dense: "",
                                "search-input": _vm.searchInput,
                                "menu-props": {
                                  contentClass: "user-select-item"
                                }
                              },
                              on: {
                                "update:searchInput": function($event) {
                                  _vm.searchInput = $event
                                },
                                "update:search-input": function($event) {
                                  _vm.searchInput = $event
                                },
                                change: function($event) {
                                  return _vm.updateUserTags($event)
                                }
                              },
                              model: {
                                value: _vm.userIds,
                                callback: function($$v) {
                                  _vm.userIds = $$v
                                },
                                expression: "userIds"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-primary",
                                attrs: {
                                  disabled:
                                    _vm.dataAllUses.length === 0 ||
                                    _vm.controlPanelDetail.controller
                                      .antipassbackSetting === undefined ||
                                    _vm.controlPanelDetail.controller
                                      .antipassbackSetting !== 2
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("clickConfirmClear")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                      アンチパスクリア\n                      "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "text-note mb-5",
                            staticStyle: { "font-weight": "bolder" }
                          },
                          [
                            _vm._v(
                              "※ユーザーを選択しない場合、ゲートに紐づくユーザーのアンチパスがクリアされます。"
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: " mt-3 mb-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text-subtitle-2 border-left-blue font-weight-bold pb-0"
                    },
                    [_vm._v("\n          ATTOユーザー\n        ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card", { staticClass: "pa-6 pt-0 pb-0 pr-10 mb-3" }, [
                _c(
                  "div",
                  { staticClass: "page-detail mb-0" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { staticClass: "col-12 pl-0" }, [
                                  _c(
                                    "div",
                                    { staticClass: "title-display-block" },
                                    [_vm._v("ユーザーID ")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "weight-bold " }, [
                                    _vm.controlPanelDetail.user !== undefined &&
                                    _vm.controlPanelDetail.user.userId !==
                                      undefined
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.controlPanelDetail.user
                                                  .userId
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _c("span")
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { staticClass: "col-12 pl-0" }, [
                                  _c(
                                    "div",
                                    { staticClass: "title-display-block" },
                                    [_vm._v("ユーザー名 ")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "weight-bold " }, [
                                    _vm.controlPanelDetail.user !== undefined &&
                                    _vm.controlPanelDetail.user.name !==
                                      undefined
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.controlPanelDetail.user.name
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _c("span")
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { staticClass: "col-12 pl-0" }, [
                                  _c(
                                    "div",
                                    { staticClass: "title-display-block" },
                                    [_vm._v("パスワード ")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "weight-bold " }, [
                                    _vm._v(
                                      "\n                    *******\n                  "
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mb-0 mt-1", attrs: { justify: "end" } },
                [
                  _vm.authority.isAdmin
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-red",
                          attrs: {
                            outlined: "",
                            disabled: _vm.controlPanelDetail.controllerId === ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("clickReconfigure")
                            }
                          }
                        },
                        [_vm._v("\n          制御盤を再設定する\n      ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "common-modal" },
            [
              _c("ModalTitle", {
                attrs: { title: "制御盤の再設定" },
                on: {
                  click: function($event) {
                    return _vm.$emit("closeModal")
                  }
                }
              }),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-4 mb-10" },
                [
                  _vm.successMessageModal === "" && _vm.errorMessageModal === ""
                    ? _c("p", [
                        _vm._v("\n          制御盤の設定を一度クリアした後に "),
                        _c("br"),
                        _vm._v(
                          "\n          再度現在の設定を更新します。\n        "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _vm.successMessageModal !== ""
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mt-5 mb-4 font-weight-bold mt-1 bg-success"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.successMessageModal) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageModal !== ""
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mt-5 mb-4 font-weight-bold mt-1 bg-error"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.errorMessageModal) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _vm.errorMessageModal === "" && _vm.successMessageModal === ""
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c("success-button", {
                            attrs: {
                              text: "OK",
                              disabled:
                                _vm.controlPanelDetail.controllerId === ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "clickPostReconfigure",
                                  _vm.controlPanelDetail.controllerId
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c("success-button", {
                            attrs: { text: "OK" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("closeModal")
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Modal", {
        attrs: {
          title: "アンチパスクリア",
          smallScreen: "600px",
          className: "common-modal"
        },
        scopedSlots: _vm._u([
          {
            key: "modal-body",
            fn: function() {
              return [
                _vm._v(
                  "\n      チェックしたユーザーをアンチパスバッククリアしますか？ "
                ),
                _c("br"),
                _vm._v(
                  "\n      ※ユーザーを選択していない場合、ゲートに紐づくユーザーのアンチパスがクリアされます。\n    "
                )
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c("v-spacer"),
                _vm._v(" "),
                _c("success-button", {
                  attrs: { text: "OK" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("clickAntiPassBackClear", {
                        data: _vm.userIds,
                        controllerId: _vm.controlPanelDetail.controllerId
                      })
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.isModalClear,
          callback: function($$v) {
            _vm.isModalClear = $$v
          },
          expression: "isModalClear"
        }
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showProgress,
            callback: function($$v) {
              _vm.showProgress = $$v
            },
            expression: "showProgress"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v("ユーザーの関連付けをアンチパスバッククリア")
              ]),
              _vm._v(" "),
              _vm.totalDataRowProcess > 0
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-progress-linear",
                        {
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.progress,
                            callback: function($$v) {
                              _vm.progress = $$v
                            },
                            expression: "progress"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(Math.round(_vm.progress)) + "%")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.successItemProcess > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.totalDataRowProcess) +
                                "件中" +
                                _vm._s(_vm.successItemProcess) +
                                "件を追加しました。"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageProcess.length > 0
                        ? _c("div", [
                            _c("span", { staticClass: "error-box-title" }, [
                              _vm._v("追加失敗")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "error-box" },
                              [
                                _vm._l(_vm.errorMessageProcess, function(item) {
                                  return [
                                    _c("p", {
                                      key: item,
                                      domProps: { innerHTML: _vm._s(item) }
                                    })
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-progress-linear",
                        {
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.progress,
                            callback: function($$v) {
                              _vm.progress = $$v
                            },
                            expression: "progress"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(Math.round(_vm.progress)) + "%")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _vm.isFinishProcess
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c("success-button", {
                            attrs: { text: "OK" },
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "closeModalProgress",
                                  _vm.successItemProcess > 0 ? true : false
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }