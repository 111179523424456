





























































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import { Group } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import ModalTitle from '@/components/molecules/ModalTitle/ModalTitle.vue';
import ModalLoading from '@/components/molecules/ModalTitle/ModalLoading.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import * as Config from '@/config';
export interface TypeItem {
  title: string;
}
export interface TypeDessert {
  name: string;
  isVaild: boolean;
}
export interface TimeSettings {
  dayTypeId: number;
  dayTypeName: string;
  isAllDay: number;
  startTime: string;
  endTime: string;
}
export default Vue.extend({
  name: 'GateDetailTable',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    TextLink,
    ModalTitle,
    ModalLoading,
    Modal,
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    unlockTimes: {
      type: Array as PropType<TypeDessert[]>,
      default: () => [],
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    successMessageModal : {
      type: String,
      default: '',
    },
    errorMessageModal : {
      type: String,
      default: '',
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
    gateDetail: {
      default: {},
    },
    groupList: {
      default: [],
    },
    dayTypes: {
      type: Array,
      default: [],
    },
    dataUnlockingTime: {
      type: Array as PropType<TimeSettings[]>,
      default: [],
    },
    isFinishEditGate: {
      default: false,
    },
    showModal: {
      default: false,
    },
    showConfirm: {
      default: false,
    },
    isRemoteUnlockValid: {
      default: false,
    },
    isAdminOfRemoteUnlock: {
      default: false,
    },
    loading: {
      default: false,
    },
    authority: {
      type: Object,
      default: ({}),
    },
  },
  watch: {
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '曜日',
            align: 'start',
            sortable: false,
            value: 'date',
          },
          {
            text: '期間指定',
            align: 'start',
            sortable: false,
            value: 'time',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
    isConfirm: {
      get(): boolean {
        return this.showConfirm;
      },
      set(val: boolean): void {
        if (val === false) {
          this.$emit('closeModalConfirm');
        }
        return;
      },
    },
  },
  methods: {
    formatTime(hours: string, minute: number | string) {
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}`;
    },
    clickGroup( groupId: any ) {
      this.$emit('clickGroupDetail', groupId);
    },
  },
});
