





















import Vue, { PropType } from 'vue';
import { Auth, Hub } from 'aws-amplify';
import * as Config from '@/config';
import Login from '@/components/organisms/Login/Login.vue';
import { LoginInfo } from '@/types/alligate';
import auth from '@/auth';

export interface DataType {
  credentials: { ownerId: string; userId: string; password: string; mfaCode: string,
  resetCode: string, confirmPassword: string };
  loginProcess: number;
  error: string;
  federatedLoginServices: [];
  googleLoginInfo: { isValid: boolean; cognitoIdProviderName: string; };
  appleLoginInfo: { isValid: boolean; cognitoIdProviderName: string; };
  oneloginLoginInfo: { isValid: boolean; cognitoIdProviderName: string; };
  cognitoUser: {};
  mfaCode: string;
  loading: boolean;
  errorMessage: any;
  IpAddress: any;
}

export default Vue.extend({
  name: 'LoginPage',
  components: {
    Login,
  },
  props: {
    step: {
      type: Number,
      default: 0,
    },
  },
  data: (): DataType => ({
    credentials: {
      ownerId: '',
      userId: '',
      password: '',
      mfaCode: '',
      confirmPassword: '',
      resetCode: '',
    },
    loginProcess: 0,
    error: '',
    federatedLoginServices: [],
    googleLoginInfo: {
      isValid: false,
      cognitoIdProviderName: '',
    },
    appleLoginInfo: {
      isValid: false,
      cognitoIdProviderName: '',
    },
    oneloginLoginInfo: {
      isValid: false,
      cognitoIdProviderName: '',
    },
    cognitoUser: {},
    mfaCode: '',
    IpAddress: '',
    loading: false,
    errorMessage: {
      ownerId: '',
      userId: '',
      password: '',
      mfaCode: '',
      resetCode: '',
      confirmPassword: '',
    },
  }),
  mounted() {
    this.loginProcess = this.step;
    const checkCode = window.location.href.match(/code=/);
    if (checkCode) {
      this.loading = true;
    }
    const getCode = this.$route.query.status;
    if (getCode !== '' && getCode !== undefined) {
      this.errorMessage.ownerId = 'ログインに失敗しました。ユーザー登録状況について管理者にお問い合わせください。';
    }
    this.getIPAddress();
  },
  created() {
    // ($('.main-content') as any).append('<div id="loading"><div class="loadingMsg"></div></div>');
    Hub.listen('auth', this.authEventListener);
  },
  methods: {
    authEventListener(data: any) {
      console.log(data);
      // ($('.main-content') as any).append('<div id="loading"><div class="loadingMsg"></div></div>');
      switch (data.payload.event) {
        case 'signIn':
          auth.saveCurrentAuthenticatedUser();
          break;
        case 'signIn_failure':
          auth.errorAuthenticatedUser();
          break;
        default:
          break;
      }
    },
    goPageLogin() {
      this.errorMessage = {
        ownerId: '',
        userId: '',
        password: '',
        mfaCode: '',
        resetCode: '',
        confirmPassword: '',
      };
      this.loginProcess = 0;
      this.federatedLoginServices = [];
    },
    next(ownerId: string) {
      const ID =  ownerId;
      const data = {
        ownerId: ID,
      };
      if (this.validateBeforeSubmit(1, data)) {
        // const newURL = location.href.split('?')[0];
        // window.history.pushState('object', document.title, newURL);
        this.submitOwner(ownerId);
      }
    },
    confirmSignin(data: any) {
      if (this.validateBeforeSubmit(2, data)) {
        this.loading = true;
        Auth.confirmSignIn(this.cognitoUser, data.mfaCode, 'SMS_MFA')
          .then((user) => {
            console.log(user);
          })
          .catch((error) => {
            this.loading = false;
            this.errorMessage.mfaCode = '認証コードが間違っています。';
          });
      }
    },
    forgotPassword(data: LoginInfo) {
      this.loginProcess = 4;
    },
    formSubmitForgot(data: LoginInfo) {
      this.validateBeforeSubmit(4, data);
    },
    formSubmit(data: LoginInfo) {
      this.validateBeforeSubmit(3, data);
    },
    signIn(data: LoginInfo) {
      if (this.validateBeforeSubmit(3, data)) {
        this.messageHandlerSignIn(data);
      }
    },
    forgot(data: LoginInfo) {
      if (this.validateBeforeSubmit(4, data)) {
        this.loading = true;
        const credentials = {
          ownerId: data.ownerId,
          userId: data.username,
        };
        this.$http.post(Config.FORGOT_PASSWORD_API, JSON.stringify(credentials))
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.loading = false;
            // this.message = Config.FORGOT_API_SUCCESS_SEND_RESET_CODE
            // owserIdとuserIdの値をlocalstorageに保存する
            localStorage.setItem('ownerId', credentials.ownerId);
            localStorage.setItem('userId', credentials.userId);
            this.loginProcess = 5;
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          this.errorMessage.userId = '入力情報が間違ってます。';
          this.federatedLoginServices = [];
        });
      }
    },
    resetPass(data: any) {
      if (this.validateBeforeSubmit(5, data.credentials)) {
        this.loading = true;
        const credentials = {
          ownerId: data.credentials.ownerId,
          userId: data.credentials.username,
          resetCode: data.credentials.resetCode,
          newPassword: data.credentials.password,
        };
        console.log('data', data);
        this.$http.post(Config.RESET_PASSWORD_API, JSON.stringify(credentials))
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.loading = false;
            // this.message = Config.FORGOT_API_SUCCESS_SEND_RESET_CODE
            // owserIdとuserIdの値をlocalstorageに保存する
            localStorage.setItem('ownerId', this.credentials.ownerId);
            localStorage.setItem('userId', this.credentials.userId);
            this.loginProcess = 6;
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          switch (err.status) {
            case 400:
              this.error = '入力したパラメーターが誤っています。入力内容をご確認ください。';
              break;
            case 444:
              this.error = '事業者ID、ユーザーIDもしくはリセットコードが間違っています。';
              break;
            case 445:
              this.error = '事業者ID、ユーザーIDもしくはリセットコードが間違っています。';
              break;
            case 500:
              this.error = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
            case 503:
              this.error = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
            default:
              this.error = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
          }
        });
      }
    },
    messageHandlerSignIn(infoUser: LoginInfo) {
      const username  = infoUser.username + '\\' + infoUser.ownerId;
      const password = infoUser.password;
      // this.dispLoading();
      localStorage.setItem('signIn', 'true');
      localStorage.setItem('ownerId', infoUser.ownerId);
      this.loading = true;
      Auth.signIn(
        username,
        password,
        {ownerId: infoUser.ownerId, ipAddress: this.IpAddress},
      )
        .then((user) => {
          this.loading = false;
          return auth.saveCurrentAuthenticatedUser()
            .then(() => {
              if (user.challengeName === 'SMS_MFA') {
          // 二段階認証がONの場合
                this.cognitoUser = user;
                this.loginProcess = 3;
                this.errorMessage = {
                  ownerId: '',
                  userId: '',
                  password: '',
                  mfaCode: '',
                  resetCode: '',
                  confirmPassword: '',
                };
              } else {
                this.$router.push({ path: 'dashboard'});
              }
            });
        })
        .catch((error) => {
          this.loading = false;
          if (error.message.indexOf('PreAuthentication failed with error') !== -1) {
            this.errorMessage.password = 'ご使用の環境からはご利用出来ません';
          } else {
            this.errorMessage.password = '入力情報が間違ってます。';
          }
          // $('div#loading').remove()
        });
    },
    signInWithIdentityProvider(provider: any) {
      this.setStartLogin()
          .then((_) => {
            Auth.federatedSignIn({provider});
            });
    },
    submitOwner(ownerId: string) {
      this.loading = true;
      this.dispLoading();
      this.$http.get(Config.OWNER_LOGIN_METHOD_API + '?ownerId=' + ownerId)
        .then((response: any) => {
          this.loading = false;
          this.error = '';
          localStorage.setItem('ownerId', this.credentials.ownerId);
          this.credentials.ownerId = this.credentials.ownerId;
          this.credentials.userId = '';
          this.credentials.password = '';
          this.loginProcess = 2;
          this.federatedLoginServices = response.body.federatedLoginServices;
          this.getFederatedLoginServices();
        })
        .then( null, (err: any) => {
          this.loading = false;
          this.errorMessage.ownerId = '事業者IDが間違っています。';
          this.federatedLoginServices = [];
        });
    },
    getFederatedLoginServices() {
      this.googleLoginInfo.isValid = false;
      this.googleLoginInfo.cognitoIdProviderName = '';
      this.appleLoginInfo.isValid = false;
      this.appleLoginInfo.cognitoIdProviderName = '';
      this.oneloginLoginInfo.isValid = false;
      this.oneloginLoginInfo.cognitoIdProviderName = '';
      const google: any = this.federatedLoginServices.find((item: any) => item.name === 'Google');
      const apple: any = this.federatedLoginServices.find((item: any) => item.name === 'Apple');
      const onelogin: any = this.federatedLoginServices.find((item: any) => item.name === 'Onelogin');
      if (google) {
        this.googleLoginInfo.isValid = true;
        this.googleLoginInfo.cognitoIdProviderName = google.cognitoIdProviderName;
      }
      if (apple) {
        this.appleLoginInfo.isValid = true;
        this.appleLoginInfo.cognitoIdProviderName = apple.cognitoIdProviderName;
      }
      if (onelogin) {
        this.oneloginLoginInfo.isValid = true;
        this.oneloginLoginInfo.cognitoIdProviderName = onelogin.cognitoIdProviderName;
      }
    },
    setStartLogin() {
      return new Promise((resolve, _) => {
        const authStore = this.$store.state.auth;
        authStore.isLoginChallenge = true;
        this.$store.commit('UPDATE_AUTH', authStore);
        resolve(null);
      });
    },
    validateBeforeSubmit(loginProcess: number, data: any) {
      switch (loginProcess) {
        case 1:
          if (data.ownerId === null || data.ownerId === '') {
            this.errorMessage.ownerId = '事業者IDは必須項目です。';
            return false;
          }
          this.errorMessage.ownerId = '';
          return true;
        case 2:
          if (data.mfaCode === null || data.mfaCode === '') {
            this.errorMessage.mfaCode = '認証コードは必須項目です。';
            return false;
          } else {
            this.errorMessage.mfaCode = '';
            return true;
          }
        case 3:
          let check = true;
          if (data.ownerId === null || data.ownerId === '') {
            this.errorMessage.ownerId = '事業者IDは必須項目です。';
            check = false;
          } else {
            this.errorMessage.ownerId = '';
          }
          if (data.username === null || data.username === '') {
            this.errorMessage.userId = 'ユーザーIDは必須項目です。';
            check = false;
          } else {
            this.errorMessage.userId = '';
          }
          if (data.password === null || data.password === '') {
            this.errorMessage.password = 'パスワードは必須項目です。';
            check = false;
          } else {
            this.errorMessage.password = '';
          }
          return check;
        case 4:
          let checkF = true;
          if (data.ownerId === null || data.ownerId === '') {
            this.errorMessage.ownerId = '事業者IDは必須項目です。';
            checkF = false;
          } else {
            this.errorMessage.ownerId = '';
          }
          if (data.username === null || data.username === '') {
            this.errorMessage.userId = 'ユーザーIDは必須項目です。';
            checkF = false;
          } else {
            this.errorMessage.userId = '';
          }
          return checkF;
        case 5:
          let checkR = true;
          if (data.ownerId === null || data.ownerId === '') {
            this.errorMessage.ownerId = '事業者IDは必須項目です。';
            checkR = false;
          } else {
            this.errorMessage.ownerId = '';
          }
          if (data.resetCode === null || data.resetCode === '') {
            this.errorMessage.resetCode = 'リセットコードは必須項目です。';
            checkR = false;
          } else {
            this.errorMessage.resetCode = '';
          }
          if (data.username === null || data.username === '') {
            this.errorMessage.userId = 'ユーザーIDは必須項目です。';
            checkR = false;
          } else {
            this.errorMessage.userId = '';
          }
          if (data.password === null || data.password === '') {
            this.errorMessage.password = 'パスワードは必須項目です。';
            check = false;
          } else if (!data.password.match('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$')) {
            this.errorMessage.password = 'ログインパスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください';
            check = false;
          } else {
            this.errorMessage.password = '';
          }
          if (data.password !== data.confirmPassword) {
            this.errorMessage.confirmPassword = '「新しいパスワード」と一致しません。';
            check = false;
          } else {
            this.errorMessage.confirmPassword = '';
          }
          return checkR;
        default:
          return true;
      }
    },
    dispLoading() {
      // $('.main-content').append('<div id="loading"><div class="loadingMsg"></div></div>')
    },
    getIPAddress() {
      return fetch('https://api.ipify.org?format=json')
        .then((x) => x.json())
        .then(({ ip }) => {
          this.IpAddress = ip;
        })
        .catch((err: any) => console.log(`error: ${err}`));
    },
  },
});
